import * as Page from './style';
import { Spacer } from '../components';

import { logoGcSmall, logo3NSmall } from '../assets/png';
import { useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { ProductEnum } from '../models/OrganizationData';
import { AuthenticatedWizard, authenticatedWizardKey } from '../services/authenticated';

const CancellationPolicy = () => {
  const authWizard = useService<AuthenticatedWizard>(authenticatedWizardKey);
  const authState = useObservable(authWizard.state$);
  const product = authState?.product;
  const logo = product === ProductEnum.GuidedChoice ? logoGcSmall : logo3NSmall;
  return (
    <Page.Container>
      <Page.LogoContainer>
        <img style={{ maxWidth: '220px' }} src={logo} alt='Logo' />
      </Page.LogoContainer>
      <Spacer height='s25' />
      <Page.Header>Cancellation and Refund Policy</Page.Header>
      <Spacer height='xs' />
      <Page.PolicyText>
        You may cancel your subscription at any time, though no refunds will be made for the time
        remaining on your current subscription period.
      </Page.PolicyText>
      <Spacer height='xs' />
      <Page.PolicyText>
        Refunds on new subscriptions are available only if you have not yet completed the setup of
        your account.
      </Page.PolicyText>
      <Spacer height='xs' />
      <Page.PolicyText>
        If you have questions or would like to request a refund, please contact
        GuidedChoice/3Nickels Customer Service at 1-800-774-7459, Monday thru Friday, 8:00 a.m. to
        5:00 p.m. Pacific Time, help@guidedchoice.com, or help@3nickels.com.
      </Page.PolicyText>
    </Page.Container>
  );
};

export default CancellationPolicy;
