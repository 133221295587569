import {
  Drawer,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Paper,
  Typography,
  Link,
} from '@mui/material';
import { Row, Spacer } from '.';
import { useLocation, useNavigate } from 'react-router-dom';

interface SideNavDrawerProps extends React.PropsWithChildren {
  sidebarItems: any[];
  secondDividerIndex?: number;
  name?: string;
  clickableName?: string;
}

const SideNavDrawer: React.FC<SideNavDrawerProps> = ({
  sidebarItems,
  secondDividerIndex,
  name,
  clickableName,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      style={{
        display: 'flex',
        width: '297px',
        height: '100%',
        flexShrink: 0,
        zIndex: 2,
      }}>
      <Row>
        <Paper
          style={{
            backgroundColor: '#038ab7',
            width: '35px',
            minHeight: '100vh',
            height: '100%',
            borderRadius: 0,
            position: 'fixed',
          }}
        />
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              left: '35px',
              boxSizing: 'border-box',
              backgroundColor: 'rgba(231, 243, 249, 0.41)',
              color: '#4a606a',
            },
          }}
          variant='permanent'
          anchor='left'>
          <Box>
            <Toolbar />
            <List style={{ padding: 0 }}>
              <Spacer height='xs' />
              {name && (
                <Typography
                  sx={{ marginLeft: '20px', maxWidth: '220px', display: 'block' }}
                  noWrap={true}
                  variant='p16SemiBold'
                  color='secondary.main'>
                  {name}
                </Typography>
              )}
              {clickableName && (
                <Link
                  className='underlineOnHover'
                  sx={{ marginLeft: '20px', maxWidth: '220px', display: 'block' }}
                  noWrap={true}
                  variant='p16SemiBold'
                  color='secondary.main'
                  onClick={() => navigate(sidebarItems[0].pathname)}>
                  {clickableName}
                </Link>
              )}
              <Spacer height='sm' />
              {sidebarItems.map(
                (item: any, index: any) =>
                  item && (
                    <div key={index}>
                      <NavItem
                        pathname={item.pathname}
                        label={item.label}
                        artDefault={item.artDefault}
                        artActive={item.artActive}
                      />
                      <Spacer height='xs' />
                      {(index === 0 || index === secondDividerIndex) && (
                        <>
                          <Divider
                            style={{
                              backgroundColor: '#038ab7',
                              width: '85%',
                              height: '3px',
                              borderRadius: '8px',
                              justifyContent: 'space-around',
                              margin: 'auto',
                            }}
                          />
                          <Spacer height='xs' />
                        </>
                      )}
                    </div>
                  ),
              )}
            </List>
          </Box>
        </Drawer>
      </Row>
    </Box>
  );
};
export interface NavItemProps {
  pathname: string;
  label: string;
  artDefault: JSX.Element;
  artActive: JSX.Element;
}

const NavItem: React.FC<NavItemProps> = ({ pathname, label, artDefault, artActive }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNav = () => {
    navigate(pathname);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={{ paddingLeft: '20px', paddingRight: '20px', flexShrink: 0 }}
        onClick={handleNav}>
        <ListItemIcon sx={{ minWidth: '40px' }}>
          {location.pathname === pathname ? artActive : artDefault}
        </ListItemIcon>
        <ListItemText
          sx={{ minWidth: '182px' }}
          primaryTypographyProps={{
            color: `${location.pathname === pathname ? 'primary' : 'secondary'}`,
            variant: 'p20SemiBold',
          }}
          primary={label}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SideNavDrawer;
