import { Link, Typography, useTheme } from '@mui/material';
import React from 'react';
import Column from '../../components/Column';

interface ContractFooterProps {
  meteredPayment: boolean;
  autoPay: boolean | undefined;
  orgPays: boolean;
  prePaid: boolean;
  invoiceDate: string;
  tosLink: string | undefined;
}

const ContractFooter: React.FC<ContractFooterProps> = ({
  meteredPayment,
  autoPay,
  orgPays,
  prePaid,
  invoiceDate,
  tosLink,
}) => {
  const theme = useTheme();

  const invoiceMessage =
    orgPays &&
    (prePaid ? !autoPay : true) &&
    'An invoice will be ' +
      (autoPay ? 'generated' : 'sent') +
      (' on the ' + invoiceDate.toLowerCase() + ' for any new or renewing licenses') +
      (!autoPay ? ' and will be due upon receipt.' : '.') +
      (meteredPayment && autoPay
        ? ' It will be automatically paid by the specified default payment.'
        : '');

  const additionalDetails = (
    <>
      {' '}
      Additional details are available in our{' '}
      <Link href={tosLink} target='_blank'>
        Terms of Service
      </Link>
      {orgPays && (
        <>
          {' and '}
          <Link href='/cancellation-policy' target='_blank'>
            Cancellation and Refund Policy
          </Link>
        </>
      )}
      .
    </>
  );

  return (
    <Column style={{ flex: 1, width: '70%', alignSelf: 'center' }}>
      <Column style={{ flex: 1, width: '100%' }}>
        <Typography variant='p14' color='info.main'>
          <span style={{ color: theme.palette.secondary.main }}>* </span>
          {invoiceMessage}
          {additionalDetails}
        </Typography>
      </Column>
    </Column>
  );
};

export default ContractFooter;
