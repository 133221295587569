import { Card, CardContent, Checkbox, Grid, Typography } from '@mui/material';

interface AutoPayAgreementProps {
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const AutoPayAgreement: React.FC<AutoPayAgreementProps> = ({ setChecked }) => {
  return (
    <Grid>
      <Card variant='innerCard' style={{ paddingRight: '20px' }}>
        <CardContent>
          <Grid container wrap='nowrap'>
            <Grid item>
              <Checkbox onClick={() => setChecked(prev => !prev)} />
            </Grid>
            <Grid item mt={1}>
              <Typography variant='p16' color='secondary.dark'>
                <strong>
                  Yes, set up auto-pay. I am aware of and understand the information detailed above.
                </strong>{' '}
                By selecting the ‘Set up auto-pay’ button below, I authorize the financial
                institution for the payment method specified above to charge the account and remit
                payment to GuidedChoice until auto-pay is canceled via the link on the Payment page
                in the Partner Portal; provided, however, that no termination of authorization will
                relieve my organization of any obligation to pay amounts due for services my
                organization has received. I represent and warrant that I have full authority to
                enter into this agreement and make the representations herein.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AutoPayAgreement;
