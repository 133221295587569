import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { MemberData, MembersApi } from '../../api/apis/MembersApi';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { combineLatest, map } from 'rxjs';
import { OrganizationData } from '../../models/OrganizationData';

export interface MemberCompartments {
  members: DataCompartmentOptions<MemberData[]>;
}

export const membersStorageKey = 'data/members';

export class MemberDataCachePolicy extends DataCachePolicy<MemberCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.Members)
    private readonly membersApi: MembersApi,
  ) {
    super(membersStorageKey);
  }

  buildDataCache() {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(map(([isAuthenticated, orgData]) => isAuthenticated === true && orgData !== undefined));
    return createObservedDataCache<MemberCompartments>({
      members: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.membersApi.getMembers();
          return data;
        }),
        defaultValue: [],
      },
    });
  }
}

export const withMemberData = createServiceModule(membersStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, MemberDataCachePolicy);
});
