import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { TeamMetricsData } from '../../models/MetricsData';

export class TeamMetricsApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<TeamMetricsData>> {
    return this.axios.get('team-metrics');
  }

  getAll(): Promise<AxiosResponse<TeamMetricsData[]>> {
    return this.axios.get('team-metrics/all/by-group-id');
  }
}
