import { LicenseCompartments, licenseStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { LicenseAgreementApi, LicenseAgreementData } from '../../../api/apis/LicenseAgreementApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { OrganizationCompartments, organizationStorageKey } from '../../organization';
import { PricebookCompartments, pricebookStorageKey } from '../../pricebook';
import { ProductTypeCompartments, productTypeStorageKey } from '../../productType';

export class InvalidateLicenseAgreement implements IMutation<LicenseAgreementData> {
  async execute(context: MutationContext<LicenseAgreementData>): Promise<void> {
    const { data, storage, container } = context;
    const licenseAgreementApi = container.get<LicenseAgreementApi>(ApiKeys.LicenseAgreement);

    await licenseAgreementApi.invalidate(data.id);

    const cache = storage.retrieve<LicenseCompartments>(licenseStorageKey);
    const pricebookDataCache = storage.retrieve<PricebookCompartments>(pricebookStorageKey);
    const productTypeDataCache = storage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
    const organizationDataCache =
      storage.retrieve<OrganizationCompartments>(organizationStorageKey);

    await cache.reloadAll();
    await pricebookDataCache.reload('pricebookSelections');
    await productTypeDataCache.reload('paidBy');
    await organizationDataCache.reload('allMetrics');
  }
}

export class AcceptLicenseAgreement implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const licenseAgreementApi = container.get<LicenseAgreementApi>(ApiKeys.LicenseAgreement);

    await licenseAgreementApi.accept(data);

    const cache = storage.retrieve<LicenseCompartments>(licenseStorageKey);
    const organizationDataCache =
      storage.retrieve<OrganizationCompartments>(organizationStorageKey);

    await cache.reloadAll();
    await organizationDataCache.reload('allMetrics');
  }
}
