import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';

import { ApiServices } from '../ApiServices';
import { OrgDomain } from '../../models/OffboardingData';

export class RestrictionApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  post(data: OrgDomain[]): Promise<AxiosResponse<OrgDomain>> {
    return this.axios.post(`org-domain/all`, data);
  }

  delete(id: string): Promise<AxiosResponse<OrgDomain>> {
    return this.axios.delete(`org-domain/${id}`);
  }

  get(): Promise<AxiosResponse<OrgDomain[]>> {
    return this.axios.get(`org-domain`);
  }
}
