interface Props extends React.PropsWithChildren {
    style?: {};
}

const Column: React.FC<Props> = props => {
 return (
    <div {...props} style={{ display: 'flex', flexDirection: 'column', ...props.style}}>{props.children}</div>
 );
}

export default Column;