import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import ProgressCards, { ProgressItem } from './ProgressCards';
import { AnyReportType, ReportProps } from '../../../models/ReportData';
import { ProductTypeEnum } from '../../../models/PricebookData';
import { Spacer } from '../../../components';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import { ReactNode, useEffect, useState } from 'react';
import { progressTracker3Nickels } from './Progress3nickels';
import { AvgProgress3nuHelp, progressTracker3NU } from './Progress3nu';
import { progressTrackerGuidedChoice } from './ProgressGc';
import { formatNumber } from '../../../helpers/utilityFunctions';
import { Bold } from '../../../components/Bold';

interface CalculatedProgressData {
  title: string;
  totalUsers: number;
  notStarted: number;
  inProgress: ProgressItem[];
  completed: number;
  avgProgress?: number;
  helpText: string;
  helpContent: ReactNode;
}

export type ProgressCalculator = (report: AnyReportType) => CalculatedProgressData;

const progressCalculators: Record<string, ProgressCalculator> = {
  [ProductTypeEnum.NickelsU]: progressTracker3NU,
  [ProductTypeEnum.Nickels]: progressTracker3Nickels,
  [ProductTypeEnum.GuidedChoice]: progressTrackerGuidedChoice,
};

interface ReportProgressTrackerProps extends ReportProps {
  productType: ProductTypeEnum;
  guidedChoiceOnly?: boolean;
}

const ReportProgressTracker: React.FC<ReportProgressTrackerProps> = ({
  report,
  productType,
  guidedChoiceOnly,
}) => {
  const theme = useTheme();
  const [data, setData] = useState<CalculatedProgressData | null>(null);
  const gcOnly = guidedChoiceOnly && productType === ProductTypeEnum.GuidedChoice;
  const is3nu = productType === ProductTypeEnum.NickelsU;
  const is3n = productType === ProductTypeEnum.Nickels;
  const isGc = productType === ProductTypeEnum.GuidedChoice;

  useEffect(() => {
    if (productType) {
      const calculator = progressCalculators[productType];

      if (calculator) {
        const calculatedData = calculator(report);
        setData(calculatedData);
      }
    }
  }, [report, productType]);

  if (!data) {
    return null;
  }

  const {
    title,
    helpContent,
    helpText,
    notStarted,
    inProgress,
    completed,
    totalUsers,
    avgProgress,
  } = data;

  const subHeaderComponents = [
    <Grid display='inherit'>
      <Typography variant='p16' color='secondary.main'>
        {helpText}
      </Typography>
      <Spacer width='xxxs' />
      <Tippy placement='right' content={helpContent} theme='tippy-rounded'>
        <HelpOutline style={{ color: theme.palette.info.main }} />
      </Tippy>
    </Grid>,

    <Spacer width='xxs' />,

    <Typography variant='p24Bold' color='secondary.main'>
      {formatNumber(totalUsers)}
    </Typography>,
  ];

  const statusComponents = [
    <Grid
      xs={gcOnly ? true : 1.5}
      display='flex'
      flex={1}
      flexDirection='column'
      whiteSpace='nowrap'>
      <Typography variant='p16' color='secondary.main'>
        {isGc ? 'Incomplete' : 'Not started'}
      </Typography>
      <Spacer height='xxs' />
      <ProgressCards cards={[{ value: notStarted }]} />
    </Grid>,

    inProgress.length > 0 ? (
      <Grid display='flex' flex={1} justifyContent='flex-start'>
        <Grid sx={{ px: 2 }} display='flex' flexDirection='column'>
          <Typography variant='p16' color='secondary.main'>
            In progress
          </Typography>
          <Spacer height='xxs' />
          <ProgressCards cards={inProgress} />
        </Grid>
      </Grid>
    ) : (
      <Spacer width='xs' />
    ),

    <Grid
      item
      xs={gcOnly ? true : 1.5}
      whiteSpace='nowrap'
      display='flex'
      flex={1}
      flexDirection='column'>
      <Typography variant='p16' color='secondary.main'>
        Completed
      </Typography>
      <Spacer height='xxs' />
      <Grid height='100%'>
        <Bold>
          <ProgressCards
            cards={[
              {
                label: is3n ? 'Ran advice for the first time' : '',
                value: completed,
              },
            ]}
          />
        </Bold>
      </Grid>
    </Grid>,
  ];

  return (
    <Grid pb={3} height='100%'>
      <Grid
        flexDirection={gcOnly ? 'column' : 'row'}
        display='flex'
        justifyContent='space-between'
        height='100%'>
        <Typography variant='p24' color='secondary.main'>
          {title}
        </Typography>
        <Spacer height='xxxs' />

        <Grid display='flex' alignItems='center'>
          {gcOnly ? subHeaderComponents.reverse() : subHeaderComponents}
        </Grid>
      </Grid>

      <Spacer height='xxs' />

      <Card variant='ghost' color='primary'>
        <CardContent>
          <Grid container flexDirection='row' flexWrap='nowrap' justifyContent='space-between'>
            {gcOnly ? statusComponents.reverse() : statusComponents}
          </Grid>
        </CardContent>
      </Card>

      {is3nu && (
        <Grid display='flex' flexDirection='row' mt={1} ml='15px' alignItems='center'>
          <Typography variant='p16' color='secondary.main'>
            average progress
          </Typography>
          <Spacer width='xxs' />
          <Typography variant='p16' color='secondary.main'>
            <Bold>{avgProgress}</Bold>%
          </Typography>
          <Spacer width='xxxs' />
          <Tippy placement='right' content={<AvgProgress3nuHelp />} theme='tippy-rounded'>
            <HelpOutline style={{ color: theme.palette.info.main }} />
          </Tippy>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportProgressTracker;
