import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { formatDateMMDDYYYY } from '../../../helpers/utilityFunctions';

interface InvoiceDetailsProps {
  invoiceNumber?: string;
  invoiceDate?: string;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ invoiceNumber, invoiceDate }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow sx={{ border: 'none' }}>
            <TableCell variant='dataHead' style={{ width: '155px' }}>
              Invoice details
            </TableCell>
            <TableCell variant='dataHead'>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant='dataBodyKey'>Invoice number</TableCell>
            <TableCell variant='dataBodyValue'>{invoiceNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='dataBodyKey'>Invoice date</TableCell>
            <TableCell variant='dataBodyValue'>{formatDateMMDDYYYY(invoiceDate ?? '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceDetails;
