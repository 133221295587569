import { OrganizationCompartments, organizationStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { OrganizationApi } from '../../../api/apis/OrganizationApi';
import { OrgSelectionApi } from '../../../api/apis/OrgSelectionApi';
import { DataCompartment, IMutation, MutationContext } from '@aesop-fables/scrinium';
import { OrganizationData } from '../../../models/OrganizationData';
import { OffboardingCompartments, offboardingStorageKey } from '../../offboarding';
import { SignUpCompartments, signUpStorageKey } from '../../signUp';
import { UserCompartments, userStorageKey } from '../../user';
import { PayrollDeductionCompartments, payrollDeductionStorageKey } from '../../deductions';
import { AdminCompartments, adminStorageKey } from '../../admin';

export class SaveOrganizationData implements IMutation<OrganizationData> {
  async execute(context: MutationContext<OrganizationData>): Promise<void> {
    const { data, storage, container } = context;
    const organizationApi = container.get<OrganizationApi>(ApiKeys.Organization);
    await organizationApi.post(data);

    // reload user data to load/refresh org data cache
    const userDataCache = storage.retrieve<UserCompartments>(userStorageKey);
    userDataCache.reloadAll();
  }
}
export class UpdateOrganizationData implements IMutation<OrganizationData> {
  async execute(context: MutationContext<OrganizationData>): Promise<void> {
    const { data, storage, container } = context;
    const organizationApi = container.get<OrganizationApi>(ApiKeys.Organization);

    const cache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const currentData = (
      cache.findCompartment('organization') as DataCompartment<OrganizationData>
    ).getData() as OrganizationData;

    if (currentData?.id) {
      await organizationApi.update({ ...currentData, ...data });
    }

    // reload user data to load/refresh org data cache
    const userDataCache = storage.retrieve<UserCompartments>(userStorageKey);
    const offboardingDataCache = storage.retrieve<OffboardingCompartments>(offboardingStorageKey);
    userDataCache.reloadAll();
    offboardingDataCache.reloadAll();
  }
}

export class SaveOrganizationLogo implements IMutation<Blob> {
  async execute(context: MutationContext<Blob>): Promise<void> {
    const { data, storage, container } = context;
    const organizationApi = container.get<OrganizationApi>(ApiKeys.Organization);

    try {
      let formData = new FormData();
      formData.append('fileData', data);
      await organizationApi.uploadLogo(formData);
      const cache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);
      await cache.reloadAll();
    } catch (err) {
      console.error(err);
    }
  }
}

export class SelectOrganization implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const orgSelectionApi = container.get<OrgSelectionApi>(ApiKeys.OrgSelection);

    await orgSelectionApi.select(data);

    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const signUpDataCache = storage.retrieve<SignUpCompartments>(signUpStorageKey);
    const adminDataCache = storage.retrieve<AdminCompartments>(adminStorageKey);
    const deductionsDataCache = storage.retrieve<PayrollDeductionCompartments>(
      payrollDeductionStorageKey,
    );

    await Promise.all([
      orgDataCache.reload('organization'),
      signUpDataCache.reloadAll(),
      adminDataCache.reload('orgAdmins'),
      deductionsDataCache.reload('deductions'),
    ]);
  }
}

export class UnselectOrganization implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { storage, container } = context;
    const orgSelectionApi = container.get<OrgSelectionApi>(ApiKeys.OrgSelection);

    await orgSelectionApi.unselect();

    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);

    await orgDataCache.reload('organization');
  }
}

export class DeleteOrganization implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const organizationApi = container.get<OrganizationApi>(ApiKeys.Organization);

    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);

    await organizationApi.delete(data);

    await orgDataCache.reloadAll();
  }
}
