export const ApiServices = {
  Axios: 'axios',
  AxiosFactory: 'axiosFactory',
  AxiosRetryActivator: 'axiosRetryActivator',
  AxiosRetryConfig: 'axiosRetryConfig',
  AxiosSettings: 'axiosSettings',
  ApiSettings: 'apiSettings',
  RequestInterceptors: 'requestInterceptors',
  ResponseInterceptors: 'responseInterceptors',
};
