import { Card, CardContent, Typography } from '@mui/material';
import { Spacer } from '../../components';

const EmptyReport = () => {
  return (
    <Card variant='ghost' color='primary'>
      <CardContent sx={{ textAlign: 'center' }} className={'dataTable'}>
        <Spacer height='xxs' />
        <Typography variant='h3' color='secondary.main'>
          We couldn’t find any data for this report.
        </Typography>
        <Spacer height='xxs' />
      </CardContent>
    </Card>
  );
};

export default EmptyReport;
