export interface PlanWizardState {
  planData: PlanData;
  organizationId?: number;
}

export const InitialPlanWizardState: PlanWizardState = {
  planData: {},
};

export interface PlanData {
  id?: string;
  planName?: string;
  planType?: string;
  rkPlanId?: string;
  planSponsorName?: string;
  planAnniversaryDate?: string;
  employerContributions?: EmployerContributionsData[];
  employerContributionName?: string;
  deferralChoiceAllowed?: string;
  roth401kAllowed?: boolean;
  planContribPercentLimitPosttax?: number;
  levelOfService?: string;
}

export interface EmployerContributionsData {
  category?: number;
  dimension?: string;
  employerContributionDollarMax?: number;
  contributionTiers?: ContributionTierData[];
}

export interface ContributionTierData {
  from?: number;
  to?: number;
  percentContribution?: number;
}
