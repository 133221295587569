import ChartBar from '../../../components/ChartBar';
import { RecentPlatformData, ReportProps } from '../../../models/ReportData';
import useChartTheme from '../../../themes/chart';
import { getReportChartData, getAccountType } from '../../../helpers/reportUtils';

const primaryPlatformMap = new Map<keyof RecentPlatformData, string>([
  ['recentPlatformWebCount', 'Desktop'],
  ['recentPlatformAppleMobileCount', 'Apple (mobile)'],
  ['recentPlatformAppleWebCount', 'Apple (web)'],
  ['recentPlatformAndroidMobileCount', 'Android (mobile)'],
  ['recentPlatformAndroidWebCount', 'Android (web)'],
]);

const PrimaryPlatformChart: React.FC<ReportProps> = ({ report }) => {
  const { chartColors } = useChartTheme();
  const chartData = getReportChartData(
    report,
    pricebookReport => getAccountType(pricebookReport?.productType),
    primaryPlatformMap,
  );

  const dataSeries = Object.entries(chartData).map(([accountType, data]) => ({
    data,
    color: chartColors[accountType as keyof typeof chartColors],
    label: accountType,
  }));

  return (
    <ChartBar
      title='Users per primary platform'
      dataSeries={dataSeries}
      yAxisLabel='Count per primary platform'
      seriesLabel='Account type'
      padding={{ bottom: 85 }}
    />
  );
};

export default PrimaryPlatformChart;
