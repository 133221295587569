import type { IAppStorage } from '@aesop-fables/scrinium';
import type { IActivator } from '@aesop-fables/containr';
import { ScriniumServices } from '@aesop-fables/scrinium';
import { inject } from '@aesop-fables/containr';
import { Policies } from './IDataCachePolicy';
import type { IDataCachePolicy } from './IDataCachePolicy';

export class DataCachePolicyActivator implements IActivator {
  constructor(
    @inject(ScriniumServices.AppStorage) private readonly appStorage: IAppStorage,
    @inject(Policies) private readonly policies: IDataCachePolicy[],
  ) {}

  activate(): void {
    this.policies.forEach(policy => {
      const key = policy.registerAs();
      this.appStorage.store(key, policy.buildDataCache());
    });
  }
}
