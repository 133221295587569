// COPIED FROM https://github.com/shotforsky/react-corner-ribbon/blob/master/src/index.jsx
// The actual react-corner-ribbon package was giving many errors (possibly due to react 17) so I stripped out the implementation
// I also converted their class component into a functional component, and personalized the default colors

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    position: PropTypes.string,
    fontColor: PropTypes.string,
    backgroundColor: PropTypes.string,
}

const defaultProps = {
    backgroundColor: '#f6c760',
    fontColor: '#1e2969',
    position: 'top-right',
}

const CornerRibbon: React.FC<any> = (props) => {
    const {
        children,
        style,
        msg,
        backgroundColor = '#f6c760',
        fontColor = '#1e2969',
        position = 'top-right',
        containerStyle: userContainerStyle,
        className,
        ...rest
    } = props;

    let positionStyle = {};
    switch (position) {
        case 'top-left':
            positionStyle = {
                top: 0,
                left: 0,
                transform: 'translateY(-100%) rotate(-90deg) translateX(-70.71067811865476%) rotate(45deg)',
                transformOrigin: 'bottom left',
                WebkitTransform: 'translateY(-100%) rotate(-90deg) translateX(-70.71067811865476%) rotate(45deg)',
            };
            break;
        case 'top-right':
            positionStyle = {
                top: 0,
                right: 0,
                transform: 'translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)',
                transformOrigin: 'bottom right',
                WebkitTransform: 'translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)',
            };
            break;
        case 'bottom-left':
            positionStyle = {
                bottom: 0,
                left: 0,
                transform: 'translateY(100%) rotate(90deg) translateX(-70.71067811865476%) rotate(-45deg)',
                transformOrigin: 'top left',
                WebkitTransform: 'translateY(100%) rotate(90deg) translateX(-70.71067811865476%) rotate(-45deg)',
            };
            break;
        case 'bottom-right':
            positionStyle = {
                right: 0,
                bottom: 0,
                transform: 'translateY(100%) rotate(-90deg) translateX(70.71067811865476%) rotate(45deg)',
                transformOrigin: 'top right',
                WebkitTransform: 'translateY(100%) rotate(-90deg) translateX(70.71067811865476%) rotate(45deg)',
            };
            break;
        default:
            break;
    }

    const computedStyle = {
        position: 'absolute',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '2em',
        paddingLeft: '2em',
        zIndex: 99,
        textAlign: 'center',
        letterSpacing: '1px',
        fontSize: '14px',
        fontWeight: '500',
        pointerEvents: 'auto',
        boxShadow: '0 0 3px rgba(0,0,0,.3)',
        fontFamily: 'Open Sans',
        ...backgroundColor && { backgroundColor },
        ...fontColor && { color: fontColor },
        ...positionStyle,
        ...style,
    };

    // 不需要外层组件写overflow
    const containerStyle = {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: 'hidden',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        ...userContainerStyle,
    };

    return (
        <div style={containerStyle} className={className} {...rest}>
            <div style={computedStyle}>
                {msg}
                {children}
            </div>
        </div>
    );
}

CornerRibbon.propTypes = propTypes;
CornerRibbon.defaultProps = defaultProps;

export default CornerRibbon;