import { Route, Routes, useLocation } from 'react-router-dom';
import { TopNavBar } from '../../components';
import {
  EmployerContributions,
  InvestmentLineup,
  EditInvestmentLineup,
  MemberContributions,
  AddPackage,
  PlanBasics,
  CustomUrl,
  EmailTemplate,
  RestrictMemberAccess,
  UploadLogo,
} from '../';
import { CancellationPolicy, Contract } from '../../sign-up';
import * as Style from './style';
import { DashboardRoutes } from '../../routes/DashboardRoutes';
import AddMembersBulk from '../members/AddMembersBulk';
import RemoveMembersBulk from '../members/RemoveMembersBulk';
import Congrats from '../../sign-up/Congrats';

const Main: React.FC = () => {
  const location = useLocation();

  const addPackageFlow = location.pathname.includes('addPackage');
  const hideOrgsInNav =
    location.pathname.includes('plan-info/') ||
    location.pathname.includes('uploadLogo') ||
    location.pathname.includes('customUrl');

  return (
    <Style.MainWrapper>
      <Style.MainLayout>
        <TopNavBar
          hideDashboard={addPackageFlow}
          hideOrganizations={hideOrgsInNav}
          hideCompanyLogo
          useDrillDownCrumbs
        />
        <Routes>
          <Route path='/uploadLogo' element={<UploadLogo />} />
          <Route path='/restrictMembers' element={<RestrictMemberAccess />} />
          <Route path='/customUrl' element={<CustomUrl />} />
          <Route path='/members/add' element={<AddMembersBulk />} />
          <Route path='/members/remove' element={<RemoveMembersBulk />} />
          <Route path='/communications/template' element={<EmailTemplate />} />
          <Route path='/addPackage' element={<AddPackage />} />
          <Route path='/addPackage/contract' element={<Contract />} />
          <Route path='/congrats' element={<Congrats />} />
          <Route path='/cancellation-policy' element={<CancellationPolicy />} />
          <Route path='/plan-info/investment-lineup' element={<InvestmentLineup />} />
          <Route path='/plan-info/edit-investment-lineup' element={<EditInvestmentLineup />} />
          <Route path='/plan-info/plan-basics' element={<PlanBasics />} />
          <Route path='/plan-info/employer-contributions' element={<EmployerContributions />} />
          <Route path='/plan-info/member-contributions' element={<MemberContributions />} />

          <Route path='/*' element={<DashboardRoutes />} />
        </Routes>
      </Style.MainLayout>
    </Style.MainWrapper>
  );
};

export default Main;
