import { Grid, Typography } from '@mui/material';
import { Spacer } from '../../../components';

interface AutoPayDetailsProps {
  hasOutstanding: boolean;
}

const AutoPayDetails: React.FC<AutoPayDetailsProps> = ({ hasOutstanding }) => {
  const bullets = [
    <>
      Payments are processed the last day of each month.{' '}
      <strong>You will not receive any advance notice of an upcoming auto-payment.</strong> You
      will, however, receive a post-payment confirmation email following all automatic payments.
    </>,
    <>
      The <strong>amount due may differ from month to month</strong>, depending upon the number of
      licenses required by your organization the previous month.
    </>,
    <>
      Your organization’s{' '}
      <strong>
        invoices will be paid automatically using the specified default payment method
      </strong>
      , as indicated below.
    </>,
    'Changes to your organization’s default payment method or termination of auto-pay must be completed on the Payment page in the Partner Portal by midnight PST at least two days before the last day of the month.',
  ];

  return (
    <Grid>
      {bullets.map(bullet => (
        <Typography
          className='bullet'
          key={bullets.indexOf(bullet)}
          variant='p16'
          color='info.main'
          lineHeight={1.5}>
          {bullet}
        </Typography>
      ))}
      <Spacer height='xxs' />
      {hasOutstanding && (
        <Typography variant='p14' color='secondary.main' lineHeight='24px'>
          Additionally, the total of any outstanding invoices is due today and will be sent for
          payment immediately after selecting the “Set up auto-pay” button.
        </Typography>
      )}
    </Grid>
  );
};

export default AutoPayDetails;
