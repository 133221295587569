import { Typography } from '@mui/material';
import { FormInput, Column, Spacer } from '.';
import { FormInputProps } from './FormInput';

interface StackedFormInputProps<T> extends FormInputProps<T> {
  label?: string;
  inputStyle?: {};
  isDomain?: boolean;
  formattedValue?: string;
}

const StackedFormInput = <T extends unknown>(props: StackedFormInputProps<T>) => {
  const { label } = props;
  const isDomain = props.isDomain ?? false;
  const rowStyles = { alignItems: 'left' };
  const labelStyles = { alignItems: 'left' };
  const inputStyles = { alignItems: 'left' };

  return (
    <Column style={{ ...rowStyles }}>
      {label && (
        <>
          <Typography sx={{ ...labelStyles }} variant='body1' color='info.main'>
            {label}
          </Typography>
        </>
      )}
      {isDomain ? (
        <>
          <Typography variant='body1' color='secondary.dark'>
            @
          </Typography>
          <Spacer width='xxxs' />
        </>
      ) : null}
      <FormInput {...props} inputStyle={{ ...inputStyles }} />
    </Column>
  );
};

export default StackedFormInput;
