import { Typography } from '@mui/material';
import React from 'react';
import { Row } from '.';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { StepSequences, StepsHeaderTypes } from '../models/StepsHeaderData';

interface Props extends React.PropsWithChildren {
  style?: any;
  activeStep: number;
  totalSteps: number;
  headerSteps: StepsHeaderTypes;
}

const StepsHeader: React.FC<Props> = props => {
  const { activeStep, totalSteps, headerSteps, ...rest } = props;
  const { isMobile, isTablet, smDesktop, mdDesktop } = useDeviceType();
  const baseWidth = smDesktop || mdDesktop ? 15 : 10;
  const width = baseWidth * totalSteps + '%';
  const headers = headerSteps && StepSequences[headerSteps];

  const getSteps = () => {
    return headers?.map((header, index) => {
      const isActive = activeStep === index + 1;
      return (
        <React.Fragment key={index}>
          {index !== 0 && <Svgs.IconStepper style={{ transform: 'scale(0.8)' }} />}
          <Typography
            color={isActive ? 'primary' : 'info.main'}
            variant={isActive ? 'h4' : 'p14'}
            textAlign='center'
            paddingRight={index === 0 && (smDesktop || mdDesktop) ? '5px' : ''}>
            {header}
          </Typography>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {isMobile || isTablet ? (
        <Row
          {...rest}
          style={{
            cursor: 'default',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'center',
            ...rest.style,
          }}>
          {getSteps()}
        </Row>
      ) : (
        <Row
          {...rest}
          style={{
            cursor: 'default',
            width: width,
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'center',
            ...rest.style,
          }}>
          {getSteps()}
        </Row>
      )}
    </>
  );
};

export default StepsHeader;
