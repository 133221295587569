import { Typography, Grid, Card, CardContent, Button } from '@mui/material';
import React, { useState } from 'react';
import { Column, Row, DownloadButton, SingleCheckbox, Spacer, Modal } from '../../components';
import { Loading, useLoading } from '../../hooks/useLoading';
import { Document, Page } from 'react-pdf';
import { useDeviceType } from '../../hooks/useDeviceType';

interface LicenseModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  docUri: string | undefined;
  reviewed: boolean | undefined;
  onDownload: () => void;
  onAgree: () => void;
}

const LicenseModal: React.FC<LicenseModalProps> = ({
  open,
  setOpen,
  docUri,
  reviewed,
  onDownload,
  onAgree,
}) => {
  const { setLoading } = useLoading();
  const { isMobile, isTablet } = useDeviceType();
  const [checked, setChecked] = useState(false);

  return (
    <Modal open={open} setOpen={setOpen} hideButtons fullWidth>
      <Column
        style={{
          padding: '0px 100px 50px 130px',
          alignItems: 'center',
        }}>
        <Row
          style={{
            position: 'sticky',
            zIndex: 3,
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Typography variant='p30Light' color='secondary'>
            Contract
          </Typography>
          <DownloadButton onDownload={onDownload} style={{ width: '150px' }} />
        </Row>
        <Grid
          container
          style={{
            maxHeight: '900px',
            overflow: 'hidden',
            zIndex: 1,
            marginTop: -120,
            minWidth: '1100px',
            justifyContent: 'center',
          }}>
          <Document
            file={docUri}
            options={{ withCredentials: true, withXSRFToken: true }}
            onLoadSuccess={() => setLoading(false)}
            loading={
              <Grid container paddingTop={50} paddingBottom={50}>
                <Loading />
              </Grid>
            }>
            <Page pageNumber={1} scale={2.15} renderTextLayer={false} />
          </Document>
        </Grid>
        {!reviewed && (
          <Card variant='innerCard' style={{ position: 'sticky', zIndex: 2, minWidth: '100%' }}>
            <CardContent className='yearlyPrice'>
              {isMobile || isTablet ? (
                <Column style={{ alignItems: 'left' }}>
                  <SingleCheckbox label='I have read the license agreement, and I agree to it.' />
                  <Spacer height='xs' />
                  <Button onClick={onAgree} color='primary' disabled={!checked}>
                    Agree
                  </Button>
                </Column>
              ) : (
                <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                  <SingleCheckbox
                    label='I have read the license agreement, and I agree to it.'
                    onChange={() => setChecked(prev => !prev)}
                  />
                  <Button
                    onClick={onAgree}
                    color='primary'
                    style={{ width: '150px' }}
                    disabled={!checked}>
                    Agree
                  </Button>
                </Row>
              )}
            </CardContent>
          </Card>
        )}
      </Column>
    </Modal>
  );
};

export default LicenseModal;
