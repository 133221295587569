import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { OrgSignUpStateData } from '../../models/SignUpData';

export class OrgSignUpStateApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<OrgSignUpStateData>> {
    return this.axios.get('org-signup-state');
  }

  getAll(): Promise<AxiosResponse<OrgSignUpStateData[]>> {
    return this.axios.get('org-signup-state/all');
  }

  post(data: OrgSignUpStateData): Promise<AxiosResponse<OrgSignUpStateData>> {
    return this.axios.post('org-signup-state', data);
  }

  patch(data: OrgSignUpStateData): Promise<AxiosResponse<OrgSignUpStateData>> {
    return this.axios.patch('org-signup-state', data);
  }

  delete(): Promise<AxiosResponse<OrgSignUpStateData>> {
    return this.axios.delete('org-signup-state');
  }
}
