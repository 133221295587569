import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import {
  MendixClientApi,
  MendixClientData,
  MendixPublicKey,
} from '../../../api/apis/MendixClientApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { MendixClientCompartments, mendixClientStorageKey } from '..';

export class AddPublicKey implements IMutation<MendixClientData> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const mendixClientApi = container.get<MendixClientApi>(ApiKeys.MendixClient);

    await mendixClientApi.addPublicKey(data);

    const dataCache = storage.retrieve<MendixClientCompartments>(mendixClientStorageKey);
    await dataCache.reload('mendixClientData');
  }
}

export class EditPublicKey implements IMutation<MendixPublicKey> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const mendixClientApi = container.get<MendixClientApi>(ApiKeys.MendixClient);

    await mendixClientApi.editPublicKey(data);

    const dataCache = storage.retrieve<MendixClientCompartments>(mendixClientStorageKey);
    await dataCache.reload('mendixClientData');
  }
}
