import { FundData } from '../../../../api/apis/TrustFamilyApi';
import { DataTable } from '../../../../components';

interface LineupProps {
  editing?: boolean;
  data: FundData[];
  remove?: (row: FundData) => Promise<void>;
  backgroundColor?: string;
}

const LineupTable: React.FC<LineupProps> = props => {
  const { editing, data, remove, backgroundColor } = props;
  const headers = [
    {
      title: 'Ticker',
      label: 'ticker',
    },
    {
      title: 'Investment Name',
      label: 'name',
    },
    // {
    //   title: 'Type',
    //   label: 'type',
    // },
    // {
    //   title: 'Asset Class',
    //   label: 'assetClass',
    // },
  ];

  return (
    <DataTable
      headers={headers}
      sortBy='ticker'
      backgroundColor={backgroundColor}
      data={data.map(security => ({
        deletableRow: editing ? true : false,
        ticker: security.ticker,
        name: security.name,
        // type: SecurityTypeEnum[security.securityType],
        // assetClass: 'Equity',
      }))}
      emptyStateText={`You haven't added any investments yet.`}
      onDelete={remove}
    />
  );
};

export default LineupTable;
