import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class TeamSelectionApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  select(teamId: number): Promise<AxiosResponse<string>> {
    return this.axios.post(`team-selection/select/${teamId}`);
  }

  unselect(): Promise<AxiosResponse<string>> {
    return this.axios.post('team-selection/unselect');
  }
}
