import { DataCompartment, IMutation, MutationContext } from '@aesop-fables/scrinium';
import { GroupCompartments, groupStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { GroupApi } from '../../../api/apis/GroupApi';
import { GroupData } from '../../../models/GroupData';
import { UserCompartments, userStorageKey } from '../../user';
import { OffboardingCompartments, offboardingStorageKey } from '../../offboarding';
import { GroupSelectionApi } from '../../../api/apis/GroupSelectionApi';

export class SaveGroupData implements IMutation<GroupData> {
  async execute(context: MutationContext<GroupData>): Promise<void> {
    const { data, storage, container } = context;
    const groupApi = container.get<GroupApi>(ApiKeys.Group);
    await groupApi.post(data);

    // reload user data to load/refresh org data cache
    const groupDataCache = storage.retrieve<GroupCompartments>(userStorageKey);
    groupDataCache.reloadAll();
  }
}

export class UpdateGroupData implements IMutation<GroupData> {
  async execute(context: MutationContext<GroupData>): Promise<void> {
    const { data, storage, container } = context;
    const groupApi = container.get<GroupApi>(ApiKeys.Group);

    const cache = storage.retrieve<GroupCompartments>(groupStorageKey);
    const currentData = (
      cache.findCompartment('group') as DataCompartment<GroupData>
    ).getData() as GroupData;

    if (currentData?.id) {
      await groupApi.update({ ...currentData, ...data });
    }

    // reload user data to load/refresh group data cache
    const userDataCache = storage.retrieve<UserCompartments>(userStorageKey);
    const offboardingDataCache = storage.retrieve<OffboardingCompartments>(offboardingStorageKey);
    userDataCache.reloadAll();
    offboardingDataCache.reloadAll();
  }
}

export class SelectGroup implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const groupSelectionApi = container.get<GroupSelectionApi>(ApiKeys.GroupSelection);

    await groupSelectionApi.select(data);

    const dataCache = storage.retrieve<GroupCompartments>(groupStorageKey);
    await dataCache.reload('group');
  }
}

export class UnselectGroup implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { storage, container } = context;
    const groupSelectionApi = container.get<GroupSelectionApi>(ApiKeys.GroupSelection);

    await groupSelectionApi.unselect();

    const dataCache = storage.retrieve<GroupCompartments>(groupStorageKey);

    await dataCache.reload('group');
  }
}

// can you delete a group?
export class DeleteGroup implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const groupApi = container.get<GroupApi>(ApiKeys.Group);

    const orgDataCache = storage.retrieve<GroupCompartments>(groupStorageKey);

    await groupApi.delete(data);

    await orgDataCache.reloadAll();
  }
}
