import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { PlanData } from '../../models/PlanData';

export class PlanApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getPlan(): Promise<AxiosResponse<PlanData>> {
    return this.axios.get('plan');
  }

  createPlan(plan: PlanData): Promise<AxiosResponse<PlanData>> {
    return this.axios.post('plan', plan);
  }

  editPlan(plan: PlanData): Promise<AxiosResponse<PlanData>> {
    return this.axios.patch('plan', plan);
  }
}
