import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Column, Row, Spacer } from '../../components';
import { Typography } from '@mui/material';
import { RoleInfoCard } from '../multitier/AdminInfo';
import { Svgs } from '../../assets/svg';
import { MetricsCompartments, metricsStorageKey } from '../../data/metrics';
import { TeamMetricsData } from '../../models/MetricsData';

const TeamMetricsCard = () => {
  const appStorage = useAppStorage();
  const metricsDataCache = appStorage.retrieve<MetricsCompartments>(metricsStorageKey);
  const teamMetrics = useObservable(metricsDataCache.observe$<TeamMetricsData>('teamMetrics'));

  return (
    <>
      <Row style={{ justifyContent: 'space-between' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          Team metrics
        </Typography>
      </Row>
      <Spacer height='xs' />
      <RoleInfoCard editable={false}>
        <Row style={{ alignSelf: 'center' }}>
          <Column>
            <Typography variant='p12SemiBold' color='secondary.main'>
              Total active users
            </Typography>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconMembersDefault />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {teamMetrics?.activeLicenses ?? 0}
              </Typography>
            </Row>
          </Column>
          <Spacer width='xxxl' />
          <Column>
            <Typography variant='p12SemiBold' color='secondary.main'>
              Organizations
            </Typography>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconOrgPaysLarge />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {teamMetrics?.organizations ?? 0}
              </Typography>
            </Row>
          </Column>
          <Spacer width='xxxl' />
          <Column>
            <Typography variant='p12SemiBold' color='secondary.main'>
              Team admins
            </Typography>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconAdminsDefault />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {teamMetrics?.admins ?? 0}
              </Typography>
            </Row>
          </Column>
        </Row>
      </RoleInfoCard>
    </>
  );
};

export default TeamMetricsCard;
