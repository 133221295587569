import { Button, Card, CardContent, Typography, Link } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Svgs } from '../../assets/svg';
import { Column, Page, Row, Searchbox, Spacer } from '../../components';
import {
  MutationStatus,
  MutatorState,
  useAppStorage,
  useMutation,
  useObservable,
} from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { UserCompartments, userStorageKey } from '../../data/user';
import { UserData } from '../../models/UserData';
import { useNavigate } from 'react-router-dom';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { DeleteOrganization } from '../../data/organization/mutations';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { API_URL } from '../../api/apis';
import { OrganizationMetricsData } from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { SignUpWizard, signUpWizardKey } from '../../services/signUp';
import { useLoading } from '../../hooks/useLoading';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { OrgSignUpStateData } from '../../models/SignUpData';
import IncompleteOrgs from './IncompleteOrgs';
import SubHeaderRow from '../multitier/SubHeaderRow';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { TeamCompartments, teamStorageKey } from '../../data/team';
import { TeamData } from '../../models/TeamData';

interface OrgDataDisplayProps {
  select: MutatorState<number>;
  setOrgStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const OrgDataDisplay: React.FC<OrgDataDisplayProps> = ({ select, setOrgStatus }) => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const [tryAgain, setTryAgain] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const deleteOrganization = useMutation(new DeleteOrganization());

  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);

  const highestAccess = useObservable(drillDownWizard.highestAccess$);
  const origin = useObservable(drillDownWizard.origin$);
  const userData = useObservable(userDataCache.observe$<UserData>('user'));
  const allOrgSignUpStates = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData[]>('allOrgSignUpStates'),
  );
  const allOrganizations = useObservable(
    organizationDataCache.observe$<OrganizationData[]>('allOrganizations'),
  );
  const allMetrics = useObservable(
    organizationDataCache.observe$<OrganizationMetricsData[]>('allMetrics'),
  );
  const teamName = useObservable(teamDataCache.observe$<TeamData>('team'))?.name ?? '';
  const numTeams = useObservable(teamDataCache.observe$<TeamData[]>('allTeams'))?.length ?? 0;

  const numOrgs = allOrganizations?.length ?? 0;
  const landingPage =
    (highestAccess === AdminTierTypeEnum.Organization && numOrgs >= 2) ||
    (highestAccess === AdminTierTypeEnum.Team && numTeams < 2) ||
    origin === AdminTierTypeEnum.Organization;

  const incompleteOrgMetrics =
    allMetrics && allMetrics.length > 0
      ? allMetrics.filter(orgMetrics => {
          const x = allOrgSignUpStates?.filter(signUpState => {
            if (orgMetrics.organizationId === signUpState.id) {
              if (!signUpState.offboardingComplete) return !signUpState.onboardingComplete;
            }
            return false;
          });
          if (x?.length !== 0) {
            return true;
          }
          return false;
        })
      : undefined;

  incompleteOrgMetrics?.sort((a, b) => a.organizationName.localeCompare(b.organizationName));
  const showIncompleteOrgs = incompleteOrgMetrics && incompleteOrgMetrics.length > 0;

  useEffect(() => {
    if (!tryAgain) {
      return;
    }
    if (deleteOrganization.error) {
      setLoading(false);
      setToast(
        new Toast({
          message: 'Error removing organization',
          severity: 'error',
          open: true,
        }),
      );
      setTryAgain(false);
    } else if (deleteOrganization.status === MutationStatus.Complete) {
      setLoading(false);
      setRemoveModalOpen(false);
      setToast(new Toast({ message: 'Organization removed!', severity: 'success', open: true }));
      setTryAgain(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrganization, tryAgain]);

  if (
    allMetrics === undefined ||
    allOrganizations === undefined ||
    allOrgSignUpStates === undefined
  ) {
    return null;
  }

  const completeOrgMetrics = allMetrics.filter(orgMetrics => {
    const x = allOrgSignUpStates.filter(signUpState => {
      if (orgMetrics.organizationId === signUpState.id) {
        if (!signUpState.onboardingComplete && signUpState.offboardingComplete) {
          return signUpState.offboardingComplete;
        }
        return signUpState.onboardingComplete;
      }
      return false;
    });
    if (x?.length !== 0) {
      return true;
    }
    return false;
  });
  completeOrgMetrics?.sort((a, b) => a.organizationName.localeCompare(b.organizationName));

  const onCreate = () => {
    signUpWizard.reset();
    navigate('/onboarding/aboutYourOrg');
  };

  return (
    <Page
      title={landingPage ? (userData?.firstName ? `Hi, ${userData.firstName}!` : 'Hi!') : teamName}>
      <Spacer height='sm' />
      {showIncompleteOrgs && (
        <>
          <IncompleteOrgs
            incompleteOrgMetrics={incompleteOrgMetrics}
            setRemoveModalOpen={setRemoveModalOpen}
            setTryAgain={setTryAgain}
            setOrgStatus={setOrgStatus}
            deleteOrganization={deleteOrganization}
            select={select}
            removeModalOpen={removeModalOpen}
          />
          <Spacer height='sm' />
        </>
      )}
      <SubHeaderRow
        onCreate={onCreate}
        hideCreate={highestAccess === AdminTierTypeEnum.Organization || showIncompleteOrgs}
        tierIcon={<Svgs.ArtDashboardOrganization />}
        tier='organization'
      />
      <OrgDisplay
        allMetrics={completeOrgMetrics}
        orgList={allOrganizations}
        select={select}
        setOrgStatus={setOrgStatus}
      />
    </Page>
  );
};

export interface OrgDisplayProps {
  orgList: OrganizationData[];
  allMetrics: OrganizationMetricsData[];
  select: MutatorState<number>;
  setOrgStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const OrgDisplay: React.FC<OrgDisplayProps> = ({ orgList, allMetrics, select, setOrgStatus }) => {
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const [searchWord, setSearchWord] = useState('');
  const [filteredOrgDataForTable, setFilteredOrgDataForTable] = useState<OrganizationMetricsData[]>(
    [],
  );

  const numCompleteOrgs = allMetrics.length ?? 0;
  const enoughOrgs = numCompleteOrgs >= 10;

  useEffect(() => {
    if (!searchWord) {
      setFilteredOrgDataForTable(allMetrics);
      return;
    }
    setFilteredOrgDataForTable(
      allMetrics.filter((item: any) =>
        Object.values(item).some(
          (val: any) => val && val.toString().toLowerCase().includes(searchWord.toLowerCase()),
        ),
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord, allMetrics]);

  const selectOrganization = async (orgId: any) => {
    setOrgStatus('complete');
    setLoading(true);
    await select.action(orgId);
  };

  const onView = async (orgId: any) => {
    if (orgId === undefined) {
      setToast(new Toast({ message: 'No organization id found', severity: 'error', open: true }));
      return;
    }
    setLoading(true);
    selectOrganization(orgId);
  };

  return (
    <>
      {enoughOrgs && (
        <>
          <Spacer height='xs' />
          <Card variant='ghost' color='primary'>
            <CardContent className='searchbox'>
              <Column>
                <Searchbox
                  callback={value => setSearchWord(value)}
                  label='Search'
                  placeholder='Search by organization name'
                />
              </Column>
            </CardContent>
          </Card>
        </>
      )}

      <Spacer height='xs' />
      <Card variant='ghost' color='primary'>
        <CardContent
          className='pad25'
          sx={{ textAlign: filteredOrgDataForTable?.length > 0 ? undefined : 'center' }}>
          {numCompleteOrgs === 0 ? (
            <Typography
              variant='p20'
              color='secondary.main'
              textAlign='center'
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              You haven't created any organizations yet.
            </Typography>
          ) : filteredOrgDataForTable?.length > 0 ? (
            filteredOrgDataForTable?.map((org, i) => {
              const orgData = orgList.filter(x => x.id === org.organizationId).length > 0;
              return (
                <Card
                  variant='ghost'
                  color='secondary'
                  key={org.organizationId}
                  sx={{ marginTop: i === 0 ? '0px' : '10px' }}>
                  <CardContent className='pad10'>
                    <Row style={{ justifyContent: 'space-between' }}>
                      <Row
                        style={{
                          alignItems: 'center',
                        }}>
                        <Svgs.IconOrgPaysLarge />
                      </Row>
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flex: '2 0',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        <Spacer width='xxs' />
                        <Link
                          className='darkenOnHover'
                          noWrap={true}
                          variant='p18SemiBold'
                          color='secondary.main'
                          onClick={() => {
                            onView(org.organizationId);
                          }}>
                          {org.organizationName}
                        </Link>
                      </Row>

                      <Spacer width='xxs' />
                      {orgData && orgList.filter(x => x.id === org.organizationId)[0].logoUri && (
                        <Row
                          style={{
                            flex: '2 0 10px',
                            overflow: 'hidden',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <img
                            src={`${API_URL}organization/logo/${org.organizationId}`}
                            style={{
                              height: '35px',
                            }}
                            alt={`${org.organizationName}'s logo`}
                          />
                        </Row>
                      )}

                      <Spacer width='xxs' />
                      <Row style={{ alignItems: 'center' }}>
                        <Column>
                          <Typography variant='p12SemiBold' color='secondary.main'>
                            Active agreements
                          </Typography>
                          <Row
                            style={{
                              alignItems: 'center',
                            }}>
                            <Svgs.IconLicenseDefault />
                            <Spacer width='xxs' />
                            <Typography variant='p20SemiBold' color='secondary.main'>
                              {countActiveAgreements(org)}
                            </Typography>
                          </Row>
                        </Column>
                        <Spacer width='md' />
                        <Column>
                          <Typography variant='p12SemiBold' color='secondary.main'>
                            Active users
                          </Typography>
                          <Row
                            style={{
                              alignItems: 'center',
                            }}>
                            <Svgs.IconMembersDefault />
                            <Spacer width='xxs' />
                            <Typography variant='p20SemiBold' color='secondary.main'>
                              {countActiveUsers(org)}
                            </Typography>
                          </Row>
                        </Column>

                        <Spacer width='xxl' />
                        <Button
                          onClick={() => onView(org.organizationId)}
                          sx={{
                            padding: '5px 30px',
                            typography: { fontSize: 12 },
                            height: '30px',
                          }}
                          color='secondary'>
                          View
                        </Button>
                      </Row>
                    </Row>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Typography variant='p18SemiBold' color='secondary.main'>
              No results found
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export const countActiveUsers = (metric: OrganizationMetricsData) => {
  let count = 0;
  if (!metric) return count;
  metric.pricebookLicenseMetrics?.forEach(license => (count += license.activeLicenses));
  return count;
};
export const countActiveAgreements = (metric: OrganizationMetricsData) => {
  let count = 0;
  if (!metric) return count;
  metric.pricebookLicenseMetrics?.forEach(() => count++);
  return count;
};

export default OrgDataDisplay;
