import { MutatorState } from '@aesop-fables/scrinium';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { Svgs } from '../../assets/svg';
import { Spacer, Row } from '../../components';
import { PricebookBundle, PaymentTypeEnum } from '../../models/PricebookData';
import { OrgSignUpStateData, State } from '../../models/SignUpData';
import BundleCard from './BundleCard';

interface ProductOptionsProps {
  memberPaidOptions: PricebookBundle[];
  orgPaidOptions: PricebookBundle[];
  selectedPricebook?: PricebookBundle;
  setSelectedPricebook: React.Dispatch<React.SetStateAction<PricebookBundle | undefined>>;
  pricebookSelections?: PricebookBundle[];
  currentLicense?: LicenseAgreementData;
  signUpState?: State;
  updateSignUpState: MutatorState<OrgSignUpStateData>;
}

const ProductOptions: React.FC<ProductOptionsProps> = ({
  memberPaidOptions,
  orgPaidOptions,
  selectedPricebook,
  setSelectedPricebook,
  pricebookSelections,
  currentLicense,
  signUpState,
  updateSignUpState,
}) => {
  const promotion = [
    ...memberPaidOptions?.filter(bundle =>
      bundle.prices.find(price => price.introductionPeriodInDays > 1),
    ),
    ...orgPaidOptions?.filter(bundle =>
      bundle.prices.find(price => price.introductionPeriodInDays > 1),
    ),
  ];
  const introPeriod = promotion
    ?.map(bundle => bundle.prices)
    .map(price => price.map(arr => arr.introductionPeriodInDays).find(promo => promo > 1));
  const introPeriodInMonths = introPeriod?.map(promo => Math.floor((promo ?? 0) / 30));
  const meteredPayment = orgPaidOptions.filter(
    bundle => bundle.pricebook.paymentType === PaymentTypeEnum.Metered,
  );

  return (
    <>
      {memberPaidOptions.length > 0 && (
        <>
          <Spacer height='xs' />
          <Row style={{ alignItems: 'center' }}>
            <Svgs.IconMembersDefault2 />
            <Spacer width='xxs' />
            <Typography color='secondary' variant='p18SemiBold'>
              Member pays
            </Typography>
          </Row>
          <Spacer height='xs' />
          <Row style={{ flexWrap: 'wrap' }}>
            {memberPaidOptions.map(bundle => (
              <BundleCard
                style={{ marginBottom: '10px', marginRight: '10px' }}
                key={bundle.pricebook.id}
                pricebookBundle={bundle}
                currentLicense={currentLicense}
                updateSignUpState={updateSignUpState}
                signUpState={signUpState}
                selected={_.isEqual(bundle, selectedPricebook)}
                setSelectedPricebook={setSelectedPricebook}
                current={
                  (pricebookSelections?.filter(
                    pricebook => pricebook.pricebook.id === bundle.pricebook.id,
                  )?.length ?? 0) > 0
                }
              />
            ))}
          </Row>
        </>
      )}
      {orgPaidOptions.length > 0 && (
        <>
          <Spacer height='sm' />
          <Row style={{ alignItems: 'center' }}>
            <Svgs.IconOrgPaysLarge />
            <Spacer width='xxs' />
            <Typography color='secondary' variant='p18SemiBold'>
              Organization pays
            </Typography>
          </Row>
          <Spacer height='xs' />
          <Row style={{ flexWrap: 'wrap' }}>
            {orgPaidOptions.map(bundle => (
              <BundleCard
                style={{ marginBottom: '10px', marginRight: '10px' }}
                key={bundle.pricebook.id}
                pricebookBundle={bundle}
                currentLicense={currentLicense}
                updateSignUpState={updateSignUpState}
                signUpState={signUpState}
                selected={_.isEqual(bundle, selectedPricebook)}
                setSelectedPricebook={setSelectedPricebook}
                current={
                  (pricebookSelections?.filter(
                    pricebook => pricebook.pricebook.id === bundle.pricebook.id,
                  )?.length ?? 0) > 0
                }
              />
            ))}
          </Row>
        </>
      )}
      {promotion ? (
        introPeriodInMonths?.length ?? 0 > 1 ? (
          <>
            <Spacer height='xxxs' />
            <Typography variant='p14' color='secondary'>
              * After the period of free access ends, Advice subscription auto-renews at
              $14.99/month
            </Typography>
          </>
        ) : (
          introPeriodInMonths?.map((x, index) => (
            <>
              <Spacer height='xxxs' />
              <Typography key={index} variant='p14' color='secondary'>
                * After {x === 1 ? '30 days' : x}
                {x > 1 && ' months'}, Advice subscription auto-renews at $14.99/month
              </Typography>
            </>
          ))
        )
      ) : meteredPayment ? (
        <>
          <Spacer height='xxxs' />
          <Typography variant='p14' color='secondary'>
            * An invoice will be sent at the end of each month for any new or renewing licenses.
          </Typography>
        </>
      ) : (
        <Spacer height='xs' />
      )}
    </>
  );
};

export default ProductOptions;
