import React, { useContext, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import '../../themes/tippy-rounded.css';
import ClickableList from '../../components/ClickableList';
import { AdminStatusEnum, UserData } from '../../models/UserData';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { ChangePrimaryBillingUser } from '../../data/admin/mutations';
import { UserCompartments, userStorageKey } from '../../data/user';
import _ from 'lodash';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';

interface ChangeBillingAdminProps {
  admins: UserData[];
}

const ChangeBillingAdmin: React.FC<ChangeBillingAdminProps> = ({ admins }) => {
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);

  const activeAdmins = admins.filter(admin => admin.status === AdminStatusEnum.Active);
  const primaryBillingUser = admins.find(admin => admin.primaryBillingUser);
  const changePrimaryBillingUser = useMutation(new ChangePrimaryBillingUser());
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const userData = useObservable(userDataCache.observe$<UserData>('user'));
  const currentUser = admins.find(admin => admin.id === userData?.id);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleClick = async (userId: number) => {
    await changePrimaryBillingUser.action(userId).then(() => {
      setIsVisible(false);
      setToast(
        new Toast({
          message: 'Billing admin updated!',
          severity: 'success',
          open: true,
          autoHideDuration: 2000,
        }),
      );
    });
  };

  return (
    primaryBillingUser && (
      <Grid container>
        <Typography variant='p16' color='secondary.main'>
          All billing communications are sent to {primaryBillingUser.email}.&nbsp;&nbsp;
        </Typography>
        {activeAdmins.length > 1 && (
          <Tippy
            content={
              <ChangeBillingContent
                admins={activeAdmins}
                primaryBillingUser={primaryBillingUser}
                currentUser={currentUser}
                handleClick={handleClick}
              />
            }
            placement='bottom'
            arrow={false}
            trigger='click'
            interactive={true}
            visible={isVisible}
            onClickOutside={() => setIsVisible(false)}
            theme='tippy-rounded'>
            <Link onClick={() => setIsVisible(true)} variant='p16'>
              Change billing admin
            </Link>
          </Tippy>
        )}
      </Grid>
    )
  );
};

export default ChangeBillingAdmin;

interface ChangeBillingContentProps {
  admins: UserData[];
  primaryBillingUser: UserData;
  currentUser: UserData | undefined;
  handleClick: (val: any) => void;
}

const ChangeBillingContent: React.FC<ChangeBillingContentProps> = ({
  admins,
  primaryBillingUser,
  currentUser,
  handleClick,
}) => {
  return (
    <Grid container>
      <Typography variant='p18SemiBold' color='secondary.dark'>
        Change billing admin
      </Typography>
      <ClickableList
        items={admins}
        initialSelectedId={primaryBillingUser.id}
        renderItem={admin =>
          _.isEqual(admin, currentUser)
            ? `${admin.firstName} ${admin.lastName} (You)`
            : `${admin.firstName} ${admin.lastName}`
        }
        getItemKey={admin => admin?.id}
        onItemClick={handleClick}
      />
    </Grid>
  );
};
