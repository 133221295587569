import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";

interface GroupCheckboxProps extends React.PropsWithChildren {
    data?: any[];
}

const GroupCheckbox: React.FC<GroupCheckboxProps> = (props) => {
  const { data } = props;
  return (
    <FormGroup {...props}>
      {(data && data.length > 0) ? data.map((item) => (
        <FormControlLabel control={<Checkbox />} key={item.id} label={item.text} value={item.text} />
      )) : null}
    </FormGroup>
  );
};

export default GroupCheckbox;

