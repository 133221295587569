import { HelpOutline } from '@mui/icons-material';
import { Grid, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import { Spacer } from '../../../components';
import BulletedList from '../../../components/BulletedList';
import { HelpTitle } from '../../../components/HelpText';
import { AnyReportType, ReportProps } from '../../../models/ReportData';
import { formatDecimalOrWholeNumber } from '../../../helpers/utilityFunctions';

interface AvgDaysSinceLoginProps extends ReportProps {
  summedReport: AnyReportType | null;
}

const AvgDaysSinceLogin: React.FC<AvgDaysSinceLoginProps> = ({ summedReport, report }) => {
  const theme = useTheme();
  const avgDaysSinceLogin = (summedReport ?? report).averageDaysSinceLastLogin ?? 0;

  return (
    <Grid item flexDirection='column'>
      <Typography variant='p30' color='secondary.main'>
        {formatDecimalOrWholeNumber(avgDaysSinceLogin)}
      </Typography>
      <Grid container flexDirection='row'>
        <Typography variant='p16' color='secondary.main'>
          Avg. days since last login
        </Typography>
        <Spacer width='xxxs' />
        <Tippy placement='right' content={<AvgDaysHelpText />} theme='tippy-rounded'>
          <HelpOutline style={{ color: theme.palette.info.main }} />
        </Tippy>
      </Grid>
    </Grid>
  );
};

export default AvgDaysSinceLogin;

const AvgDaysHelpText = () => {
  return (
    <Grid pr={3}>
      <HelpTitle>Average days since last login</HelpTitle>
      <Spacer height='xxs' />
      <BulletedList
        children={[
          'This is the average number of days since the last time a user logged into their account.',
          'Calculated using the last login timestamp for all user accounts.',
          'For those who created an account but haven’t logged back in, their last login is when they signed up.',
        ]}
      />
      <Spacer height='xxs' />
    </Grid>
  );
};
