import { usePlacesWidget } from 'react-google-autocomplete';
import React, { useContext, useState } from 'react';
import { AddressInfo, OrganizationData } from '../models/OrganizationData';
import { ToastbarContext } from '../App';
import { Toast } from '../models/Toast';
import { FormInput } from '.';
import { FormInputProps } from './FormInput';

interface AddressSearchboxProps<T> extends FormInputProps<T> {
  googleKey: string;
  onSubmit: (address: AddressInfo) => void;
  organizationData: OrganizationData;
  setStreetAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFullAddress: React.Dispatch<React.SetStateAction<AddressInfo | undefined>>;
  setState: React.Dispatch<React.SetStateAction<string>>;
  streetAddress: string | undefined;
  fullAddress: AddressInfo | undefined;
}

const AddressSearchbox = <T extends unknown>(props: AddressSearchboxProps<T>) => {
  const {
    googleKey,
    organizationData,
    onSubmit,
    streetAddress,
    setStreetAddress,
    setFullAddress,
    setState,
    fullAddress,
  } = props;

  const [cityFound, setCityFound] = useState<boolean>(false);
  const { setToast } = useContext(ToastbarContext);

  /* The google results need to have the Z-index manually set or it wont appear in modals/drawers */
  const resultsContainer = document.querySelectorAll<HTMLElement>('.pac-container');
  resultsContainer.forEach(result => {
    result.style.zIndex = `${99999} !important`;
  });

  const { ref: materialRef } = usePlacesWidget({
    apiKey: googleKey,
    onPlaceSelected: place => {
      if (!place) return;
      setStreetAddress(place.name);
      parseAddress(place.address_components);
    },
    options: {
      componentRestrictions: { country: 'us' },
      fields: ['name', 'address_component'],
      types: ['address'],
    },
  });

  const parseAddress = (address: google.maps.GeocoderAddressComponent[] | undefined) => {
    if (!address) return;

    let streetNumber;
    let streetName;
    let city: string = '';
    let state: string = '';
    let zip: string = '';

    address.forEach(element => {
      if (element.types[0] === 'street_number') {
        streetNumber = element.short_name;
      } else if (element.types[0] === 'route') {
        streetName = element.short_name;
      } else if (element.types[0] === 'locality') {
        city = element.short_name;
        setCityFound(true);
      } else if (element.types[0] === 'neighborhood' && !cityFound) {
        city = element.short_name;
        setCityFound(true);
      } else if (element.types[0] === 'sublocality_level_1' && !cityFound) {
        city = element.short_name;
        setCityFound(true);
      } else if (element.types[0] === 'administrative_area_level_1') {
        state = element.short_name;
        setState(element.short_name);
      } else if (element.types[0] === 'postal_code') {
        zip = element.short_name;
      }
    });
    if (!streetNumber) {
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Please re-enter your address, beginning with the street number.',
        }),
      );
      return;
    }
    const numAndName = streetNumber + ' ' + streetName;

    setFullAddress({
      streetAddress: numAndName,
      city: city,
      state: state,
      zip: zip,
    });

    onSubmit({
      streetAddress: numAndName,
      city: city,
      state: state,
      zip: zip,
    });
  };

  return (
    <FormInput<OrganizationData>
      inputRef={materialRef}
      placeholder=''
      fieldName='streetAddress'
      onChange={x => {
        setStreetAddress(x.target.value);
        setFullAddress({ ...fullAddress, streetAddress: x.target.value });
      }}
      formattedValue={streetAddress}
      initialValue={organizationData?.streetAddress}
      inputStyle={{ minWidth: '120px', flexShrink: '0', flexGrow: '5', ...props.inputStyle }}
      required
    />
  );
};
export default AddressSearchbox;
