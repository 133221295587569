import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import { Column, Row, Spacer } from '../components';
import { Svgs } from '../assets/svg';
import { domain } from '../api/apis';
import { useDeviceType } from '../hooks/useDeviceType';
import { logoGcSmall } from '../assets/png';
import * as Style from '../dashboard/main/style';

const ChooseAccountType = () => {
  const handleOrgSignUp = () => {
    window.location.replace(`https://ipm-auth.${domain}/oauth2/authorization/B2C_1_signup1`);
  };

  const { isMobile, isTablet } = useDeviceType();
  return (
    <Style.MainWrapper>
      <Style.MainLayout>
        <Style.MainSection>
          <Style.MainContentRight>
            <Column style={{ margin: isMobile || isTablet ? '20px' : '20px 100px' }}>
              <Row style={{ justifyContent: isMobile || isTablet ? 'flex-start' : 'flex-end' }}>
                <img
                  src={logoGcSmall}
                  alt={`GuidedChoice Logo`}
                  style={{ width: '200px', height: 'auto' }}
                />
              </Row>
              <Spacer height='xs' />
              <Typography variant='h1' color='secondary'>
                Partner portal
              </Typography>
              <Spacer height='xs' />
              <Typography variant='h2' color='info.main'>
                Let's set up your GuidedChoice partner portal account.
              </Typography>
              <Spacer height='xs' />
              <Typography variant='h2' color='primary'>
                Already have an account? <Link href={`https://ipm-auth.${domain}`}>Log in</Link>
              </Typography>
              <Spacer height='xs' />
              {isMobile || isTablet ? (
                <Column style={{ alignItems: 'center' }}>
                  <AccountTypeCard
                    art={<Svgs.ArtOrganization style={{ width: '60%', paddingBottom: '25px' }} />}
                    typeText='my organization'
                    handleSignUp={handleOrgSignUp}
                  />
                </Column>
              ) : (
                <Row style={{ justifyContent: 'center' }}>
                  <AccountTypeCard
                    art={<Svgs.ArtOrganization style={{ width: '60%', paddingBottom: '25px' }} />}
                    typeText='my organization'
                    handleSignUp={handleOrgSignUp}
                  />
                </Row>
              )}
            </Column>
          </Style.MainContentRight>
        </Style.MainSection>
      </Style.MainLayout>
    </Style.MainWrapper>
  );
};

interface AccountTypeCardProps {
  art: JSX.Element;
  typeText: string;
  handleSignUp: () => void;
}

const AccountTypeCard: React.FC<AccountTypeCardProps> = ({ art, typeText, handleSignUp }) => {
  const navToNextStep = () => {
    handleSignUp();
  };
  const { isMobile, isTablet } = useDeviceType();
  return (
    <Card color='primary' style={{ width: isMobile || isTablet ? '100%' : '45%' }}>
      <CardContent className='basic'>
        <Spacer height='md' />
        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '150px',
          }}>
          {art}
        </Row>
        <Spacer height='sm' />
        <Typography variant='h6' align='center'>
          I'm creating an account for {typeText}
        </Typography>
        <Spacer height='xs' />
        <Row style={{ justifyContent: 'center' }}>
          <Button className='xl' color='primary' onClick={navToNextStep}>
            Sign Up
          </Button>
        </Row>
        <Spacer height='md' />
      </CardContent>
    </Card>
  );
};

export default ChooseAccountType;
