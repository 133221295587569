// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import { RequestInterceptor } from '../Interceptor';

export class CurlHelper {
  constructor(private request: AxiosRequestConfig) {}

  getHeaders(): string {
    const headers = this.request.headers;
    let curlHeaders = '';

    // get the headers concerning the appropriate method (defined in the global axios instance)
    if (this.request.headers && headers?.hasOwnProperty('common')) {
      headers[this.request.method ?? 'GET'] = this.request.headers[this.request.method ?? 'GET'];
    }

    // add any custom headers (defined upon calling methods like .get(), .post(), etc.)
    for (const property in this.request.headers) {
      if (!['common', 'delete', 'get', 'head', 'patch', 'post', 'put'].includes(property) && headers) {
        headers[property] = this.request.headers[property];
      }
    }

    for (const property in headers) {
      if ({}.hasOwnProperty.call(headers, property)) {
        const header = `${property}:${headers[property]}`;
        curlHeaders = `${curlHeaders} -H "${header}"`;
      }
    }

    return curlHeaders.trim();
  }

  getMethod(): string {
    return `-X ${this.request.method?.toUpperCase()}`;
  }

  getBody(): string {
    if (
      typeof this.request.data !== 'undefined' &&
      this.request.data !== '' &&
      this.request.data !== null &&
      this.request.method?.toUpperCase() !== 'GET'
    ) {
      const data =
        typeof this.request.data === 'object' || Object.prototype.toString.call(this.request.data) === '[object Array]'
          ? JSON.stringify(this.request.data)
          : this.request.data;
      return `--data '${data}'`.trim();
    } else {
      return '';
    }
  }

  getUrl(): string {
    if (this.request.baseURL) {
      return this.request.baseURL + '/' + this.request.url;
    }
    return this.request.url ?? '';
  }

  getQueryString(): string {
    let params = '',
      i = 0;

    for (const param in this.request.params) {
      if ({}.hasOwnProperty.call(this.request.params, param)) {
        params += i !== 0 ? `&${param}=${this.request.params[param]}` : `?${param}=${this.request.params[param]}`;
        i++;
      }
    }

    return params;
  }

  getBuiltURL(): string {
    let url = this.getUrl() ?? '';

    if (this.getQueryString() !== '') {
      url = url.charAt(url.length - 1) === '/' ? url.substr(0, url.length - 1) : url;
      url += this.getQueryString();
    }

    return url.trim().replace(/([a-zA-Z]+)\/\//g, '$1/');
  }

  generateCommand(): string {
    return `curl ${this.getMethod()} ${this.getHeaders()} ${this.getBody()} "${this.getBuiltURL()}"`
      .trim()
      .replace(/\s{2,}/g, ' ');
  }
}

export class CurlLoggingInterceptor implements RequestInterceptor {
  async onFulfilled(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const curlHelper = new CurlHelper(config);
    console.log(curlHelper.generateCommand());

    return config;
  }
}
