import { Route, RouteProps, Routes } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';
import * as Style from '../dashboard/main/style';
import { Profile } from '../dashboard';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { OrganizationData } from '../models/OrganizationData';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import RoleDashboard from '../dashboard/multitier/AdminDashboard';

const DrillDownRoutes: React.FC<RouteProps> = () => {
  const appStorage = useAppStorage();
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const selectedOrganization = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );

  return (
    <Style.MainWrapper>
      <Style.MainLayout>
        <TopNavBar hideCompanyLogo hideDashboard={!selectedOrganization} useDrillDownCrumbs />
        <Style.MainSection>
          <Style.MainContentRight>
            <Routes>
              <Route path='/choose-group' element={<RoleDashboard />} />
              <Route path='/choose-team' element={<RoleDashboard />} />
              <Route path='/choose-organization' element={<RoleDashboard />} />
              <Route path='/profile' element={<Profile />} />
            </Routes>
          </Style.MainContentRight>
        </Style.MainSection>
      </Style.MainLayout>
    </Style.MainWrapper>
  );
};

export default DrillDownRoutes;
