import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Typography, Card, CardContent, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LicenseMetricsData } from '../../api/apis/LicenseMetricsApi';
import { Svgs } from '../../assets/svg';
import { Spacer, Row, Column } from '../../components';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { ProductTypeCompartments, productTypeStorageKey } from '../../data/productType';
import { filterByPayer, formatDollars } from '../../helpers/utilityFunctions';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { PricebookBundle, PaymentTypeEnum } from '../../models/PricebookData';
import PurchaseLicensesDrawer from '../license/PurchaseLicensesDrawer';
import MemberPaysMetricsCard from './MemberPaysMetricsCard';
import OrgPaysMetricsCard from './OrgPaysMetricsCard';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';

const AtAGlance: React.FC = () => {
  const navigate = useNavigate();
  const appStorage = useAppStorage();
  const [packagesAvailable, setPackagesAvailable] = useState(false);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);

  const productTypeDataCache = appStorage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
  const paidBy = useObservable(productTypeDataCache.observe$<string>('paidBy'));
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const licenseMetrics =
    useObservable(licenseDataCache.observe$<LicenseMetricsData[]>('licenseMetrics')) ?? undefined;
  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  // eslint-disable-next-line
  const pricebookOptions =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions')) ?? [];
  const pricebookSelections = useObservable(
    pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections'),
  );
  const pendingAgreement =
    licenseAgreements &&
    licenseAgreements?.find(
      licenseAgreement => licenseAgreement.docStatus && !licenseAgreement.hasAccepted,
    );
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );
  const [purchaseDrawerOpen, setPurchaseDrawerOpen] = useState(false);
  const [selectedPricebook, setSelectedPricebook] = useState<PricebookBundle | undefined>(
    undefined,
  );
  const meteredPayment = pricebookSelections?.some(
    pricebook => pricebook.pricebook.paymentType === PaymentTypeEnum.Metered,
  );

  useEffect(() => {
    const filteredPricebook = filterByPayer(pricebookOptions, paidBy);
    setPackagesAvailable(filteredPricebook.length > (pricebookSelections?.length ?? 0));
  }, [paidBy, pricebookOptions, pricebookSelections?.length]);

  useEffect(() => {
    let count = 0;
    licenseMetrics?.map(license => (count += license.activeLicenses));
    setTotalActiveUsers(count);
  }, [licenseMetrics]);

  const findPricebookById = (id: number): PricebookBundle | undefined => {
    const bundle = pricebookSelections?.find(pricebook => pricebook.pricebook.id === id);
    if (
      bundle &&
      bundle.pricebook &&
      (pricebookSelections?.filter(
        pricebook => pricebook.pricebook.pricebookName === bundle.pricebook.pricebookName,
      )?.length ?? 0) > 1
    ) {
      const bundleCopy = {
        pricebook: { ...bundle?.pricebook },
        prices: [...(bundle?.prices ?? [])],
      };
      bundleCopy.pricebook.pricebookName =
        bundleCopy.pricebook.pricebookName +
        ' (' +
        formatDollars((bundleCopy.prices[0].price ?? 0) / 100.0) +
        ')';
      return bundleCopy;
    }
    return bundle;
  };

  const onPurchaseLicenses = (pricebook: PricebookBundle) => {
    setSelectedPricebook(pricebook);
    setPurchaseDrawerOpen(true);
  };
  const onAddPackage = () => {
    navigate('/addPackage', { state: { origin: 'dashboard' } });
  };

  return (
    <>
      <Typography variant='h1' color='secondary'>
        At a glance
      </Typography>
      <Spacer height='xxs' />
      <Card variant='ghost' color='primary'>
        <CardContent className='pad30'>
          {(licenseMetrics?.length ?? 0) === 0 ? (
            <Row style={{ justifyContent: 'center' }}>
              <Typography variant='h3' color='secondary'>
                You do not have any active license agreements.
              </Typography>
            </Row>
          ) : paidBy === 'ORG' && !meteredPayment ? (
            <Column>
              <Row style={{ alignItems: 'center' }}>
                <Svgs.IconMembersLarge />
                <Spacer width='xs' />
                <Typography variant='p20SemiBold' color='secondary'>
                  Active Users
                </Typography>
                <Spacer width='xs' />
                <Typography variant='p60Bold' color='secondary.dark'>
                  {totalActiveUsers}
                </Typography>
              </Row>
              <Spacer height='xxs' />
              {licenseMetrics?.map(metric => {
                const pricebook = findPricebookById(metric.pricebookId);
                return (
                  pricebook !== undefined && (
                    <OrgPaysMetricsCard
                      key={metric.pricebookId}
                      licenseMetric={metric}
                      pricebook={pricebook}
                      onPurchaseLicenses={() => onPurchaseLicenses(pricebook)}
                      purchaseDisabled={typeof pendingAgreement !== 'undefined'}
                    />
                  )
                );
              })}
            </Column>
          ) : (
            <Column>
              <Row style={{ justifyContent: 'space-between' }}>
                <Row style={{ alignItems: 'center' }}>
                  <Svgs.IconMembersLarge />
                  <Spacer width='xs' />
                  <Typography variant='p20SemiBold' color='secondary'>
                    Active Users
                  </Typography>
                  <Spacer width='xs' />
                  <Typography variant='p60Bold' color='secondary.dark'>
                    {totalActiveUsers}
                  </Typography>
                </Row>
                {packagesAvailable && groupFeatureMapData?.canAddPackage && (
                  <Button
                    onClick={onAddPackage}
                    className='lg'
                    style={{ alignSelf: 'center' }}
                    color='primary'>
                    Add a package
                  </Button>
                )}
              </Row>
              <Spacer height='xxs' />
              {licenseMetrics?.map((metric, index) => {
                const pricebook = findPricebookById(metric.pricebookId);
                return (
                  pricebook !== undefined && (
                    <React.Fragment key={metric.pricebookId}>
                      <MemberPaysMetricsCard licenseMetric={metric} pricebook={pricebook} />
                      {index !== licenseMetrics.length && <Spacer height='xxs' />}
                    </React.Fragment>
                  )
                );
              })}
            </Column>
          )}
        </CardContent>
      </Card>
      {selectedPricebook && (
        <PurchaseLicensesDrawer
          open={purchaseDrawerOpen}
          setOpen={setPurchaseDrawerOpen}
          pricebook={selectedPricebook}
        />
      )}
    </>
  );
};

export default AtAGlance;
