import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Svgs } from '../assets/svg';

export interface SearchboxProps extends React.PropsWithChildren {
  label?: string;
  placeholder?: string;
  inputRef?: React.RefObject<any>;
  address?: string;
  callback?: (value: string) => void;
  style?: React.CSSProperties;
  id?: string;
}

const Searchbox: React.FC<SearchboxProps> = props => {
  const { address } = props;
  const [inputText, setInputText] = useState<string>('');
  const inputHandler = (e: any) => {
    setInputText(e.target.value);
  };

  const clearInput = (e: any) => {
    setInputText('');
  };

  useEffect(() => {
    if (props.callback) {
      props.callback(inputText);
    }
  }, [props, inputText]);

  useEffect(() => {
    if (!address) {
      return;
    }
    setInputText(address);
  }, [address]);

  return (
    <TextField
      {...props}
      fullWidth={true}
      variant='standard'
      inputRef={props.inputRef}
      label={props.label}
      placeholder={props.placeholder}
      onChange={inputHandler}
      value={inputText}
      sx={{
        label: { color: '#022a3a !important', fontSize: 18, fontWeight: 600 },
        input: { paddingTop: '15px' },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' style={{ marginRight: '10px' }}>
            <Svgs.IconSearch />
          </InputAdornment>
        ),
        endAdornment:
          inputText.length !== 0 ? (
            <InputAdornment position='end'>
              <IconButton edge='end' color='primary' onClick={clearInput}>
                <Svgs.IconDelete />
              </IconButton>
            </InputAdornment>
          ) : null,
      }}>
      {props.children}
    </TextField>
  );
};

export default Searchbox;
