export interface LogoUpload {
  organizationId: number;
  file: File;
}

export interface AddressInfo {
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface OrganizationData {
  id?: number;
  name?: string;
  phoneNumber?: string;
  phoneExtension?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  logoUri?: string;
  type?: string;
  url?: string;
  qrUri?: string;
  restrictMemberAccess?: RestrictionEnum;
  organizationCipher?: string;
  automaticPayment?: boolean;
}

export interface CommunicationsData {
  addedEmail: boolean;
  discontinuedEmail: boolean;
}
export interface EmailTemplateData {
  name: string;
  purpose: string;
  status: boolean;
  content: string;
}

export const DefaultCommunicationsData: CommunicationsData = {
  addedEmail: true,
  discontinuedEmail: false,
};

export type EmailTemplate = { templateName: string; purpose: string; status: boolean };
export type LicenseInfo = { product: string; date: string; admin: string; id: number };
export type PaymentMethodData = { paymentName: string; paymentType: string };
export type PaymentHistoryData = {
  description: string;
  totalAmount: string;
  paymentMethod: string;
  datePaid: string;
  button: JSX.Element;
};
export type MemberRow = {
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  deletableRow?: boolean;
};

export enum ProductEnum {
  GuidedChoice,
  Nickels,
}
export enum RestrictionEnum {
  DOMAIN = 'DOMAIN',
  LIST = 'LIST',
  NONE = 'NONE',
}
