import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { TrustFamilyApi, TrustFamilyData } from '../../../api/apis/TrustFamilyApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { TrustFamilyCompartments, trustFamilyStorageKey } from '..';

export class AddTrustFamily implements IMutation<TrustFamilyData> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const trustFamilyApi = container.get<TrustFamilyApi>(ApiKeys.TrustFamily);

    await trustFamilyApi.addTrustFamily({ ...data });

    const dataCache = storage.retrieve<TrustFamilyCompartments>(trustFamilyStorageKey);
    await dataCache.reload('trustFamilyData');
  }
}

export class EditTrustFamily implements IMutation<TrustFamilyData> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const trustFamilyApi = container.get<TrustFamilyApi>(ApiKeys.TrustFamily);

    await trustFamilyApi.editTrustFamily(data);

    const dataCache = storage.retrieve<TrustFamilyCompartments>(trustFamilyStorageKey);
    await dataCache.reload('trustFamilyData');
  }
}
