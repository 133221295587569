import React, { useEffect } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Row } from '.';

interface DropdownMenuProps extends React.PropsWithChildren {
  options: any[];
  label?: string;
  selected?: string | null;
  innerRef?: React.RefObject<any>;
  callback?: (value: string) => void;
  inputStyles?: any;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  options,
  label,
  selected: selectedParam,
  innerRef,
  callback,
  inputStyles,
}) => {
  const [selected, setSelected] = React.useState('');

  useEffect(() => {
    if (selectedParam) {
      setSelected(selectedParam);
    }
  }, [selectedParam]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
    callback && callback(event.target.value);
  };

  return (
    <Row style={{ alignItems: 'center' }}>
      {label && (
        <Typography variant='h2' color='info.main'>
          {label}
        </Typography>
      )}
      <FormControl variant='standard' sx={{ minWidth: 120, ...inputStyles }}>
        <Select ref={innerRef} labelId='label' id='id' value={selected} onChange={handleChange}>
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          å
        </Select>
      </FormControl>
    </Row>
  );
};

export default DropdownMenu;
