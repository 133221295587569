import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Column, Row, Spacer } from '../../components';
import { useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { OffboardingWizard, offboardingWizardKey } from '../../services/offboarding';
import { useDeviceType } from '../../hooks/useDeviceType';

export interface TodoListProps {
  hideButton?: boolean;
}

const TodoList: React.FC<TodoListProps> = ({ hideButton }) => {
  const navigate = useNavigate();
  const offboardingWizard = useService<OffboardingWizard>(offboardingWizardKey);
  const todos = useObservable(offboardingWizard.todoList$) ?? [];
  const finalTodo = todos.length;

  const onStart = () => {
    const step = offboardingWizard.resumeOffboarding('');
    navigate(step.route);
  };
  const { isMobile, isTablet } = useDeviceType();

  return (
    <>
      {isMobile || isTablet ? (
        <Column>
          {todos.map(todo => {
            return (
              <div key={todo.number}>
                <Row style={{ alignItems: 'center' }}>
                  <todo.art />
                  <Spacer width='xs' />
                  <Typography variant='h2' color='secondary'>
                    {todo.number}. {todo.text}
                  </Typography>
                </Row>
                {todo.number !== finalTodo ? <Spacer height='xs' /> : null}
              </div>
            );
          })}
          <Spacer height='xs' />
          {!hideButton && (
            <Button
              onClick={onStart}
              style={{ alignSelf: 'center', width: '100%' }}
              className='md'
              color='primary'>
              Start
            </Button>
          )}
        </Column>
      ) : (
        <>
          <Column>
            {todos.map(todo => {
              return (
                <div key={todo.number}>
                  <Row style={{ alignItems: 'center' }}>
                    <todo.art />
                    <Spacer width='xs' />
                    <Typography variant='h2' color='secondary'>
                      {todo.number}. {todo.text}
                    </Typography>
                  </Row>
                  {todo.number !== finalTodo ? <Spacer height='xs' /> : null}
                </div>
              );
            })}
          </Column>
          {!hideButton && (
            <Button
              onClick={onStart}
              style={{ alignSelf: 'flex-end' }}
              className='md'
              color='primary'>
              Start
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default TodoList;
