import { useService } from '@aesop-fables/containr-react';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { SetStateAction, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GroupCompartments, groupStorageKey } from '../data/group';
import { UnselectGroup } from '../data/group/mutations';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../data/groupFeatureMap';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { UnselectOrganization } from '../data/organization/mutations';
import { TeamCompartments, teamStorageKey } from '../data/team';
import { UnselectTeam } from '../data/team/mutations';
import { GroupData } from '../models/GroupData';
import { GroupFeatureMapData } from '../models/GroupFeatureMapData';
import { OrganizationData } from '../models/OrganizationData';
import { TeamData } from '../models/TeamData';
import { DrillDownWizard, drillDownWizardKey, AdminTierTypeEnum } from '../services/drillDown';

const useResetToOrigin = (
  onGo: boolean,
  setOnGo: { (value: SetStateAction<boolean>): void; (value: SetStateAction<boolean>): void },
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const appStorage = useAppStorage();

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const unselectGroup = useMutation(new UnselectGroup());
  const unselectTeam = useMutation(new UnselectTeam());
  const unselectOrganization = useMutation(new UnselectOrganization());

  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
  const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);
  const orgDataCache = appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  const groupInit = useObservable(groupDataCache.initialized$());
  const teamInit = useObservable(teamDataCache.findCompartment('team').initialized$());
  const orgInit = useObservable(orgDataCache.findCompartment('organization').initialized$());

  const groupId = useObservable(groupDataCache.observe$<GroupData>('group'))?.id ?? 0;
  const teamId = useObservable(teamDataCache.observe$<TeamData>('team'))?.id ?? 0;
  const orgId = useObservable(orgDataCache.observe$<OrganizationData>('organization'))?.id ?? 0;

  const highestAccess = useObservable(drillDownWizard.highestAccess$);
  const origin = useObservable(drillDownWizard.origin$);
  const inOnboarding = location.pathname.includes('onboarding');

  useEffect(() => {
    const findOrigin = async () => {
      if (
        groupFeatureMapData?.anyoneCanCreateOrgs ||
        (groupFeatureMapData?.disableBreadCrumbs && inOnboarding)
      ) {
        navigate(location.pathname);
      } else if (origin === AdminTierTypeEnum.Group) {
        navigate('/drill-down/choose-group');
        if (groupId) {
          await unselectGroup.action(groupId);
        }
        if (teamId) {
          await unselectTeam.action(teamId);
        }
        if (orgId) {
          await unselectOrganization.action(orgId);
        }
      } else if (highestAccess === AdminTierTypeEnum.Group) {
        navigate('/controller/teams');
        if (teamId) {
          await unselectTeam.action(teamId);
        }
        if (orgId) {
          await unselectOrganization.action(orgId);
        }
      } else if (origin === AdminTierTypeEnum.Team) {
        navigate('/drill-down/choose-team');
        if (teamId) {
          await unselectTeam.action(teamId);
        }
        if (orgId) {
          await unselectOrganization.action(orgId);
        }
      } else if (origin === AdminTierTypeEnum.Organization) {
        navigate('/drill-down/choose-organization');
        if (orgId) {
          await unselectOrganization.action(orgId);
        }
      } else if (highestAccess === AdminTierTypeEnum.Team) {
        navigate('/controller/organizations');
        if (orgId) {
          await unselectOrganization.action(orgId);
        }
      } else {
        navigate('/');
      }
      setOnGo(false);
    };
    if (groupInit && teamInit && orgInit && onGo) {
      findOrigin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInit, teamInit, orgInit, onGo]);
};

export default useResetToOrigin;
