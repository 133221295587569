import { Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { PlanData } from '../../../models/PlanData';
import { ToastbarContext } from '../../../App';
import {
  EditableCard,
  KeyValueLabel,
  RightDrawer,
  Row,
  Spacer,
  ToggleInput,
} from '../../../components';
import { RightDrawerProps } from '../../../components/RightDrawer';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { PlanCompartments, planStorageKey } from '../../../data/plan';
import { EditPlan } from '../../../data/plan/mutations';
import { useLoading } from '../../../hooks/useLoading';
import { Toast } from '../../../models/Toast';

const MemberContributionsCard = () => {
  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <EditableCard
      onEditClick={() => setDrawerOpen(drawer => !drawer)}
      editable
      title='Member contributions'>
      <KeyValueLabel
        keyString='Percent of pay (%) or fixed dollar amount ($)?'
        value={
          plan?.deferralChoiceAllowed === 'P'
            ? '% of pay'
            : plan?.deferralChoiceAllowed === 'D'
            ? 'Fixed $ amount'
            : plan?.deferralChoiceAllowed === 'B'
            ? 'Both'
            : undefined
        }
      />
      {!(plan?.planType === '401(a)') && (
        <KeyValueLabel
          keyString='Are Roth contributions permitted?'
          value={plan?.roth401kAllowed ? 'Yes' : 'No'}
        />
      )}
      <KeyValueLabel
        keyString='Are after-tax contributions permitted?'
        value={
          plan?.planContribPercentLimitPosttax === 0
            ? 'No'
            : (plan?.planContribPercentLimitPosttax ?? 0) > 0
            ? 'Yes'
            : undefined
        }
      />

      <PlanMemberContributionsCardEdit
        title='Member contributions'
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </EditableCard>
  );
};

const PlanMemberContributionsCardEdit: React.FC<RightDrawerProps> = ({ title, open, setOpen }) => {
  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));
  const editPlan = useMutation(new EditPlan());

  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (editPlan.error) {
      console.error(editPlan.error);
      setLoading(false);
      setToast(
        new Toast({
          message: 'Could not save plan',
          severity: 'error',
          open: true,
        }),
      );
    } else if (editPlan.status === MutationStatus.Complete) {
      setOpen(false);
      setLoading(false);
      setToast(
        new Toast({ message: 'Member contributions updated!', severity: 'success', open: true }),
      );
      planDataCache.reload('plan');
    }
  }, [editPlan.error, editPlan.status, planDataCache, setOpen, setLoading, setToast]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    const contributionMethod = values['contributionMethod'];
    const planData = {
      ...plan,
      deferralChoiceAllowed:
        contributionMethod === '% of pay'
          ? 'P'
          : contributionMethod === 'Fixed $ amount'
          ? 'D'
          : 'B',
      roth401kAllowed: values['rothAllowed'] === 'Yes',
      planContribPercentLimitPosttax: values['afterTaxAllowed'] === 'Yes' ? 100 : 0,
    };

    await editPlan.action(planData);
  };

  return (
    <RightDrawer open={open} setOpen={setOpen} title={title}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Spacer height='sm' />
            <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
              <Typography
                sx={{ flex: '2 0 10px', textAlign: 'right' }}
                variant='p18SemiBold'
                color='secondary.dark'>
                Contribution methods
              </Typography>
              <div style={{ flex: '2 0 10px' }} />
            </Row>
            <Spacer height='xxs' />
            <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
              <Typography
                sx={{ flex: '2 0 10px', textAlign: 'right' }}
                variant='body1'
                color='info.main'>
                Are members’ contributions a percentage of pay (%) or a fixed dollar amount ($)?
              </Typography>
              <Row style={{ flex: '2 0 10px' }}>
                <ToggleInput
                  fullWidth
                  buttonStyle={{ maxWidth: '200px', height: '45px' }}
                  initialValue={
                    plan?.deferralChoiceAllowed === 'P'
                      ? '% of pay'
                      : plan?.deferralChoiceAllowed === 'D'
                      ? 'Fixed $ amount'
                      : plan?.deferralChoiceAllowed === 'B'
                      ? 'Both'
                      : '% of pay'
                  }
                  fieldName='contributionMethod'
                  required={true}
                  items={['% of pay', 'Fixed $ amount', 'Both']}
                />
              </Row>
            </Row>
            <Spacer height='sm' />

            <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
              <Typography
                sx={{ flex: '2 0 10px', textAlign: 'right' }}
                variant='p18SemiBold'
                color='secondary.dark'>
                Contribution types
              </Typography>
              <div style={{ flex: '2 0 10px' }} />
            </Row>
            <Spacer height='xxs' />
            {!(plan?.planType === '401(a)') && (
              <>
                <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                  <Typography
                    sx={{ flex: '2 0 10px', textAlign: 'right' }}
                    variant='body1'
                    color='info.main'>
                    Are Roth contributions permitted?
                  </Typography>
                  <Row style={{ flex: '2 0 10px' }}>
                    <ToggleInput
                      fullWidth
                      buttonStyle={{ maxWidth: '200px', height: '45px' }}
                      fieldName='rothAllowed'
                      initialValue={
                        plan?.roth401kAllowed
                          ? 'Yes'
                          : plan?.roth401kAllowed === false
                          ? 'No'
                          : 'No'
                      }
                      required={true}
                      items={['Yes', 'No']}
                    />
                  </Row>
                </Row>
                <Spacer height='xs' />
              </>
            )}
            <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
              <Typography
                sx={{ flex: '2 0 10px', textAlign: 'right' }}
                variant='body1'
                color='info.main'>
                Are after-tax contributions permitted?
              </Typography>
              <Row style={{ flex: '2 0 10px' }}>
                <ToggleInput
                  fullWidth
                  buttonStyle={{ maxWidth: '200px', height: '45px' }}
                  fieldName='afterTaxAllowed'
                  initialValue={
                    plan?.planContribPercentLimitPosttax !== undefined
                      ? plan.planContribPercentLimitPosttax > 0
                        ? 'Yes'
                        : 'No'
                      : 'No'
                  }
                  required={true}
                  items={['Yes', 'No']}
                />
              </Row>
            </Row>

            <Spacer height='sm' />

            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button style={{ width: 127 }} color='primary' onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default MemberContributionsCard;
