import { Card, CardContent, Link, Typography } from '@mui/material';
import Row from './Row';
import Spacer from './Spacer';

interface EditableCardProps {
  title?: string;
  children: React.ReactNode;
  editable?: boolean;
  onEditClick?: () => void;
}

const EditableCard: React.FC<EditableCardProps> = props => {
  const { title, editable, onEditClick } = props;
  return (
    <Card variant='ghost' color='primary'>
      <CardContent className='basicCard'>
        <Typography variant='subtitle1' color='secondary.main'>
          <Row style={{ justifyContent: 'space-between' }}>
            {title}
            {editable ? (
              <Link onClick={onEditClick} style={{ alignSelf: 'flex-end' }} variant='body2'>
                Edit
              </Link>
            ) : null}
          </Row>
        </Typography>
        <Spacer height='xxs' />
        {props.children}
      </CardContent>
    </Card>
  );
};

export default EditableCard;
