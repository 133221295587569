import { Typography, Button } from '@mui/material';
import { ChargeData } from '../../../api/apis/ChargeApi';
import { RightDrawer, Spacer, Row, Column } from '../../../components';
import BillingDetails from './BillingDetails';
import InvoiceDetails from './InvoiceDetails';
import ProductDetails from './ProductDetails';

interface PastInvoiceDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: ChargeData | undefined;
}
const PastInvoiceDrawer: React.FC<PastInvoiceDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  data,
}) => {
  const receiptUrl = data?.charge?.receiptUrl ?? '';

  const onViewReceipt = () => {
    window.open(receiptUrl);
  };
  return (
    <RightDrawer
      open={drawerOpen}
      setOpen={setDrawerOpen}
      smallTitle='View payment history'
      title='View past invoice'>
      {receiptUrl ? (
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography color='secondary' variant='h2' sx={{ width: '80%' }}>
            You can easily view and download your receipt.
          </Typography>
          <Button onClick={onViewReceipt} color='primary' style={{ minWidth: 'fit-content' }}>
            View Receipt
          </Button>
        </Row>
      ) : (
        <>
          <Spacer height='xxs' />
          <Typography color='error.dark' variant='h2' sx={{ width: '90%' }}>
            Your receipt hasn’t been generated yet. Check back in a few days to view and download
            it.
          </Typography>
        </>
      )}
      <Spacer height='sm' />
      <Row style={{ justifyContent: 'center' }}>
        <Column>
          <InvoiceDetails
            invoiceNumber={data?.charge?.invoiceId}
            invoiceDate={data?.charge?.datePaid}
          />
          <Spacer height='xxs' />
          <ProductDetails
            productName={data?.charge?.description}
            total={(data?.charge?.amountPaid ?? 0) / 100}
          />
          <Spacer height='xxs' />
          <BillingDetails
            name={data?.charge?.name}
            email={data?.charge?.email}
            zipCode={data?.paymentInfo?.zipCode}
            country={data?.paymentInfo?.country}
          />
        </Column>
      </Row>
      <Spacer height='lg' />
      <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
        Close
      </Button>
    </RightDrawer>
  );
};

export default PastInvoiceDrawer;
