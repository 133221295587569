import { SubscriptionCompartments, subscriptionStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { SubscriptionApi, SubscriptionRest } from '../../../api/apis/SubscriptionApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';

export class AddLicenses implements IMutation<SubscriptionRest> {
  async execute(context: MutationContext<SubscriptionRest>): Promise<void> {
    const { data, storage, container } = context;
    const subscriptionApi = container.get<SubscriptionApi>(ApiKeys.Subscription);

    await subscriptionApi.add(data);

    const cache = storage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
    await cache.reloadAll();
  }
}

export class DecreaseLicenses implements IMutation<SubscriptionRest> {
  async execute(context: MutationContext<SubscriptionRest>): Promise<void> {
    const { data, storage, container } = context;
    const subscriptionApi = container.get<SubscriptionApi>(ApiKeys.Subscription);

    await subscriptionApi.decrease(data);

    const cache = storage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
    await cache.reloadAll();
  }
}

export class CancelInvoice implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const subscriptionApi = container.get<SubscriptionApi>(ApiKeys.Subscription);

    await subscriptionApi.cancelInvoice(data);

    const cache = storage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
    await cache.reloadAll();
  }
}
