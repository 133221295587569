import { MutableState, Tools } from 'final-form';
import { v4 as uuidv4 } from 'uuid';
import { PricebookBundle } from '../models/PricebookData';
import { Dayjs } from 'dayjs';

const sortData = (
  data: any,
  by: any,
  sortDirection: any,
  setNewData: any,
  setSortBy: any,
  customSorts?: {
    column: string;
    function: (a: any, b: any) => number;
  }[],
) => {
  const sortedData = [...data];
  const customSort = customSorts?.find(sort => sort.column === by);

  if (customSort) {
    sortedData.sort((a, b) => customSort.function(a, b) * (sortDirection ? 1 : -1));
  } else {
    sortedData.sort((a: any, b: any) => {
      const itemA = a[by]?.toUpperCase();
      const itemB = b[by]?.toUpperCase();
      return sortDirection ? (itemA > itemB ? 1 : -1) : itemA > itemB ? -1 : 1;
    });
  }

  setNewData(sortedData);
  setSortBy(by);
  return sortedData;
};

const sortDollars = (a: any, b: any) => {
  return convertDollarToNumber(a) - convertDollarToNumber(b);
};

const convertDollarToNumber = (dollar: string) => {
  return parseFloat(dollar.replace(/[$,]/g, ''));
};

const extractCalendarDate = (date: Dayjs): string => {
  return date.format('YYYY-MM-DD');
};

const formatDateMMDDYYYY = (dateString: string): string => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // add 1 for 0 indexing
  const day = date.getDate();
  const year = date.getFullYear();

  return month + '/' + day + '/' + year;
};

const formatDateYYYYMMDD = (dateString: string): string => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // add 1 for 0 indexing
  const day = date.getDate();
  const year = date.getFullYear();

  return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
};

const trimPhoneNumber = (value: string) => {
  return value.replace(/[^\d]/g, '');
};

const formatPhoneNumber = (value: string | undefined) => {
  if (!value) return undefined;
  const currentValue = trimPhoneNumber(value);
  const cvLength = currentValue.length;
  if (cvLength < 4) {
    return currentValue;
  }
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

const filterByPayer = (pricebookOptions: PricebookBundle[], paidBy: string | undefined) => {
  if (paidBy) {
    const filtered = pricebookOptions.filter(pricebook => pricebook.pricebook.paidBy === paidBy);
    return filtered;
  }
  return pricebookOptions;
};

const formatDollars = (amount: number, decimalPlaces?: number): string => {
  const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalPlaces,
  });
  return formatter.format(amount);
};

const formatTwoDecimalPlaces = (value: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

// will display whole number if decimals are ".00"
const formatDecimalOrWholeNumber = (value: number | null | undefined): string => {
  if (value == null) return '0';

  const val = parseFloat(value.toString());
  if (val % 1 < 0.01) {
    return Math.floor(val).toString();
  }
  return formatTwoDecimalPlaces(val);
};

const formatNumber = (x: number): string => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 0 });
  return formatter.format(x);
};

// * https://github.com/jquense/yup
// managing validation errors for yup
const reduceValidationError = (validationError: any) =>
  validationError.inner.reduce((total: any, currentValue: any) => {
    total[currentValue.path] = currentValue.message;
    return total;
  }, {});

/**
 * @desc disallow special characters
 * @return String -> Boolean
 */
const stringWhitelist = (str: string) => !/[^a-zA-Z0-9-, .()']+/g.test(str);

const validateEmail = (value: string) => {
  if (!/\S+@\S+\.\S+/.test(value)) {
    return 'Invalid Email';
  }
};

const uniqueId = () => {
  return uuidv4();
};

const mutateFieldValue = (args: any[], state: MutableState<any>, tools: Tools<any>) => {
  const [fieldName, value] = args;
  tools.changeValue(state, fieldName, () => value);
};

const convertToTitleCase = (str: string) => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/Us/g, 'US');
};

export {
  sortData,
  sortDollars,
  convertDollarToNumber,
  extractCalendarDate,
  formatDateMMDDYYYY,
  formatDateYYYYMMDD,
  formatPhoneNumber,
  trimPhoneNumber,
  filterByPayer,
  formatDollars,
  formatTwoDecimalPlaces,
  formatDecimalOrWholeNumber,
  formatNumber,
  reduceValidationError,
  stringWhitelist,
  validateEmail,
  uniqueId,
  mutateFieldValue,
  convertToTitleCase,
};
