import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from '@mui/material';
import { Row, Column, Spacer } from '../components';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { ProductTypeEnum } from '../models/PricebookData';

export interface HelpChooseProductProps {
  recommendedProduct?: string;
  setRecommendedProduct: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const HelpChooseProduct: React.FC<HelpChooseProductProps> = ({
  recommendedProduct,
  setRecommendedProduct,
}) => {
  const [selection, setSelection] = useState<number>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const email = 'help@guidedchoice.com';

  const isSelected = (id: number) => {
    return selection === id;
  };

  const onSelect = (id: number) => {
    if (isSelected(id)) {
      setSelection(undefined);
      return;
    }
    setSelection(id);
  };

  const onChange = (event: React.SyntheticEvent, value: boolean) => {
    if (!value) {
      setSelection(undefined);
      setRecommendedProduct(undefined);
    }
    setExpanded(value);
  };

  const onEmail = () => {
    window.location.href = `mailto:${email}`;
  };

  const { isMobile, isTablet } = useDeviceType();

  useEffect(() => {
    const makeRecommendation = () => {
      switch (selection) {
        case 0:
        case 2:
          setRecommendedProduct(ProductTypeEnum.GuidedChoice);
          break;
        case 1:
        case 3:
          setRecommendedProduct(ProductTypeEnum.Nickels);
      }
    };

    makeRecommendation();
  }, [selection, setRecommendedProduct]);

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        sx={{ '& > *': { fill: theme => theme.palette.secondary.main } }}
        expandIcon={expanded ? <Svgs.IconCollapse /> : <Svgs.IconAdd />}>
        <Typography variant='p18SemiBold' color='secondary'>
          Help me choose a product
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Card variant='ghost' color='primary'>
          <CardContent>
            <Typography variant='h2' color='secondary'>
              Which option best describes your needs?
            </Typography>
            <Spacer height='xs' />
            {isMobile || isTablet ? (
              <Column style={{ justifyContent: 'space-between' }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProductChoiceCard
                    boldText='my retired members have help tax-efficiently spending their savings'
                    art={<Svgs.ArtRetirement />}
                    id={0}
                    onSelect={onSelect}
                    isSelected={isSelected}
                  />
                  <ProductChoiceCard
                    boldText='we grow financial literacy in my organization'
                    art={<Svgs.ArtBooks />}
                    id={1}
                    onSelect={onSelect}
                    isSelected={isSelected}
                  />
                </Row>
                <Spacer height='xs' />
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProductChoiceCard
                    boldText='my members have help with their retirement planning'
                    art={<Svgs.ArtTarget />}
                    id={2}
                    onSelect={onSelect}
                    isSelected={isSelected}
                  />
                  <ProductChoiceCard
                    boldText='my members have access to financial planning for their household'
                    art={<Svgs.ArtHouse />}
                    id={3}
                    onSelect={onSelect}
                    isSelected={isSelected}
                  />
                </Row>
              </Column>
            ) : (
              <Row style={{ justifyContent: 'space-between' }}>
                <ProductChoiceCard
                  boldText='my retired members have help tax-efficiently spending their savings'
                  art={<Svgs.ArtRetirement />}
                  id={0}
                  onSelect={onSelect}
                  isSelected={isSelected}
                />
                <ProductChoiceCard
                  boldText='we grow financial literacy in my organization'
                  art={<Svgs.ArtBooks />}
                  id={1}
                  onSelect={onSelect}
                  isSelected={isSelected}
                />
                <ProductChoiceCard
                  boldText='my members have help with their retirement planning'
                  art={<Svgs.ArtTarget />}
                  id={2}
                  onSelect={onSelect}
                  isSelected={isSelected}
                />
                <ProductChoiceCard
                  boldText='my members have access to financial planning for their household'
                  art={<Svgs.ArtHouse />}
                  id={3}
                  onSelect={onSelect}
                  isSelected={isSelected}
                />
              </Row>
            )}

            {recommendedProduct !== undefined && (
              <>
                <Spacer height='sm' />
                <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Svgs.ArtLightbulbSmall />
                  <Spacer width='sm' />
                  <Typography variant='h2' color='secondary'>
                    Our recommendation:{' '}
                    {recommendedProduct === ProductTypeEnum.GuidedChoice
                      ? 'GuidedChoice'
                      : '3Nickels'}
                  </Typography>
                </Row>
                <Spacer height='xxs' />
                <Typography variant='body1' color='info.main'>
                  Based on your selection(s) above, we recommend{' '}
                  {recommendedProduct === ProductTypeEnum.GuidedChoice
                    ? 'GuidedChoice'
                    : '3Nickels'}{' '}
                  for your members.
                </Typography>
                <Spacer height='xxs' />
                <Row>
                  <Typography variant='h3' color='primary'>
                    Still not sure? We’re available M-F 8am-5pm PST at (800) 774-7459, or anytime
                    at&nbsp;
                  </Typography>
                  <Link
                    style={{ textDecoration: 'underline' }}
                    variant='h3'
                    color='primary'
                    onClick={onEmail}>
                    {email}
                  </Link>
                  <Typography variant='h2' color='primary'>
                    .
                  </Typography>
                </Row>
              </>
            )}
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

interface ProductChoiceCardProps {
  boldText: string;
  art: JSX.Element;
  id: number;
  onSelect: (id: number) => void;
  isSelected: (id: number) => boolean;
}

const ProductChoiceCard: React.FC<ProductChoiceCardProps> = ({
  boldText,
  art,
  id,
  onSelect,
  isSelected,
}) => {
  const selected = isSelected(id);
  const { isMobile, isTablet } = useDeviceType();
  return (
    <>
      {isMobile || isTablet ? (
        <Card
          color='primary'
          variant={selected ? 'selected' : undefined}
          style={{ width: '45%', height: 'auto' }}>
          <CardActionArea style={{ height: '100%', width: '100%' }} onClick={() => onSelect(id)}>
            <Spacer height='xs' />
            <Row style={{ justifyContent: 'center', height: '25%', alignItems: 'center' }}>
              {selected ? <Svgs.DisplayLargeCustomCheckmark /> : art}
            </Row>
            <Spacer height='xxxs' />
            <CardContent className='helpChoose'>
              <Typography style={{ display: 'inline' }} variant='p14'>
                It&apos;s most important to me that{' '}
              </Typography>
              <Typography style={{ display: 'inline', lineHeight: 1.5 }} variant='h4'>
                {boldText}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <Card
          color='primary'
          variant={selected ? 'selected' : undefined}
          style={{ width: '22%', height: 'auto' }}>
          <CardActionArea style={{ height: '100%', width: '100%' }} onClick={() => onSelect(id)}>
            <Spacer height='xs' />
            <Row style={{ justifyContent: 'center', height: '25%', alignItems: 'center' }}>
              {selected ? <Svgs.DisplayLargeCustomCheckmark /> : art}
            </Row>
            <Spacer height='xxxs' />
            <CardContent className='helpChoose'>
              <Typography style={{ display: 'inline' }} variant='p14'>
                It&apos;s most important to me that{' '}
              </Typography>
              <Typography style={{ display: 'inline', lineHeight: 1.5 }} variant='h4'>
                {boldText}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </>
  );
};

export default HelpChooseProduct;
