import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { InviteAdminsDrawer, Page, Row, Spacer } from '../../components';
import { MutatorState, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { capitalize, lowerCase } from 'lodash';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { useService } from '@aesop-fables/containr-react';
import { UserData } from '../../models/UserData';
import { AdminCompartments, adminStorageKey } from '../../data/admin';
import {
  AddGroupAdmin,
  AddOrgAdmin,
  AddTeamAdmin,
  RemoveGroupAdmin,
  RemoveOrgAdmin,
  RemoveTeamAdmin,
} from '../../data/admin/mutations';
import AdminDataTable from '../../components/AdminDataTable';
import ChangeBillingAdmin from './ChangeBillingAdmin';

const RoleAccess: React.FC = () => {
  const appStorage = useAppStorage();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const adminDataCache = appStorage.retrieve<AdminCompartments>(adminStorageKey);

  const tier = useObservable(drillDownWizard.tier$);
  const groupAdmins = useObservable(adminDataCache.observe$<UserData[]>('groupAdmins'));
  const teamAdmins = useObservable(adminDataCache.observe$<UserData[]>('teamAdmins'));
  const orgAdmins = useObservable(adminDataCache.observe$<UserData[]>('orgAdmins'));

  type RoleAccessInfo = {
    role: AdminTierTypeEnum;
    usersList: UserData[] | undefined;
    removeAdmin: MutatorState<number>;
    addAdmin: MutatorState<UserData>;
  };

  const determineRoleAndComponents = (tier: string | undefined): RoleAccessInfo => {
    switch (tier) {
      case AdminTierTypeEnum.Team:
        return {
          role: AdminTierTypeEnum.Group,
          usersList: groupAdmins,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          removeAdmin: useMutation(new RemoveGroupAdmin()),
          // eslint-disable-next-line react-hooks/rules-of-hooks
          addAdmin: useMutation(new AddGroupAdmin()),
        };
      case AdminTierTypeEnum.Organization:
        return {
          role: AdminTierTypeEnum.Team,
          usersList: teamAdmins,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          removeAdmin: useMutation(new RemoveTeamAdmin()),
          // eslint-disable-next-line react-hooks/rules-of-hooks
          addAdmin: useMutation(new AddTeamAdmin()),
        };
      default:
        return {
          role: AdminTierTypeEnum.Organization,
          usersList: orgAdmins,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          removeAdmin: useMutation(new RemoveOrgAdmin()),
          // eslint-disable-next-line react-hooks/rules-of-hooks
          addAdmin: useMutation(new AddOrgAdmin()),
        };
    }
  };

  const { role, usersList, removeAdmin, addAdmin } = determineRoleAndComponents(tier);

  return (
    <Page title={`${capitalize(role)} access`}>
      <Spacer height='xxs' />
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid>
          <Typography variant='p20' color='secondary.main'>
            Give access to other people {role === AdminTierTypeEnum.Team ? 'on' : 'in'} your{' '}
            {lowerCase(role)}.
          </Typography>
          {role === AdminTierTypeEnum.Organization && (
            <>
              <Spacer height='xxs' />
              <ChangeBillingAdmin admins={orgAdmins ?? []} />
            </>
          )}
        </Grid>
        <Button onClick={() => setDrawerOpen(true)} color='primary'>
          Invite to {lowerCase(role)}
        </Button>
        <InviteAdminsDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          role={role}
          addAdmin={addAdmin}
        />
      </Row>
      <Spacer height='sm' />

      <AdminDataTable
        role={role}
        usersList={usersList ?? []}
        removeAdmin={removeAdmin}
        loading={!usersList}
      />
    </Page>
  );
};

export default RoleAccess;
