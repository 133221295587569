import { useContext, useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Column, CornerRibbon, Row, Spacer, StepsHeader } from '../components';
import { logo3NLarge, logo3NLargeWhite, logoGcLarge, logoGcLargeWhite } from '../assets/png';
import { Svgs } from '../assets/svg';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { SignUpWizard, signUpWizardKey } from '../services/signUp';
import { HelpChooseProduct } from '.';
import { useDeviceType } from '../hooks/useDeviceType';
import { StepsHeaderTypes } from '../models/StepsHeaderData';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { OrgSignUpStateData } from '../models/SignUpData';
import { UpdateSignUpState } from '../data/signUp/mutations';
import { LicenseAgreementData } from '../api/apis/LicenseAgreementApi';
import { LicenseCompartments, licenseStorageKey } from '../data/license';
import CancelContract, { cancelContractToast } from '../components/CancelContract';
import { ToastbarContext } from '../App';
import { ProductTypeEnum } from '../models/PricebookData';

const ChooseProduct: React.FC = () => {
  const appStorage = useAppStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>(undefined);
  const [recommendedProduct, setRecommendedProduct] = useState<string | undefined>(undefined);

  const updateSignUpState = useMutation(new UpdateSignUpState());
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );

  const product =
    orgSignUpState?.state && orgSignUpState?.state?.product
      ? orgSignUpState?.state?.product
      : undefined;
  const pricebook =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state?.pricebook);
  const { activeStep, totalSteps } = signUpWizard.currentStep(location);
  const isSelected = (product: string) => selectedProduct === product;
  const isRecommended = (product: string) => recommendedProduct === product;
  const licenseAgreement =
    licenseAgreements && licenseAgreements?.find(license => license.docStatus);
  const pendingAgreement = licenseAgreement?.docStatus && !licenseAgreement?.hasAccepted;
  const activeContract = pendingAgreement || licenseAgreement?.docStatus;

  useEffect(() => {
    if (typeof activeContract === 'undefined' && !pricebook) {
      setSelectedProduct(undefined);
    }
  }, [activeContract, pricebook]);

  useEffect(() => {
    if (!product) return;
    setSelectedProduct(product);
  }, [product]);

  const setProduct = async (selectedProduct: string) => {
    await updateSignUpState.action({
      state: {
        ...orgSignUpState?.state,
        product: selectedProduct,
      },
    });
  };

  const navToNextStep = () => {
    if (selectedProduct) {
      setProduct(selectedProduct);
      const next = signUpWizard.nextStep(location);
      navigate(next?.route ?? '');
    }
  };

  const navBack = () => {
    const step = signUpWizard.previousStep(location);
    navigate(step?.route ?? '');
  };

  const { isMobile, isTablet } = useDeviceType();
  return (
    <Column style={{ margin: isMobile || isTablet ? '0px 20px' : '0px 100px' }}>
      <Spacer height='xs' />
      <StepsHeader
        activeStep={activeStep}
        totalSteps={totalSteps}
        headerSteps={StepsHeaderTypes.Onboarding}
      />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Step {activeStep}: Which product are you interested in?
      </Typography>
      <Spacer height='xs' />
      <HelpChooseProduct
        recommendedProduct={recommendedProduct}
        setRecommendedProduct={setRecommendedProduct}
      />
      <Spacer height='sm' />
      {isMobile || isTablet ? (
        <Column>
          <PlanCard
            altText='GuidedChoice Logo'
            logo={logoGcLarge}
            whiteLogo={logoGcLargeWhite}
            bullets={[
              '• Retirement planning',
              '• Retirement saving and spending',
              '• Best for desktop/laptop',
            ]}
            art={<Svgs.ArtRetirementLarge />}
            product={ProductTypeEnum.GuidedChoice}
            setSelectedProduct={setSelectedProduct}
            selected={isSelected(ProductTypeEnum.GuidedChoice)}
            recommended={isRecommended(ProductTypeEnum.GuidedChoice)}
            activeContract={activeContract && product === ProductTypeEnum.GuidedChoice}
          />
          <Spacer height='xs' />
          <PlanCard
            altText='3Nickels Logo'
            logo={logo3NLarge}
            whiteLogo={logo3NLargeWhite}
            bullets={['• Financial planning', '• Robust financial education', '• Mobile exclusive']}
            art={<Svgs.ArtPieChart />}
            product={ProductTypeEnum.Nickels}
            setSelectedProduct={setSelectedProduct}
            selected={isSelected(ProductTypeEnum.Nickels)}
            recommended={isRecommended(ProductTypeEnum.Nickels)}
            activeContract={activeContract && product === ProductTypeEnum.Nickels}
          />
        </Column>
      ) : (
        <Row style={{ justifyContent: 'space-between' }}>
          <PlanCard
            altText='GuidedChoice Logo'
            logo={logoGcLarge}
            whiteLogo={logoGcLargeWhite}
            bullets={[
              '• Retirement planning',
              '• Retirement saving and spending',
              '• Best for desktop/laptop',
            ]}
            art={<Svgs.ArtRetirementLarge />}
            product={ProductTypeEnum.GuidedChoice}
            setSelectedProduct={setSelectedProduct}
            selected={isSelected(ProductTypeEnum.GuidedChoice)}
            recommended={isRecommended(ProductTypeEnum.GuidedChoice)}
            activeContract={activeContract && product === ProductTypeEnum.GuidedChoice}
          />
          <PlanCard
            altText='3Nickels Logo'
            logo={logo3NLarge}
            whiteLogo={logo3NLargeWhite}
            bullets={['• Financial planning', '• Robust financial education', '• Mobile exclusive']}
            art={<Svgs.ArtPieChart />}
            product={ProductTypeEnum.Nickels}
            setSelectedProduct={setSelectedProduct}
            selected={isSelected(ProductTypeEnum.Nickels)}
            recommended={isRecommended(ProductTypeEnum.Nickels)}
            activeContract={activeContract && product === ProductTypeEnum.Nickels}
          />
        </Row>
      )}

      <Spacer height='sm' />
      {isMobile ? (
        <Column style={{ alignItems: 'center' }}>
          {activeContract && <CancelContract />}
          <Spacer height='xs' />
          <Button
            style={{ width: '100%' }}
            disabled={selectedProduct === undefined && !activeContract}
            onClick={navToNextStep}
            className='sm'
            color='primary'>
            Next: Licenses
          </Button>
          <Spacer height='xs' />
          <Button
            style={{ width: '100%' }}
            onClick={navBack}
            className='xs'
            variant='outlined'
            color='secondary'>
            Back
          </Button>
        </Column>
      ) : (
        <Row style={{ justifyContent: 'space-between' }}>
          <Button onClick={navBack} className='xs' variant='outlined' color='secondary'>
            Back
          </Button>
          <Row style={{ alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
            {activeContract && <CancelContract />}
            <Spacer width='sm' />
            <Button
              disabled={selectedProduct === undefined && !activeContract}
              onClick={navToNextStep}
              className='sm'
              color='primary'>
              Next: Licenses
            </Button>
          </Row>
        </Row>
      )}
      <Spacer height='xs' />
    </Column>
  );
};

interface PlanCardProps {
  altText: string;
  logo: string;
  whiteLogo: string;
  bullets: string[];
  art: JSX.Element;
  product: string;
  setSelectedProduct: React.Dispatch<React.SetStateAction<string | undefined>>;
  selected: boolean;
  recommended: boolean;
  activeContract: boolean | undefined;
}

const PlanCard: React.FC<PlanCardProps> = ({
  altText,
  logo,
  whiteLogo,
  bullets,
  art,
  product,
  setSelectedProduct,
  selected,
  recommended,
  activeContract,
}) => {
  const { setToast } = useContext(ToastbarContext);
  const { isMobile, isTablet } = useDeviceType();

  const setProduct = () => {
    if (activeContract === false) {
      cancelContractToast(setToast);
      return;
    } else if (selected) {
      setSelectedProduct(undefined);
      return;
    }
    setSelectedProduct(product);
  };

  return (
    <div style={{ width: isMobile || isTablet ? '100%' : '45%', position: 'relative' }}>
      {recommended && <CornerRibbon msg='Recommended' />}

      <Card
        color='primary'
        variant={
          activeContract
            ? 'current'
            : selected
            ? 'selected'
            : recommended
            ? 'recommended'
            : undefined
        }>
        <Spacer height='md' />
        <Row style={{ justifyContent: 'center' }}>
          <img
            alt={altText}
            src={selected && !activeContract ? whiteLogo : logo}
            style={{ width: '60%' }}
          />
        </Row>
        <Spacer height='s15' />
        <CardContent className='innerContent'>
          <Row style={{ justifyContent: 'space-between' }}>
            <Column>
              {bullets.map(bullet => (
                <Typography key={bullets.indexOf(bullet)} variant='body1'>
                  {bullet}
                </Typography>
              ))}
            </Column>
            {selected && !activeContract ? (
              <Svgs.DisplayLargeCustomCheckmark style={{ height: 'auto', width: '115px' }} />
            ) : (
              art
            )}
          </Row>
        </CardContent>
        <Spacer height={product ? 's12' : 's15'} />
        <CardActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={setProduct}
            className='onDisabled'
            sx={{ width: '40%' }}
            disabled={activeContract}
            color={activeContract ? 'secondary' : 'tertiary'}
            variant={selected || activeContract ? 'outlined' : undefined}>
            {activeContract ? 'Current' : selected ? `Selected` : `Select`}
          </Button>
        </CardActions>
        <Spacer height='s15' />
      </Card>
    </div>
  );
};

export default ChooseProduct;
