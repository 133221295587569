import { DataCompartment, IMutation, MutationContext } from '@aesop-fables/scrinium';
import { TeamCompartments, teamStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { TeamSelectionApi } from '../../../api/apis/TeamSelectionApi';
import { TeamApi } from '../../../api/apis/TeamApi';
import { TeamData } from '../../../models/TeamData';
import { SignUpCompartments, signUpStorageKey } from '../../signUp';
import { MetricsCompartments, metricsStorageKey } from '../../metrics';

export class SaveTeamData implements IMutation<TeamData> {
  async execute(context: MutationContext<TeamData>): Promise<void> {
    const { data, storage, container } = context;
    const teamApi = container.get<TeamApi>(ApiKeys.Team);
    await teamApi.post(data);

    const teamDataCache = storage.retrieve<TeamCompartments>(teamStorageKey);
    const metricsDataCache = storage.retrieve<MetricsCompartments>(metricsStorageKey);

    await teamDataCache.reloadAll();
    await metricsDataCache.reload('groupMetrics');
  }
}

export class UpdateTeamData implements IMutation<TeamData> {
  async execute(context: MutationContext<TeamData>): Promise<void> {
    const { data, storage, container } = context;
    const teamApi = container.get<TeamApi>(ApiKeys.Team);

    const cache = storage.retrieve<TeamCompartments>(teamStorageKey);
    const currentData = (
      cache.findCompartment('team') as DataCompartment<TeamData>
    ).getData() as TeamData;

    if (currentData?.id) {
      await teamApi.update({ ...currentData, ...data });
    }

    const teamDataCache = storage.retrieve<TeamCompartments>(teamStorageKey);
    teamDataCache.reloadAll();
  }
}

export class SelectTeam implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const teamSelectionApi = container.get<TeamSelectionApi>(ApiKeys.TeamSelection);

    await teamSelectionApi.select(data);

    const teamDataCache = storage.retrieve<TeamCompartments>(teamStorageKey);

    await teamDataCache.reload('team');
  }
}

export class UnselectTeam implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { storage, container } = context;
    const teamSelectionApi = container.get<TeamSelectionApi>(ApiKeys.TeamSelection);

    await teamSelectionApi.unselect();

    const teamDataCache = storage.retrieve<TeamCompartments>(teamStorageKey);
    const signUpDataCache = storage.retrieve<SignUpCompartments>(signUpStorageKey);

    await Promise.all([teamDataCache.reload('team'), signUpDataCache.reset('allOrgSignUpStates')]);
  }
}

export class DeleteTeam implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const teamApi = container.get<TeamApi>(ApiKeys.Team);

    const teamDataCache = storage.retrieve<TeamCompartments>(teamStorageKey);

    await teamApi.delete(data);

    await teamDataCache.reloadAll();
  }
}
