import React from 'react';
import { HelpOutline } from '@mui/icons-material';
import { Grid, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import { Spacer } from '../../../components';
import { Bold } from '../../../components/Bold';
import BulletedList from '../../../components/BulletedList';
import { HelpTitle } from '../../../components/HelpText';
import { AnyReportType, ReportProps } from '../../../models/ReportData';
import { formatNumber } from '../../../helpers/utilityFunctions';
import {
  UserDataTableRow,
  getPricebookReportsByType,
  sumNestedProperty,
} from '../../../helpers/reportUtils';
import { AdminTierTypeEnum, getNextHigherTier } from '../../../services/drillDown';

interface TotalUserAccountsProps extends ReportProps {
  isEmpty?: boolean;
  showHelp?: boolean;
  tier?: AdminTierTypeEnum;
  tableData?: UserDataTableRow[];
}

const TotalUserAccounts: React.FC<TotalUserAccountsProps> = ({
  report,
  isEmpty,
  showHelp,
  tier,
  tableData,
}) => {
  const theme = useTheme();
  const totalUsers = calculateTotalUsers(report);

  if (isEmpty) return null;

  return (
    <Grid item flexDirection='column' pr={5.5}>
      <Typography variant='p30' color='secondary.main'>
        <Bold>{formatNumber(totalUsers)}</Bold>
      </Typography>
      <Grid container flexDirection='row'>
        <Typography variant='p16' color='secondary.main'>
          Total user accounts
        </Typography>
        <Spacer width='xxxs' />
        {showHelp && (
          <Tippy
            placement='right'
            content={<TotalUsersHelpText tier={tier} tableData={tableData} />}
            theme='tippy-rounded'>
            <HelpOutline style={{ color: theme.palette.info.main }} />
          </Tippy>
        )}
      </Grid>
    </Grid>
  );
};

export default TotalUserAccounts;

const calculateTotalUsers = (report: AnyReportType): number => {
  const pricebookReports = getPricebookReportsByType(report);
  const totalCreated = pricebookReports.reduce((total, pricebook) => {
    return (
      total +
      (pricebook?.accountCreationMethodAppleCount ?? 0) +
      (pricebook?.accountCreationMethodGoogleCount ?? 0) +
      (pricebook?.accountCreationMethodManualCount ?? 0)
    );
  }, 0);

  const totalInvited = sumNestedProperty(report, 'invitedCount');
  const undecidedCount = sumNestedProperty(report, 'undecidedCount');

  return totalCreated + totalInvited + undecidedCount;
};

interface TotalUsersHelpTextProps {
  tier?: AdminTierTypeEnum;
  tableData?: UserDataTableRow[];
}

const TotalUsersHelpText: React.FC<TotalUsersHelpTextProps> = ({ tier, tableData }) => {
  const nextTier = tier ? getNextHigherTier(tier) : AdminTierTypeEnum.Organization;

  const descriptions: Record<string, string> = {
    'Total user accounts': `all accounts connected to this ${nextTier?.toLowerCase()}`,
    'Active': 'accounts with an active license',
    'Invited': 'emails that have been added but do not have an account',
    'Undecided': 'users who have either started creating an account or have not chosen a product',
    'Ineligible': `users who have been removed from their ${nextTier?.toLowerCase()}`,
    'In trial period': 'users who have started a free trial from the mobile app',
    'Unsubscribed': 'users who no longer have an active license',
  };

  const relevantDescriptions = [
    'Total user accounts',
    ...(tableData?.map(item => item.rowKey) || []),
  ];

  const listItems = relevantDescriptions
    .filter(key => descriptions.hasOwnProperty(key))
    .map(key => (
      <>
        <Bold>{key}</Bold>: {descriptions[key]}
      </>
    ));

  return (
    <>
      <HelpTitle>User Accounts</HelpTitle>
      <Spacer height='xxs' />
      <BulletedList children={listItems} />
      <Spacer height='xxs' />
    </>
  );
};
