import { OrganizationMetadata } from './../../models/OrganizationMetadata';
import { OrganizationMetadataApi } from './../../api/apis/OrganizationMetadataApi';
import { combineLatest, map } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCache,
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { OrganizationData } from '../../models/OrganizationData';

export interface OrganizationMetadataCompartments {
  metadata: DataCompartmentOptions<OrganizationMetadata | undefined>;
}

export const organizationMetadataStorageKey = 'data/organizationMetadata';

export class OrganizationMetadataDataCachePolicy extends DataCachePolicy<OrganizationMetadataCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.OrganizationMetadata)
    private readonly orgMetadataApi: OrganizationMetadataApi,
  ) {
    super(organizationMetadataStorageKey);
  }

  buildDataCache(): DataCache<OrganizationMetadataCompartments> {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(
      map(
        ([isAuthenticated, organization]) =>
          isAuthenticated === true && typeof organization !== 'undefined',
      ),
    );
    return createObservedDataCache<OrganizationMetadataCompartments>({
      metadata: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const domains = await this.orgMetadataApi.get();
          return domains?.data;
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withOrganizationMetadata = createServiceModule(
  organizationMetadataStorageKey,
  services => services.add<IDataCachePolicy>(Policies, OrganizationMetadataDataCachePolicy),
);
