import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrustFamilyData } from '../../../api/apis/TrustFamilyApi';
import { Svgs } from '../../../assets/svg';

import { EditableCard, KeyValueLabel } from '../../../components';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { TrustFamilyCompartments, trustFamilyStorageKey } from '../../../data/trustFamily';
import { formatDateMMDDYYYY } from '../../../helpers/utilityFunctions';
import LineupTable from '../add-plan/investment-lineup/LineupTable';

const InvestmentLineupCard = () => {
  const navigate = useNavigate();
  const appStorage = useAppStorage();
  const trustFamilyDataCache = appStorage.retrieve<TrustFamilyCompartments>(trustFamilyStorageKey);
  const trustFamilyData = useObservable(
    trustFamilyDataCache.observe$<TrustFamilyData[]>('trustFamilyData'),
  );

  const [expanded, setExpanded] = useState<boolean>(false);
  const trustFamily = trustFamilyData?.at(0);
  const changesMade = (trustFamilyData?.length ?? 0) > 1;
  const newestChanges = changesMade ? trustFamilyData?.slice(-1)[0] : undefined;
  const noncurrentChanges = newestChanges
    ? new Date(newestChanges?.effectiveDate) > new Date()
    : undefined;

  const handleEdit = () => {
    navigate('/plan-info/edit-investment-lineup');
  };

  return (
    <EditableCard onEditClick={handleEdit} editable title='Investment line-up'>
      <KeyValueLabel keyString='Line-up name' value={trustFamily?.trustFamilyName} />
      <KeyValueLabel
        keyString='Number of investment options'
        value={trustFamily?.fundList.length.toString()}
      />
      {noncurrentChanges ? (
        <>
          <KeyValueLabel
            keyString='Effective date for current line-up'
            value={
              trustFamily?.effectiveDate ? formatDateMMDDYYYY(trustFamily.effectiveDate) : undefined
            }
          />
          <KeyValueLabel
            keyString='Effective date for newest changes'
            value={
              newestChanges?.effectiveDate
                ? formatDateMMDDYYYY(newestChanges.effectiveDate)
                : undefined
            }
          />
        </>
      ) : (
        <KeyValueLabel
          keyString='Effective date'
          value={
            trustFamily?.effectiveDate ? formatDateMMDDYYYY(trustFamily.effectiveDate) : undefined
          }
        />
      )}
      <Accordion expanded={expanded} onChange={(event, value) => setExpanded(value)}>
        <AccordionSummary
          sx={{ '& > *': { fill: theme => theme.palette.secondary.main }, 'padding': '0' }}
          expandIcon={expanded ? <Svgs.IconCollapse /> : <Svgs.IconAdd />}>
          <Typography variant='p18SemiBold' color='secondary'>
            View investments
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '-50px' }}>
          {trustFamily?.fundList && (
            <LineupTable
              editing={false}
              data={[...trustFamily.fundList.values()]}
              backgroundColor='white'
            />
          )}
        </AccordionDetails>
      </Accordion>
    </EditableCard>
  );
};

export default InvestmentLineupCard;
