import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { CustomerOrganizationData } from '../../models/CustomerOrganizationData';

export class CustomerOrganizationApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getCustomerOrganization(): Promise<AxiosResponse<CustomerOrganizationData>> {
    return this.axios.get('customer-organization/by-org');
  }
}
