import { CompanyPayrollDeductionApi } from '../../api/apis/CompanyPayrollDeductionApi';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { PayrollDeductionData } from '../../api/apis/CompanyPayrollDeductionApi';
import { UserCompartments, userStorageKey } from '../user';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';

export interface PayrollDeductionCompartments {
  deductions: DataCompartmentOptions<PayrollDeductionData[] | undefined>;
}

export const payrollDeductionStorageKey = 'data/deductions';

export class PayrollDeductionDataCachePolicy extends DataCachePolicy<PayrollDeductionCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.PayrollDeduction)
    private readonly payrollDeductionApi: CompanyPayrollDeductionApi,
  ) {
    super(payrollDeductionStorageKey);
  }

  buildDataCache() {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const groupFeatureMapDataCache =
      this.appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.findCompartment('user').initialized$(),
      groupFeatureMapDataCache.findCompartment('groupFeatureMap').initialized$(),
    ]).pipe(
      map(
        ([isAuthenticated, userInitialized, groupFeatureMapInitialized]) =>
          isAuthenticated === true && userInitialized && groupFeatureMapInitialized,
      ),
    );

    return createObservedDataCache<PayrollDeductionCompartments>({
      deductions: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const groupFeatureMap = await firstValueFrom(
            groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
          );
          if (groupFeatureMap.enableCompanyPayrollInfo) {
            const { data } = await this.payrollDeductionApi.get();
            if (data) {
              return [...data];
            }
          }
          return undefined;
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withPayrollDeductionData = createServiceModule(
  payrollDeductionStorageKey,
  services => {
    services.add<IDataCachePolicy>(Policies, PayrollDeductionDataCachePolicy);
  },
);
