import { ApiKeys } from '../../api/apis/ApiKeys';
import { OnboardingTemplateApi } from '../../api/apis/OnboardingTemplateApi';
import { OrgSignUpStateApi } from '../../api/apis/OrgSignUpStateApi';
import type { IAppStorage } from '@aesop-fables/scrinium';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import {
  ConfiguredDataSource,
  createObservedDataCache,
  DataCache,
  ScriniumServices,
  DataCompartmentOptions,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import { OrgSignUpStateData, TemplateData } from '../../models/SignUpData';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import { map } from 'rxjs';
import { TeamData } from '../../models/TeamData';
import { TeamCompartments, teamStorageKey } from '../team';

export interface SignUpCompartments {
  onboardingTemplate: DataCompartmentOptions<TemplateData | undefined>;
  orgSignUpState: DataCompartmentOptions<OrgSignUpStateData | undefined>;
  allOrgSignUpStates: DataCompartmentOptions<OrgSignUpStateData[] | undefined>;
}

export const signUpStorageKey = 'data/signUp';

export class SignUpDataCachePolicy extends DataCachePolicy<SignUpCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.OnboardingTemplate)
    private readonly onboardingTemplateApi: OnboardingTemplateApi,
    @inject(ApiKeys.OrgSignUpState)
    private readonly orgSignUpStateApi: OrgSignUpStateApi,
  ) {
    super(signUpStorageKey);
  }

  buildDataCache(): DataCache<SignUpCompartments> {
    const dependsOn = this.authContext.isAuthenticated$.pipe(
      map(isAuthenticated => isAuthenticated === true),
    );
    const teamDataCache = this.appStorage.retrieve<TeamCompartments>(teamStorageKey);
    const teamDependsOn = teamDataCache
      .observe$<TeamData>('team')
      .pipe(map(teamData => teamData !== undefined));

    return createObservedDataCache<SignUpCompartments>({
      onboardingTemplate: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.onboardingTemplateApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
      orgSignUpState: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.orgSignUpStateApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
      allOrgSignUpStates: {
        dependsOn: teamDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.orgSignUpStateApi.getAll();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withSignUpData = createServiceModule(signUpStorageKey, services =>
  services.add<IDataCachePolicy>(Policies, SignUpDataCachePolicy),
);
