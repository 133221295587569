import { Button } from '@mui/material';
import { Column, Spacer, Row } from '../../components';
import { useDeviceType } from '../../hooks/useDeviceType';
import CancelContract from '../../components/CancelContract';

interface ContractActionButtonsProps {
  agreeText: string;
  resumeAddPackage: boolean;
  reviewed: boolean | undefined;
  navBack: () => void;
  onSubmit: () => void;
}

export const ContractActionButtons: React.FC<ContractActionButtonsProps> = ({
  agreeText,
  resumeAddPackage,
  reviewed,
  navBack,
  onSubmit,
}) => {
  const { isMobile } = useDeviceType();

  return isMobile ? (
    <Column style={{ alignItems: 'center' }}>
      {!resumeAddPackage && <CancelContract />}
      <Spacer height='xs' />
      <Button
        style={{ width: '100%' }}
        sx={{ padding: '5px 40px' }}
        className='xxxl'
        onClick={onSubmit}
        color='primary'
        disabled={!reviewed}>
        {agreeText}
      </Button>
      <Spacer height='xs' />
      {resumeAddPackage ? (
        <CancelContract componentType='button' />
      ) : (
        <Button className='xs' onClick={navBack} variant='outlined' color='secondary'>
          Back
        </Button>
      )}
    </Column>
  ) : (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      {resumeAddPackage ? (
        <CancelContract componentType='button' />
      ) : (
        <Button className='xs' onClick={navBack} variant='outlined' color='secondary'>
          Back
        </Button>
      )}
      <Row style={{ alignItems: 'center' }}>
        {!resumeAddPackage && <CancelContract />}
        <Spacer width='sm' />
        <Row style={{ alignItems: 'center' }}>
          <Button
            sx={{ padding: '5px 40px' }}
            className='xxxl'
            onClick={onSubmit}
            color='primary'
            disabled={!reviewed}>
            {agreeText}
          </Button>
        </Row>
      </Row>
    </Row>
  );
};
