import React from 'react';
import DatePicker from '../../components/DatePicker';
import { Spacer } from '../../components';
import RefreshButton from '../../components/RefreshButton';
import dayjs, { Dayjs } from 'dayjs';
import { Grid } from '@mui/material';

interface ReportsHeaderProps {
  selectedDate: Dayjs | null;
  onDateChange: (date: Dayjs | null) => void;
  onRefresh: () => void;
  earliestDate: Dayjs | null;
}

const ReportsHeader: React.FC<ReportsHeaderProps> = ({
  selectedDate,
  onDateChange,
  onRefresh,
  earliestDate,
}) => {
  const today = dayjs().endOf('day');
  const isCurrentDate = selectedDate?.isSame(dayjs(), 'day');

  return (
    <Grid display='flex' flexDirection='row'>
      {isCurrentDate && (
        <>
          <RefreshButton onRefresh={onRefresh} />
          <Spacer width='md' />
        </>
      )}
      <DatePicker
        initialDate={selectedDate}
        onDateChange={onDateChange}
        minDate={earliestDate}
        maxDate={today}
      />
    </Grid>
  );
};

export default ReportsHeader;
