import React, { ReactNode, useState } from 'react';
import { Card, CardContent, Collapse, Grid, Typography, useTheme } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import Spacer from '../../../components/Spacer';
import { formatNumber } from '../../../helpers/utilityFunctions';

export interface ProgressItem {
  label?: string;
  value: number;
  expandableComponent?: ReactNode;
}

interface ProgressCardsProps {
  cards: ProgressItem[];
}

const ProgressCards: React.FC<ProgressCardsProps> = ({ cards }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<number | null>(null);

  return (
    <Grid display='flex' flex={1} flexDirection='column' height='100%' width='100%'>
      <Card
        variant='ghost'
        color='tertiary'
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
        }}>
        <CardContent
          sx={{
            flex: 'inherit',
            display: 'inherit',
            flexDirection: 'inherit',
            padding: '16px !important',
          }}>
          {cards.map((card, index) => {
            const expandable = typeof card.expandableComponent !== 'undefined';
            const isExpanded = expanded === index;

            const labeledCard = card.label;
            const rowOfLabeledCards = labeledCard && cards.length > 1;

            return (
              <Grid
                display='flex'
                flex={1}
                flexDirection='column'
                whiteSpace='nowrap'
                maxWidth={rowOfLabeledCards ? '100px' : undefined}
                mr={rowOfLabeledCards ? 1 : 0}
                ml={index > 0 ? 10 : 0}
                py={labeledCard ? 0 : '12px'}
                sx={{
                  '@media (max-width: 1400px)': {
                    ml: 0,
                    maxWidth: 'none',
                  },
                  '@media (max-width: 1900px)': {
                    whiteSpace: 'wrap',
                  },
                }}>
                {labeledCard && (
                  <>
                    <Typography
                      variant='p12SemiBold'
                      color='secondary.main'
                      lineHeight={1.5}
                      flexGrow={1}
                      display='flex'>
                      {card.label}
                    </Typography>
                    <Spacer height='xxs' />
                  </>
                )}
                <Grid
                  height='100%'
                  display='flex'
                  alignItems={labeledCard ? 'flex-end' : 'center'}
                  justifyContent={rowOfLabeledCards ? '' : 'center'}>
                  <Grid display='flex' alignItems='center' justifyContent='center'>
                    <Typography variant='p20' color='secondary.main'>
                      {formatNumber(card.value)}
                    </Typography>
                    {expandable && (
                      <Grid
                        display='flex'
                        ml={1}
                        onClick={() => setExpanded(isExpanded ? null : index)}
                        sx={{
                          cursor: 'pointer',
                        }}>
                        {isExpanded ? (
                          <Svgs.IconCollapse fill={theme.palette.info.main} />
                        ) : (
                          <Svgs.IconAdd fill={theme.palette.info.main} />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </CardContent>
      </Card>

      {cards.map((card, index) => {
        const expandable = typeof card.expandableComponent !== 'undefined';
        const isExpanded = expanded === index;
        return expandable ? (
          <Collapse key={index} in={isExpanded}>
            <Grid mt={1} display='flex' flexGrow={1} flexDirection='column'>
              {card.expandableComponent}
            </Grid>
          </Collapse>
        ) : null;
      })}
    </Grid>
  );
};

export default ProgressCards;
