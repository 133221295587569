import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Svgs } from '../assets/svg';

interface ClickableListProps<T> {
  items: T[];
  initialSelectedId?: string | number;
  renderItem: (item: T) => ReactNode;
  getItemKey: (item: T) => any;
  onItemClick: (val: any) => void;
}

function ClickableList<T>({
  items,
  initialSelectedId,
  renderItem,
  getItemKey,
  onItemClick,
}: ClickableListProps<T>) {
  const [selectedItemId, setSelectedItemId] = useState(initialSelectedId);

  const handleItemClick = (itemId: string | number) => {
    setSelectedItemId(itemId);
    onItemClick(itemId);
  };

  return (
    <List style={{ width: '100%' }}>
      {items.map(item => {
        const itemId = getItemKey(item);
        const isSelected = selectedItemId === itemId;
        return (
          <ListItem key={itemId} className='clickableListItem'>
            <ListItemButton onClick={() => handleItemClick(itemId)}>
              <ListItemText
                primary={
                  <Typography
                    variant='p16'
                    color='secondary.dark'
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {renderItem(item)}
                    {isSelected && <Svgs.IconCheckmark />}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default ClickableList;
