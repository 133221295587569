import { ApiServices } from './ApiServices';
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import type { Interceptor, RequestInterceptor, ResponseInterceptor } from './Interceptor';
import type { IAxiosRetryActivator } from './IAxiosRetryActivator';
import type { AxiosInstanceSettings } from './AxiosInstanceSettings';
import { inject } from '@aesop-fables/containr';

export interface IAxiosFactory {
  create(baseUrl: string): AxiosInstance;
}

declare type InterceptorProxy<T> = (value: T) => Promise<T>;

const onFulfilled = <T>(interceptor: Interceptor<T>): InterceptorProxy<T> => {
  return val => {
    if (interceptor.onFulfilled) {
      return interceptor.onFulfilled(val);
    }

    return Promise.resolve(val);
  };
};

const onRejected = <T>(interceptor: Interceptor<T>): InterceptorProxy<AxiosResponse> => {
  return val => {
    if (interceptor.onRejected) {
      return interceptor.onRejected(val);
    }

    return Promise.resolve(val);
  };
};

export class AxiosFactory implements IAxiosFactory {
  constructor(
    @inject(ApiServices.AxiosSettings) private readonly settings: AxiosInstanceSettings,
    @inject(ApiServices.AxiosRetryActivator) private readonly activator: IAxiosRetryActivator,
    @inject(ApiServices.RequestInterceptors)
    private readonly requestInterceptors: RequestInterceptor[],
    @inject(ApiServices.ResponseInterceptors)
    private readonly responseInterceptors: ResponseInterceptor[],
  ) {}

  create(baseUrl: string): AxiosInstance {
    const instance = axios.create({
      baseURL: baseUrl,
      ...this.settings,
    });

    this.activator.activate(instance);

    this.runRequestInterceptors(instance);
    this.runResponseInterceptors(instance);

    return instance;
  }

  private runRequestInterceptors(instance: AxiosInstance): void {
    this.requestInterceptors.forEach(interceptor => {
      instance.interceptors.request.use(
        //@ts-ignore
        onFulfilled(interceptor),
        onRejected(interceptor),
        interceptor.options,
      );
    });
  }

  private runResponseInterceptors(instance: AxiosInstance): void {
    this.responseInterceptors.forEach(interceptor => {
      instance.interceptors.response.use(
        onFulfilled(interceptor),
        onRejected(interceptor),
        interceptor.options,
      );
    });
  }
}
