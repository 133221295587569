import { DataCompartment, IMutation, MutationContext } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { PayrollDeductionCompartments, payrollDeductionStorageKey } from '..';
import {
  CompanyPayrollDeductionApi,
  PayrollDeductionData,
} from '../../../api/apis/CompanyPayrollDeductionApi';

export class AddDeductions implements IMutation<PayrollDeductionData> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;

    const payrollDeductionApi = container.get<CompanyPayrollDeductionApi>(ApiKeys.PayrollDeduction);

    await payrollDeductionApi.addDeductions(data);

    const dataCache = storage.retrieve<PayrollDeductionCompartments>(payrollDeductionStorageKey);
    await dataCache.reload('deductions');
  }
}

export class EditDeduction implements IMutation<PayrollDeductionData> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const payrollDeductionApi = container.get<CompanyPayrollDeductionApi>(ApiKeys.PayrollDeduction);

    await payrollDeductionApi.editDeduction(data);

    const dataCache = storage.retrieve<PayrollDeductionCompartments>(payrollDeductionStorageKey);
    await dataCache.reload('deductions');
  }
}

export class UpdateDeduction implements IMutation<PayrollDeductionData> {
  async execute(context: MutationContext<PayrollDeductionData>): Promise<void> {
    const { data, storage, container } = context;
    const teamApi = container.get<CompanyPayrollDeductionApi>(ApiKeys.PayrollDeduction);

    const cache = storage.retrieve<PayrollDeductionCompartments>(payrollDeductionStorageKey);
    const currentData = (
      cache.findCompartment('deductions') as DataCompartment<PayrollDeductionData>
    ).getData() as PayrollDeductionData;

    if (currentData?.id) {
      await teamApi.update({ ...currentData, ...data });
    }

    const dataCache = storage.retrieve<PayrollDeductionCompartments>(payrollDeductionStorageKey);
    dataCache.reloadAll();
  }
}
