import { Grid } from '@mui/material';
import { Spacer } from '../../../components';
import { HelpTitle, HelpBody } from '../../../components/HelpText';
import { AnyReportType } from '../../../models/ReportData';
import { ProgressCalculator } from './ReportProgressTracker';
import { getPricebookReportsByType, sumPropertyInArray } from '../../../helpers/reportUtils';
import { ProductTypeEnum } from '../../../models/PricebookData';

export const progressTracker3NU: ProgressCalculator = (report: AnyReportType) => {
  const reports3nu = getPricebookReportsByType(report, ProductTypeEnum.NickelsU);

  const notStarted = sumPropertyInArray(reports3nu, 'notStarted3nuCount');
  const zeroToTwentyFour = sumPropertyInArray(reports3nu, 'zeroToTwentyFour3nuCount');
  const twentyFiveToSeventyFive = sumPropertyInArray(reports3nu, 'twentyFiveToSeventyFive3nuCount');
  const seventySixToNinetyNine = sumPropertyInArray(reports3nu, 'seventySixToNinetyNine3nuCount');
  const completed = sumPropertyInArray(reports3nu, 'nuCompletedCount');
  const totalUsers = sumPropertyInArray(reports3nu, 'activeUserCount');
  const avgProgress = sumPropertyInArray(reports3nu, 'average3nuProgress');

  return {
    title: '3NickelsU progress',
    totalUsers: totalUsers,
    notStarted: notStarted,
    inProgress: [
      { label: 'Less than 25% complete', value: zeroToTwentyFour },
      { label: '25-75% complete', value: twentyFiveToSeventyFive },
      { label: '76-99% complete', value: seventySixToNinetyNine },
    ],
    completed: completed,
    avgProgress: avgProgress,
    helpText: '3NickelsU-eligible users',
    helpContent: <HelpText3nu />,
  };
};

const HelpText3nu = () => {
  return (
    <Grid>
      <HelpTitle>3NickelsU-eligible users</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody>
        Count of users connected to this organization who have access to 3NickelsU content.
      </HelpBody>
    </Grid>
  );
};

export const AvgProgress3nuHelp = () => {
  return (
    <Grid>
      <HelpTitle>Average progress</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody>
        The average progress through 3NickelsU content for all users that have started at least one
        video.
      </HelpBody>
    </Grid>
  );
};
