import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { OrganizationData } from '../../models/OrganizationData';
import { ApiServices } from '../ApiServices';
import { API_URL } from '.';

export class OrganizationApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<OrganizationData>> {
    return this.axios.get(`organization`);
  }

  getAll(): Promise<AxiosResponse<OrganizationData[]>> {
    return this.axios.get(`organization/all`);
  }

  post(organization: OrganizationData): Promise<AxiosResponse<OrganizationData>> {
    return this.axios.post('organization', organization);
  }

  replace(organization: OrganizationData): Promise<AxiosResponse<OrganizationData>> {
    return this.axios.put('organization', organization);
  }

  update(organization: OrganizationData): Promise<AxiosResponse<OrganizationData>> {
    return this.axios.patch('organization', organization);
  }

  getLogo(): string {
    return `${API_URL}organization/logo`;
  }

  uploadLogo(data: FormData): Promise<AxiosResponse<OrganizationData>> {
    return this.axios.post(`organization/logo`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getUrl(): Promise<AxiosResponse<OrganizationData>> {
    return this.axios.get(`organization/org-url`);
  }

  getQR(id: number): string {
    return `${API_URL}organization/org-qr-code/download#${id}`;
  }

  delete(id: number): Promise<AxiosResponse<OrganizationData>> {
    return this.axios.delete(`organization/${id}`);
  }
}
