import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Column, FormInputNumber, RightDrawer, Row, Spacer } from '../../components';
import { LicenseData } from '../../models/LicenseData';
import { Svgs } from '../../assets/svg';
import { useContext, useEffect, useState } from 'react';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { LicenseMetricsData } from '../../api/apis/LicenseMetricsApi';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import {
  SubscriptionApi,
  SubscriptionData,
  SubscriptionRest,
} from '../../api/apis/SubscriptionApi';
import { SubscriptionCompartments, subscriptionStorageKey } from '../../data/subscription';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { PricebookBundle } from '../../models/PricebookData';
import { formatTwoDecimalPlaces } from '../../helpers/utilityFunctions';
import { Loading } from '../../routes/AuthenticatedRoutes';
import { RightDrawerProps } from '../../components/RightDrawer';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { PaymentCompartments, paymentStorageKey } from '../../data/payment';
import { TermsApi } from '../../api/apis/TermsApi';
import { useLoading } from '../../hooks/useLoading';

interface PurchaseLicensesDrawerProps extends RightDrawerProps {
  pricebook: PricebookBundle;
}

const PurchaseLicensesDrawer: React.FC<PurchaseLicensesDrawerProps> = ({
  open,
  setOpen,
  pricebook,
}) => {
  const navigate = useNavigate();
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const [tosLink, setTosLink] = useState<string>();
  const [activeSubscription, setActiveSubscription] = useState<SubscriptionData>();

  const subscriptionApi = useService<SubscriptionApi>(ApiKeys.Subscription);
  const termsApi = useService<TermsApi>(ApiKeys.Terms);

  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const pricebookSelections =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections')) ?? [];
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const licenseMetrics =
    useObservable(licenseDataCache.observe$<LicenseMetricsData[]>('licenseMetrics')) ?? undefined;
  const license = licenseMetrics?.find(license => license.pricebookId === pricebook.pricebook.id);

  const subscriptionDataCache =
    appStorage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
  const subscription =
    useObservable(subscriptionDataCache.observe$<SubscriptionData[]>('subscription')) ?? undefined;
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);

  useEffect(() => {
    if (!subscription) {
      setActiveSubscription(undefined);
    }
    if (subscription) {
      // TODO this logic must change when there are more than 1 package to purchase additional licenses for
      const subscriptionId = subscription?.find(
        x => x.productId === pricebook.prices[0].product.id,
      );
      setActiveSubscription(subscriptionId);
    }
  }, [pricebook.prices, subscription]);

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await termsApi.getAll();
      const termsObject = res.data.find(doc => doc.docDescription === 'Terms of Service');
      if (!termsObject) {
        return;
      }
      const url = termsApi.get(termsObject.id);
      setTosLink(url);
    };
    getTermsLink();
    //eslint-disable-next-line
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const onCheckOut = async (values: any) => {
    try {
      setLoading(true);
      const addLicensesData = {
        subscriptionId: activeSubscription?.id,
        amount: values.quantity,
      } as SubscriptionRest;
      const checkOut = await subscriptionApi.add(addLicensesData);
      if (checkOut.data.paymentFailed === true) {
        const failedInvoiceUrl = checkOut.data.failedInvoiceUrl;
        window.open(failedInvoiceUrl);
        await subscriptionDataCache.reloadAll();
        setOpen(false);
        return;
      } else {
        await Promise.all([
          subscriptionDataCache.reload('subscription'),
          paymentDataCache.reloadAll(),
        ]);
        navigate('/congrats', {
          state: {
            quantity: values.quantity,
            chosenPackage: pricebookSelections[0],
          },
        });
        setToast(
          new Toast({
            message: `${values.quantity} additional ${
              values.quantity === 1 ? 'license' : 'licenses'
            } purchased!`,
            severity: 'success',
            open: true,
          }),
        );
      }
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  };

  if (!license) return null;

  return (
    <RightDrawer open={open} setOpen={setOpen}>
      <Form onSubmit={onCheckOut}>
        {({ handleSubmit, values }) => (
          <Column>
            <Typography color='secondary.main' variant='p40'>
              Purchase additional licenses
            </Typography>
            <Spacer height='xxs' />
            <Typography variant='h2' color='info.main'>
              How many licenses would you like to add?
            </Typography>
            <Spacer height='xs' />
            <AtAGlance licenseMetrics={license} pricebookBundle={pricebook} />
            <Spacer height='xs' />
            {pricebookSelections ? (
              <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <FormInputNumber<LicenseData>
                  fieldName='quantity'
                  label='Number of licenses'
                  maxLength={7}
                  extraStyles={{ width: '80%' }}
                  inputStyle={{ width: '40%' }}
                  required
                />
                <Spacer width='xs' />
                <Typography color='secondary' variant='p20SemiBold' style={{ display: 'inline' }}>
                  Total
                </Typography>
                <Spacer width='sm' />
                <Card variant='innerCard' sx={{ width: '50%' }}>
                  <CardContent className='purchaseAdditional' style={{ textAlign: 'center' }}>
                    <Row style={{ justifyContent: 'center' }}>
                      <Typography
                        color='info.main'
                        style={{ display: 'inline' }}
                        variant='subtitle1'>
                        $
                      </Typography>
                      <Typography
                        color='secondary.dark'
                        style={{ display: 'inline' }}
                        variant='subtitle1'>
                        {formatTwoDecimalPlaces(
                          ((values.quantity ?? 0) * (pricebook.prices[0]?.price ?? 0)) / 100.0,
                        )}
                        {values.quantity > 0 && '*'}
                      </Typography>
                    </Row>
                    <Typography variant='p12SemiBold' color='secondary.main'>
                      due today
                    </Typography>
                  </CardContent>
                </Card>
              </Row>
            ) : (
              <Loading />
            )}
            <Spacer height='sm' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button onClick={onClose} variant='outlined' color='secondary' className='sm'>
                Cancel
              </Button>
              <Button
                disabled={
                  !values.quantity || values.quantity === '0' || Number.isNaN(values.quantity * 1)
                }
                onClick={handleSubmit}
                color='primary'
                className='lg'>
                Purchase
              </Button>
            </Row>
            {values.quantity > 0 && (
              <>
                <Spacer height='xs' />
                <Typography variant='p14' color='secondary'>
                  *This amount may be prorated based on date of purchase.
                </Typography>
                <Spacer height='xxs' />
                <Typography variant='p14' color='secondary'>
                  Please see our{' '}
                  <Link href={tosLink} target='_blank'>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link href='/cancellation-policy' target='_blank'>
                    Cancellation and Refund Policy
                  </Link>{' '}
                  for details
                </Typography>
              </>
            )}
            <Spacer height='xxl' />
          </Column>
        )}
      </Form>
    </RightDrawer>
  );
};
interface AtAGlanceProps {
  licenseMetrics: LicenseMetricsData;
  pricebookBundle: PricebookBundle;
}
const AtAGlance: React.FC<AtAGlanceProps> = ({ licenseMetrics, pricebookBundle }) => {
  const pricebookName = pricebookBundle.pricebook.pricebookName;
  const [packageArt, setPackageArt] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    collectContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collectContext = () => {
    /* TODO get medium versions for these svgs */
    switch (pricebookName) {
      case 'GuidedChoice Advisory Service':
        setPackageArt(<Svgs.PackageGcAdviceMedium style={{ width: '20%' }} />);
        break;
      case '3Nickels Advice':
        setPackageArt(<Svgs.Package3nAdviceMedium style={{ height: '20%' }} />);
        break;
      case '3Nickels Free':
        setPackageArt(<Svgs.Package3nFreeMedium style={{ height: '20%' }} />);
        break;
      case 'Advice + 3NickelsU':
        setPackageArt(<Svgs.Package3nUMedium style={{ height: '20%' }} />);
        break;
    }
  };

  return (
    <Card color='primary' variant='ghost'>
      <CardContent className='basic'>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {packageArt}
          <Typography style={{ width: '30%' }} variant='p18Bold' color='secondary.main'>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Column style={{ width: '25%' }}>
            <Typography color='secondary.main' variant='p12SemiBold'>
              Licenses purchased
            </Typography>
            <Row>
              <Svgs.IconLicenseDefault />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {licenseMetrics.purchasedLicenses ?? 0}
              </Typography>
            </Row>
          </Column>
          <Column style={{ width: '25%' }}>
            <Typography color='secondary.main' variant='p12SemiBold'>
              Licenses remaining
            </Typography>
            <Row>
              <Svgs.IconLicenseDefault />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {licenseMetrics.availableLicenses ?? 0}
              </Typography>
            </Row>
          </Column>
        </Row>
      </CardContent>
    </Card>
  );
};

export default PurchaseLicensesDrawer;
