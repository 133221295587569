import { combineLatest, map } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OffboardingTemplateApi } from '../../api/apis/OffboardingTemplateApi';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCache,
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import { PricebookBundle } from '../../models/PricebookData';
import { TemplateData } from '../../models/SignUpData';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import { PricebookCompartments, pricebookStorageKey } from '../pricebook';

export interface OffboardingCompartments {
  offboardingTemplate: DataCompartmentOptions<TemplateData | undefined>;
}

export const offboardingStorageKey = 'data/offboarding';

export class OffboardingDataCachePolicy extends DataCachePolicy<OffboardingCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.OffboardingTemplate)
    private readonly offboardingTemplateApi: OffboardingTemplateApi,
  ) {
    super(offboardingStorageKey);
  }

  buildDataCache(): DataCache<OffboardingCompartments> {
    const pricebookDataCache = this.appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections'),
    ]).pipe(
      map(
        ([isAuthenticated, pricebookSelections]) =>
          isAuthenticated === true && pricebookSelections?.length > 0,
      ),
    );
    return createObservedDataCache<OffboardingCompartments>({
      offboardingTemplate: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.offboardingTemplateApi.get();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withOffboardingData = createServiceModule(offboardingStorageKey, services =>
  services.add<IDataCachePolicy>(Policies, OffboardingDataCachePolicy),
);
