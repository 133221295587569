interface LogoProps {
  imageUrl: string;
}

const Logo: React.FC<LogoProps> = ({ imageUrl }) => {
  return (
    <img
      src={imageUrl}
      alt='Your logo'
      style={{
        maxWidth: '400px',
        maxHeight: '100px',
        width: 'fit-content',
        height: 'fit-content',
        objectFit: 'contain',
        borderRadius: '8px',
        border: '1px solid #038ab7',
      }}
    />
  );
};

export default Logo;
