import { useContext, useEffect, useState } from 'react';
import { sortData } from '../helpers/utilityFunctions';
import { Caret, CaretPlaceholder, Modal, Row, Spacer } from '.';
import { Svgs } from '../assets/svg';
import {
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Button,
  useTheme,
  Link,
  Grid,
} from '@mui/material';
import { MemberRow } from '../models/OrganizationData';
import { Toast } from '../models/Toast';
import { ToastbarContext } from '../App';
import { ActivePaymentMethodData } from '../api/apis/AdminPaymentApi';
import { useLoading } from '../hooks/useLoading';

type PrimitiveType = string | number | boolean | JSX.Element;
type DataTableType =
  | 'paymentMethod'
  | 'member'
  | 'admins'
  | 'licenseAgreement'
  | 'investmentLineup'; // create a type for each delete modal
type SortDirections = {
  [key: string]: boolean;
};
type RowData = {
  id?: number | string;
  deletableRow?: boolean;
  isPinned?: boolean;
  isDefault?: boolean;
  ticker?: string | undefined;
  name?: string | undefined;
  type?: string | undefined;
  assetClass?: string | undefined;
};

function objectValues<T extends {}>(obj: T) {
  return Object.keys(obj)
    .filter(objKey => objKey !== 'id') // filter out ids
    .map(objKey => obj[objKey as keyof T]);
}

function isPrimitive(value: any): value is PrimitiveType {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'object'
  );
}

interface DataTableProps {
  title?: string;
  headers: {
    label: string;
    title: string;
  }[];
  headerRight?: React.ReactNode;
  sortBy?: string;
  backgroundColor?: string;
  outlined?: boolean;
  hideCaret?: boolean;
  scrollable?: boolean;
  data: RowData[];
  type?: DataTableType;
  lastColumnIsButtons?: boolean;
  button1Text?: string | ((val: any) => string);
  button2Text?: string | ((val: any) => string);
  onButton1Click?: (row: any) => void;
  onButton2Click?: (row: any) => void;
  button1Disabled?: boolean;
  button2Disabled?: boolean;
  onDelete?: (row: any) => Promise<void>;
  emptyStateText?: string;
  filtered?: boolean;
  action?: (row: any) => JSX.Element;
  action2?: (entry: any) => void;
  action3?: (entry: any) => JSX.Element;
  role?: string;
  itemsPerPage?: number;
  customSort?: {
    column: string;
    function: (a: any, b: any) => number;
  }[];
  loading?: boolean;
}

function DataTable(props: DataTableProps) {
  const {
    title,
    headers,
    headerRight,
    sortBy,
    backgroundColor,
    outlined,
    hideCaret,
    scrollable,
    data,
    button1Text,
    button2Text,
    onButton1Click,
    onButton2Click,
    button1Disabled,
    button2Disabled,
    onDelete,
    type,
    emptyStateText,
    filtered,
    action,
    action2,
    action3,
    role,
    itemsPerPage,
    customSort,
    loading,
  } = props;
  const theme = useTheme();
  const lastColumnIsButtons = props.lastColumnIsButtons ?? true;
  const cellVariant = outlined ? 'outlined' : undefined;
  const [sortedData, setSortedData] = useState<RowData[]>(data);
  const [displayedData, setDisplayedData] = useState<RowData[]>(sortedData.slice(0, itemsPerPage));
  const [sortDirections, setSortDirections] = useState<SortDirections>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeData, setActiveData] = useState<any>();
  const [activeEntry, setActiveEntry] = useState<any>();
  const [activeEntry2, setActiveEntry2] = useState<any>();
  const [sortByCol, setSortByCol] = useState<string>(sortBy ?? headers[0].label ?? null);
  const [modalText, setModalText] = useState<{
    title: string;
    primaryText: string;
    secondaryText?: string;
    toastMessage: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
  }>();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const atLeastOneRowIsDeletable = data.some(row => row.deletableRow);
  const showLoadMore = itemsPerPage && data.length > displayedData.length;

  useEffect(() => {
    setLoading(loading ?? false);
    return () => setLoading(false);
  }, [loading, setLoading]);

  useEffect(() => {
    // each header handles its own sort direction
    const initialSortDirections: SortDirections = headers.reduce((acc, header) => {
      acc[header.label] = true;
      return acc;
    }, {} as SortDirections);
    setSortDirections(initialSortDirections);
  }, [headers]);

  useEffect(() => {
    onSort(data, setSortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setDisplayedData(sortedData.slice(0, itemsPerPage || data.length));
  }, [sortedData, data.length, itemsPerPage]);

  useEffect(() => {
    if (!type) return;
    createContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData]);

  const createContext = () => {
    if (!activeData) return;
    if (type === 'paymentMethod') {
      setModalText({
        title: 'Remove payment method?',
        primaryText: `Are you sure you want to remove ${
          (activeData as ActivePaymentMethodData).name
        } as a payment method?`,
        secondaryText: 'This action cannot be undone.',
        toastMessage: `${(activeData as ActivePaymentMethodData).name} removed`,
      });
      return;
    }
    if (type === 'admins') {
      setModalText({
        title: 'Remove admin?',
        primaryText: `Are you sure you want to remove ${activeData.name} as an admin?`,
        secondaryText: ` This will remove any association between their account and your ${role?.toLowerCase()}.`,
        toastMessage: 'Admin removed',
        primaryButtonText: 'Yes, Remove',
        secondaryButtonText: 'No, Keep',
      });
      return;
    }
    if (type === 'member') {
      setModalText({
        title: 'Discontinue member?',
        primaryText: `Are you sure you want to discontinue ${
          (activeData as MemberRow).firstName + ' ' + (activeData as MemberRow).lastName
        } as a member?`,
        secondaryText:
          'This will remove any association between their account and your organization.',
        toastMessage: 'Member removed',
      });
      return;
    }
    if (type === 'licenseAgreement') {
      setModalText({
        title: 'Cancel contract?',
        primaryText: 'Are you sure you want to cancel this active contract?',
        secondaryText: ' This action cannot be undone.',
        toastMessage: 'License agreement canceled!',
        primaryButtonText: 'Yes, cancel',
        secondaryButtonText: 'No, keep',
      });
    }
  };

  const onSort = (data: RowData[], setData: (value: React.SetStateAction<RowData[]>) => void) => {
    const pinnedData = data.filter(row => row.isPinned);
    const mainData = data.filter(row => !row.isPinned);
    const mainSorted = sortData(
      mainData,
      sortByCol,
      sortDirections[sortByCol],
      () => {},
      setSortByCol,
      customSort,
    );
    setData([...pinnedData, ...mainSorted]);
  };

  const onDeleteClick = (row: any) => {
    if (type !== undefined) {
      setActiveData(row);
      setModalOpen(true);
      return;
    }
    deleteRow(row);
  };

  const deleteRow = async (row: any) => {
    if (onDelete) {
      await onDelete(row);
    } else {
      sortedData.splice(sortedData.indexOf(row), 1);
      setSortedData([...sortedData]);
    }
  };

  const onHandleSave = () => {
    deleteRow(activeData);
    setToast(new Toast({ message: modalText?.toastMessage, severity: 'success', open: true }));
  };

  const onLoadMore = () => {
    const newDisplayedData = sortedData.slice(0, displayedData.length + (itemsPerPage ?? 0));
    onSort(newDisplayedData, setDisplayedData);
  };

  const getLicenseArt = (entry: any) => {
    const productName = entry?.toString();

    if (productName.includes('GuidedChoice Advisory Service')) {
      return <Svgs.PackageGcAdviceSmall />;
    }
    if (productName.includes('3Nickels Advice')) {
      return <Svgs.Package3nAdviceSmall />;
    }
    if (productName.includes('3Nickels Free')) {
      return <Svgs.Package3nFreeSmall />;
    }
    if (productName.includes('Advice + 3NickelsU')) {
      return <Svgs.Package3nUSmall />;
    }
  };

  const firstColumnStyles =
    type === 'licenseAgreement'
      ? { typography: { textDecoration: 'underline', color: theme.palette.primary.main } }
      : undefined;

  const extraButton1Styles = onButton2Click
    ? undefined
    : {
        justifyContent: 'flex-end',
      };

  return (
    <>
      {type && (
        <Modal
          title={modalText?.title ?? ''}
          handleSave={onHandleSave}
          data={activeData}
          open={modalOpen}
          setOpen={setModalOpen}
          primaryButtonText={modalText?.primaryButtonText ?? undefined}
          secondaryButtonText={modalText?.secondaryButtonText ?? undefined}>
          <Typography variant='p14' color='secondary.main' gutterBottom>
            {modalText?.primaryText ?? ''}
          </Typography>
          <Spacer height='sm' />
          {modalText?.secondaryText ? (
            <Typography variant='p14' color='secondary.main' gutterBottom>
              {modalText?.secondaryText ?? ''}
            </Typography>
          ) : null}
          <Spacer height='sm' />
        </Modal>
      )}
      {type === 'licenseAgreement' && action && activeEntry ? action(activeEntry) : null}
      {type === 'licenseAgreement' && action3 && activeEntry2 ? action3(activeEntry2) : null}
      {title ? (
        <>
          <Typography variant='subtitle1' color='secondary.main'>
            {title}
          </Typography>
          <Spacer height='xxs' />
        </>
      ) : null}
      {displayedData && displayedData.length > 0 ? (
        <Card variant='ghost' color={backgroundColor ?? 'primary'}>
          <CardContent className={atLeastOneRowIsDeletable ? 'deletable' : 'dataTable'}>
            <TableContainer className={scrollable ? 'scrollable' : undefined} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    {atLeastOneRowIsDeletable ? (
                      <TableCell sx={{ border: 'none', width: '50px' }}>&nbsp;</TableCell>
                    ) : null}
                    {headers.map((header, index) => (
                      <TableCell
                        className={outlined ? 'paddedHeader' : undefined}
                        style={{
                          userSelect: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const newDirection =
                            sortByCol !== header.label || !sortDirections[header.label]; // if click new column, set sort direction to true; otherwise, toggle direction
                          sortData(
                            displayedData,
                            header.label,
                            newDirection,
                            setDisplayedData,
                            setSortByCol,
                            customSort,
                          );
                          setSortDirections(prev => ({
                            ...prev,
                            [header.label]: newDirection,
                          }));
                        }}
                        key={index}>
                        <Row
                          style={{
                            alignItems: 'center',
                          }}>
                          {header.title}
                          {!hideCaret ? (
                            sortByCol === header.label ? (
                              <Caret sortDirection={sortDirections[header.label]} />
                            ) : (
                              <CaretPlaceholder />
                            )
                          ) : null}
                        </Row>
                      </TableCell>
                    ))}
                    <TableCell style={{ justifyContent: 'flex-end' }}>
                      <Row
                        style={{
                          justifyContent: 'flex-end',
                        }}>
                        {headerRight}
                      </Row>
                    </TableCell>
                    {lastColumnIsButtons && !outlined ? <TableCell>&nbsp;</TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedData.map((row: any, i: number) => (
                    <TableRow key={i}>
                      {row.deletableRow && !row.isDefault ? (
                        <TableCell sx={{ width: '50px', padding: 0 }}>
                          <IconButton color='primary' onClick={() => onDeleteClick(row)}>
                            <Svgs.IconDelete />
                          </IconButton>
                        </TableCell>
                      ) : atLeastOneRowIsDeletable ? (
                        <TableCell sx={{ width: '50px' }}></TableCell>
                      ) : null}
                      {objectValues(row).map((entry, j) => {
                        if (j === 0) {
                          return typeof entry === 'boolean' ? null : (
                            <TableCell variant={cellVariant} key={j} sx={{ ...firstColumnStyles }}>
                              <Row
                                style={{
                                  alignItems: 'center',
                                }}>
                                {type === 'licenseAgreement' && action2 ? (
                                  <>
                                    {entry && getLicenseArt(entry)}
                                    <Spacer width='xxxs' />
                                    <Link
                                      onClick={() => {
                                        setActiveEntry(row);
                                        action2(true);
                                      }}>
                                      {isPrimitive(entry) ? entry : 'Error Reading Table Entry'}
                                    </Link>
                                  </>
                                ) : isPrimitive(entry) ? (
                                  entry
                                ) : (
                                  'Error Reading Table Entry'
                                )}
                              </Row>
                            </TableCell>
                          );
                        }
                        return typeof entry === 'boolean' ? null : (
                          <TableCell variant={cellVariant} key={j}>
                            {isPrimitive(entry) ? entry : 'Error Reading Table Entry'}
                          </TableCell>
                        );
                      })}

                      {lastColumnIsButtons && onButton1Click && !row.isDefault ? (
                        <TableCell variant='hasButton'>
                          <Row
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              ...extraButton1Styles,
                            }}>
                            <Button
                              onClick={() => onButton1Click(row)}
                              disabled={button1Disabled}
                              sx={{
                                typography: { fontSize: 14 },
                                minWidth: 'fit-content',
                              }}
                              variant='outlined'
                              color='primary'>
                              {typeof button1Text === 'function'
                                ? button1Text(row)
                                : button1Text || `View`}
                            </Button>
                            {onButton2Click && button2Text && (
                              <>
                                <Spacer width='xxs' />
                                <Button
                                  onClick={() => {
                                    setActiveEntry2(row);
                                    onButton2Click(true);
                                  }}
                                  disabled={button2Disabled}
                                  sx={{ typography: { fontSize: 14 }, minWidth: 'fit-content' }}
                                  color='primary'>
                                  {typeof button2Text === 'function'
                                    ? button2Text(row)
                                    : button2Text}
                                </Button>
                              </>
                            )}
                          </Row>
                        </TableCell>
                      ) : row.isDefault ? (
                        <TableCell variant='hasButton'>
                          <Row
                            style={{
                              display: 'inline-block',
                              minWidth: '120px',
                              justifySelf: 'flex-end',
                            }}>
                            <Row
                              style={{
                                justifyContent: 'center',
                              }}>
                              <Typography variant='p16Bold' color='primary'>
                                Default
                              </Typography>
                            </Row>
                          </Row>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {showLoadMore && (
              <Grid container justifyContent='center' mt={1} mb={1}>
                <Link
                  onClick={onLoadMore}
                  variant='p14'
                  color='secondary'
                  sx={{ textDecorationColor: theme.palette.secondary.main }}>
                  Load More
                </Link>
              </Grid>
            )}
          </CardContent>
        </Card>
      ) : (
        <Card variant='ghost' color={backgroundColor ?? 'primary'}>
          <CardContent sx={{ textAlign: 'center' }} className={'dataTable'}>
            <Spacer height='xxs' />
            <Typography variant='h3' color='secondary.main'>
              {filtered ? 'No results found' : emptyStateText}
            </Typography>
            <Spacer height='xxs' />
          </CardContent>
        </Card>
      )}
      <Spacer height='lg' />
    </>
  );
}

export default DataTable;
