import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class MendixClientApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<MendixClientData>> {
    return this.axios.get('mendix-client');
  }

  addPublicKey(publicKey: PublicKey): Promise<AxiosResponse<MendixClientData>> {
    return this.axios.post('mendix-client', publicKey);
  }

  editPublicKey(publicKey: PublicKey): Promise<AxiosResponse<MendixPublicKey>> {
    return this.axios.patch('mendix-client', publicKey);
  }
}

export interface MendixClientData {
  id: number;
  clientId: number;
  recordKeeperId: number;
}

export interface MendixPublicKey {
  clientName: string;
  publicKey: string;
  organizationId: number;
  id: number;
}

export interface PublicKey {
  publicKey: string;
}
