import { ApiKeys } from '../../../api/apis/ApiKeys';
import { RestrictionApi } from '../../../api/apis/RestrictionApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';

import { UserCompartments, userStorageKey } from '../../user';

export class SetDomains implements IMutation<string> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, container } = context;
    const restrictionApi = container.get<RestrictionApi>(ApiKeys.Restriction);

    // post new data to api
    await restrictionApi.post(data);
  }
}
export class DeleteDomain implements IMutation<string> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, container } = context;
    const restrictionApi = container.get<RestrictionApi>(ApiKeys.Restriction);

    await restrictionApi.delete(data);
  }
}

export class GetDomains implements IMutation<string> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { storage, container } = context;
    const restrictionApi = container.get<RestrictionApi>(ApiKeys.Restriction);

    // post new data to api
    await restrictionApi.get();

    // reload user data to load/refresh org data cache
    const userDataCache = storage.retrieve<UserCompartments>(userStorageKey);
    userDataCache.reloadAll();
  }
}
