import { ApiServices } from './ApiServices';
import type { AxiosInstance } from 'axios';
import type { IAxiosRetryActivator } from './IAxiosRetryActivator';
import axiosRetry from 'axios-retry';
import type { ApiSettings } from './ApiSettings';
import { inject } from '@aesop-fables/containr';

export class AxiosRetryActivator implements IAxiosRetryActivator {
  constructor(@inject(ApiServices.ApiSettings) private readonly settings: ApiSettings) {}

  activate(axios: AxiosInstance): void {
    const { retries: config } = this.settings;
    if (!config.retries || config.retries === 0) {
      return;
    }

    axiosRetry(axios, config);
  }
}
