import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CardDisplay from './CardDisplay';
import { Svgs } from '../assets/svg';
import { Grid, IconButton } from '@mui/material';

interface DatePickerProps {
  initialDate?: Dayjs | null;
  onDateChange: (date: Dayjs | null) => void;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
}

const DatePicker: React.FC<DatePickerProps> = ({
  initialDate = dayjs(),
  onDateChange,
  minDate,
  maxDate,
}) => {
  const [date, setDate] = useState<Dayjs | null>(initialDate);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onDateChange(date);
  }, [date, onDateChange]);

  const handleDateChange = (newDate: Dayjs | null) => {
    setDate(newDate);
    setTimeout(() => setOpen(false), 0);
  };

  const handleFieldClick = () => {
    setOpen(true);
  };

  return (
    <CardDisplay
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      onClick={handleFieldClick}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid display='flex' alignItems='center' ml={2}>
          <IconButton>
            <Svgs.ActionSmallCalendarDefault />
          </IconButton>
        </Grid>
        <DesktopDatePicker
          className='date-picker'
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          format='MM/DD/YYYY'
          slots={{
            openPickerIcon: () => null,
            inputAdornment: () => null,
          }}
          slotProps={{
            textField: {
              inputProps: {
                readOnly: true,
              },
              sx: {
                '& .MuiInputBase-input': {
                  marginRight: -10,
                },
              },
            },
          }}
          value={date}
          onChange={handleDateChange}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
        />
      </LocalizationProvider>
    </CardDisplay>
  );
};

export default DatePicker;
