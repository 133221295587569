import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class TrustFamilyApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getTrustFamily(): Promise<AxiosResponse<TrustFamilyData[]>> {
    return this.axios.get('trust-family');
  }

  addTrustFamily(trustFamily: TrustFamilyData): Promise<AxiosResponse<TrustFamilyData[]>> {
    return this.axios.post('trust-family', { ...trustFamily });
  }

  editTrustFamily(trustFamily: TrustFamilyData): Promise<AxiosResponse> {
    return this.axios.put('trust-family', { ...trustFamily });
  }
}

export interface TrustFamilyData {
  id: number;
  effectiveDate: string;
  trustFamilyName: string;
  fundList: FundData[];
}

export interface FundData {
  ticker: string;
  cusip?: number;
  name: string;
  morningStarCategory?: string;
  expenseRatio?: number;
  publiclyTraded?: boolean;
  primaryAssetClass?: string;
  partnerAssetClass?: string;
  monthlyReturn?: number;
  fundPerformances?: FundPerformances[];
}

export interface FundPerformances {
  monthlyReturn: number;
  month: number;
}
