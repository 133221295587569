import { MouseEvent, useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Svgs } from '../assets/svg';
import { Breadcrumbs, Row, Spacer } from '.';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { logoGcSmall, logo3NSmall } from '../assets/png';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { AuthenticatedWizard, authenticatedWizardKey } from '../services/authenticated';
import { OrganizationData, ProductEnum } from '../models/OrganizationData';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { API_URL, domain } from '../api/apis';
import { useDeviceType } from '../hooks/useDeviceType';
import { ProductTypeCompartments, productTypeStorageKey } from '../data/productType';
import { getSidebarItemsPaidByAdmin, sidebarItemsPaidByMember } from './OrganizationSideNav';
import { OrganizationMetricsData } from '../api/apis/OrgPricebookLicenseMetricsApi';
import { UnselectOrganization } from '../data/organization/mutations';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { OrgSignUpStateData } from '../models/SignUpData';
import { LicenseAgreementData } from '../api/apis/LicenseAgreementApi';
import { LicenseCompartments, licenseStorageKey } from '../data/license';
import { Crumbs } from './Breadcrumbs';
import DrillDownBreadcrumbs from './DrillDownBreadcrumbs';
import { useLoading } from '../hooks/useLoading';
import { UserCompartments, userStorageKey } from '../data/user';
import { UserData } from '../models/UserData';
import useResetToOrigin from '../hooks/useResetToOrigin';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': 8,
    'marginTop': theme.spacing(3),
    'minWidth': 300,
    'color': theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    'boxShadow':
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '15px 15px',
    },
  },
}));

interface TopNavBarProps {
  hideProfile?: boolean;
  hideDashboard?: boolean;
  hideOrganizations?: boolean;
  hideCompanyLogo?: boolean;
  crumbs?: Crumbs[];
  useDrillDownCrumbs?: boolean;
}

const TopNavBar: React.FC<TopNavBarProps> = ({
  hideProfile,
  hideDashboard,
  hideOrganizations,
  hideCompanyLogo,
  crumbs,
  useDrillDownCrumbs,
}) => {
  const appStorage = useAppStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { isMobile, isTablet } = useDeviceType();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [onGo, setOnGo] = useState<boolean>(false);
  const resetToOrigin = useResetToOrigin(onGo, setOnGo);

  const authWizard = useService<AuthenticatedWizard>(authenticatedWizardKey);
  const unselectOrganization = useMutation(new UnselectOrganization());

  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const productTypeDataCache = appStorage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);

  const authState = useObservable(authWizard.state$);
  const userData = useObservable(userDataCache.observe$<UserData>('user'));
  const orgData = useObservable(organizationDataCache.observe$<OrganizationData>('organization'));
  const paidBy = useObservable(productTypeDataCache.observe$<string>('paidBy'));
  const licenseAgreements =
    useObservable(licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement')) ?? [];
  const allMetrics = useObservable(
    organizationDataCache.observe$<OrganizationMetricsData[]>('allMetrics'),
  );
  const allOrgSignUpStates = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData[]>('allOrgSignUpStates'),
  );

  const open = Boolean(anchorEl);
  const product = authState?.product;
  const ourLogo = product === ProductEnum.GuidedChoice ? logoGcSmall : logo3NSmall;
  const ourLogoAlt = product === ProductEnum.GuidedChoice ? 'GuidedChoice Logo' : '3Nickels Logo';
  const sidebarItems =
    paidBy === 'ORG' ? getSidebarItemsPaidByAdmin(licenseAgreements) : sidebarItemsPaidByMember;

  const incompleteOrgMetrics =
    allMetrics && allMetrics.length > 0
      ? allMetrics.filter(orgMetrics => {
          const x = allOrgSignUpStates?.filter(signUpState => {
            if (orgMetrics.organizationId === signUpState.id) {
              if (!signUpState.offboardingComplete) return !signUpState.onboardingComplete;
            }
            return false;
          });

          if (x?.length !== 0) {
            return true;
          }
          return false;
        })
      : undefined;
  const currentOrgIsIncomplete =
    (
      incompleteOrgMetrics?.filter(incompleteOrg => incompleteOrg.organizationId === orgData?.id) ??
      []
    ).length > 0;

  const addPackageFlow = location.pathname.includes('addPackage');

  const navToHome = () => {
    setOnGo(true);
    return resetToOrigin;
  };
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (unselectOrganization.status === MutationStatus.Complete) {
      navigate('/drill-down/choose-organization');
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unselectOrganization.status]);

  return (
    <>
      <AppBar
        sx={{
          display: 'flex',
          flex: 1,
          zIndex: 3,
          position: 'fixed',
          backgroundColor: 'white',
          color: '#4a606a',
          height: '64px',
        }}>
        <Toolbar>
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '98%',
            }}>
            <Row>
              <div style={{ flexGrow: 1, display: 'block' }}>
                <img
                  style={{ width: '250px', marginTop: '15px', cursor: 'pointer' }}
                  src={ourLogo}
                  alt={ourLogoAlt}
                  onClick={navToHome}
                />
              </div>
              {crumbs ? (
                <div
                  style={{
                    paddingTop: '23px',
                    paddingLeft: '10px',
                  }}>
                  <Breadcrumbs crumbs={crumbs} />
                </div>
              ) : useDrillDownCrumbs ? (
                <div
                  style={{
                    paddingTop: '23px',
                    paddingLeft: '10px',
                  }}>
                  <DrillDownBreadcrumbs />
                </div>
              ) : null}
            </Row>
            {!hideProfile && (isMobile || isTablet) ? (
              <Row style={{ alignItems: 'center' }}>
                <IconButton
                  id='demo-customized-button'
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}>
                  <Svgs.IconGrip />
                </IconButton>
              </Row>
            ) : (
              <Row style={{ alignItems: 'center' }}>
                {!hideCompanyLogo && orgData?.logoUri && (
                  <>
                    <img
                      src={`${API_URL}organization/logo/${orgData?.id}`}
                      style={{ height: '45px' }}
                      alt={`${orgData.name}'s logo`}
                    />
                    <Spacer width='md' />
                  </>
                )}
                {!hideProfile ? (
                  <Button
                    onClick={() => navigate('/drill-down/profile')}
                    variant={`${
                      location.pathname === '/drill-down/profile' ? 'activeGhost' : 'ghost'
                    }`}>
                    <Row
                      style={{
                        display: 'flex',
                        marginRight: '2px',
                      }}>
                      {location.pathname === '/drill-down/profile' ? (
                        <Svgs.IconProfileActive />
                      ) : (
                        <Svgs.IconProfileDefault />
                      )}
                    </Row>
                    <Spacer width='xxs' />
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                      }}>
                      {userData?.firstName ?? 'Profile'}
                    </div>
                  </Button>
                ) : null}
                <Spacer width='md' />
                <form method='post' action={`https://ipm.${domain}/logout`}>
                  <Button type='submit' variant='outlined' color='secondary'>
                    Logout
                  </Button>
                  <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                </form>
              </Row>
            )}
          </Row>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {/* Mobile */}
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <Row style={{ alignItems: 'center', justifyContent: 'right' }}>
          <IconButton
            id='demo-customized-button'
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClose}>
            <Svgs.IconClose />
          </IconButton>
        </Row>
        {/* TODO we're removing organizations button from topnavbar, but not sure about mobile menu */}
        {/* {superAdmin && (
          <div>
            <MenuItem
              onClick={() => {
                navToSuperAdminHome();
                handleClose();
              }}>
              <Svgs.IconOrganizationsDefault />
              <Spacer width='xs' />
              Organizations
            </MenuItem>
            <Spacer height='xs' />
            <Divider
              style={{
                backgroundColor: '#038ab7',
                width: '85%',
                height: '3px',
                borderRadius: '8px',
                justifyContent: 'space-around',
                margin: 'auto',
              }}
            />
            <Spacer height='xs' />
          </div>
        )} */}
        {orgData && !currentOrgIsIncomplete && !addPackageFlow && (
          <div>
            {sidebarItems.map(
              (item, index) =>
                item && (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      navigate(item.pathname);
                      handleClose();
                    }}>
                    {item.artDefault}
                    <Spacer width='xs' />
                    {item.label}
                  </MenuItem>
                ),
            )}

            <Spacer height='xs' />
            <Divider
              style={{
                backgroundColor: '#038ab7',
                width: '85%',
                height: '3px',
                borderRadius: '8px',
                justifyContent: 'space-around',
                margin: 'auto',
              }}
            />
            <Spacer height='xs' />
          </div>
        )}
        <MenuItem
          onClick={() => {
            navigate('/drill-down/profile');
            handleClose();
          }}>
          <Svgs.IconProfileDefault />
          <Spacer width='xs' />
          {userData?.firstName ?? 'Profile'}
        </MenuItem>
        <Spacer height='xs' />
        <Divider
          style={{
            backgroundColor: '#038ab7',
            width: '85%',
            height: '3px',
            borderRadius: '8px',
            justifyContent: 'space-around',
            margin: 'auto',
          }}
        />
        <Spacer height='xs' />
        <MenuItem onClick={() => navigate('/')} disableRipple>
          <form method='post' action={`https://ipm.${domain}/logout`}>
            <Button
              type='submit'
              variant='ghost'
              className='font16'
              sx={{
                borderRadius: '1px',
                padding: '15px 17px !important',
                width: '100%',
                justifyContent: 'flex-start',
              }}>
              <Svgs.IconLogout style={{ alignSelf: 'center', marginRight: '25px' }} />
              Logout
            </Button>
            <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
          </form>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default TopNavBar;
