import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Column, Page, Row, Searchbox, Spacer } from '../../components';
import {
  MutationStatus,
  MutatorState,
  useAppStorage,
  useMutation,
  useObservable,
} from '@aesop-fables/scrinium';
import { UserCompartments, userStorageKey } from '../../data/user';
import { UserData } from '../../models/UserData';
import { ToastbarContext } from '../../App';
import { useLoading } from '../../hooks/useLoading';
import { GroupCompartments, groupStorageKey } from '../../data/group';
import { Svgs } from '../../assets/svg';
import { GroupData } from '../../models/GroupData';
import { Toast } from '../../models/Toast';
import { DeleteGroup, SaveGroupData } from '../../data/group/mutations';
import SubHeaderRow from '../multitier/SubHeaderRow';
import { DataDisplayProps } from '../multitier/AdminDashboard';
import { GroupMetricsData } from '../../models/MetricsData';

const GroupDataDisplay: React.FC<DataDisplayProps> = ({ select }) => {
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const [tryAgain, setTryAgain] = useState<boolean>(false);

  const saveGroupData = useMutation(new SaveGroupData());
  const deleteGroup = useMutation(new DeleteGroup());

  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);

  const userData = useObservable(userDataCache.observe$<UserData>('user'));
  const allGroups = useObservable(groupDataCache.observe$<GroupData[]>('allGroups'));
  const allMetrics = useObservable(groupDataCache.observe$<GroupMetricsData[]>('allMetrics'));

  const onCreate = async (values: GroupData) => {
    const { name } = values;
    await saveGroupData.action({
      name: name,
    });
  };

  useEffect(() => {
    if (!tryAgain) {
      return;
    }
    if (deleteGroup.error) {
      setLoading(false);
      setToast(
        new Toast({
          message: 'Error removing group',
          severity: 'error',
          open: true,
        }),
      );
      setTryAgain(false);
    } else if (deleteGroup.status === MutationStatus.Complete) {
      setLoading(false);
      setToast(new Toast({ message: 'Group removed!', severity: 'success', open: true }));
      setTryAgain(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGroup, tryAgain]);

  if (allGroups === undefined || allMetrics === undefined) {
    return null;
  }

  return (
    <Page title={userData?.firstName ? `Hi, ${userData.firstName}!` : 'Hi!'}>
      <Spacer height='sm' />
      <SubHeaderRow onCreate={onCreate} hideCreate tier='group' />
      <GroupDisplay groupList={allGroups} select={select} allMetrics={allMetrics} />
    </Page>
  );
};

export interface GroupDisplayProps {
  groupList: GroupData[];
  select: MutatorState<number>;
  allMetrics: GroupMetricsData[];
}

const GroupDisplay: React.FC<GroupDisplayProps> = ({ groupList, select, allMetrics }) => {
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const [searchWord, setSearchWord] = useState('');
  const [filteredGroupDataForTable, setFilteredGroupDataForTable] = useState<GroupData[]>([]);

  const numGroups = groupList?.length ?? 0;
  const enoughGroups = numGroups >= 10;

  useEffect(() => {
    if (!searchWord) {
      setFilteredGroupDataForTable(groupList);
      return;
    }
    setFilteredGroupDataForTable(
      groupList.filter((item: any) =>
        Object.values(item).some((val: any) =>
          val.toString().toLowerCase().includes(searchWord.toLowerCase()),
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord, groupList]);

  const selectGroup = async (groupId: any) => {
    await select.action(groupId);
  };

  const onView = async (groupId: any) => {
    if (groupId === undefined) {
      setToast(new Toast({ message: 'No group id found', severity: 'error', open: true }));
      return;
    }
    setLoading(true);
    selectGroup(groupId);
  };

  return (
    <>
      {enoughGroups && (
        <>
          <Spacer height='xs' />
          <Card variant='ghost' color='primary'>
            <CardContent className='searchbox'>
              <Column>
                <Searchbox
                  callback={value => setSearchWord(value)}
                  label='Search'
                  placeholder='Search by group name'
                />
              </Column>
            </CardContent>
          </Card>
        </>
      )}
      <Spacer height='xs' />
      <Card variant='ghost' color='primary'>
        <CardContent className='pad25'>
          {numGroups === 0 ? (
            <Typography
              variant='p20'
              color='secondary.main'
              textAlign='center'
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              You haven't created any groups yet.
            </Typography>
          ) : filteredGroupDataForTable.length > 0 ? (
            filteredGroupDataForTable.map((group, i) => {
              const metrics = allMetrics.find(x => x.groupId === group.id);
              return (
                <Card
                  variant='ghost'
                  color='secondary'
                  key={group.id}
                  sx={{ marginTop: i === 0 ? '0px' : '10px' }}>
                  <CardContent className='pad10'>
                    <Row style={{ justifyContent: 'space-between' }}>
                      <Row
                        style={{
                          alignItems: 'center',
                          flex: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        <Spacer width='xxs' />
                        <Link
                          className='darkenOnHover'
                          noWrap={true}
                          variant='p18SemiBold'
                          color='secondary.main'
                          sx={{ flex: 1 }}
                          onClick={() => {
                            onView(group.id);
                          }}>
                          {group.name}
                        </Link>
                        <Spacer width='lg' />
                      </Row>

                      <Row style={{ alignItems: 'center' }}>
                        {metrics ? (
                          <>
                            <Column>
                              <Typography variant='p12SemiBold' color='secondary.main'>
                                Organizations
                              </Typography>
                              <Row style={{ alignItems: 'center' }}>
                                <Svgs.IconOrgPaysLarge />
                                <Spacer width='xxs' />
                                <Typography variant='p20SemiBold' color='secondary.main'>
                                  {metrics?.organizations}
                                </Typography>
                              </Row>
                            </Column>
                            <Spacer width='md' />
                            <Column>
                              <Typography variant='p12SemiBold' color='secondary.main'>
                                Active users
                              </Typography>
                              <Row style={{ alignItems: 'center' }}>
                                <Svgs.IconMembersDefault />
                                <Spacer width='xxs' />
                                <Typography variant='p20SemiBold' color='secondary.main'>
                                  {metrics?.activeLicenses}
                                </Typography>
                              </Row>
                            </Column>
                          </>
                        ) : (
                          <div style={{ padding: '24px ' }} />
                        )}

                        <Spacer width='xxl' />
                        <Button
                          onClick={() => onView(group.id)}
                          sx={{
                            padding: '5px 30px',
                            typography: { fontSize: 12 },
                            height: '30px',
                          }}
                          color='secondary'>
                          View
                        </Button>
                      </Row>
                    </Row>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Typography variant='p18SemiBold' color='secondary.main'>
              No results found
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default GroupDataDisplay;
