import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class SubscriptionApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<SubscriptionData[]>> {
    return this.axios.get('subscription/organizationId');
  }
  add(data: SubscriptionRest): Promise<AxiosResponse<SubscriptionData>> {
    return this.axios.patch(
      `subscription/add-license?subscriptionId=${data.subscriptionId}&amount=${data.amount}`,
    );
  }
  decrease(data: SubscriptionRest): Promise<AxiosResponse<SubscriptionData>> {
    return this.axios.patch(
      `subscription/decrease-license?subscriptionId=${data.subscriptionId}&amount=${data.amount}`,
    );
  }
  cancelInvoice(subscriptionId: number): Promise<AxiosResponse<SubscriptionData>> {
    return this.axios.post(`subscription/cancel/failed/add?subscriptionId=${subscriptionId}`);
  }
}

export interface SubscriptionData {
  id: number;
  stripeSubscriptionId: string;
  quantity: number;
  customerId: number;
  productId: number;
  priceId: number;
  startPeriod: string;
  active: boolean;
  failedInvoiceUrl: string;
  paymentFailed: boolean;
  amountAdded: number;
  product: string;
  paymentType: string;
}

export interface SubscriptionRest {
  subscriptionId: number;
  amount: number;
}
