import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppGateway } from './routes';
import { createContext } from 'react';
import { ToastBar } from './components';
import { Toast, ToastDefaultValue } from './models/Toast';
import { LoadingProvider } from './hooks/useLoading';

export const ToastbarContext = createContext<ToastDefaultValue>({
  toast: new Toast({ open: false }),
  setToast: () => {},
});

function App() {
  const location = useLocation();
  const [toast, setToast] = useState(new Toast({ open: false }));

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(new Toast({ open: false }));
  };

  return (
    <ToastbarContext.Provider value={{ toast, setToast }}>
      <LoadingProvider>
        <AppGateway />
        <ToastBar
          open={toast.open}
          severity={toast.severity}
          message={toast.message}
          autoHideDuration={toast.autoHideDuration}
          handleClose={handleClose}
          actionElement={toast.actionElement}
        />
      </LoadingProvider>
    </ToastbarContext.Provider>
  );
}

export default App;
