import { Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { FormInput, PasswordInput, Row, Spacer } from '.';
import { formatPhoneNumber } from '../helpers/utilityFunctions';

interface Props extends React.PropsWithChildren {
  keyString?: string;
  value?: string | React.ReactComponentElement<any>;
  editing?: boolean;
  fieldName?: string;
  type?: string;
  valueIsLink?: boolean;
  required?: boolean;
  containerStyles?: any;
  keyStyles?: any;
  inputStyles?: any;
  valueStyles?: any;
  variant?: any;
}

const KeyValueLabel: React.FC<Props> = props => {
  const theme = useTheme();
  const {
    keyString,
    value,
    editing,
    fieldName,
    valueIsLink,
    required,
    containerStyles,
    inputStyles,
    valueStyles,
    variant,
  } = props;
  const extraValueStyles = valueIsLink
    ? { textDecoration: 'underline', cursor: 'pointer', ...valueStyles }
    : valueStyles;
  const keyStyles = editing
    ? { marginTop: '10px', ...props.keyStyles }
    : { alignSelf: 'baseline', ...props.keyStyles };
  const [formattedPhone, setFormattedPhone] = useState<string>();

  const validatePhoneNumber = (value: string) => {
    const result = formatPhoneNumber(value);
    setFormattedPhone(result);

    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;
    return regex.test(value) ? undefined : 'Invalid Phone Number';
  };

  return (
    <>
      <Row
        style={{
          flexWrap: 'wrap',
          gap: '17px',
          justifyContent: 'flex-end',
          alignItems: 'baseline',
          ...containerStyles,
        }}>
        <Typography
          style={{ flex: '2 0 10px', textAlign: 'right', ...keyStyles }}
          variant='p14'
          color='info.main'>
          {keyString}
        </Typography>

        {editing ? (
          props.type === 'password' ? (
            <PasswordInput
              style={{ flex: '3 0 10px' }}
              label={''}
              amount={''}
              weight={''}
              weightRange={''}
              innerStyle={{ margin: 0 }}
            />
          ) : props.fieldName === 'phoneNumber' ? (
            <FormInput
              formattedValue={formattedPhone}
              validate={validatePhoneNumber}
              maxLength={14}
              inputStyle={{ flex: '3 0 10px', ...inputStyles }}
              fieldName={fieldName ?? ''}
              initialValue={typeof value === 'string' ? value : ''}
              type={props.type ?? 'text'}
              required
            />
          ) : (
            <FormInput
              inputStyle={{ flex: '3 0 10px', ...inputStyles }}
              fieldName={fieldName ?? ''}
              initialValue={typeof value === 'string' ? value : ''}
              type={props.type ?? 'text'}
              required={required ? required : false}
            />
          )
        ) : (
          <Typography
            style={{
              flex: '3 0 10px',
              wordWrap: 'break-word',
              maxWidth: '58.5%',
              ...extraValueStyles,
            }}
            variant={variant ?? 'p14'}
            color={valueIsLink ? undefined : `secondary.dark`}>
            {valueIsLink ? (
              <a
                style={{ color: theme.palette.primary.main }}
                href={value?.toString()}
                target='_blank'
                rel='noreferrer'>
                {value}
              </a>
            ) : (
              value
            )}
          </Typography>
        )}
      </Row>
      <Spacer height='xxs' />
    </>
  );
};

export default KeyValueLabel;
