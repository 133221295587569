import { Typography } from '@mui/material';
import { Spacer, Row } from '../../components';
import TodoList from '../offboarding/TodoList';
import { OrganizationCard } from './OrganizationInfo';

const FinishTodosCard = () => {
  return (
    <OrganizationCard title='Members’ sign up page' editable={false}>
      <Typography variant='p20SemiBold' color='secondary.main'>
        Finish setting up your custom sign up page
      </Typography>
      <Spacer height='sm' />
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <TodoList />
      </Row>
    </OrganizationCard>
  );
};

export default FinishTodosCard;
