import { Typography, Button } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../../components';
import { logo3NSmall } from '../../assets/png';
import { Svgs } from '../../assets/svg';
import { LearnMoreDrawerProps, PackageCard } from './LearnMoreAdviceAndThreeNickelsUDrawer';

export const LearnMoreAdviceDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
}) => {
  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: '90%' }}>
          <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            {pricebookBundle.pricebook.paidBy === 'USER' ? (
              <Svgs.IconMemberPaysLarge />
            ) : (
              <Svgs.IconOrgPaysLarge />
            )}
            <Spacer width='xxs' />
            <Typography color='secondary' variant='p12SemiBold'>
              {pricebookBundle.pricebook.paidBy === 'USER' ? 'Member pays' : 'Organization pays'}
            </Typography>
          </Row>

          <Spacer height='xxs' />
          <Typography color='secondary' variant='p30Light'>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        <img src={logo3NSmall} alt='logo' style={{ height: '40px' }} />
      </Row>

      <PackageCard pricebookBundle={pricebookBundle} />
      <Spacer height='xs' />

      <Typography color='secondary' variant='p18Bold'>
        Description
      </Typography>
      <Spacer height='xxs' />
      <Row>
        <Column style={{ width: '100%' }}>
          <Typography color='info.main' variant='body1'>
            Experience The Financial Advisor In Your Pocket<sup>&#174;</sup>!
          </Typography>
          <Spacer height='xxs' />
          <Typography color='info.main' variant='body1'>
            Connect all of your finances in one place and let us do the heavy lifting. We’ve been
            advising people on their finances for over 20 years, backed by our sophisticated,
            award-winning technology. Now it’s in your hands!
          </Typography>
        </Column>
        <Svgs.ArtPieChart style={{ marginLeft: '20px', marginRight: '20px' }} />
      </Row>
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        At a glance...
      </Typography>
      <Column style={{ padding: '0px 10px' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletBook />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Accessible education</strong> at every level of financial literacy.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletCalculator />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Customizable tools</strong> to help you with budgeting, retirement, debt payoff
            and much more.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletChecklist />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Actionable financial planning</strong> to help you reach your goals.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletInvestment />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Objective investment advice</strong> for each of your personal accounts.
          </Typography>
        </Row>
      </Column>

      <Spacer height='sm' />
      <Row style={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => setDrawerOpen(false)}
          className='sm'
          variant='outlined'
          color='secondary'>
          Close
        </Button>
      </Row>
      <Spacer height='md' />
    </RightDrawer>
  );
};
