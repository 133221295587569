import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, map } from 'rxjs';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { SubscriptionCompartments, subscriptionStorageKey } from '../subscription';
import { LicenseMetricsApi, LicenseMetricsData } from '../../api/apis/LicenseMetricsApi';
import { LicenseAgreementApi, LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationData } from '../../models/OrganizationData';

export interface LicenseCompartments {
  licenseAgreement: DataCompartmentOptions<LicenseAgreementData[] | undefined>;
  licenseMetrics: DataCompartmentOptions<LicenseMetricsData[] | undefined>;
}

export const licenseStorageKey = 'data/license';

export class LicenseDataCachePolicy extends DataCachePolicy<LicenseCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.LicenseAgreement)
    private readonly licenseAgreementApi: LicenseAgreementApi,
    @inject(ApiKeys.LicenseMetrics)
    private readonly licenseMetricsApi: LicenseMetricsApi,
  ) {
    super(licenseStorageKey);
  }

  buildDataCache() {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const subscriptionDataCache =
      this.appStorage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(map(([isAuthenticated, orgData]) => isAuthenticated === true && orgData !== undefined));
    const licenseMetricsDependsOn = combineLatest([
      dependsOn,
      subscriptionDataCache.initialized$(),
    ]).pipe(
      map(([dependsOn$, subscriptionDataInitialized]) => dependsOn$ && subscriptionDataInitialized),
    );
    return createObservedDataCache<LicenseCompartments>({
      licenseAgreement: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.licenseAgreementApi.getAllDocs();
          return data;
        }),
        defaultValue: undefined,
      },
      licenseMetrics: {
        dependsOn: licenseMetricsDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.licenseMetricsApi.get();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withLicenseData = createServiceModule(licenseStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, LicenseDataCachePolicy);
});
