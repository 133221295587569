import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class SecuritiesApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  searchByName(name: string): Promise<AxiosResponse<SecuritiesSearchRest[]>> {
    return this.axios.get<SecuritiesSearchRest[]>(
      `security/search/name?name=${name}&securityType=`,
    );
  }

  searchBySymbol(symbol: string): Promise<AxiosResponse<SecuritiesSearchRest[]>> {
    return this.axios.get<SecuritiesSearchRest[]>(
      `security/search/symbol?symbol=${symbol}&securityType=`,
    );
  }

  findById(id: number): Promise<AxiosResponse<SecurityRest>> {
    return this.axios.get<SecurityRest>(`security/search/${id}`);
  }
}

export interface SecuritiesSearchRest {
  id: number;
  securityName: string;
  securityType: number;
  ticker: string;
  expenseRatio?: number;
  msCategory?: number;
}

export interface SecurityRest extends SecuritiesSearchRest {
  price?: number;
}

export enum SecurityTypeEnum {
  Stock = 1,
  Bond = 2,
  Cash = 3,
  ETF = 4,
  'Mutual Fund' = 4,
  Trust = 5,
  Other = 6,
  Restricted = 7,
}
