import {
  ConfiguredEntityResolver,
  IAppStorage,
  IRepository,
  RepositoryCompartmentOptions,
  ScriniumServices,
  createRepository,
} from '@aesop-fables/scrinium';
import {
  AnyReportRest,
  AnyReportType,
  BaseReportData,
  GroupReportData,
  OrgReportData,
  SummedGroupReportData,
  SummedTeamReportData,
  TeamReportData,
} from '../../models/ReportData';
import { Scopes, createServiceModule } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { ReportApi } from '../../api/apis/ReportApi';
import { Repositories } from '../IDataCachePolicy';
import dayjs, { Dayjs } from 'dayjs';
import { extractCalendarDate } from '../../helpers/utilityFunctions';
import { AdminTierTypeEnum } from '../../services/drillDown';

export interface ReportRegistry {
  orgReport: RepositoryCompartmentOptions<Dayjs, BaseReportData>;
  teamReport: RepositoryCompartmentOptions<Dayjs, BaseReportData>;
  groupReport: RepositoryCompartmentOptions<Dayjs, BaseReportData>;
  summedTeamReport: RepositoryCompartmentOptions<Dayjs, BaseReportData>;
  summedGroupReport: RepositoryCompartmentOptions<Dayjs, BaseReportData>;
  earliestDate: RepositoryCompartmentOptions<string, string>;
}

export const reportsRepositoryKey = 'data/report';

export const withReportData = createServiceModule(reportsRepositoryKey, services => {
  const factory = (): IRepository<ReportRegistry> => {
    const api = services.resolve<ReportApi>(ApiKeys.Report);
    const appStorage = services.resolve<IAppStorage>(ScriniumServices.AppStorage);

    const repository = createRepository<ReportRegistry>({
      orgReport: {
        resolver: new ConfiguredEntityResolver<Dayjs, BaseReportData>(async date => {
          try {
            const { data } = await api.getOrgReports(date);
            return data;
          } catch (err) {
            console.error('Error: ', err);
            throw err;
          }
        }),
      },
      teamReport: {
        resolver: new ConfiguredEntityResolver<Dayjs, BaseReportData>(async date => {
          try {
            const { data } = await api.getTeamReports(date);
            return data;
          } catch (err) {
            console.error('Error: ', err);
            throw err;
          }
        }),
      },
      groupReport: {
        resolver: new ConfiguredEntityResolver<Dayjs, BaseReportData>(async date => {
          try {
            const { data } = await api.getGroupReports(date);
            return data;
          } catch (err) {
            console.error('Error: ', err);
            throw err;
          }
        }),
      },
      summedTeamReport: {
        resolver: new ConfiguredEntityResolver<Dayjs, BaseReportData>(async date => {
          try {
            const { data } = await api.getSummedTeamReport(date);
            return data;
          } catch (err) {
            console.error('Error: ', err);
            throw err;
          }
        }),
      },
      summedGroupReport: {
        resolver: new ConfiguredEntityResolver<Dayjs, BaseReportData>(async date => {
          try {
            const { data } = await api.getSummedGroupReport(date);
            return data;
          } catch (err) {
            console.error('Error: ', err);
            throw err;
          }
        }),
      },
      earliestDate: {
        resolver: new ConfiguredEntityResolver<string, string>(async () => {
          try {
            const { data } = await api.getEarliestDate();
            return data;
          } catch (err) {
            console.error('Error fetching earliest date: ', err);
            throw err;
          }
        }),
      },
    });

    appStorage.store(reportsRepositoryKey, repository);
    return repository;
  };

  services.factory<IRepository<ReportRegistry>>(Repositories, factory, Scopes.Transient);
  return factory();
});

type CompartmentKeys = (keyof ReportRegistry)[];

export const getReportCompartmentKeys = (tier: AdminTierTypeEnum | undefined): CompartmentKeys => {
  switch (tier) {
    case AdminTierTypeEnum.Root:
      return ['orgReport'];
    case AdminTierTypeEnum.Organization:
      return ['teamReport', 'summedTeamReport'];
    case AdminTierTypeEnum.Team:
      return ['groupReport', 'summedGroupReport'];
    default:
      return [];
  }
};

export const mapReportRestToData = (data: AnyReportRest): AnyReportType => {
  if ('organizationReportId' in data) {
    return {
      ...data,
      id: data.organizationReportId,
      name: data.organizationName,
      pricebookReports: data.organizationPricebookReports,
    } as OrgReportData;
  } else if ('teamId' in data && 'organizationReports' in data) {
    return {
      ...data,
      id: data.teamId,
      name: data.teamName,
      organizationReports: data.organizationReports.map(
        report => mapReportRestToData(report) as OrgReportData,
      ),
    } as TeamReportData;
  } else if ('groupId' in data && 'teamReports' in data) {
    return {
      ...data,
      id: data.groupId,
      teamReports: data.teamReports.map(
        report => mapReportRestToData(report) as SummedTeamReportData,
      ),
    } as GroupReportData;
  } else if ('teamId' in data && 'teamPricebookReportList' in data) {
    return {
      ...data,
      id: data.teamId,
      name: data.teamName,
      pricebookReports: data.teamPricebookReportList,
    } as SummedTeamReportData;
  } else if ('groupId' in data && 'groupPricebookReportList' in data) {
    return {
      ...data,
      id: data.groupId,
      pricebookReports: data.groupPricebookReportList,
    } as SummedGroupReportData;
  }
  throw new Error('Unknown report type');
};

export const reloadReportCompartments = async (
  storage: IAppStorage,
  compartments: (keyof ReportRegistry)[],
  date: Dayjs = dayjs(),
) => {
  const reportRepository = storage.repository<ReportRegistry>(reportsRepositoryKey);
  const dateKey = extractCalendarDate(date);

  for (const compartmentKey of compartments) {
    const compartment = reportRepository.get(compartmentKey, dateKey);
    await compartment.reload();
  }
};
