import { assign } from 'lodash';
import { useTheme } from '@mui/material';
import { VictoryThemeDefinition } from 'victory';

// original taken from https://formidable.com/open-source/victory/guides/themes

export const useChartTheme = () => {
  const theme = useTheme();

  // Colors
  enum chartColors {
    '3Nickels' = '#a09cff',
    'GuidedChoice' = '#b8d6a9',
    'Free' = '#9eacb0',
    'Subscription' = '#038ab7',
  }
  const secondaryLight = theme.palette.secondary.light;
  const secondaryMain = theme.palette.secondary.main;
  const secondaryDark = theme.palette.secondary.dark;

  // Typography
  const sansSerif = "'Open Sans', 'Helvetica Neue', 'Helvetica', sans-serif";
  const letterSpacing = 'normal';
  const fontSize = 12;

  // Layout
  const padding = 8;
  const baseProps = {
    width: 350,
    height: 350,
    padding: 50,
  };

  // * Labels
  const baseLabelStyles = {
    fontFamily: sansSerif,
    fontSize,
    letterSpacing,
    padding,
    fill: theme.palette.secondary.dark,
    stroke: 'transparent',
    strokeWidth: 0,
  };
  const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles);

  // Strokes
  const strokeDasharray = '8, 8';
  const strokeLinecap = 'round';
  const strokeLinejoin = 'round';

  // Put it all together...
  const chartTheme: VictoryThemeDefinition = {
    area: assign(
      {
        style: {
          data: {
            fill: secondaryDark,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    axis: assign(
      {
        style: {
          axis: {
            fill: 'transparent',
            stroke: secondaryLight,
            strokeWidth: 2,
            strokeLinecap,
            strokeLinejoin,
          },
          axisLabel: assign({}, centeredLabelStyles, {
            padding,
            stroke: 'transparent',
          }),
          ticks: {
            fill: 'transparent',
            size: 5,
            stroke: secondaryLight,
            strokeWidth: 1,
            strokeLinecap,
            strokeLinejoin,
          },
          tickLabels: assign({}, baseLabelStyles, {
            fill: secondaryDark,
          }),
        },
      },
      baseProps,
    ),
    dependentAxis: {
      style: {
        grid: {
          fill: 'none',
          stroke: '#b6c0c4',
          strokeDasharray,
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: 'painted',
        },
        tickLabels: baseLabelStyles,
        axisLabel: baseLabelStyles,
      },
    },
    independentAxis: {
      style: {
        grid: {
          stroke: 'transparent',
        },
        tickLabels: baseLabelStyles,
        axisLabel: baseLabelStyles,
      },
    },
    bar: assign(
      {
        style: {
          data: {
            fill: secondaryMain,
            padding,
            strokeWidth: 0,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    chart: baseProps,
    legend: {
      gutter: 10,
      orientation: 'vertical',
      titleOrientation: 'top',
      style: {
        data: {
          type: 'circle',
        },
        labels: baseLabelStyles,
        title: assign({}, baseLabelStyles, { padding: 5 }),
      },
    },
    line: assign(
      {
        style: {
          data: {
            fill: 'transparent',
            opacity: 1,
            stroke: secondaryMain,
            strokeWidth: 2,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    tooltip: {
      style: assign({}, baseLabelStyles, {
        padding: 0,
        pointerEvents: 'none',
        fill: theme.palette.secondary.dark,
      }),
      flyoutStyle: {
        fill: 'white',
        stroke: '#979797',
        strokeWidth: 1,
        filter: 'drop-shadow(0 2px 3px rgba(0, 0, 0, 0.5))',
      },
      flyoutPadding: { top: 8, bottom: 8, left: 8, right: 8 },
      cornerRadius: 5,
      pointerLength: 10,
    },
    voronoi: assign(
      {
        style: {
          data: {
            fill: 'transparent',
            stroke: 'transparent',
            strokeWidth: 0,
          },
          labels: assign({}, baseLabelStyles, { padding: 5, pointerEvents: 'none' }),
          flyout: {
            stroke: secondaryDark,
            strokeWidth: 1,
            fill: '#f0f0f0',
            pointerEvents: 'none',
          },
        },
      },
      baseProps,
    ),
  };
  return { chartColors, chartTheme };
};

export default useChartTheme;
