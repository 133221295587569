import { useRef } from 'react';
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Typography } from '@mui/material';
import { useField } from 'react-final-form';
import Column from './Column';

export interface ToggleInputProps<T> extends ToggleButtonGroupProps {
  inputStyle?: {};
  buttonStyle?: {};
  fieldName: keyof T;
  items: string[];
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  exclusive?: boolean;
  className?: string;
  validate?: (x: any) => undefined | string;
}

const ToggleInput = <T extends unknown>({
  inputStyle,
  buttonStyle,
  fieldName,
  items,
  initialValue,
  required,
  disabled,
  readonly,
  exclusive,
  className,
  validate,
  fullWidth,
}: ToggleInputProps<T>) => {
  const dataExists = items && items.length > 0;

  const inputRef = useRef<typeof ToggleButtonGroup>(null);

  const { input, meta } = useField<string>(fieldName.toString(), {
    initialValue,
    validate: (value: string) =>
      !required || value ? (validate ? validate(value) : undefined) : 'Required',
    subscription: { value: true, touched: true, error: true },
  });

  return (
    <Column style={{ width: fullWidth ? '100%' : undefined, ...inputStyle }}>
      <ToggleButtonGroup
        fullWidth={fullWidth}
        ref={inputRef}
        style={inputStyle}
        value={!input.value ? '' : input.value.toString()}
        exclusive={exclusive}
        onChange={v => input.onChange(v)}
        disabled={disabled || readonly}>
        {dataExists &&
          items.map((item, index) => (
            <ToggleButton
              classes={className}
              style={{ flexGrow: 3, ...buttonStyle }}
              key={index}
              value={item}>
              {item}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
      {meta.touched && meta.error !== undefined && (
        <Typography style={{ fontSize: '.75rem', marginTop: '3px' }} color='error.main'>
          {meta.touched ? meta.error : undefined}
        </Typography>
      )}
    </Column>
  );
};

export default ToggleInput;
