import { combineLatest, map } from 'rxjs';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  ScriniumServices,
  DataCache,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { inject, createServiceModule } from '@aesop-fables/containr';
import { PricebookBundle } from '../../models/PricebookData';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import { PricebookCompartments, pricebookStorageKey } from '../pricebook';

export interface ProductTypeCompartments {
  productType: DataCompartmentOptions<string>;
  paidBy: DataCompartmentOptions<string | undefined>;
}

export const productTypeStorageKey = 'data/productType';

export class ProductTypeDataCachePolicy extends DataCachePolicy<ProductTypeCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
  ) {
    super(productTypeStorageKey);
  }

  buildDataCache(): DataCache<ProductTypeCompartments> {
    const pricebookDataCache = this.appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
    const dependsOn = combineLatest([
      pricebookDataCache.findCompartment('pricebookSelections').initialized$(),
      pricebookDataCache.observe$<PricebookBundle[] | undefined>('pricebookSelections'),
    ]).pipe(
      map(
        ([pricebookSelectionsInitialized, pricebookSelections]) =>
          pricebookSelectionsInitialized &&
          pricebookSelections !== undefined &&
          pricebookSelections.length > 0,
      ),
    );

    return createObservedDataCache<ProductTypeCompartments>({
      productType: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const pricebookSelections = (
            pricebookDataCache.findCompartment('pricebookSelections') as DataCompartment<
              PricebookBundle[]
            >
          ).getData() as PricebookBundle[];

          return pricebookSelections[0].pricebook.productType;
        }),
        defaultValue: '',
      },
      paidBy: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const pricebookSelections = (
            pricebookDataCache.findCompartment('pricebookSelections') as DataCompartment<
              PricebookBundle[]
            >
          ).getData() as PricebookBundle[];

          if (pricebookSelections.length > 0) {
            return pricebookSelections[0]?.pricebook.paidBy;
          }
          return undefined;
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withProductTypeData = createServiceModule(productTypeStorageKey, services =>
  services.add<IDataCachePolicy>(Policies, ProductTypeDataCachePolicy),
);
