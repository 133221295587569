import React, { useState, useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { Spacer } from '../../../components';
import { AnyReportType, ReportProps } from '../../../models/ReportData';
import { AdminTierTypeEnum } from '../../../services/drillDown';
import { alphabetizeReports, getIndividualReports } from '../../../helpers/reportUtils';
import UserAccountsTable from './UserAccountsTable';
import { Loading } from '../../../hooks/useLoading';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';

interface ReportBreakdownProps extends ReportProps {
  tier: AdminTierTypeEnum | undefined;
  isEmpty: boolean;
  summedReport: AnyReportType | null;
}

const ReportBreakdown: React.FC<ReportBreakdownProps> = ({
  tier,
  report,
  isEmpty,
  summedReport,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const hasBreakdown = tier === AdminTierTypeEnum.Team || tier === AdminTierTypeEnum.Organization;

  const pricebookReports = useMemo(() => getIndividualReports(report), [report]);
  const sortedReports = useMemo(() => alphabetizeReports(pricebookReports), [pricebookReports]);

  const {
    displayedItems: displayedReports,
    hasMore,
    lastElementRef,
  } = useInfiniteScroll(sortedReports, expanded);

  if (isEmpty || !hasBreakdown) {
    return null;
  }

  return (
    hasBreakdown && (
      <Grid container direction='column'>
        <Grid item display='flex' mb={3} onClick={() => setExpanded(prev => !prev)}>
          {expanded ? (
            <Svgs.IconCollapse fill={theme.palette.info.main} />
          ) : (
            <Svgs.IconAdd fill={theme.palette.info.main} />
          )}
          <Spacer width='xxs' />
          <Typography variant='p18SemiBold' color='secondary.main'>
            {expanded ? 'Hide' : 'See'} breakdown by {tier?.toLowerCase()}
          </Typography>
        </Grid>

        {expanded && (
          <Grid>
            {displayedReports.map((pricebookReport, index) => (
              <Grid
                key={pricebookReport.id}
                ref={index === displayedReports.length - 1 ? lastElementRef : null}>
                <UserAccountsTable
                  fullReport={report}
                  individualReport={pricebookReport}
                  summedReport={summedReport}
                />
              </Grid>
            ))}

            {hasMore && <Loading />}
          </Grid>
        )}
      </Grid>
    )
  );
};

export default ReportBreakdown;
