import { useContext, useEffect } from 'react';
import { Button } from '@mui/material';
import { Column, HorizontalFormInput, KeyValueLabel, Page, Row, Spacer } from '../../components';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { Form } from 'react-final-form';
import { ToastbarContext } from '../../App';
import { useLoading } from '../../hooks/useLoading';
import { Toast } from '../../models/Toast';
import { MendixClientCompartments, mendixClientStorageKey } from '../../data/mendixClient';
import { MendixClientData } from '../../api/apis/MendixClientApi';
import { AddPublicKey, EditPublicKey } from '../../data/mendixClient/mutations';

const PublicKey = () => {
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const appStorage = useAppStorage();
  const mendixClientDataCache =
    appStorage.retrieve<MendixClientCompartments>(mendixClientStorageKey);
  const mendixClientData = useObservable(
    mendixClientDataCache.observe$<MendixClientData>('mendixClientData'),
  );

  const addPublicKey = useMutation(new AddPublicKey());
  const editPublicKey = useMutation(new EditPublicKey());

  useEffect(() => {
    if (addPublicKey.error || editPublicKey.error) {
      setLoading(false);
      setToast(
        new Toast({
          message: 'Could not save public key',
          severity: 'error',
          open: true,
        }),
      );
    } else if (
      addPublicKey.status === MutationStatus.Complete ||
      editPublicKey.status === MutationStatus.Complete
    ) {
      setLoading(false);
      setToast(new Toast({ message: 'Public key saved!', severity: 'success', open: true }));
      mendixClientDataCache.reload('mendixClientData');
    }
  }, [
    addPublicKey.error,
    addPublicKey.status,
    editPublicKey.error,
    editPublicKey.status,
    mendixClientDataCache,
    setLoading,
    setToast,
  ]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    if (!mendixClientData?.clientId && !mendixClientData?.recordKeeperId) {
      await addPublicKey.action({ publicKey: values.publicKey });
    } else {
      await editPublicKey.action({ publicKey: values.publicKey });
    }
  };

  return (
    <Page title='Mendix'>
      <Spacer height='sm' />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column
              style={{
                width: '50%',
              }}>
              <KeyValueLabel
                keyString='Client ID'
                value={mendixClientData?.clientId?.toString()}
                keyStyles={{ fontSize: '16px' }}
                variant={'body1'}
              />
              <Spacer height='xxs' />
              <KeyValueLabel
                keyString='Recordkeeper ID'
                value={mendixClientData?.recordKeeperId?.toString()}
                keyStyles={{ fontSize: '16px' }}
                variant={'body1'}
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Public key'
                fieldName='publicKey'
                inputStyle={{ flex: '3 0 10px' }}
                rightAlignedKey
                required
              />
              <Spacer height='sm' />
            </Column>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button color='primary' style={{ width: '185px' }} onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Page>
  );
};

export default PublicKey;
