import { Box, Card } from '@mui/material';

interface CardDisplayProps {
  children: React.ReactNode;
  disableAction?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  elevation?: number;
  sx?: React.CSSProperties;
}

const CardDisplay: React.FC<CardDisplayProps> = ({ elevation = 0, ...props }) => {
  return (
    <Card className='card-display' onClick={props.onClick} elevation={elevation}>
      <Box sx={props.sx}>{props.children}</Box>
    </Card>
  );
};

export default CardDisplay;
