import React from 'react';
import ChartBar from '../../../components/ChartBar';
import { PaymentPlatformData, ReportProps } from '../../../models/ReportData';
import useChartTheme from '../../../themes/chart';
import {
  getPricebookReportsByType,
  getProductTypeKey,
  getReportChartData,
  isPaidProduct,
} from '../../../helpers/reportUtils';

const paymentPlatformMap = new Map<keyof PaymentPlatformData, string>([
  ['stripePaymentPlatformCount', 'Stripe'],
  ['applePaymentPlatformCount', 'Apple'],
  ['androidPaymentPlatformCount', 'Google'],
  ['freeTrialPaymentPlatformCount', 'Free trial'],
  ['orgPaysPaymentPlatformCount', 'Org pays'],
]);

const PaymentPlatformChart: React.FC<ReportProps> = ({ report }) => {
  const { chartColors } = useChartTheme();
  const allPricebookReports = getPricebookReportsByType(report);
  const paidPricebookReports = allPricebookReports.filter(pricebookReport =>
    isPaidProduct(pricebookReport?.productType),
  );

  const paidProductsReport = {
    ...report,
    organizationPricebookReports: paidPricebookReports,
  };

  const chartData = getReportChartData(
    paidProductsReport,
    pricebookReport => getProductTypeKey(pricebookReport.productType),
    paymentPlatformMap,
  );

  const dataSeries = Object.entries(chartData).map(([productType, data]) => ({
    data,
    color: chartColors[productType as keyof typeof chartColors],
    label: productType,
  }));

  return (
    <ChartBar
      title='Active users per payment platform'
      dataSeries={dataSeries}
      yAxisLabel='Count per payment platform'
      seriesLabel='Product type'
    />
  );
};

export default PaymentPlatformChart;
