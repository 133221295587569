import { Typography, Divider } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import { DownloadButton, Row, Spacer } from '.';

interface CustomPaperProps extends PaperProps {
  handleScroll?: (e: React.UIEvent<HTMLElement>) => void;
  title?: string;
  onDownload?: () => void;
}

const Scrollbox: React.FC<CustomPaperProps> = props => {
  const { handleScroll, title, onDownload, ...rest } = props;

  return (
    <Paper
      {...rest}
      onScroll={handleScroll}
      style={{
        borderRadius: '8px',
        maxHeight: 400,
        overflow: 'auto',
        border: `1px solid #4a606a`,
        ...props.style,
      }}>
      <div style={{ padding: '30px' }}>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='subtitle1' color='secondary'>
            {title}
          </Typography>
          {onDownload ? <DownloadButton onDownload={onDownload} /> : null}
        </Row>
        <Spacer height='xs' />
        <Divider />
        {props.children}
      </div>
    </Paper>
  );
};

export default Scrollbox;
