import { useContext, useState } from 'react';
import { KeyValueLabel, Spacer } from '../../components';
import { ProfileCard } from './Profile';
import ProfileEditDrawer from './ProfileEditDrawer';
import { UserData } from '../../models/UserData';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { AuthenticatedWizard, authenticatedWizardKey } from '../../services/authenticated';
import { UserCompartments, userStorageKey } from '../../data/user';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';

const AboutYouCard: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const appStorage = useAppStorage();
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const userData = useObservable(userDataCache.observe$<UserData>('user'));

  return (
    // Not editable for initial version
    <ProfileCard setDrawerOpen={setDrawerOpen} title='About you' editable={false}>
      <KeyValueLabel keyString='Your first name' value={userData?.firstName} />
      <KeyValueLabel keyString='Your last name' value={userData?.lastName} />
      <KeyValueLabel keyString='Your contact email' value={userData?.email} />
      {/* <KeyValueLabel keyString='Your role' value={userData?.role} /> */}
      <AboutYouCardEdit
        userData={userData}
        title='About you'
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </ProfileCard>
  );
};

interface AboutYouCardEditProps {
  userData?: UserData;
  title: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AboutYouCardEdit: React.FC<AboutYouCardEditProps> = ({
  userData,
  title,
  drawerOpen,
  setDrawerOpen,
}) => {
  const authWizard = useService<AuthenticatedWizard>(authenticatedWizardKey);
  const { setToast } = useContext(ToastbarContext);

  const onSubmit = (values: any) => {
    try {
      authWizard.saveAboutYou(values as UserData);
      setToast(new Toast({ message: 'Profile info updated!', severity: 'success', open: true }));
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    }
  };
  return (
    <ProfileEditDrawer
      onSubmit={onSubmit}
      title={title}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}>
      <KeyValueLabel
        keyString='Your first name'
        value={userData?.firstName}
        fieldName='firstName'
        editing
      />
      <KeyValueLabel
        keyString='Your last name'
        value={userData?.lastName}
        fieldName='lastName'
        editing
      />
      <KeyValueLabel
        keyString='Your contact email'
        value={userData?.email}
        fieldName='email'
        editing
      />
      {/* <KeyValueLabel keyString='Your role' value={userData?.role} /> */}
      <Spacer height='lg' />
    </ProfileEditDrawer>
  );
};

export default AboutYouCard;
