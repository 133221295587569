import { Button, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, Row, Spacer } from '../components';
import { Svgs } from '../assets/svg';
import { logo3NSmall, logoGcSmall } from '../assets/png';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { signUpWizardKey, SignUpWizard as SignUpWizardService } from '../services/signUp';
import { OffboardingWizard, offboardingWizardKey } from '../services/offboarding';
import TodoList from '../dashboard/offboarding/TodoList';
import { UpdateSignUpState } from '../data/signUp/mutations';
import { useDeviceType } from '../hooks/useDeviceType';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { OrgSignUpStateData } from '../models/SignUpData';
import { PricebookBundle } from '../models/PricebookData';
import { useLoading } from '../hooks/useLoading';
import { LicenseData } from '../models/LicenseData';

const Congrats = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();

  const updateSignUpState = useMutation(new UpdateSignUpState());
  const signUpWizard = useService<SignUpWizardService>(signUpWizardKey);
  const offboardingWizard = useService<OffboardingWizard>(offboardingWizardKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const pricebook: PricebookBundle =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state?.pricebook);
  const licenseData: LicenseData =
    orgSignUpState?.state?.licenseData && JSON.parse(orgSignUpState?.state?.licenseData);
  const product = pricebook?.pricebook?.productType;
  const licenseQuantity = licenseData?.quantity;
  const { quantity, chosenPackage } = location?.state ?? {
    quantity: licenseQuantity,
    chosenPackage: pricebook,
  };

  let logo, logoAlt, subheading;
  if (!orgSignUpState?.onboardingComplete) {
    logo = product === 'GUIDED_CHOICE' ? logoGcSmall : logo3NSmall;
    logoAlt = `${product} Logo`;
    subheading = 'Finish setting up your members’ custom sign up page.';
  } else {
    const gcPackage = product === 'GUIDED_CHOICE';
    logo = gcPackage ? logoGcSmall : logo3NSmall;
    logoAlt = gcPackage ? 'GuidedChoice Logo' : '3Nickels Logo';
    subheading = `Thank you for adding ${quantity} license${
      quantity === 1 ? '' : 's'
    } of ${chosenPackage?.pricebook?.pricebookName} for your members.`;
  }

  const onSkip = async () => {
    signUpWizard.agreeAndSignUp();
    try {
      await updateSignUpState.action({
        lastCompletedStep: signUpWizard.getCurrent(location),
        onboardingComplete: true,
      });
      navigate('/');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const onStart = async () => {
    signUpWizard.agreeAndSignUp();
    await updateSignUpState.action({
      lastCompletedStep: signUpWizard.getCurrent(location),
      onboardingComplete: true,
    });
    const next = offboardingWizard.nextStep(location);
    navigate(next.route);
  };
  const { isMobile, isTablet } = useDeviceType();

  const navToNextStep = () => {
    navigate('/');
  };

  return (
    <Column style={{ margin: '15px 100px', alignItems: 'center' }}>
      <Row style={{ justifyContent: 'flex-end' }}>
        <img src={logo} alt={logoAlt} style={{ width: '30%' }} />
      </Row>
      <Spacer height='lg' />
      <Typography variant='p40Bold' color='secondary'>
        Congratulations!
      </Typography>
      <Spacer height='xs' />
      <Typography variant='p20' color='secondary.dark'>
        {subheading}
      </Typography>

      {orgSignUpState?.onboardingComplete && (
        <>
          <Spacer height='xxs' />
          <Typography variant='h2' color='info.main'>
            We have sent you a confirmation email.
          </Typography>
        </>
      )}

      <Spacer height='sm' />
      <Svgs.ArtThumbsUp />

      {orgSignUpState?.onboardingComplete ? (
        <>
          <Spacer height='lg' />
          <Button className='xs' onClick={navToNextStep} color='secondary' variant='outlined'>
            Close
          </Button>
        </>
      ) : (
        <>
          <Spacer height='md' />
          <TodoList hideButton />
          <Spacer height='xs' />
          <Button
            style={{ width: isMobile || isTablet ? '100%' : '' }}
            className='md'
            onClick={onStart}
            color='primary'>
            Start
          </Button>
          <Spacer height='sm' />
          <Typography variant='h2'>
            <Link onClick={onSkip}>Skip for now</Link>
          </Typography>
        </>
      )}
    </Column>
  );
};

export default Congrats;
