import DetailsCard, { ProgressItem } from './ProgressCards';
import { AnyReportType } from '../../../models/ReportData';
import { Box, Grid } from '@mui/material';
import { HelpBody, HelpTitle } from '../../../components/HelpText';
import { Spacer } from '../../../components';
import { ProgressCalculator } from './ReportProgressTracker';
import {
  getPricebookReportsByType,
  sumNestedProperty,
  sumPropertyInArray,
} from '../../../helpers/reportUtils';
import { ProductTypeEnum } from '../../../models/PricebookData';

const calculatePersonalDetailsSteps = (report: AnyReportType): ProgressItem[] => {
  return [1, 2, 3, 4, 5, 6].map(step => ({
    label: step === 6 ? 'Summary screen' : `Step ${step}`,
    value: sumNestedProperty(report, `personalDetailsStep${step}Count`),
  }));
};

export const progressTracker3Nickels: ProgressCalculator = (report: AnyReportType) => {
  const personalDetailsSteps = calculatePersonalDetailsSteps(report);
  const reports3nickels = getPricebookReportsByType(report, [
    ProductTypeEnum.Nickels,
    ProductTypeEnum.NickelsU,
  ]);

  const startedPersonalDetails = personalDetailsSteps.reduce((sum, step) => sum + step.value, 0);
  const finishedPersonalDetails = sumPropertyInArray(
    reports3nickels,
    'personalDetailsCompleteCount',
  );
  const totalInPersonalDetails = startedPersonalDetails - finishedPersonalDetails;
  const totalUsers = sumPropertyInArray(reports3nickels, 'activeUserCount');
  const totalCreated =
    sumPropertyInArray(reports3nickels, 'accountCreationMethodAppleCount') +
    sumPropertyInArray(reports3nickels, 'accountCreationMethodGoogleCount') +
    sumPropertyInArray(reports3nickels, 'accountCreationMethodManualCount');
  const completed = sumPropertyInArray(reports3nickels, 'onboardingCompletedCount');

  return {
    title: '3Nickels onboarding',
    totalUsers: totalUsers,
    notStarted: totalCreated - (totalInPersonalDetails + completed),
    inProgress: [
      {
        label: 'Started personal details',
        value: startedPersonalDetails,
        expandableComponent: (
          <PersonalDetailsExpandable personalDetailsSteps={personalDetailsSteps} />
        ),
      },
      {
        label: 'Finished personal details',
        value: finishedPersonalDetails,
      },
      {
        label: 'Finished account details',
        value: sumPropertyInArray(reports3nickels, 'accountDetailsCompleteCount'),
      },
      {
        label: 'Finished budget details',
        value: sumPropertyInArray(reports3nickels, 'budgetDetailsCompletedCount'),
      },
    ],
    completed: completed,
    helpText: '3Nickels active users',
    helpContent: <HelpText3nickels />,
  };
};

const PersonalDetailsExpandable = ({
  personalDetailsSteps,
}: {
  personalDetailsSteps: ProgressItem[];
}) => {
  return (
    <Grid>
      <Box
        ml='29px'
        width='0px'
        style={{
          // expandable arrow
          borderLeft: '18px solid transparent',
          borderRight: '18px solid transparent',
          borderBottom: '18px solid #fff',
        }}
      />
      <DetailsCard cards={personalDetailsSteps} />
    </Grid>
  );
};

const HelpText3nickels = () => {
  return (
    <Grid>
      <HelpTitle>3Nickels active users</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody>
        Count of users connected to this organization who have an active license for any 3Nickels
        subscription.
      </HelpBody>
    </Grid>
  );
};
