interface Props extends React.PropsWithChildren {
  style?: {};
  onClick?: () => void;
}

const Row: React.FC<Props> = props => {
  const { onClick, ...rest } = props;
  return (
    <div
      {...rest}
      onClick={onClick}
      style={{ display: 'flex', flexDirection: 'row', ...props.style }}>
      {props.children}
    </div>
  );
};
export default Row;
