import { PlanCompartments, planStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { PlanApi } from '../../../api/apis/PlanApi';
import { PlanData } from '../../../models/PlanData';
import { DataCompartment, IMutation, MutationContext } from '@aesop-fables/scrinium';
import { OrganizationData } from '../../../models/OrganizationData';
import { OrganizationCompartments, organizationStorageKey } from '../../organization';

export class CreatePlan implements IMutation<PlanData> {
  async execute(context: MutationContext<PlanData>): Promise<void> {
    const { data, storage, container } = context;
    const organizationDataCache =
      storage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const organization = (
      organizationDataCache.findCompartment('organization') as DataCompartment<OrganizationData>
    ).getData() as OrganizationData;
    const planApi = container.get<PlanApi>(ApiKeys.Plan);

    await planApi.createPlan({
      levelOfService: 'Advice_Only',
      planSponsorName: organization.name,
      ...data,
    });

    const dataCache = storage.retrieve<PlanCompartments>(planStorageKey);
    await dataCache.reload('plan');
  }
}

export class EditPlan implements IMutation<PlanData> {
  async execute(context: MutationContext<PlanData>): Promise<void> {
    const { data, container } = context;
    const planApi = container.get<PlanApi>(ApiKeys.Plan);

    await planApi.editPlan(data);

    // make sure to reload on success
  }
}
