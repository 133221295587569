import { Typography } from '@mui/material';
import Row from './Row';
import { CSSProperties } from 'react';

interface HelpTextProps {
  color?: string;
  variant?: any;
  style?: CSSProperties;
  children?: string;
}

export const HelpTitle: React.FC<HelpTextProps> = ({ children, color }) => {
  return (
    <Row>
      <Typography className='title' color={color ?? 'secondary.dark'} variant='p18Bold'>
        {children}
      </Typography>
    </Row>
  );
};

export const HelpBody: React.FC<HelpTextProps> = ({ children, style, variant }) => {
  return (
    <Typography color='secondary.dark' variant={variant ?? 'p16'} style={style}>
      {children}
    </Typography>
  );
};
