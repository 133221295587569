import React, { useEffect, useMemo } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Typography,
  Grid,
  CardContent,
  useTheme,
  Box,
} from '@mui/material';
import { AnyReportType } from '../../../models/ReportData';
import {
  AccountState,
  getIcon,
  getUniqueProductTypes,
  UserDataTableRow,
  transformReportData,
} from '../../../helpers/reportUtils';
import TotalUserAccounts from './TotalUserAccounts';
import EmptyReport from '../EmptyReport';
import { Spacer } from '../../../components';
import { formatNumber } from '../../../helpers/utilityFunctions';

interface UserAccountsTableProps {
  fullReport: AnyReportType;
  individualReport?: AnyReportType;
  handleEmptyState?: (val: boolean) => void;
  handleTableData?: (value: UserDataTableRow[]) => void;
  summedReport: AnyReportType | null;
}

const UserAccountsTable: React.FC<UserAccountsTableProps> = ({
  fullReport,
  individualReport,
  handleEmptyState,
  handleTableData,
  summedReport,
}) => {
  const theme = useTheme();
  const showingBreakdown = typeof individualReport != 'undefined';
  const report = individualReport || fullReport;
  const data: UserDataTableRow[] = useMemo(() => transformReportData(report), [report]);
  const productTypes = useMemo(
    () => getUniqueProductTypes(summedReport ?? report),
    [summedReport, report],
  );
  const isEmpty = data.length === 0;
  const columns = useMemo(
    () => (isEmpty ? [] : ['Total', ...productTypes, 'Unknown']),
    [isEmpty, productTypes],
  );
  const isLastElement = (index: number) => index === data.length - 1;
  const hasTrialState = data.some(item => item.rowKey === 'In trial period');

  useEffect(() => {
    if (handleEmptyState) handleEmptyState(isEmpty);
  }, [isEmpty, handleEmptyState]);

  useEffect(() => {
    if (handleTableData) handleTableData(data);
  }, [data, handleTableData]);

  const formatEmptyValues = (value: any): string | number => {
    return value === null || value === undefined ? '-' : formatNumber(value);
  };

  if (isEmpty) {
    return showingBreakdown ? null : <EmptyReport />;
  }

  return (
    <Grid item container mb={3}>
      <Card variant='ghost' color={showingBreakdown ? 'primary' : 'transparent'}>
        <CardContent>
          <TableContainer>
            <Grid px={showingBreakdown ? 2 : 0}>
              {showingBreakdown && (
                <Grid>
                  {'name' in report && (
                    <Grid>
                      <Typography variant='p24' color='secondary.main'>
                        {report.name}
                      </Typography>
                      <Spacer height='xs' />
                    </Grid>
                  )}

                  <TotalUserAccounts report={report} />
                </Grid>
              )}

              <Table>
                <TableHead>
                  <TableRow className='user-accounts'>
                    <TableCell
                      variant='head'
                      sx={{ borderBottom: 'none', width: 0, paddingTop: 0 }}>
                      &nbsp;
                    </TableCell>
                    <TableCell variant='head' sx={{ borderBottom: 'none', paddingTop: 0 }}>
                      &nbsp;
                    </TableCell>

                    {columns.map(col => (
                      <TableCell
                        key={col}
                        variant='head'
                        sx={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          fontWeight: col === 'Total' ? 'bold' : 'inherit',
                          paddingTop: 0,
                        }}>
                        <Typography variant='p16' color='secondary.dark'>
                          {col}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <Box
                    sx={{
                      borderRadius: showingBreakdown ? '8px' : 0,
                      overflow: showingBreakdown ? 'hidden' : 'visible',
                    }}>
                    {data.map((row, index) => (
                      <Box key={row.rowKey}>
                        <Card
                          color={showingBreakdown ? 'tertiary' : 'primary'}
                          variant='ghost'
                          sx={{
                            mb: showingBreakdown ? 0 : 2,
                            borderRadius: showingBreakdown ? 0 : '8px',
                          }}>
                          <TableRow className='user-accounts'>
                            <TableCell
                              variant='body'
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <Grid display='inherit' alignItems='inherit' pl={6} pr={2}>
                                {getIcon(row.rowKey as AccountState)}
                              </Grid>
                              <Typography variant='p18' color='secondary.dark'>
                                {row.rowKey}
                                {row.rowKey === 'In trial period' ? '*' : ''}
                              </Typography>
                            </TableCell>

                            {columns.map(column => (
                              <TableCell
                                key={column}
                                variant='body'
                                sx={{
                                  textAlign: 'center',
                                  fontWeight: column === 'Total' ? 'bold' : 'inherit',
                                }}>
                                <Typography variant='p20' color='secondary.main'>
                                  {column === 'Total'
                                    ? formatEmptyValues(row.totalCount)
                                    : column === 'Unknown'
                                    ? formatEmptyValues(row.unknownCount)
                                    : formatEmptyValues(row[column])}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        </Card>

                        {showingBreakdown && !isLastElement(index) && (
                          <Box
                            sx={{
                              backgroundColor: '#fff',
                            }}>
                            <Box
                              sx={{
                                width: '96%',
                                margin: '0 auto',
                                borderBottom: `1px solid ${theme.palette.secondary.disabled}`,
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    ))}
                    {hasTrialState && !showingBreakdown && (
                      <Typography variant='p14' color='info.main'>
                        * also included in the active user count
                      </Typography>
                    )}
                  </Box>
                </TableBody>
              </Table>
            </Grid>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserAccountsTable;
