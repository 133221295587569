import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonBase, Card, InputAdornment, Typography } from '@mui/material';
import {
  Column,
  FormInputNumber,
  Row,
  Spacer,
  StepsHeader,
  ToggleInput,
} from '../../../components';
import { Form } from 'react-final-form';
import { Svgs } from '../../../assets/svg';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { PlanWizard, planWizardKey } from '../../../services/plan';
import {
  PlanData,
  EmployerContributionsData,
  ContributionTierData,
} from '../../../models/PlanData';
import { PlanCompartments, planStorageKey } from '../../../data/plan';
import { mutateFieldValue } from '../../../helpers/utilityFunctions';
import { StepsHeaderTypes } from '../../../models/StepsHeaderData';

export interface TierData {
  from?: number;
  to?: number;
  percentContribution?: number;
}

const EmployerContributions = () => {
  const navigate = useNavigate();

  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));
  const planWizard = useService<PlanWizard>(planWizardKey);
  const planWizardState = useObservable(planWizard.state$);
  const [tiers, setTiers] = useState<TierData[]>([{}]);
  const [initialEmployerContributions, setInitialEmployerContributions] = useState<
    EmployerContributionsData | undefined
  >(undefined);
  const [initialProfitSharing, setInitialProfitSharing] = useState<
    EmployerContributionsData | undefined
  >(undefined);

  const [activeStep] = useState(3);
  const [totalSteps] = useState(4);

  useEffect(() => {
    setInitialEmployerContributions(
      planWizardState?.planData?.employerContributions?.find(
        contribution => contribution.category === 1,
      ) ?? plan?.employerContributions?.find(contribution => contribution.category === 1),
    );
  }, [plan?.employerContributions, planWizardState?.planData?.employerContributions]);

  useEffect(() => {
    setInitialProfitSharing(
      planWizardState?.planData?.employerContributions?.find(
        contribution => contribution.category === 3,
      ) ?? plan?.employerContributions?.find(contribution => contribution.category === 3),
    );
  }, [plan?.employerContributions, planWizardState?.planData?.employerContributions]);

  useEffect(() => {
    if (
      initialEmployerContributions?.contributionTiers &&
      initialEmployerContributions?.contributionTiers?.length > 0
    ) {
      setTiers(initialEmployerContributions?.contributionTiers);
    }
  }, [initialEmployerContributions?.contributionTiers]);

  const addTier = () => {
    setTiers(prevState => [...prevState, {}]);
  };

  const removeTier = (index: number) => {
    setTiers(prevState => {
      const t = [...prevState];
      t.splice(index, 1);
      return t;
    });
  };

  const validateTier = (value: number, prevValue: number) => {
    return prevValue !== undefined
      ? value <= prevValue
        ? "Must be greater than the previous tier's maximum percentage"
        : undefined
      : 'Please enter a value for the previous tier';
  };

  const navBack = () => {
    navigate(-1);
  };

  const navToNext = async (values: any) => {
    const employerContributions: EmployerContributionsData[] = [];
    if (values.employerMatch === 'Yes') {
      const contributionTiers: ContributionTierData[] = tiers.map((tier, index) => {
        return {
          from: index === 0 ? 0 : values[`to_${index - 1}`],
          to: values[`to_${index}`],
          percentContribution: values[`matchPerDollarRate_${index}`],
        };
      });
      const employerMatch: EmployerContributionsData = {
        category: 1,
        dimension: 'CP',
        employerContributionDollarMax: values.maximumMatchDollar,
        contributionTiers: contributionTiers,
      };
      employerContributions.push(employerMatch);
    }
    if (values.profitSharing === 'Yes') {
      const profitSharing: EmployerContributionsData = {
        category: 3,
        dimension: 'PS',
        contributionTiers: [
          {
            percentContribution: values.profitSharingContributionRate,
          },
        ],
      };
      employerContributions.push(profitSharing);
    }
    planWizard.setState({
      planData: {
        ...planWizardState?.planData,
        employerContributions: employerContributions,
      },
    });
    navigate('/plan-info/member-contributions');
  };

  return (
    <Column style={{ margin: '0px 100px' }}>
      <Spacer height='xs' />
      <StepsHeader
        activeStep={activeStep}
        totalSteps={totalSteps}
        headerSteps={StepsHeaderTypes.InvestmentLineup}
      />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Step {activeStep}: Employer contributions
      </Typography>
      <Spacer height='xs' />

      <Form onSubmit={navToNext} mutators={{ mutateFieldValue }}>
        {({ handleSubmit, values, form }) => (
          <>
            <Card sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }} variant='ghost'>
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='p18SemiBold'
                  color='secondary.dark'>
                  Employer match
                </Typography>
                <div style={{ flex: '2 0 10px' }} />
              </Row>
              <Spacer height='xxs' />
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='body1'
                  color='info.main'>
                  Does the employer make matching contributions?
                </Typography>
                <Row style={{ flex: '2 0 10px' }}>
                  <ToggleInput
                    buttonStyle={{ width: '200px', height: '45px' }}
                    fieldName='employerMatch'
                    initialValue={initialEmployerContributions ? 'Yes' : 'No'}
                    required={true}
                    items={['Yes', 'No']}
                  />
                </Row>
              </Row>
            </Card>
            {(values as any).employerMatch === 'Yes' && (
              <>
                <Spacer height='xs' />
                {tiers.length > 1 ? (
                  tiers.map((tier, index) => (
                    <>
                      <Card color='primary' variant='ghost'>
                        <Card
                          sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }}
                          color='transparent'
                          variant='ghost'>
                          <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                flex: '2 0 10px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                              variant='p16SemiBold'
                              color='secondary.dark'>
                              {index !== 0 && (
                                <Row style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                                  <ButtonBase
                                    onClick={() => {
                                      for (let i = index; i < tiers.length; i++) {
                                        const to = (values as any)[`to_${i + 1}`] ?? 0;
                                        const matchPerDollarRate =
                                          (values as any)[`matchPerDollarRate_${i + 1}`] ?? 0;
                                        form.mutators.mutateFieldValue(`to_${i}`, to);
                                        form.mutators.mutateFieldValue(
                                          `matchPerDollarRate_${i}`,
                                          matchPerDollarRate,
                                        );
                                      }
                                      removeTier(index);
                                    }}>
                                    <Svgs.IconDelete />
                                  </ButtonBase>
                                </Row>
                              )}
                              Tier {index + 1}: up to
                            </Typography>
                            <Row style={{ flex: '2 0 10px' }}>
                              <FormInputNumber
                                fieldName={`to_${index}`}
                                inputStyle={{ maxWidth: '150px' }}
                                initialValue={tier.to}
                                min={0}
                                max={1000}
                                required
                                validate={value =>
                                  validateTier(
                                    value,
                                    index === 0 ? 0 : (values as any)[`to_${index - 1}`],
                                  )
                                }
                                // Because we need to rerender the form when the previous form changes
                                // in order to update the value used in the validation function,
                                // set key to that value to guarantee a rerender
                                // From final-form docs:
                                // If you need your field to rerender with a new validation function,
                                // you will need to update another prop on the Field, such as key.
                                key={index === 0 ? 0 : (values as any)[`to_${index - 1}`]}
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <Typography variant='p18Light' color='info.main'>
                                      %
                                    </Typography>
                                  </InputAdornment>
                                }
                              />
                            </Row>
                          </Row>
                          <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                flex: '2 0 10px',
                                textAlign: 'right',
                              }}
                              variant='body1'
                              color='info.main'>
                              Match per dollar
                            </Typography>
                            <Row style={{ flex: '2 0 10px' }}>
                              <FormInputNumber
                                fieldName={`matchPerDollarRate_${index}`}
                                inputStyle={{ maxWidth: '150px' }}
                                initialValue={tier.percentContribution}
                                min={0}
                                max={1000}
                                required
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <Typography variant='p18Light' color='info.main'>
                                      %
                                    </Typography>
                                  </InputAdornment>
                                }
                              />
                            </Row>
                          </Row>
                        </Card>
                      </Card>
                      <Spacer height='xs' />
                    </>
                  ))
                ) : (
                  <Card sx={{ width: '60%', flexWrap: 'wrap' }} variant='ghost'>
                    <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                      <Typography
                        sx={{ flex: '2 0 10px', textAlign: 'right' }}
                        variant='body1'
                        color='info.main'>
                        Match per dollar
                      </Typography>
                      <Row style={{ flex: '2 0 10px' }}>
                        <FormInputNumber
                          fieldName='matchPerDollarRate_0'
                          inputStyle={{ maxWidth: '150px' }}
                          initialValue={tiers[0].percentContribution}
                          min={0}
                          max={1000}
                          required
                          endAdornment={
                            <InputAdornment position='end'>
                              <Typography variant='p18Light' color='info.main'>
                                %
                              </Typography>
                            </InputAdornment>
                          }
                        />
                      </Row>
                    </Row>
                    <Spacer height='xs' />
                    <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                      <Typography
                        sx={{ flex: '2 0 10px', textAlign: 'right' }}
                        variant='body1'
                        color='info.main'>
                        Maximum match percent
                      </Typography>
                      <Row style={{ flex: '2 0 10px' }}>
                        <FormInputNumber
                          fieldName='to_0'
                          inputStyle={{ maxWidth: '150px' }}
                          initialValue={tiers[0].to}
                          min={0}
                          max={1000}
                          required
                          endAdornment={
                            <InputAdornment position='end'>
                              <Typography variant='p18Light' color='info.main'>
                                %
                              </Typography>
                            </InputAdornment>
                          }
                        />
                      </Row>
                    </Row>
                    <Spacer height='xs' />
                  </Card>
                )}
                <Card sx={{ width: '60%', flexWrap: 'wrap' }} variant='ghost'>
                  <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                    <Typography
                      sx={{ flex: '2 0 10px', textAlign: 'right' }}
                      variant='body1'
                      color='info.main'>
                      Maximum match dollar amount
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <FormInputNumber
                        fieldName='maximumMatchDollar'
                        inputStyle={{ maxWidth: '150px' }}
                        initialValue={initialEmployerContributions?.employerContributionDollarMax}
                        maxLength={10}
                        startAdornment={
                          <InputAdornment position='start'>
                            <Typography variant='p18Light' color='info.main'>
                              $
                            </Typography>
                          </InputAdornment>
                        }
                      />
                    </Row>
                  </Row>
                  <Spacer height='sm' />
                  <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                    <div style={{ flex: '2 0 10px', textAlign: 'right' }} />
                    <ButtonBase
                      onClick={addTier}
                      sx={{
                        'flex': '2 0 10px',
                        'justifyContent': 'start',
                        '& > *': {
                          color: 'primary.main',
                          fill: theme => theme.palette.primary.main,
                        },
                      }}
                      disableRipple>
                      <Svgs.IconAdd />
                      <Spacer width='xxs' />
                      <Typography variant='p18SemiBold'>Add an employer match tier</Typography>
                    </ButtonBase>
                  </Row>
                </Card>
              </>
            )}
            <Card sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }} variant='ghost'>
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='p18SemiBold'
                  color='secondary.dark'>
                  Profit sharing
                </Typography>
                <div style={{ flex: '2 0 10px' }} />
              </Row>
              <Spacer height='xxs' />
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='body1'
                  color='info.main'>
                  Is profit sharing part of the employer plan?
                </Typography>
                <Row style={{ flex: '2 0 10px' }}>
                  <ToggleInput
                    buttonStyle={{ width: '200px', height: '45px' }}
                    fieldName='profitSharing'
                    initialValue={initialProfitSharing ? 'Yes' : 'No'}
                    required={true}
                    items={['Yes', 'No']}
                  />
                </Row>
              </Row>
              {(values as any).profitSharing === 'Yes' && (
                <>
                  <Spacer height='xs' />
                  <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                    <Typography
                      sx={{ flex: '2 0 10px', textAlign: 'right' }}
                      variant='body1'
                      color='info.main'>
                      Expected contribution
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <FormInputNumber
                        fieldName='profitSharingContributionRate'
                        inputStyle={{ maxWidth: '150px' }}
                        initialValue={
                          initialProfitSharing?.contributionTiers
                            ? initialProfitSharing?.contributionTiers[0].percentContribution
                            : undefined
                        }
                        min={0}
                        max={100}
                        required
                        endAdornment={
                          <InputAdornment position='end'>
                            <Typography variant='p18Light' color='info.main'>
                              %
                            </Typography>
                          </InputAdornment>
                        }
                      />
                    </Row>
                  </Row>
                </>
              )}
            </Card>
            <Spacer height='sm' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
                {activeStep === 1 ? `Cancel` : `Back`}
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Next: Member contributions
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Column>
  );
};

export default EmployerContributions;
