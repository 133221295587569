/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { RequestInterceptor } from '../Interceptor';
import { RequestLogConfig } from 'axios-logger/lib/common/types';
import * as AxiosLogger from 'axios-logger';

// TODO -- Do we need this to be configurable?
const requestLoggerConfig: RequestLogConfig = {
  headers: true,
  data: true,
  url: true,
  method: true,
};

export class RequestLoggingInterceptor implements RequestInterceptor {
  async onFulfilled(config: InternalAxiosRequestConfig): Promise<AxiosRequestConfig> {
    return AxiosLogger.requestLogger(config, requestLoggerConfig);
  }

  onRejected(error: any): any {
    return AxiosLogger.errorLogger(error);
  }
}
