import { ApiServices } from './ApiServices';
import type { ApiSettings } from './ApiSettings';
import type { AxiosInstance } from 'axios';
import { AxiosRetryActivator } from './AxiosRetryActivator';
import { CurlLoggingInterceptor } from './interceptors/CurlLoggingInterceptor';
import { IAxiosFactory, AxiosFactory } from './AxiosFactory';
import { AxiosInstanceSettings } from './AxiosInstanceSettings';
import { IAxiosRetryActivator } from './IAxiosRetryActivator';
import { RequestLoggingInterceptor } from './interceptors/RequestLoggingInterceptor';
import { ResponseLoggingInterceptor } from './interceptors/ResponseLoggingInterceptor';
import type { RequestInterceptor, ResponseInterceptor } from './Interceptor';
import { AuthenticationResponseInterceptor } from './interceptors/AuthenticationResponseInterceptor';
import { IServiceRegistry, Scopes, ServiceCollection } from '@aesop-fables/containr';
import { RequestTimestampInterceptor } from './RequestTimestampInterceptor';
import { ApiKeys } from './apis/ApiKeys';
import axios from 'axios';

export class NulloRequestInterceptor implements RequestInterceptor {}
export class NulloResponseInterceptor implements ResponseInterceptor {}

export class ApiRegistry implements IServiceRegistry {
  configureServices(services: ServiceCollection): void {
    const settings = services.resolve<ApiSettings>(ApiServices.ApiSettings);

    if (settings.enableCurlLogging) {
      services.add(ApiServices.RequestInterceptors, CurlLoggingInterceptor);
    }
    if (settings.enableRequestLogging) {
      services.add(ApiServices.RequestInterceptors, RequestLoggingInterceptor);
    }
    if (!settings.enableCurlLogging && !settings.enableRequestLogging) {
      services.add(ApiServices.RequestInterceptors, NulloRequestInterceptor);
    }

    if (settings.enableResponseLogging) {
      services.add(ApiServices.ResponseInterceptors, ResponseLoggingInterceptor);
    }

    services.add(ApiServices.ResponseInterceptors, AuthenticationResponseInterceptor);

    services.use<IAxiosRetryActivator>(ApiServices.AxiosRetryActivator, AxiosRetryActivator);
    services.use<IAxiosFactory>(ApiServices.AxiosFactory, AxiosFactory);

    services.register<AxiosInstance>(ApiServices.Axios, provider => {
      const factory = provider.get<IAxiosFactory>(ApiServices.AxiosFactory);
      const settings = provider.get<AxiosInstanceSettings>(ApiServices.AxiosSettings);
      if (!settings.baseURL) {
        throw new Error(`AxiosInstanceSettings.baseURL must be set`);
      }

      return factory.create(settings.baseURL);
    });

    services.factory<AxiosInstance>(
      ApiKeys.AuthUserAxios,
      provider => {
        const settings = provider.get<AxiosInstanceSettings>(ApiServices.AxiosSettings);
        if (!settings.baseURL) {
          throw new Error(`AxiosInstanceSettings.baseURL must be set`);
        }
        const instance = axios.create({
          baseURL: settings.baseURL,
          withCredentials: true,
          withXSRFToken: true,
        });
        return instance;
      },
      Scopes.Transient,
    );

    services.arrayAutoResolve(ApiServices.RequestInterceptors, RequestTimestampInterceptor);
  }
}
