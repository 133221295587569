import { combineLatest, map } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import {
  LicenseAgreementAcceptLogApi,
  LicenseAgreementAcceptLogData,
} from '../../api/apis/LicenseAgreementAcceptLogApi';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCache,
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import { LicenseCompartments, licenseStorageKey } from '../license';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { OrganizationData } from '../../models/OrganizationData';

export interface licenseAgreementAcceptLogCompartments {
  licenseAgreementAcceptLog: DataCompartmentOptions<
    Map<number, LicenseAgreementAcceptLogData> | undefined
  >;
}

export const licenseAgreementAcceptLogStorageKey = 'data/licenseAgreementAcceptLog';

export class LicenseAgreementAcceptLogDataCachePolicy extends DataCachePolicy<licenseAgreementAcceptLogCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.LicenseAgreementAcceptLog)
    private readonly licenseAgreementAcceptLogApi: LicenseAgreementAcceptLogApi,
  ) {
    super(licenseAgreementAcceptLogStorageKey);
  }

  buildDataCache(): DataCache<licenseAgreementAcceptLogCompartments> {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const licenseDataCache = this.appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
      licenseDataCache.findCompartment('licenseAgreement').initialized$(),
    ]).pipe(
      map(
        ([isAuthenticated, orgData, licenseAgreementDataInitialized]) =>
          isAuthenticated === true && orgData !== undefined && licenseAgreementDataInitialized,
      ),
    );

    return createObservedDataCache<licenseAgreementAcceptLogCompartments>({
      licenseAgreementAcceptLog: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const licenseAgreements = (
              licenseDataCache.findCompartment('licenseAgreement') as DataCompartment<
                LicenseAgreementData[]
              >
            ).getData() as LicenseAgreementData[];

            const acceptLogs: Map<number, LicenseAgreementAcceptLogData> = new Map<
              number,
              LicenseAgreementAcceptLogData
            >();
            licenseAgreements &&
              licenseAgreements?.forEach(async agreement => {
                try {
                  const { data } = await this.licenseAgreementAcceptLogApi.getByDocId(agreement.id);
                  acceptLogs.set(data.docId, data);
                } catch (err) {
                  return undefined;
                }
              });
            return acceptLogs;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withLicenseAgreementAcceptLogData = createServiceModule(
  licenseAgreementAcceptLogStorageKey,
  services => {
    services.add<IDataCachePolicy>(Policies, LicenseAgreementAcceptLogDataCachePolicy);
  },
);
