import { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { Svgs } from '../assets/svg';
import { Row, Logo, Spacer } from '.';
import { Toast } from '../models/Toast';
import { ToastbarContext } from '../App';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { OrganizationData } from '../models/OrganizationData';
import { API_URL } from '../api/apis';

type UploadTypes = 'logo' | 'tsv' | 'csv' | 'txt';
interface UploadDropZoneProps {
  uploadTypes: UploadTypes[];
  setData: React.Dispatch<React.SetStateAction<any | undefined>>;
}

const fileTypesMap = {
  tsv: { 'file/tsv': ['.tsv'] },
  csv: { 'text/csv': ['.csv'] },
  txt: { 'text/plain': ['.txt'] },
  logo: {
    'image/jpeg': [],
    'image/png': [],
  },
};

const UploadDropZone: React.FC<UploadDropZoneProps> = ({ uploadTypes, setData }) => {
  const theme = useTheme();
  const appStorage = useAppStorage();
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const { id, logoUri } = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  ) ?? { id: undefined, logoUri: undefined };
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const { setToast } = useContext(ToastbarContext);
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: uploadTypes.reduce((acc, type) => ({ ...acc, ...fileTypesMap[type] }), {}),
    maxFiles: 1,
    maxSize: 1000000, // Maximum file size (in bytes) => 1MB
    multiple: false,
  });
  useEffect(() => {
    if (fileRejections.length > 0) {
      setToast(
        new Toast({
          message: `Please ensure your file is less than 1MB and try again`,
          severity: 'error',
          open: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  // initialize logo if one already exists
  useEffect(() => {
    if (logoUri) {
      setUploadedImage(`${API_URL}organization/logo/${id}`);
    }
  }, [id, logoUri, setUploadedImage]);

  useEffect(() => {
    const readFile = (file: File) => {
      if (uploadTypes.includes('logo')) {
        const url = URL.createObjectURL(file);
        setUploadedImage(url);
      }
      setFileName(file.name);
      setData(file);
    };

    if (acceptedFiles.length > 0) {
      readFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const clearUpload = () => {
    if (uploadTypes.includes('logo')) {
      setUploadedImage('');
    }
    setFileName('');
    setData(undefined);
  };

  return (
    <>
      {uploadTypes.includes('logo') && uploadedImage.length > 0 ? (
        <Row style={{ alignItems: 'center' }}>
          <Svgs.IconDelete onClick={clearUpload} style={{ cursor: 'pointer' }} />
          <Spacer width='xxs' />
          <Logo imageUrl={uploadedImage} />
        </Row>
      ) : uploadTypes.some(type => ['tsv', 'txt', 'csv'].includes(type)) && fileName ? (
        <Row style={{ alignItems: 'center' }}>
          <Svgs.IconDelete onClick={clearUpload} style={{ cursor: 'pointer' }} />
          <Spacer width='xxs' />
          <Card variant='innerCard' sx={{ height: '50px' }}>
            <CardContent className='whoPays'>
              <Row style={{ alignItems: 'center' }}>
                <Svgs.IconDocument />
                <Spacer width='xxs' />
                <Typography variant='p20' color='secondary.dark'>
                  {fileName}
                </Typography>
              </Row>
            </CardContent>
          </Card>
        </Row>
      ) : (
        <Card
          {...getRootProps()}
          style={{
            cursor: 'pointer',
            width: '50%',
            height: '150px',
            boxShadow: 'none',
            borderWidth: '1px',
            borderStyle: 'dashed',
            borderColor: theme.palette.info.main,
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#e6e9ea',
          }}>
          <div style={{ textAlign: 'center' }}>
            <input {...getInputProps()} />
            <Typography variant='p18Bold' color='primary' style={{ display: 'inline' }}>
              Choose a file
            </Typography>
            <Typography variant='h2' style={{ display: 'inline' }}>
              &nbsp;or drag it here to upload.
            </Typography>
          </div>
        </Card>
      )}
    </>
  );
};

export default UploadDropZone;
