import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { PricebookBundle } from '../../models/PricebookData';

export class PricebookApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getAvailable(): Promise<AxiosResponse<PricebookBundle[]>> {
    return this.axios.get('organization-pricebook-options/available');
  }

  getSelected(): Promise<AxiosResponse<SelectionData[]>> {
    return this.axios.get('organization-pricebook-selections');
  }

  getSelections(): Promise<AxiosResponse<PricebookBundle[]>> {
    return this.axios.get('organization-pricebook-selections/available');
  }

  selectByPricebookId(id: number): Promise<AxiosResponse<any>> {
    return this.axios.post(`organization-pricebook-selections?pricebookId=${id}`);
  }
}
interface SelectionData {
  id: number;
  organizationId: number;
  pricebookId: number;
}
