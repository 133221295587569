import { Button, ButtonBase, Card, InputAdornment, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
  PlanData,
  EmployerContributionsData,
  ContributionTierData,
} from '../../../models/PlanData';
import { ToastbarContext } from '../../../App';
import { Svgs } from '../../../assets/svg';
import {
  EditableCard,
  FormInputNumber,
  KeyValueLabel,
  RightDrawer,
  Row,
  Spacer,
  ToggleInput,
} from '../../../components';
import { RightDrawerProps } from '../../../components/RightDrawer';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { PlanCompartments, planStorageKey } from '../../../data/plan';
import { EditPlan } from '../../../data/plan/mutations';
import { formatDollars, mutateFieldValue } from '../../../helpers/utilityFunctions';
import { useLoading } from '../../../hooks/useLoading';
import { Toast } from '../../../models/Toast';
import { TierData } from '../add-plan/EmployerContributions';

const EmployerContributionsCard = () => {
  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));

  const [employerContributions, setEmployerContributions] = useState<
    EmployerContributionsData | undefined
  >(undefined);
  const [profitSharing, setProfitSharing] = useState<EmployerContributionsData | undefined>(
    undefined,
  );
  const [tiers, setTiers] = useState<TierData[]>([{}]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setEmployerContributions(
      plan?.employerContributions?.find(contribution => contribution.category === 1),
    );
  }, [plan?.employerContributions]);

  useEffect(() => {
    setProfitSharing(
      plan?.employerContributions?.find(contribution => contribution.category === 3),
    );
  }, [plan?.employerContributions]);

  useEffect(() => {
    if (
      employerContributions?.contributionTiers &&
      employerContributions?.contributionTiers?.length > 0
    ) {
      setTiers(employerContributions?.contributionTiers);
    }
  }, [employerContributions?.contributionTiers]);

  return (
    <EditableCard
      onEditClick={() => setDrawerOpen(drawer => !drawer)}
      editable
      title='Employer contributions'>
      <KeyValueLabel
        keyStyles={{ fontWeight: '600' }}
        keyString='Employer matches?'
        value={employerContributions ? 'Yes' : 'No'}
      />
      {employerContributions && (
        <>
          {tiers.length > 1
            ? tiers
                .filter(tier => Object.keys(tier).length !== 0)
                .map((tier, index) => (
                  <div key={index}>
                    <KeyValueLabel
                      keyString={'Match per dollar (tier ' + (index + 1) + ')'}
                      value={tier.percentContribution + '%'}
                    />
                    <KeyValueLabel
                      keyString={'Maximum match percent (tier ' + (index + 1) + ')'}
                      value={tier.to + '%'}
                    />
                  </div>
                ))
            : tiers.map((tier, index) => (
                <div key={index}>
                  <KeyValueLabel
                    keyString={'Match per dollar'}
                    value={tier.percentContribution + '%'}
                  />
                  <KeyValueLabel keyString={'Maximum match percent'} value={tier.to + '%'} />
                </div>
              ))}
          <KeyValueLabel
            keyString={'Maximum match dollar amount'}
            value={
              employerContributions.employerContributionDollarMax
                ? formatDollars(employerContributions.employerContributionDollarMax, 0)
                : 'None'
            }
          />
          <Spacer height='xxs' />
        </>
      )}
      <KeyValueLabel
        keyStyles={{ fontWeight: '600' }}
        keyString='Profit sharing?'
        value={profitSharing ? 'Yes' : 'No'}
      />
      {profitSharing && profitSharing.contributionTiers && (
        <KeyValueLabel
          keyString='Expected contribution'
          value={profitSharing.contributionTiers[0]?.percentContribution + '%'}
        />
      )}

      <EmployerContributionsCardEdit
        title='Employer contributions'
        open={drawerOpen}
        setOpen={setDrawerOpen}
        tiers={tiers}
        setTiers={setTiers}
        initialEmployerContributions={employerContributions}
        initialProfitSharing={profitSharing}
      />
    </EditableCard>
  );
};

interface EmployerContributionsCardEditProps extends RightDrawerProps {
  tiers: TierData[];
  setTiers: React.Dispatch<React.SetStateAction<TierData[]>>;
  initialEmployerContributions?: EmployerContributionsData;
  initialProfitSharing?: EmployerContributionsData;
}

const EmployerContributionsCardEdit: React.FC<EmployerContributionsCardEditProps> = ({
  title,
  open,
  setOpen,
  tiers,
  setTiers,
  initialEmployerContributions,
  initialProfitSharing,
}) => {
  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));
  const editPlan = useMutation(new EditPlan());

  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (editPlan.error) {
      console.error(editPlan.error);
      setLoading(false);
      setToast(
        new Toast({
          message: 'Could not save plan',
          severity: 'error',
          open: true,
        }),
      );
    } else if (editPlan.status === MutationStatus.Complete) {
      setOpen(false);
      setLoading(false);
      setToast(
        new Toast({ message: 'Employer contributions updated!', severity: 'success', open: true }),
      );
      planDataCache.reload('plan');
    }
  }, [editPlan.error, editPlan.status, planDataCache, setOpen, setLoading, setToast]);

  const addTier = () => {
    setTiers(prevState => [...prevState, {}]);
  };

  const removeTier = (index: number) => {
    setTiers(prevState => {
      const t = [...prevState];
      t.splice(index, 1);
      return t;
    });
  };

  const validateTier = (value: number, prevValue: number) => {
    return prevValue !== undefined
      ? value <= prevValue
        ? "Must be greater than the previous tier's maximum percentage"
        : undefined
      : 'Please enter a value for the previous tier';
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    const employerContributions: EmployerContributionsData[] = [];
    if (values.employerMatch === 'Yes') {
      const contributionTiers: ContributionTierData[] = tiers.map((tier, index) => {
        return {
          from: index === 0 ? 0 : values[`to_${index - 1}`],
          to: values[`to_${index}`],
          percentContribution: values[`matchPerDollarRate_${index}`],
        };
      });
      const employerMatch: EmployerContributionsData = {
        category: 1,
        dimension: 'CP',
        employerContributionDollarMax: values.maximumMatchDollar,
        contributionTiers: contributionTiers,
      };
      employerContributions.push(employerMatch);
    }
    if (values.profitSharing === 'Yes') {
      const profitSharing: EmployerContributionsData = {
        category: 3,
        dimension: 'PS',
        contributionTiers: [
          {
            percentContribution: values.profitSharingContributionRate,
          },
        ],
      };
      employerContributions.push(profitSharing);
    }
    await editPlan.action({
      ...plan,
      employerContributions: employerContributions,
    });
  };

  const onCancel = () => {
    setOpen(false);
    if (
      initialEmployerContributions?.contributionTiers &&
      initialEmployerContributions?.contributionTiers?.length > 0
    ) {
      setTiers(initialEmployerContributions?.contributionTiers);
    }
  };

  return (
    <RightDrawer open={open} setOpen={setOpen} title={title}>
      <Form onSubmit={onSubmit} mutators={{ mutateFieldValue }}>
        {({ handleSubmit, values, form }) => (
          <>
            <Card sx={{ padding: 2, flexWrap: 'wrap' }} variant='ghost'>
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='p18SemiBold'
                  color='secondary.dark'>
                  Employer match
                </Typography>
                <div style={{ flex: '2 0 10px' }} />
              </Row>
              <Spacer height='xxs' />
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='body1'
                  color='info.main'>
                  Does the employer make matching contributions?
                </Typography>
                <Row style={{ flex: '2 0 10px' }}>
                  <ToggleInput
                    inputStyle={{ flexGrow: 1 }}
                    buttonStyle={{ height: '45px' }}
                    fieldName='employerMatch'
                    initialValue={initialEmployerContributions ? 'Yes' : 'No'}
                    required={true}
                    items={['Yes', 'No']}
                  />
                </Row>
              </Row>
            </Card>
            {(values as any).employerMatch === 'Yes' && (
              <>
                <Spacer height='xs' />
                {tiers.length > 1 ? (
                  tiers.map((tier, index) => (
                    <>
                      <Card color='primary' variant='ghost'>
                        <Card
                          sx={{ padding: 2, flexWrap: 'wrap' }}
                          color='transparent'
                          variant='ghost'>
                          <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                flex: '2 0 10px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                              variant='p16SemiBold'
                              color='secondary.dark'>
                              {index !== 0 && (
                                <Row style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                                  <ButtonBase
                                    onClick={() => {
                                      for (let i = index; i < tiers.length; i++) {
                                        const to = (values as any)[`to_${i + 1}`] ?? 0;
                                        const matchPerDollarRate =
                                          (values as any)[`matchPerDollarRate_${i + 1}`] ?? 0;
                                        form.mutators.mutateFieldValue(`to_${i}`, to);
                                        form.mutators.mutateFieldValue(
                                          `matchPerDollarRate_${i}`,
                                          matchPerDollarRate,
                                        );
                                      }
                                      removeTier(index);
                                    }}>
                                    <Svgs.IconDelete />
                                  </ButtonBase>
                                </Row>
                              )}
                              Tier {index + 1}: up to
                            </Typography>
                            <Row style={{ flex: '2 0 10px' }}>
                              <FormInputNumber
                                fieldName={`to_${index}`}
                                inputStyle={{ maxWidth: '150px' }}
                                initialValue={tier.to}
                                min={0}
                                max={1000}
                                required
                                validate={value =>
                                  validateTier(
                                    value,
                                    index === 0 ? 0 : (values as any)[`to_${index - 1}`],
                                  )
                                }
                                // Because we need to rerender the form when the previous form changes
                                // in order to update the value used in the validation function,
                                // set key to that value to guarentee a rerender
                                // From final-form docs:
                                // If you need your field to rerender with a new validation function,
                                // you will need to update another prop on the Field, such as key.
                                key={index === 0 ? 0 : (values as any)[`to_${index - 1}`]}
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <Typography variant='p18Light' color='info.main'>
                                      %
                                    </Typography>
                                  </InputAdornment>
                                }
                              />
                            </Row>
                          </Row>
                          <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                flex: '2 0 10px',
                                textAlign: 'right',
                              }}
                              variant='body1'
                              color='info.main'>
                              Match per dollar
                            </Typography>
                            <Row style={{ flex: '2 0 10px' }}>
                              <FormInputNumber
                                fieldName={`matchPerDollarRate_${index}`}
                                inputStyle={{ maxWidth: '150px' }}
                                initialValue={tier.percentContribution}
                                min={0}
                                max={1000}
                                required
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <Typography variant='p18Light' color='info.main'>
                                      %
                                    </Typography>
                                  </InputAdornment>
                                }
                              />
                            </Row>
                          </Row>
                        </Card>
                      </Card>
                      <Spacer height='xs' />
                    </>
                  ))
                ) : (
                  <Card sx={{ flexWrap: 'wrap' }} variant='ghost'>
                    <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                      <Typography
                        sx={{ flex: '2 0 10px', textAlign: 'right' }}
                        variant='body1'
                        color='info.main'>
                        Match per dollar
                      </Typography>
                      <Row style={{ flex: '2 0 10px' }}>
                        <FormInputNumber
                          fieldName='matchPerDollarRate_0'
                          inputStyle={{ maxWidth: '150px' }}
                          initialValue={tiers[0].percentContribution}
                          min={0}
                          max={1000}
                          required
                          endAdornment={
                            <InputAdornment position='end'>
                              <Typography variant='p18Light' color='info.main'>
                                %
                              </Typography>
                            </InputAdornment>
                          }
                        />
                      </Row>
                    </Row>
                    <Spacer height='xs' />
                    <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                      <Typography
                        sx={{ flex: '2 0 10px', textAlign: 'right' }}
                        variant='body1'
                        color='info.main'>
                        Maximum match percent
                      </Typography>
                      <Row style={{ flex: '2 0 10px' }}>
                        <FormInputNumber
                          fieldName='to_0'
                          inputStyle={{ maxWidth: '150px' }}
                          initialValue={tiers[0].to}
                          min={0}
                          max={1000}
                          required
                          endAdornment={
                            <InputAdornment position='end'>
                              <Typography variant='p18Light' color='info.main'>
                                %
                              </Typography>
                            </InputAdornment>
                          }
                        />
                      </Row>
                    </Row>
                    <Spacer height='xs' />
                  </Card>
                )}
                <Card sx={{ flexWrap: 'wrap' }} variant='ghost'>
                  <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                    <Typography
                      sx={{ flex: '2 0 10px', textAlign: 'right' }}
                      variant='body1'
                      color='info.main'>
                      Maximum match dollar amount
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <FormInputNumber
                        fieldName='maximumMatchDollar'
                        inputStyle={{ maxWidth: '150px' }}
                        initialValue={initialEmployerContributions?.employerContributionDollarMax}
                        maxLength={10}
                        startAdornment={
                          <InputAdornment position='start'>
                            <Typography variant='p18Light' color='info.main'>
                              $
                            </Typography>
                          </InputAdornment>
                        }
                      />
                    </Row>
                  </Row>
                  <Spacer height='sm' />
                  <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                    <div style={{ flex: '2 0 10px', textAlign: 'right' }} />
                    <ButtonBase
                      onClick={addTier}
                      sx={{
                        'flex': '2 0 10px',
                        'justifyContent': 'start',
                        '& > *': {
                          color: 'primary.main',
                          fill: theme => theme.palette.primary.main,
                        },
                      }}
                      disableRipple>
                      <Svgs.IconAdd />
                      <Spacer width='xxs' />
                      <Typography variant='p18SemiBold'>Add an employer match tier</Typography>
                    </ButtonBase>
                  </Row>
                </Card>
              </>
            )}
            <Card sx={{ padding: 2, flexWrap: 'wrap' }} variant='ghost'>
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='p18SemiBold'
                  color='secondary.dark'>
                  Profit sharing
                </Typography>
                <div style={{ flex: '2 0 10px' }} />
              </Row>
              <Spacer height='xxs' />
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='body1'
                  color='info.main'>
                  Is profit sharing part of the employer plan?
                </Typography>
                <Row style={{ flex: '2 0 10px' }}>
                  <ToggleInput
                    inputStyle={{ flexGrow: 1 }}
                    buttonStyle={{ height: '45px' }}
                    fieldName='profitSharing'
                    initialValue={initialProfitSharing ? 'Yes' : 'No'}
                    required={true}
                    items={['Yes', 'No']}
                  />
                </Row>
              </Row>
              {(values as any).profitSharing === 'Yes' && (
                <>
                  <Spacer height='xs' />
                  <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                    <Typography
                      sx={{ flex: '2 0 10px', textAlign: 'right' }}
                      variant='body1'
                      color='info.main'>
                      Expected contribution
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <FormInputNumber
                        fieldName='profitSharingContributionRate'
                        inputStyle={{ maxWidth: '150px' }}
                        initialValue={
                          initialProfitSharing?.contributionTiers
                            ? initialProfitSharing?.contributionTiers[0].percentContribution
                            : undefined
                        }
                        min={0}
                        max={100}
                        required
                        endAdornment={
                          <InputAdornment position='end'>
                            <Typography variant='p18Light' color='info.main'>
                              %
                            </Typography>
                          </InputAdornment>
                        }
                      />
                    </Row>
                  </Row>
                </>
              )}
            </Card>
            <Spacer height='sm' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={onCancel}>
                Cancel
              </Button>
              <Button style={{ width: 127 }} color='primary' onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default EmployerContributionsCard;
