import React from 'react';
import { AnyReportType } from '../../../models/ReportData';
import ReportProgressTracker from './ReportProgressTracker';
import EmptyReport from '../EmptyReport';
import { getPricebookReportsByType } from '../../../helpers/reportUtils';
import { ProductTypeEnum } from '../../../models/PricebookData';
import { Box } from '@mui/material';

interface ReportUserProgressProps {
  report: AnyReportType | null;
  summedReport: AnyReportType | null;
}
const ReportUserProgress: React.FC<ReportUserProgressProps> = ({ report, summedReport }) => {
  if (!report) return null;

  const pricebookReports = getPricebookReportsByType(report);
  const uniqueProductTypes = [
    ...new Set(pricebookReports?.map(pricebook => pricebook?.productType)),
  ];
  const guidedChoiceOnly = uniqueProductTypes.every(type => type === ProductTypeEnum.GuidedChoice);

  if (uniqueProductTypes.length <= 0) {
    return <EmptyReport />;
  }

  return (
    <Box display={guidedChoiceOnly ? 'flex' : 'inline'}>
      {uniqueProductTypes.map(productType => (
        <ReportProgressTracker
          key={productType}
          report={summedReport ?? report}
          productType={productType}
          guidedChoiceOnly={guidedChoiceOnly}
        />
      ))}
    </Box>
  );
};

export default ReportUserProgress;
