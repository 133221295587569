import { Typography, Button } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../../components';
import { logo3NSmall } from '../../assets/png';
import { Svgs } from '../../assets/svg';
import { LearnMoreDrawerProps, PackageCard } from './LearnMoreAdviceAndThreeNickelsUDrawer';

export const LearnMoreThreeNickelsFreeDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
}) => {
  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: '90%' }}>
          <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            {pricebookBundle.pricebook.paidBy === 'USER' ? (
              <Svgs.IconMemberPaysLarge />
            ) : (
              <Svgs.IconOrgPaysLarge />
            )}
            <Spacer width='xxs' />
            <Typography color='secondary' variant='p12SemiBold'>
              {pricebookBundle.pricebook.paidBy === 'USER' ? 'Member pays' : 'Organization pays'}
            </Typography>
          </Row>

          <Spacer height='xxs' />
          <Typography color='secondary' variant='p30Light'>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        <img src={logo3NSmall} alt='logo' style={{ height: '40px' }} />
      </Row>

      <PackageCard pricebookBundle={pricebookBundle} />
      <Spacer height='xs' />

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Column style={{ width: '80%' }}>
          <Typography color='secondary' variant='p18Bold'>
            Description
          </Typography>
          <Spacer height='xxs' />
          <Typography color='info.main' variant='body1'>
            Get answers to your basic financial questions at no cost to you. Work through different
            aspects of your finances, one at a time.
          </Typography>
        </Column>

        <Svgs.ArtPlant style={{ width: '100px', height: 'auto' }} />
      </Row>
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        At a glance...
      </Typography>
      <Column style={{ padding: '0px 10px' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletBook />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Accessible education</strong> at an introductory level.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletCalculator />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Calculators and checklists</strong> to help you answer one-off questions.
          </Typography>
        </Row>
      </Column>

      <Spacer height='sm' />
      <Row style={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => setDrawerOpen(false)}
          className='sm'
          variant='outlined'
          color='secondary'>
          Close
        </Button>
      </Row>
      <Spacer height='md' />
    </RightDrawer>
  );
};
