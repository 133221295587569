import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, map } from 'rxjs';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { InvoiceApi, InvoiceData } from '../../api/apis/InvoiceApi';
import { UserCompartments, userStorageKey } from '../user';

export interface InvoiceCompartments {
  invoices: DataCompartmentOptions<InvoiceData[] | undefined>;
}

export const invoiceStorageKey = 'data/invoices';

export class InvoiceDataCachePolicy extends DataCachePolicy<InvoiceCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.Invoice)
    private readonly invoiceApi: InvoiceApi,
  ) {
    super(invoiceStorageKey);
  }

  buildDataCache() {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.findCompartment('user').initialized$(),
      organizationDataCache.findCompartment('organization').initialized$(),
    ]).pipe(
      map(
        ([isAuthenticated, userInitialized, organizationInitialized]) =>
          isAuthenticated === true && userInitialized && organizationInitialized,
      ),
    );
    return createObservedDataCache<InvoiceCompartments>({
      invoices: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.invoiceApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withInvoiceData = createServiceModule(invoiceStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, InvoiceDataCachePolicy);
});
