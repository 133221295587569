/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosResponse } from 'axios';
import { ResponseInterceptor } from '../Interceptor';
import { ResponseLogConfig } from 'axios-logger/lib/common/types';
import * as AxiosLogger from 'axios-logger';

// TODO -- Do we need this to be configurable?
const responseLoggerConfig: ResponseLogConfig = {
  headers: false,
  data: false,
  status: true,
  statusText: true,
};

export class ResponseLoggingInterceptor implements ResponseInterceptor {
  async onFulfilled(config: AxiosResponse): Promise<AxiosResponse> {
    return AxiosLogger.responseLogger(config, responseLoggerConfig);
  }

  onRejected(error: any): any {
    return AxiosLogger.errorLogger(error);
  }
}
