import { DataCache } from '@aesop-fables/scrinium';

export const Policies = '@3nickels/data-modules/DataCachePolicies';
export const Repositories = '@3nickels/data-modules/Repositories';

export interface IDataCachePolicy<Compartments = any> {
  registerAs(): string;
  buildDataCache(): DataCache<Compartments>;
}

export abstract class DataCachePolicy<Compartments = any> {
  constructor(private readonly key: string) {}

  registerAs(): string {
    if (!this.key) {
      throw new Error(
        'DataCachePolicy key is undefined. Did you forget to call `super` in the constructor of your policy?',
      );
    }

    return this.key;
  }

  abstract buildDataCache(): DataCache<Compartments>;
}
