import React from 'react';
import { Spacer } from '../../components';
import MemberSignUpUrl from '../dash/MemberSignUpUrl';

const CustomUrlCard: React.FC<React.PropsWithChildren> = () => {
  return (
    <>
      <Spacer height='xxs' />
      <MemberSignUpUrl />
    </>
  );
};

export default CustomUrlCard;
