import CardDisplay from './CardDisplay';
import { Grid, Typography } from '@mui/material';
import { Svgs } from '../assets/svg';
import Spacer from './Spacer';
import React from 'react';

interface RefreshButtonProps {
  onRefresh: () => void;
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ onRefresh }) => {
  return (
    <CardDisplay onClick={onRefresh}>
      <Grid display='flex' flexDirection='row' alignItems='center' px={2}>
        <Svgs.ActionSmallRefreshDefault style={{ width: 30, height: 30, marginLeft: '-5px' }} />
        <Spacer width='xxs' />
        <Typography variant='p16' color='secondary.dark'>
          Refresh
        </Typography>
      </Grid>
    </CardDisplay>
  );
};

export default RefreshButton;
