import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class GooglePlacesApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<string>> {
    return this.axios.get('google-places-key');
  }
}
