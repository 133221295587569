import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { TemplateData } from '../../models/SignUpData';

export class OnboardingTemplateApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<TemplateData>> {
    return this.axios.get('onboarding-template');
  }
}
