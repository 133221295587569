import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { Button, Link, Typography } from '@mui/material';
import { InvalidateLicenseAgreement } from '../data/license/mutations';
import { useContext, useEffect, useState } from 'react';
import { LicenseAgreementData } from '../api/apis/LicenseAgreementApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { LicenseCompartments, licenseStorageKey } from '../data/license';
import { PricebookBundle } from '../models/PricebookData';
import { PricebookCompartments, pricebookStorageKey } from '../data/pricebook';
import { useLoading } from '../hooks/useLoading';
import { Toast } from '../models/Toast';
import { ToastbarContext } from '../App';
import Modal from './Modal';
import Spacer from './Spacer';
import Column from './Column';
import { UpdateSignUpState } from '../data/signUp/mutations';
import { OrgSignUpStateData } from '../models/SignUpData';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';

interface CancelContractProps {
  componentType?: 'link' | 'button';
  textStyle?: React.CSSProperties;
}

const CancelContract: React.FC<CancelContractProps> = ({ componentType, textStyle }) => {
  const appStorage = useAppStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);

  // eslint-disable-next-line
  const pricebookOptions =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions')) ?? [];
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const pendingAgreement =
    licenseAgreements &&
    licenseAgreements?.find(
      licenseAgreement => licenseAgreement.docStatus && !licenseAgreement.hasAccepted,
    );
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );

  const [open, setOpen] = useState<boolean>(false);
  const [doc, setDoc] = useState<LicenseAgreementData>();
  const updateSignUpState = useMutation(new UpdateSignUpState());
  const invalidateAgreement = useMutation(new InvalidateLicenseAgreement());
  const resumeAddPackage = location?.state?.redirectReason === 'abandonedAddPackage';
  const pricebook: PricebookBundle =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state.pricebook);
  const selectedPricebook = pricebookOptions?.find(
    selectedPricebook => selectedPricebook.pricebook.id === pricebook?.pricebook?.id,
  );

  useEffect(() => {
    const doc = pendingAgreement
      ? pendingAgreement
      : licenseAgreements &&
        licenseAgreements?.find(
          license => license.docStatus && license.pricebookId === selectedPricebook?.pricebook.id,
        );
    if (doc) {
      setDoc(doc);
    }
  }, [licenseAgreements, pendingAgreement, resumeAddPackage, selectedPricebook?.pricebook.id]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (doc) {
        await invalidateAgreement.action(doc);
        setToast(
          new Toast({
            message: 'Contract canceled!',
            severity: 'success',
            open: true,
            autoHideDuration: 2000,
          }),
        );
      }
      await updateSignUpState.action({
        state: {
          ...orgSignUpState?.state,
          pricebook: JSON.stringify(undefined),
          licenseData: JSON.stringify(undefined),
          product: JSON.stringify(undefined),
        },
      });
      if (orgSignUpState?.onboardingComplete) {
        navigate('/addPackage');
      } else {
        await updateSignUpState.action({
          lastCompletedStep: 'inviteOtherAdmins',
        });
        navigate('/onboarding/chooseProduct', { replace: true });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {componentType === 'button' ? (
        <Button
          className='xs'
          onClick={() => setOpen(true)}
          variant='outlined'
          color='secondary'
          style={{ width: 'auto' }}>
          Cancel contract
        </Button>
      ) : (
        <Link onClick={() => setOpen(true)} variant='p16' color='error.active' style={textStyle}>
          Cancel contract
        </Link>
      )}
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          handleSave={onSubmit}
          primaryButtonText='Yes, cancel'
          secondaryButtonText='No, keep'>
          <Column style={{ padding: '0px 0px 20px 15px', marginTop: '-20px' }}>
            <Typography variant='p32Light' color='secondary'>
              Cancel contract?
            </Typography>
            <Spacer height='xs' />
            <Typography>
              Are you sure you want to cancel the current contract and abandon this process?
            </Typography>
            <Spacer height='xs' />
            <Typography>This action cannot be undone.</Typography>
          </Column>
        </Modal>
      )}
    </>
  );
};

export default CancelContract;

export const cancelContractToast = (setToast: React.Dispatch<React.SetStateAction<Toast>>) => {
  const errorDark = '#ce0b24';

  setToast(
    new Toast({
      message: `To make any changes, you need to first cancel your current contract.`,
      actionElement: (
        <CancelContract
          textStyle={{ fontSize: 14, color: errorDark, textDecorationColor: errorDark }}
        />
      ),
      severity: 'error',
      open: true,
      autoHideDuration: 5000,
    }),
  );
};
