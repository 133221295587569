import { TextField } from '@mui/material';
import { useRef } from 'react';
import { useField } from 'react-final-form';

export interface FormInputProps<T> extends React.PropsWithChildren {
  inputStyle?: {};
  fieldName: keyof T;
  placeholder?: string;
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  validate?: (x: string) => undefined | string;
  parse?: (value: string, name: string) => string;
  type?: string;
  select?: boolean;
  formattedValue?: string;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  inputRef?: React.RefObject<any>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onInput?: React.FormEventHandler<HTMLDivElement>;
}

const FormInput = <T extends unknown>(props: FormInputProps<T>) => {
  const {
    inputStyle,
    fieldName,
    required,
    validate,
    parse,
    placeholder,
    initialValue,
    disabled,
    readonly,
    maxLength,
    type,
    select,
    formattedValue,
    startAdornment,
    endAdornment,
    children,
    onChange,
  } = props;

  const backupInputRef = useRef<typeof TextField>(null);
  const inputRef = props.inputRef ? props.inputRef : backupInputRef;

  const { input, meta } = useField<string>(fieldName.toString(), {
    initialValue,
    parse,
    validate: (value: string) =>
      !required || value ? (validate ? validate(value) : undefined) : 'Required',
    subscription: { value: true, touched: true, error: true },
  });

  return (
    <TextField
      onInput={x => input.onChange(x)}
      inputRef={inputRef}
      inputProps={{ maxLength }}
      InputProps={{ startAdornment, endAdornment }}
      id='standard-basic'
      variant='standard'
      style={inputStyle}
      type={type}
      select={select}
      placeholder={placeholder}
      value={
        formattedValue !== undefined
          ? !input.value
            ? ''
            : formattedValue
          : !input.value
          ? ''
          : input.value.toString()
      }
      onChange={v => {
        input.onChange(v);
        onChange && onChange(v);
      }}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched ? meta.error : undefined}
      disabled={disabled || readonly}>
      {children}
    </TextField>
  );
};

export default FormInput;
