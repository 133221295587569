import { Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import {
  Column,
  DropdownMenu,
  HorizontalFormInput,
  Row,
  Spacer,
  ToggleInput,
} from '../../components';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Svgs } from '../../assets/svg';
import { FormSpy, useForm, useFormState } from 'react-final-form';
import {
  ContributionTypesEnum,
  DeductionTypesEnum,
  PayrollDeductionData,
  deductionTypesList,
} from '../../api/apis/CompanyPayrollDeductionApi';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { PayrollDeductionCompartments, payrollDeductionStorageKey } from '../../data/deductions';
import { of } from 'rxjs';
import { Loading, useLoading } from '../../hooks/useLoading';

export interface DeductionInfoProps {
  inEditDrawer?: boolean;
  onSaveDeduction?: (index: number, values: any) => void;
}

const DeductionInfo: React.FC<DeductionInfoProps> = ({ inEditDrawer, onSaveDeduction }) => {
  const appStorage = useAppStorage();
  const theme = useTheme();
  const { loading } = useLoading();
  const form = useForm();
  const formState = useFormState();
  const { isMobile, isTablet } = useDeviceType();
  const newDeduction: PayrollDeductionData = {
    deductionName: '',
    deductionType: '',
    catchup: false,
    id: {
      organizationId: -1,
      companyCode: '',
      deductionCode: '',
    },
  };
  const deductionDataCache = appStorage.retrieve<PayrollDeductionCompartments>(
    payrollDeductionStorageKey,
  );
  const deductionData = useObservable(
    deductionDataCache?.observe$<PayrollDeductionData[]>('deductions') ?? of(undefined),
  );
  const [deductionsList, setDeductionsList] = useState<PayrollDeductionData[]>(
    deductionData ?? [newDeduction],
  );

  const onAddDeduction = () => {
    setDeductionsList(prevDeductions => [...prevDeductions, newDeduction]);
  };

  const onDeleteDeduction = (deductionToDelete: any) => {
    const index = deductionsList.indexOf(deductionToDelete);

    for (let i = index; i < deductionsList.length; i++) {
      const companyCode = formState.values[`companyCode-${i + 1}`];
      const companyName = formState.values[`companyName-${i + 1}`];
      const deductionName = formState.values[`deductionName-${i + 1}`];
      const deductionCode = formState.values[`deductionCode-${i + 1}`];
      const deductionType = formState.values[`deductionType-${i + 1}`];
      const contributionType = formState.values[`contributionType-${i + 1}`];

      form.mutators.mutateFieldValue(`companyCode-${i}`, companyCode);
      form.mutators.mutateFieldValue(`companyName-${i}`, companyName);
      form.mutators.mutateFieldValue(`deductionName-${i}`, deductionName);
      form.mutators.mutateFieldValue(`deductionCode-${i}`, deductionCode);
      form.mutators.mutateFieldValue(`deductionType-${i}`, deductionType);
      form.mutators.mutateFieldValue(`contributionType-${i}`, contributionType);
    }
    const listCopy = [...deductionsList];
    listCopy.splice(index, 1);

    setDeductionsList(listCopy);
  };

  useEffect(() => {
    if (inEditDrawer && deductionData) {
      setDeductionsList(deductionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductionData, inEditDrawer]);

  return (
    <FormSpy>
      {() => {
        return (
          <Column
            style={{
              margin: isMobile || isTablet ? '0 5%' : inEditDrawer ? '0 10px' : '0 10%',
            }}>
            {loading ? (
              <Loading />
            ) : (
              deductionsList?.map((deduction, index) => (
                <>
                  <DeductionCard
                    key={index}
                    index={index}
                    deduction={deduction}
                    inEditDrawer={inEditDrawer}
                    isExisting={deduction.id.organizationId !== -1}
                    onSave={onSaveDeduction}
                    deletableRow={index > 0}
                    onDelete={onDeleteDeduction}
                  />
                  <Spacer height='xs' />
                </>
              ))
            )}
            {
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  fullWidth
                  startIcon={<Svgs.IconAdd style={{ fill: theme.palette.primary.main }} />}
                  variant='ghost'
                  style={{
                    color: theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    ...theme.typography.p18SemiBold,
                  }}
                  onClick={onAddDeduction}>
                  Add a deduction
                </Button>
              </Row>
            }
          </Column>
        );
      }}
    </FormSpy>
  );
};

export interface DeductionCardProps {
  index: number;
  deduction: PayrollDeductionData;
  inEditDrawer?: boolean;
  isExisting?: boolean;
  onSave?: (index: number, values: any) => void;
  deletableRow?: boolean;
  onDelete: (deduction: any) => void;
}

export const DeductionCard: React.FC<DeductionCardProps> = ({
  index,
  deduction,
  inEditDrawer,
  isExisting,
  onSave,
  deletableRow,
  onDelete,
}) => {
  const [selectedDeductionType, setDeductionType] = useState<string>(
    DeductionTypesEnum[deduction.id.gcCategory ?? 0] ?? '',
  );

  useEffect(() => {
    if (onSave) onSave(index, isExisting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExisting]);

  return (
    <FormSpy>
      {({ form, values }) => {
        if (!isExisting && values[`deductionType-${index}`] !== selectedDeductionType) {
          setDeductionType(values[`deductionType-${index}`]);
        }

        return (
          <Column>
            <Card variant='ghost' color='primary'>
              <CardContent
                sx={{
                  display: 'flex',
                  marginTop: '-5px',
                }}>
                <Column
                  style={{
                    flex: 1,
                    alignItems: 'flex-end',
                  }}>
                  {deletableRow && (
                    <IconButton
                      style={{
                        position: 'absolute',
                        alignSelf: 'flex-start',
                        marginLeft: -10,
                        marginTop: -5,
                      }}
                      color='primary'
                      onClick={() => onDelete(deduction)}>
                      <Svgs.IconDelete />
                    </IconButton>
                  )}
                  <Row
                    style={{
                      gap: '20px',
                      width: inEditDrawer ? '80%' : '62%',
                      alignSelf: 'flex-start',
                    }}>
                    <Typography
                      sx={{
                        flex: '2 0 0px',
                        textAlign: 'right',
                        marginTop: '10px',
                      }}
                      variant='body1'
                      color='info.main'>
                      Company name
                    </Typography>
                    <Row style={{ flex: '2 0 0px' }}>
                      <HorizontalFormInput
                        fieldName={`companyName-${index}`}
                        initialValue={deduction.companyName}
                        rightAlignedKey={true}
                        maxLength={128}
                      />
                    </Row>
                  </Row>
                  <Row
                    style={{
                      gap: '20px',
                      width: inEditDrawer ? '80%' : '62%',
                      alignSelf: 'flex-start',
                    }}>
                    <Typography
                      sx={{
                        flex: '2 0 10px',
                        textAlign: 'right',
                        marginTop: '10px',
                      }}
                      variant='body1'
                      color='info.main'>
                      Company code
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <HorizontalFormInput
                        fieldName={`companyCode-${index}`}
                        initialValue={deduction.id.companyCode}
                        inputStyle={{
                          marginTop: inEditDrawer ? '10px' : '',
                        }}
                        rightAlignedKey={true}
                        required
                        maxLength={128}
                        readonly={isExisting}
                      />
                    </Row>
                  </Row>
                  <Row
                    style={{
                      gap: '20px',
                      width: inEditDrawer ? '80%' : '62%',
                      alignSelf: 'flex-start',
                    }}>
                    <Typography
                      sx={{
                        flex: '2 0 10px',
                        textAlign: 'right',
                        marginTop: '10px',
                      }}
                      variant='body1'
                      color='info.main'>
                      Deduction name
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <HorizontalFormInput
                        fieldName={`deductionName-${index}`}
                        initialValue={deduction.deductionName}
                        rightAlignedKey={true}
                        required
                        maxLength={128}
                      />
                    </Row>
                  </Row>
                  <Row
                    style={{
                      gap: '20px',
                      width: inEditDrawer ? '80%' : '62%',
                      alignSelf: 'flex-start',
                    }}>
                    <Typography
                      sx={{
                        flex: '2 0 10px',
                        textAlign: 'right',
                        marginTop: '10px',
                      }}
                      variant='body1'
                      color='info.main'>
                      Deduction code
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <HorizontalFormInput
                        fieldName={`deductionCode-${index}`}
                        inputStyle={{
                          marginTop: inEditDrawer ? '10px' : '',
                        }}
                        initialValue={deduction.id.deductionCode}
                        rightAlignedKey={true}
                        required
                        maxLength={50}
                        readonly={isExisting}
                      />
                    </Row>
                  </Row>
                  <Spacer width='md' />
                  <Row
                    style={{
                      gap: '20px',
                      width: inEditDrawer ? '80%' : '62%',
                      alignSelf: 'flex-start',
                    }}>
                    <Typography
                      sx={{
                        flex: '2 0 10px',
                        textAlign: 'right',
                        marginTop: '10px',
                      }}
                      variant='body1'
                      color='info.main'>
                      Deduction type
                    </Typography>
                    <Column
                      style={{
                        flex: '2 0 10px',
                        overflow: 'hidden',
                      }}>
                      {isExisting ? (
                        <HorizontalFormInput
                          fieldName={`deductionType-${index}`}
                          inputStyle={{
                            marginTop: inEditDrawer ? '10px' : '',
                          }}
                          initialValue={`${DeductionTypesEnum[deduction.id.gcCategory!]} ${
                            deduction.catchup ? 'catch-up' : ''
                          }`}
                          rightAlignedKey={true}
                          required
                          maxLength={50}
                          readonly={isExisting}
                        />
                      ) : (
                        <DropdownMenu
                          inputStyles={{
                            minWidth: '197px',
                            marginTop: inEditDrawer ? '5px' : '',
                          }}
                          callback={val =>
                            form.mutators.mutateFieldValue(`deductionType-${index}`, val)
                          }
                          selected={selectedDeductionType ?? ''}
                          options={deductionTypesList}
                        />
                      )}
                    </Column>
                  </Row>
                  {!isExisting && <Spacer height='s15' />}
                  <Row
                    style={{
                      gap: '20px',
                      width: inEditDrawer ? '98%' : '62%',
                      alignSelf: 'flex-start',
                      textAlign: 'right',
                    }}>
                    <Typography
                      sx={{
                        flex: '2 0 10px',
                        textAlign: 'right',
                        marginTop: '10px',
                      }}
                      variant='body1'
                      color='info.main'>
                      Contribution type
                    </Typography>
                    <Row
                      style={{
                        flex: inEditDrawer ? '3 0 10px' : '2 0 10px',
                        justifyContent: 'flex-start',
                        minWidth: '10px',
                      }}>
                      {isExisting ? (
                        <HorizontalFormInput
                          fieldName={`contributionType-${index}`}
                          inputStyle={{
                            marginTop: inEditDrawer ? '10px' : '',
                          }}
                          initialValue={
                            ContributionTypesEnum[
                              deduction.deductionType as keyof typeof ContributionTypesEnum
                            ]
                          }
                          rightAlignedKey={true}
                          required
                          maxLength={50}
                          readonly={isExisting}
                        />
                      ) : (
                        <ToggleInput
                          initialValue={
                            ContributionTypesEnum[
                              deduction.deductionType as keyof typeof ContributionTypesEnum
                            ]
                          }
                          buttonStyle={{ height: '45px', width: '150px' }}
                          fieldName={`contributionType-${index}`}
                          required
                          items={[ContributionTypesEnum.P, ContributionTypesEnum.D]}
                        />
                      )}
                    </Row>
                  </Row>
                </Column>
              </CardContent>
            </Card>
          </Column>
        );
      }}
    </FormSpy>
  );
};

export default DeductionInfo;
