import { Card, CardContent, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ToastbarContext } from '../../App';
import {
  AddressSearchbox,
  Column,
  DropdownMenu,
  EditDrawer,
  FormInput,
  HorizontalFormInput,
  KeyValueLabel,
  Row,
  Spacer,
} from '../../components';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { UpdateOrganizationData } from '../../data/organization/mutations';
import { formatPhoneNumber, trimPhoneNumber } from '../../helpers/utilityFunctions';
import { AddressInfo, OrganizationData } from '../../models/OrganizationData';
import { Toast } from '../../models/Toast';
import { SignUpWizard, signUpWizardKey } from '../../services/signUp';
import { OrganizationCard } from './OrganizationInfo';
import { Loading } from '../../routes/AuthenticatedRoutes';
import { statesList } from '../../helpers/constants';

const AboutYourOrganizationCard: React.FC = () => {
  const appStorage = useAppStorage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const orgData = useObservable(organizationDataCache.observe$<OrganizationData>('organization'));

  if (!orgData) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant='subtitle1' color='secondary.main'>
        About your organization
      </Typography>
      <Spacer height='xs' />
      <OrganizationCard setDrawerOpen={setDrawerOpen} editable={true}>
        {orgData?.name && <KeyValueLabel keyString='Name' value={orgData.name} />}
        {orgData?.phoneNumber && (
          <KeyValueLabel
            keyString='Phone number'
            value={
              <Row style={{ gap: '20px' }}>
                {formatPhoneNumber(orgData.phoneNumber ?? '')}
                {orgData?.phoneExtension && (
                  <KeyValueLabel
                    containerStyles={{ flexWrap: 'nowrap' }}
                    keyString='Ext.'
                    value={orgData.phoneExtension}
                  />
                )}
              </Row>
            }
          />
        )}
        {orgData?.streetAddress && (
          <KeyValueLabel
            keyString='Address'
            value={
              <>
                {orgData.streetAddress}
                <br />
                {orgData?.city}, {orgData?.state} {orgData?.zip}
              </>
            }
          />
        )}
        <AboutYourOrganizationCardEdit
          title='About your organization'
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          orgData={orgData}
        />
      </OrganizationCard>
    </>
  );
};

interface AboutYourOrgEditProps {
  orgData: OrganizationData;
  title: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AboutYourOrganizationCardEdit: React.FC<AboutYourOrgEditProps> = ({
  orgData,
  title,
  drawerOpen,
  setDrawerOpen,
}) => {
  const updateOrganizationData = useMutation(new UpdateOrganizationData());
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const googlePlacesKey = useObservable(signUpWizard.googlePlacesKey$);
  const { setToast } = useContext(ToastbarContext);
  const [fullAddress, setFullAddress] = useState<AddressInfo | undefined>({
    streetAddress: orgData.streetAddress,
    city: orgData.city,
    state: orgData.state,
    zip: orgData.zip,
  });
  const [streetAddress, setStreetAddress] = useState<string | undefined>(orgData.streetAddress);
  const [formattedZip, setFormattedZip] = useState<string>();
  const [selectedState, setState] = useState<string>(orgData.state ?? 'Choose a state');
  const [formattedPhone, setFormattedPhone] = useState<string>();
  const [formattedExt, setFormattedExt] = useState<string>();

  useEffect(() => {
    if (!orgData || Object.keys(orgData).length === 0) return;
    if (orgData.state) {
      setState(orgData.state);
    }
    if (orgData.streetAddress) {
      setStreetAddress(orgData.streetAddress);
    }
  }, [orgData, drawerOpen]);
  useEffect(() => {
    if (
      updateOrganizationData?.error?.message.includes('415') ||
      updateOrganizationData?.error?.message.includes('500')
    ) {
      setToast(
        new Toast({
          message: 'Organization name is already taken. Please use a unique name.',
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    } else if (updateOrganizationData.status === MutationStatus.Complete) {
      setToast(
        new Toast({
          message: 'Organization info updated!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    }
  }, [setToast, updateOrganizationData?.error?.message, updateOrganizationData.status]);

  const onSubmit = (values: OrganizationData) => {
    if (!selectedState || selectedState === 'Choose a state') {
      setToast(new Toast({ message: 'Please select a state', severity: 'error', open: true }));
      return;
    }
    if (!streetAddress || streetAddress === '') {
      setToast(
        new Toast({ message: 'Please enter street address', severity: 'error', open: true }),
      );
      return;
    }

    const phoneNumber = trimPhoneNumber(values.phoneNumber ?? '');
    values.phoneNumber = phoneNumber;
    const { name, phoneExtension, city, zip } = values;
    try {
      updateOrganizationData.action({
        name,
        phoneNumber,
        phoneExtension,
        city,
        state: selectedState,
        zip,
        streetAddress,
      });
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    }
    setDrawerOpen(false);
  };

  const validatePhoneNumber = (value: string) => {
    const result = formatPhoneNumber(value);
    setFormattedPhone(result);

    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;
    return regex.test(value) ? undefined : 'Invalid Phone Number';
  };

  const validateExtension = (value: string | undefined) => {
    if (!value) return undefined;
    const result = value.replace(/[^0-9]/g, '');
    setFormattedExt(result ? result : '');
  };

  const updateAddressInfo = (address: AddressInfo) => {
    setFullAddress(address);
  };
  const validateZip = (value: string | undefined) => {
    if (!value) return undefined;
    const result = value.replace(/[^0-9]/g, '');
    setFormattedZip(result ? result : '');
  };

  if (!googlePlacesKey) {
    return <Loading />;
  }
  return (
    <EditDrawer
      onSubmit={onSubmit}
      title={title}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}>
      <HorizontalFormInput<OrganizationData>
        fieldName='name'
        label='Name'
        initialValue={orgData.name}
        labelStyle={{ flex: '2 0 10px', marginTop: '10px' }}
        inputStyle={{ flex: '3 0 10px' }}
        textSize='small'
        rightAlignedKey={true}
        required
      />
      <Spacer height='xxs' />
      <Row style={{ flex: '2 0 10px', justifyContent: 'flex-end', gap: '20px' }}>
        <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
          <Typography
            sx={{
              flex: '3 0 10px',
              justifyContent: 'flex-end',
              textAlign: 'right',
              marginTop: '10px',
            }}
            variant='p14'
            color='info.main'>
            Phone number
          </Typography>
          <FormInput<OrganizationData>
            fieldName='phoneNumber'
            initialValue={orgData.phoneNumber}
            formattedValue={formattedPhone}
            inputStyle={{ minWidth: '100px', flex: '3 0 10px' }}
            validate={validatePhoneNumber}
            maxLength={14}
            required
          />
        </Row>
        <HorizontalFormInput<OrganizationData>
          fieldName='phoneExtension'
          label='Ext.'
          initialValue={orgData.phoneExtension}
          formattedValue={formattedExt}
          labelStyle={{ flex: '2 0 10px', marginTop: '10px' }}
          inputStyle={{ flex: '3 0 10px' }}
          textSize='small'
          rightAlignedKey={true}
          validate={validateExtension}
          maxLength={6}
        />
      </Row>
      <Spacer height='xxs' />
      <Row
        style={{
          flexWrap: 'wrap',
          gap: '20px',
          justifyContent: 'flex-end',
          alignItems: 'baseline',
        }}>
        <Typography
          sx={{ flex: '2 0 10px', textAlign: 'right', marginTop: '10px' }}
          variant='p18SemiBold'
          color='secondary.dark'>
          Mailing address
        </Typography>
        <div style={{ flex: '3 0 10px' }} />
      </Row>
      <Spacer height='xxs' />
      <Card variant='ghost' color='primary'>
        <CardContent className='mobileAddressCard'>
          <Column
            style={{
              flexWrap: 'wrap',
              alignItems: 'left',
              gap: '10px',
            }}>
            <Row
              style={{
                flexWrap: 'wrap',
                gap: '20px',
                justifyContent: 'flex-end',
                alignItems: 'baseline',
                height: '32px',
              }}>
              <Typography
                style={{ flex: '2 0 10px', textAlign: 'right', marginTop: '10px' }}
                variant='p14'
                color='info.main'>
                Street address
              </Typography>
              <AddressSearchbox
                organizationData={orgData}
                onSubmit={updateAddressInfo}
                googleKey={googlePlacesKey}
                inputStyle={{ flex: '3 0 10px' }}
                streetAddress={streetAddress}
                setStreetAddress={setStreetAddress}
                setFullAddress={setFullAddress}
                fullAddress={fullAddress}
                setState={setState}
                fieldName='streetAddress'
              />
            </Row>
            <Spacer style={{ height: '1px' }} />
            <HorizontalFormInput<OrganizationData>
              fieldName='city'
              label='City'
              initialValue={fullAddress?.city ?? orgData.city}
              labelStyle={{ flex: '2 0 10px', marginTop: '10px' }}
              inputStyle={{ flex: '3 0 10px' }}
              textSize='small'
              rightAlignedKey={true}
              required
            />
            <Row
              style={{
                flexWrap: 'wrap',
                gap: '20px',
                justifyContent: 'flex-end',
                alignItems: 'baseline',
                height: '32px',
              }}>
              <Typography
                style={{ flex: '2 0 10px', textAlign: 'right', marginTop: '10px' }}
                variant='p14'
                color='info.main'>
                State
              </Typography>
              <DropdownMenu
                callback={val => setState(val)}
                selected={selectedState}
                options={statesList}
                inputStyles={{ width: '376px' }}
              />
            </Row>
            <HorizontalFormInput<OrganizationData>
              fieldName='zip'
              label='Zip code'
              initialValue={fullAddress?.zip ?? orgData.zip}
              formattedValue={formattedZip}
              labelStyle={{ flex: '2 0 10px', marginTop: '10px' }}
              inputStyle={{ flex: '3 0 10px' }}
              validate={validateZip}
              textSize='small'
              rightAlignedKey={true}
              maxLength={5}
              required
            />
          </Column>
        </CardContent>
      </Card>
      <Spacer height='lg' />
    </EditDrawer>
  );
};

export default AboutYourOrganizationCard;
