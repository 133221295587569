import { Typography, Card, CardContent } from '@mui/material';
import { Spacer, Row, FormInputNumber } from '../../components';
import { formatTwoDecimalPlaces } from '../../helpers/utilityFunctions';
import { LicenseData } from '../../models/LicenseData';

interface HowManyLicensesProps {
  values: LicenseData;
  initialQuantity?: number;
  cost: number;
}

const HowManyLicenses: React.FC<HowManyLicensesProps> = ({ values, initialQuantity, cost }) => {
  return (
    <>
      <Spacer height='sm' />
      <Typography color='secondary' variant='p20SemiBold'>
        How many licenses would you like to purchase?
      </Typography>
      <Spacer height='xxs' />
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <FormInputNumber<LicenseData>
          fieldName='quantity'
          label='Number of licenses'
          maxLength={7}
          initialValue={initialQuantity}
          required
        />
        <Spacer width='md' />
        <Typography color='secondary' variant='p20SemiBold' style={{ display: 'inline' }}>
          Total
        </Typography>
        <Spacer width='xs' />
        <Card color='secondary' style={{ minWidth: '200px' }}>
          <CardContent>
            <Row style={{ justifyContent: 'center' }}>
              <Typography color='info.main' style={{ display: 'inline' }} variant='subtitle1'>
                $
              </Typography>
              <Typography color='secondary.dark' style={{ display: 'inline' }} variant='subtitle1'>
                {formatTwoDecimalPlaces((values.quantity ?? 0) * cost)}
              </Typography>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <Typography variant='p12SemiBold' color='secondary.main'>
                due today
              </Typography>
            </Row>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export default HowManyLicenses;
