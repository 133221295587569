import { SignUpCompartments, signUpStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { OrgSignUpStateApi } from '../../../api/apis/OrgSignUpStateApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { OrgSignUpStateData } from '../../../models/SignUpData';

export class UpdateSignUpState implements IMutation<OrgSignUpStateData> {
  async execute(context: MutationContext<OrgSignUpStateData>): Promise<void> {
    const { data, storage, container } = context;
    const orgSignUpStateApi = container.get<OrgSignUpStateApi>(ApiKeys.OrgSignUpState);
    const signUpDataCache = storage.retrieve<SignUpCompartments>(signUpStorageKey);

    await orgSignUpStateApi.patch(data);

    await signUpDataCache.reloadAll();
  }
}

export class CreateSignUpState implements IMutation<OrgSignUpStateData> {
  async execute(context: MutationContext<OrgSignUpStateData>): Promise<void> {
    const { data, storage, container } = context;
    const orgSignUpStateApi = container.get<OrgSignUpStateApi>(ApiKeys.OrgSignUpState);
    const signUpDataCache = storage.retrieve<SignUpCompartments>(signUpStorageKey);

    await orgSignUpStateApi.post({
      onboardingComplete: false,
      offboardingComplete: false,
      ...data,
    });
    await signUpDataCache.reloadAll();
  }
}

export class DeleteSignUpState implements IMutation<OrgSignUpStateData> {
  async execute(context: MutationContext<OrgSignUpStateData>): Promise<void> {
    const { storage, container } = context;
    const orgSignUpStateApi = container.get<OrgSignUpStateApi>(ApiKeys.OrgSignUpState);
    const signUpDataCache = storage.retrieve<SignUpCompartments>(signUpStorageKey);
    await orgSignUpStateApi.delete();
    await signUpDataCache.reloadAll();
  }
}
