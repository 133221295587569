import { PlanApi } from '../../api/apis/PlanApi';
import { PlanData } from '../../models/PlanData';
import type { IAppStorage } from '@aesop-fables/scrinium';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import {
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
  DataCache,
  ConfiguredDataSource,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { combineLatest, map } from 'rxjs';
import { OrganizationData } from '../../models/OrganizationData';

export interface PlanCompartments {
  plan: DataCompartmentOptions<PlanData | undefined>;
}

export const planStorageKey = 'data/plan';

export class PlanDataCachePolicy extends DataCachePolicy<PlanCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.Plan)
    private readonly planApi: PlanApi,
  ) {
    super(planStorageKey);
  }

  buildDataCache(): DataCache<PlanCompartments> {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(map(([isAuthenticated, orgData]) => isAuthenticated === true && orgData !== undefined));

    return createObservedDataCache<PlanCompartments>({
      plan: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.planApi.getPlan();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withPlanData = createServiceModule(planStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, PlanDataCachePolicy);
});
