import { Button, Typography, capitalize } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { ToastbarContext } from '../App';
import { Row, Spacer, Column, RightDrawer, HorizontalFormInput } from '.';
import { Toast } from '../models/Toast';
import { UserData } from '../models/UserData';
import { validateEmail } from '../helpers/utilityFunctions';
import { MutationStatus, MutatorState } from '@aesop-fables/scrinium';
import { AxiosError } from 'axios';
import { AdminTierTypeEnum } from '../services/drillDown';

interface Props {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  role: AdminTierTypeEnum;
  addAdmin: MutatorState<UserData>;
}

const InviteAdminsDrawer: React.FC<Props> = props => {
  const { drawerOpen, setDrawerOpen, role, addAdmin } = props;
  const { setToast } = useContext(ToastbarContext);
  const [tryAgain, setTryAgain] = useState<boolean>(false);

  useEffect(() => {
    if (!tryAgain) {
      return;
    }
    if (addAdmin.error) {
      const error = addAdmin.error as AxiosError;
      const errorMessage = (error.response?.data as Record<string, any>)?.message;
      if (errorMessage?.includes('already invited or active')) {
        setToast(
          new Toast({
            message: 'User already invited or active',
            severity: 'error',
            open: true,
          }),
        );
      } else {
        setToast(
          new Toast({
            message: 'Error inviting admin',
            severity: 'error',
            open: true,
          }),
        );
      }
      setTryAgain(false);
      setDrawerOpen(false);
    } else if (addAdmin.status === MutationStatus.Complete) {
      setToast(new Toast({ message: 'Admin invite sent!', severity: 'success', open: true }));
      setTryAgain(false);
      setDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAdmin, tryAgain]);

  const onSubmit = async (values: UserData) => {
    setTryAgain(true);
    try {
      await addAdmin.action({
        role: 'ADMIN',
        ...values,
      });
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    }
  };

  const validateName = (value: string) => {
    if (value.length > 32) {
      return 'Name should not exceed 32 characters.';
    }
    return undefined;
  };

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Spacer height='sm' />

      <Typography color='secondary.main' variant='p30'>
        Invite to {role.toLocaleLowerCase()}
      </Typography>
      <Spacer height='sm' />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column
              style={{
                width: '90%',
              }}>
              <HorizontalFormInput
                label='First name'
                fieldName='firstName'
                rightAlignedKey={true}
                required
                validate={validateName}
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Last name'
                fieldName='lastName'
                rightAlignedKey={true}
                required
                validate={validateName}
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Email'
                fieldName='email'
                rightAlignedKey={true}
                required
                validate={validateEmail}
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                readonly={true}
                initialValue={`${capitalize(role ?? 'Organization')} admin`}
                label='Role'
                fieldName='role'
                rightAlignedKey={true}
                required
              />

              <Spacer height='sm' />
            </Column>
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Invite to {role.toLocaleLowerCase()}
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default InviteAdminsDrawer;
