import { combineLatest, map } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { RestrictionApi } from '../../api/apis/RestrictionApi';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCache,
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import { OrgDomain } from '../../models/OffboardingData';
import { PricebookBundle } from '../../models/PricebookData';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import { PricebookCompartments, pricebookStorageKey } from '../pricebook';

export interface RestrictionCompartments {
  restrictions: DataCompartmentOptions<OrgDomain[] | undefined>;
}

export const restrictionStorageKey = 'data/restrictions';

export class RestrictionDataCachePolicy extends DataCachePolicy<RestrictionCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.Restriction)
    private readonly restrictionApi: RestrictionApi,
  ) {
    super(restrictionStorageKey);
  }

  buildDataCache(): DataCache<RestrictionCompartments> {
    const pricebookDataCache = this.appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections'),
    ]).pipe(
      map(
        ([isAuthenticated, pricebookSelections]) =>
          isAuthenticated === true && pricebookSelections?.length > 0,
      ),
    );
    return createObservedDataCache<RestrictionCompartments>({
      restrictions: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const domains = await this.restrictionApi.get();
          return domains?.data;
        }),
        defaultValue: [],
      },
    });
  }
}

export const withRestrictionData = createServiceModule(restrictionStorageKey, services =>
  services.add<IDataCachePolicy>(Policies, RestrictionDataCachePolicy),
);
