import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spacer, Row } from '.';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { UpdateSignUpState } from '../data/signUp/mutations';
import { OrganizationData } from '../models/OrganizationData';
import { useDeviceType } from '../hooks/useDeviceType';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { OrgSignUpStateData } from '../models/SignUpData';

export interface Crumbs {
  pathname: string;
  label: string;
  clickable: boolean;
  onClick?: () => void;
}

interface BreadcrumbsProps extends React.PropsWithChildren {
  crumbs?: Crumbs[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = props => {
  const { crumbs } = props;
  const { isMobile, isTablet, smDesktop, mdDesktop, lgDesktop, xlDesktop } = useDeviceType();
  const location = useLocation();
  const navigate = useNavigate();
  const appStorage = useAppStorage();

  const orgDataCache = appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const updateSignUpState = useMutation(new UpdateSignUpState());
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );

  const organization = useObservable(orgDataCache.observe$<OrganizationData>('organization'));
  const organizationData: OrganizationData =
    orgSignUpState?.state?.orgData && JSON.parse(orgSignUpState?.state?.orgData);
  const inOnboarding = location.pathname.includes('onboarding');

  const maxWidth = isMobile
    ? '15px'
    : isTablet
    ? '90px'
    : smDesktop
    ? '125px'
    : mdDesktop
    ? '200px'
    : lgDesktop
    ? '300px'
    : xlDesktop
    ? '400px'
    : '200px';

  return (
    <Row>
      {crumbs &&
        crumbs.map((item: any, index: any) => {
          if (index < crumbs.length - 1) {
            if (!item.clickable) {
              return (
                <Row key={index}>
                  {index !== 0 && (
                    <>
                      <Typography variant='h2' color='secondary.main'>
                        /
                      </Typography>
                      <Spacer width='xxs' />
                    </>
                  )}
                  <Typography
                    variant='h2'
                    sx={{
                      'color': '#9eacb0',
                      'textOverflow': 'ellipsis',
                      'whiteSpace': 'nowrap',
                      '&:hover': {
                        overflow: 'hidden !important',
                      },
                    }}>
                    {item.label}
                  </Typography>
                  <Spacer width='xxs' />
                </Row>
              );
            } else
              return (
                <Row key={index}>
                  {index !== 0 && (
                    <>
                      <Typography variant='h2' color='secondary.main'>
                        /
                      </Typography>
                      <Spacer width='xxs' />
                    </>
                  )}
                  <Link
                    className='underlineOnHover'
                    variant='h2'
                    color='secondary.main'
                    sx={{
                      'overflow': 'hidden',
                      'textOverflow': 'ellipsis',
                      'whiteSpace': 'nowrap',
                      'maxWidth': maxWidth,
                      'maxHeight': '45px',
                      '&:hover': {
                        overflow: 'hidden !important',
                      },
                    }}
                    onClick={async () => {
                      if (
                        inOnboarding &&
                        !item.pathname.includes('onboarding') &&
                        organization?.id &&
                        organization.name === organizationData?.name
                      ) {
                        await updateSignUpState.action({
                          onboardingComplete: false,
                        });
                      }
                      navigate(item.pathname);
                      if (item.onClick) {
                        item.onClick();
                      }
                    }}>
                    {item.label}
                  </Link>
                  <Spacer width='xxs' />
                </Row>
              );
          }
          return (
            <Row key={index}>
              {index !== 0 && (
                <>
                  <Typography variant='h2' color='secondary.main'>
                    /
                  </Typography>
                  <Spacer width='xxs' />
                </>
              )}
              <Link
                className='underlineOnHover'
                variant='h2'
                color='primary.main'
                sx={{
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'whiteSpace': 'nowrap',
                  'maxWidth': maxWidth,
                  'maxHeight': '45px',
                  '&:hover': {
                    overflow: 'hidden !important',
                  },
                }}
                onClick={async () => {
                  if (
                    inOnboarding &&
                    !item.pathname.includes('onboarding') &&
                    organization?.id &&
                    organization.name === organizationData?.name
                  ) {
                    await updateSignUpState.action({
                      onboardingComplete: false,
                    });
                  }
                  navigate(item.pathname);
                  if (item.onClick) {
                    item.onClick();
                  }
                }}>
                {item.label}
              </Link>
            </Row>
          );
        })}
    </Row>
  );
};

export default Breadcrumbs;
