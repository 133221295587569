import { useMutation } from '@aesop-fables/scrinium';
import { Typography, Card, CardContent, Button, Link } from '@mui/material';
import { useContext, useState } from 'react';
import { ToastbarContext } from '../../App';
import { SubscriptionData } from '../../api/apis/SubscriptionApi';
import { Svgs } from '../../assets/svg';
import { Spacer, Row, Modal } from '../../components';
import { CancelInvoice } from '../../data/subscription/mutations';
import { PaymentTypeEnum } from '../../models/PricebookData';
import { Toast } from '../../models/Toast';

interface UnpaidInvoiceCardProps {
  subscription: SubscriptionData;
}

const UnpaidInvoiceCard: React.FC<UnpaidInvoiceCardProps> = ({ subscription }) => {
  const cancelInvoice = useMutation(new CancelInvoice());
  const { setToast } = useContext(ToastbarContext);
  const [modalOpen, setModalOpen] = useState(false);
  const meteredPayment = subscription.paymentType === PaymentTypeEnum.Metered;

  const handlePay = () => {
    window.open(subscription.failedInvoiceUrl);
  };

  const handleCancel = async () => {
    await cancelInvoice.action(subscription.id);
    setToast(
      new Toast({
        message: 'Invoice canceled!',
        severity: 'success',
        open: true,
      }),
    );
  };

  return (
    <>
      <Modal
        title={'Cancel invoice?'}
        handleSave={handleCancel}
        open={modalOpen}
        setOpen={setModalOpen}
        primaryButtonText={'Yes, cancel'}
        secondaryButtonText={'No, keep'}>
        <Typography variant='p14' color='secondary.main' gutterBottom>
          Are you sure you want to cancel this invoice?
        </Typography>
        <Spacer height='sm' />
        <Typography variant='p14' color='secondary.main' gutterBottom>
          You will need to go through the purchase process again to buy additional licenses.
        </Typography>
        <Spacer height='sm' />
      </Modal>
      <Card variant='outlined'>
        <CardContent className='basic'>
          <Row style={{ justifyContent: 'space-between' }}>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconWarning />
              <Spacer width='xxs' />
              <Typography color='error.dark'>You have an unpaid invoice.</Typography>
            </Row>
            <Row style={{ alignItems: 'center' }}>
              <Typography color='secondary'>{subscription.product}&nbsp;</Typography>
              <Typography variant='p16SemiBold' color='secondary'>
                (
                {subscription.amountAdded +
                  (subscription.amountAdded === 1 ? ' license' : ' licenses')}
                )
              </Typography>
            </Row>
            <Row style={{ alignItems: 'center' }}>
              {!meteredPayment && (
                <Link variant='body1' color='secondary' onClick={() => setModalOpen(true)}>
                  Cancel invoice
                </Link>
              )}
              <Spacer width='md' />
              <Button onClick={handlePay}>Pay invoice</Button>
            </Row>
          </Row>
        </CardContent>
      </Card>
    </>
  );
};

export default UnpaidInvoiceCard;
