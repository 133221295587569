import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, Scrollbox, Spacer } from '../../components';

const EmailTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data;

  const onClose = () => {
    navigate(-1);
  };
  return (
    <Column style={{ margin: '100px' }}>
      <Typography variant='p50Light' color='secondary.main'>
        Email template: {data.templateName}
      </Typography>
      <Spacer height='sm' />
      <Scrollbox title='Subject Line'>
        <Spacer height='xs' />
        <Typography variant='body1' color='secondary.dark'>
          Body copy --- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ac blandit
          metus. Cras blandit, leo ac tincidunt fringilla, lacus elit laoreet ante, et egestas nisi
          est eu augue. Donec maximus lacinia ipsum, non scelerisque ipsum suscipit non. Nullam
          scelerisque massa sit amet ipsum vestibulum lacinia. Phasellus nisi dui, mattis dictum
          malesuada sed, interdum non leo. Mauris dolor tellus, rutrum in faucibus vel, lobortis nec
          ipsum. Nam consectetur nibh a leo mattis, blandit efficitur elit efficitur. Sed suscipit
          volutpat nibh vel dictum.
          <br />
          <br />
          Suspendisse potenti. Donec condimentum eros massa, eu porttitor velit interdum vitae.
          Proin viverra massa lorem, sed auctor neque elementum et. Nullam vitae felis et magna
          porta rutrum vitae vitae nisi. Nullam et consequat libero, sed euismod lectus. Sed lectus
          lorem, pulvinar vitae nisl nec, mattis tincidunt arcu. Mauris ultrices dui gravida,
          pharetra nisl ac, egestas dolor. Curabitur ultrices, elit quis dapibus auctor, velit ipsum
          hendrerit diam, a aliquet tortor lacus sed neque. Vivamus faucibus tortor a eros
          vestibulum bibendum. Etiam sed mattis libero.
        </Typography>
        <Spacer height='xs' />
      </Scrollbox>
      <Spacer height='xxxl' />
      <Button onClick={onClose} variant='outlined' color='secondary' className='sm'>
        Close
      </Button>
    </Column>
  );
};

export default EmailTemplate;
