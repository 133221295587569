import { Button } from '@mui/material';
import { Form } from 'react-final-form';
import { RightDrawer, Row, Spacer } from '.';

interface EditDrawerProps extends React.PropsWithChildren {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  smallTitle?: string;
  onSubmit: (values: any) => void;
  disableSave?: boolean;
  preventAutoClose?: boolean;
}

const EditDrawer: React.FC<EditDrawerProps> = props => {
  const {
    drawerOpen,
    setDrawerOpen,
    title,
    smallTitle,
    children,
    onSubmit,
    disableSave,
    preventAutoClose,
  } = props;

  const handleSave = (values: any) => {
    onSubmit(values);
    !preventAutoClose && setDrawerOpen(false);
  };

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen} title={title} smallTitle={smallTitle}>
      <Form onSubmit={handleSave}>
        {({ handleSubmit }) => (
          <>
            <Spacer height='xxxs' />
            {children}
            <Spacer height='xxxl' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Close
              </Button>
              <Button color='primary' onClick={handleSubmit} disabled={disableSave}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default EditDrawer;
