import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class CompanyPayrollDeductionApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<PayrollDeductionData[]>> {
    return this.axios.get('company-payroll-deduction');
  }

  addDeductions(
    deductions: PayrollDeductionData[],
  ): Promise<AxiosResponse<PayrollDeductionData[]>> {
    return this.axios.post('company-payroll-deduction', deductions);
  }

  editDeduction(deduction: PayrollDeductionData): Promise<AxiosResponse<PayrollDeductionData>> {
    return this.axios.patch('company-payroll-deduction', deduction);
  }

  update(deduction: PayrollDeductionData): Promise<AxiosResponse<PayrollDeductionData>> {
    return this.axios.put('company-payroll-deduction', deduction);
  }
}

export interface PayrollDeductionData {
  id: AdpOrganization;
  companyName?: string;
  deductionName: string;
  deductionType: string;
  catchup: boolean;
}

interface AdpOrganization {
  organizationId: number;
  companyCode: string;
  deductionCode: string;
  gcCategory?: number;
}

export const deductionTypesList = [
  'Pre-tax',
  'Pre-tax catch-up',
  'Roth',
  'Roth catch-up',
  'After-tax',
];

export enum DeductionTypesEnum {
  'Pre-tax' = 1,
  'After-tax' = 2,
  Roth = 5,
}

export enum ContributionTypesEnum {
  'P' = 'Percent (%)',
  'D' = 'Dollar ($)',
}

export const getContributionTypeEnum = (value: string) => {
  const entries = Object.entries(ContributionTypesEnum);
  for (const [key, enumValue] of entries) {
    if (enumValue === value) {
      return key as keyof typeof ContributionTypesEnum;
    }
  }
};
