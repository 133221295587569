import { Typography, Button, Grid } from '@mui/material';
import { Column, Row, Spacer } from '../../components';
import { formatDateMMDDYYYY } from '../../helpers/utilityFunctions';
import { Svgs } from '../../assets/svg';

interface ViewLicenseAgreementProps {
  reviewed: boolean | undefined;
  dateAccepted: string | undefined;
  onViewAgreement: () => void;
}

export const ViewContractButton: React.FC<ViewLicenseAgreementProps> = ({
  reviewed,
  dateAccepted,
  onViewAgreement,
}) => {
  const buttonText = reviewed ? 'Review contract' : 'View & accept contract';
  const buttonVariant = reviewed ? 'outlined' : 'tertiary';
  const formattedDate = formatDateMMDDYYYY(dateAccepted ?? '');

  return (
    <Column
      style={{
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
      }}>
      <Column style={{ flex: 1 }}>
        <Button
          style={{ alignSelf: 'center', padding: '5px 25px 5px 25px' }}
          onClick={onViewAgreement}
          variant={buttonVariant}
          color='secondary'>
          {buttonText}
        </Button>
      </Column>
      <Spacer height='xxs' />
      {reviewed && !formattedDate.includes('NaN') && (
        <Row>
          <Typography mt={1} variant='p18' color='info.main'>
            Agreement accepted {formattedDate}
          </Typography>
          <Grid
            style={{
              margin: '11px 0px 0px 10px',
            }}>
            <Svgs.IconCheckmark />
          </Grid>
        </Row>
      )}
    </Column>
  );
};
