import { Card, CardContent, Typography, Button, Link } from '@mui/material';
import { Svgs } from '../../assets/svg';
import { Spacer, Row, Modal } from '../../components';
import { MutatorState } from '@aesop-fables/scrinium';
import { OrganizationMetricsData } from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { useLoading } from '../../hooks/useLoading';
import { useState } from 'react';

interface IncompleteOrgsProps {
  incompleteOrgMetrics: OrganizationMetricsData[];
  setRemoveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTryAgain: React.Dispatch<React.SetStateAction<boolean>>;
  setOrgStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
  deleteOrganization: MutatorState<number>;
  select: MutatorState<number>;
  removeModalOpen: boolean;
}
const IncompleteOrgs: React.FC<IncompleteOrgsProps> = ({
  incompleteOrgMetrics,
  setRemoveModalOpen,
  setTryAgain,
  setOrgStatus,
  deleteOrganization,
  select,
  removeModalOpen,
}) => {
  const { setLoading } = useLoading();
  const [activeData, setActiveData] = useState<OrganizationMetricsData | undefined>();

  const onContinue = async (org: OrganizationMetricsData) => {
    setOrgStatus('incomplete');
    setLoading(true);
    await select.action(org.organizationId);
    setLoading(false);
    return;
  };

  const onRemove = (org: OrganizationMetricsData) => {
    setActiveData(org);
    setRemoveModalOpen(true);
    return;
  };

  const onYesRemove = () => {
    if (!activeData) return;
    setTryAgain(true);
    setLoading(true);
    deleteOrganization.action(activeData.organizationId);
  };

  return (
    <>
      {activeData && (
        <RemoveModal
          org={activeData}
          modalOpen={removeModalOpen}
          setModalOpen={setRemoveModalOpen}
          onYesRemove={onYesRemove}
        />
      )}
      <Spacer height='xxs' />
      {incompleteOrgMetrics.map(org => (
        <Card variant='outlined' key={org.organizationId} sx={{ marginTop: '10px' }}>
          <CardContent className='basic'>
            <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Row style={{ alignItems: 'center' }}>
                <Svgs.IconOrgPaysLarge />
                <Spacer width='xxs' />
                <Typography
                  noWrap={true}
                  sx={{ display: 'block', maxWidth: '500px' }}
                  variant='p18SemiBold'
                  color='secondary.main'>
                  {org.organizationName}
                </Typography>
              </Row>
              <Typography variant='body1' color='error.dark'>
                You haven't finished setting up this organization.
              </Typography>
              <Row style={{ alignItems: 'center' }}>
                <Link variant='p14' color='secondary.main' onClick={() => onRemove(org)}>
                  Remove
                </Link>
                <Spacer width='xs' />
                <Button onClick={async () => await onContinue(org)}>Continue</Button>
              </Row>
            </Row>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
interface RemoveModalProps {
  org: OrganizationMetricsData;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onYesRemove: () => void;
}

const RemoveModal: React.FC<RemoveModalProps> = ({ org, modalOpen, setModalOpen, onYesRemove }) => {
  return (
    <Modal
      title={'Remove organization?'}
      handleSave={onYesRemove}
      open={modalOpen}
      setOpen={setModalOpen}
      primaryButtonText={'Yes, remove'}
      secondaryButtonText={'No, keep'}>
      <Typography sx={{ display: 'inline' }} variant='p14' color='secondary.main'>
        Are you sure you want to remove{' '}
      </Typography>
      <Typography sx={{ display: 'inline' }} variant='p14Bold' color='secondary.main'>
        {org.organizationName ?? ''}
      </Typography>
      <Typography sx={{ display: 'inline' }} variant='p14' color='secondary.main'>
        ?
      </Typography>
      <Spacer height='sm' />
      <Typography variant='p14' color='secondary.main'>
        This action cannot be undone
      </Typography>
      <Spacer height='sm' />
    </Modal>
  );
};

export default IncompleteOrgs;
