import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { PricebookBundle } from '../../models/PricebookData';

export class LicenseAgreementApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  generate(priceBookId: number): Promise<AxiosResponse<LicenseAgreementData>> {
    return this.axios.post(`license-agreement/generate-if-not-exists?priceBookId=${priceBookId}`);
  }
  accept(docId: number): Promise<AxiosResponse<void>> {
    return this.axios.post(`license-agreement/accept?docId=${docId}`);
  }
  getAllDocs(): Promise<AxiosResponse<LicenseAgreementData[]>> {
    return this.axios.get('license-agreement/docs');
  }
  getDoc(docId: number): Promise<AxiosResponse<string[]>> {
    return this.axios.get(`license-agreement/doc?docId=${docId}`);
  }

  invalidate(docId: number): Promise<AxiosResponse<LicenseAgreementData>> {
    return this.axios.post(`license-agreement/invalidate/${docId}`);
  }
}
export interface LicenseAgreementData {
  id: number;
  docName: string;
  docDate: string;
  docUri: string;
  organizationId: number;
  pricebookId: number;
  docStatus: boolean;
  docDescription: string;
  hasAccepted: boolean;
  pricebookBundleDto: PricebookBundle;
  invoiceDate: string;
}
