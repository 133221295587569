import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { GroupData } from '../../models/GroupData';

export class GroupApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<GroupData>> {
    return this.axios.get('group');
  }

  getAll(): Promise<AxiosResponse<GroupData[]>> {
    return this.axios.get('group/all');
  }

  post(group: GroupData): Promise<AxiosResponse<GroupData>> {
    return this.axios.post('group', group);
  }

  update(group: GroupData): Promise<AxiosResponse<GroupData>> {
    return this.axios.put('group', group);
  }

  delete(id: number): Promise<AxiosResponse<GroupData>> {
    return this.axios.delete(`group/${id}`);
  }
}
