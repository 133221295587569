import { useState, useEffect } from 'react';

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

const useDeviceType = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 960px)');
  const isDesktop = useMediaQuery('(min-width: 960px)');

  const smDesktop = useMediaQuery('(min-width: 960px) and (max-width: 1200px)');
  const mdDesktop = useMediaQuery('(min-width: 1200px) and (max-width: 1500px)');
  const lgDesktop = useMediaQuery('(min-width: 1500px) and (max-width: 1800px)');
  const xlDesktop = useMediaQuery('(min-width: 1800px)');

  return {
    isMobile,
    isTablet,
    isDesktop,
    smDesktop,
    mdDesktop,
    lgDesktop,
    xlDesktop,
  };
};

export { useDeviceType };
