import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { API_URL } from './instances';

export class TermsApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getAll(): Promise<AxiosResponse<TermsData[]>> {
    return this.axios.get('termsAndConditions/docs/default');
  }

  get(docId: number): string {
    return `${API_URL}termsAndConditions/default?docId=${docId}`;
  }
}

export interface TermsData {
  id: number;
  docName: string;
  docDate: string;
  docUri: string;
  recordKeeperId: number;
  planId?: number;
  docStatus: boolean;
  docDescription: string;
}
