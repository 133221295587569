import { useAppStorage } from '@aesop-fables/scrinium';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import {
  ReportRegistry,
  getReportCompartmentKeys,
  mapReportRestToData,
  reportsRepositoryKey,
} from '../data/report';
import { extractCalendarDate } from '../helpers/utilityFunctions';
import { AnyReportRest, AnyReportType } from '../models/ReportData';
import { AdminTierTypeEnum } from '../services/drillDown';

export const useReportData = () => {
  const appStorage = useAppStorage();
  const reportRepository = appStorage.repository<ReportRegistry>(reportsRepositoryKey);

  const getReportData = useCallback(
    async (date: Dayjs, tier: AdminTierTypeEnum | undefined): Promise<AnyReportType | null> => {
      if (!date || !tier) return null;

      const compartmentKeys = getReportCompartmentKeys(tier);
      const dateKey = extractCalendarDate(date);

      for (const key of compartmentKeys) {
        const compartment = reportRepository.get(key, dateKey);
        let fetchedReport = await compartment.getData();

        if (!fetchedReport) {
          await compartment.reload();
          fetchedReport = await compartment.getData();
        }

        if (fetchedReport) {
          return mapReportRestToData(fetchedReport as AnyReportRest);
        }
      }

      return null;
    },
    [reportRepository],
  );

  const getSummedReportData = useCallback(
    async (
      date: Dayjs,
      tier: AdminTierTypeEnum.Organization | AdminTierTypeEnum.Team,
    ): Promise<AnyReportType | null> => {
      if (!date) return null;

      const compartmentKey: keyof ReportRegistry =
        tier === AdminTierTypeEnum.Organization ? 'summedTeamReport' : 'summedGroupReport';

      const dateKey = extractCalendarDate(date);
      const compartment = reportRepository.get(compartmentKey, dateKey);
      let fetchedReport = await compartment.getData();

      if (!fetchedReport) {
        await compartment.reload();
        fetchedReport = await compartment.getData();
      }

      return mapReportRestToData(fetchedReport as AnyReportRest);
    },

    [reportRepository],
  );

  const getEarliestReportDate = useCallback(async (): Promise<Dayjs | null> => {
    const compartment = reportRepository.get('earliestDate', 'earliest');
    let earliestDate = await compartment.getData();

    if (!earliestDate) {
      await compartment.reload();
      earliestDate = await compartment.getData();
    }

    return earliestDate ? dayjs(earliestDate as string) : null;
  }, [reportRepository]);

  return { getReportData, getSummedReportData, getEarliestReportDate };
};
