import { Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  ActivePaymentMethodData,
  AdminPaymentApi,
  PaymentMethodData,
  StripeSessionStatus,
} from '../../api/apis/AdminPaymentApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { ChargeData } from '../../api/apis/ChargeApi';
import { SubscriptionData } from '../../api/apis/SubscriptionApi';
import { ToastbarContext } from '../../App';
import { Page, Row, Spacer, DataTable } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { PaymentCompartments, paymentStorageKey } from '../../data/payment';
import {
  DeletePaymentMethod,
  EnableAutoPay,
  SetDefaultPaymentMethod,
} from '../../data/payment/mutations';
import { SubscriptionCompartments, subscriptionStorageKey } from '../../data/subscription';
import {
  convertToTitleCase,
  formatDateMMDDYYYY,
  formatDollars,
  sortDollars,
} from '../../helpers/utilityFunctions';
import { Toast } from '../../models/Toast';
import PastInvoiceDrawer from './past-invoice-drawer/PastInvoiceDrawer';
import { InvoiceCompartments, invoiceStorageKey } from '../../data/invoices';
import { InvoiceData, InvoiceStatusEnum, sortByInvoiceStatus } from '../../api/apis/InvoiceApi';
import { capitalize } from 'lodash';
import UnpaidInvoiceCard from './UnpaidInvoiceCard';
import AutoPayDrawer from './auto-pay-drawer/AutoPayDrawer';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import ChangeBillingAdmin from '../multitier/ChangeBillingAdmin';
import { AdminCompartments, adminStorageKey } from '../../data/admin';
import { UserData } from '../../models/UserData';
import {
  CustomerOrganizationCompartments,
  customerOrganizationStorageKey,
} from '../../data/customerOrganization';
import { CustomerOrganizationData } from '../../models/CustomerOrganizationData';
import { PaymentHistoryData } from '../../models/OrganizationData';

const methodsHeaders = [
  {
    title: 'Name',
    label: 'name',
  },
  {
    title: 'Type',
    label: 'type',
  },
];
const historyHeaders = [
  {
    title: 'Description',
    label: 'description',
  },
  {
    title: 'Total Amount',
    label: 'totalAmount',
  },
  {
    title: 'Payment Method',
    label: 'paymentMethod',
  },
  {
    title: 'Date Paid',
    label: 'datePaid',
  },
];
const invoiceHeaders = [
  {
    title: 'Amount',
    label: 'amount',
  },
  {
    title: 'Date created',
    label: 'created',
  },
  {
    title: 'Status',
    label: 'status',
  },
];

const Payment = () => {
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);

  const adminPaymentApi = useService<AdminPaymentApi>(ApiKeys.AdminPayment);
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  const subscriptionDataCache =
    appStorage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
  const invoiceDataCache = appStorage.retrieve<InvoiceCompartments>(invoiceStorageKey);
  const customerOrgDataCache = appStorage.retrieve<CustomerOrganizationCompartments>(
    customerOrganizationStorageKey,
  );
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const adminDataCache = appStorage.retrieve<AdminCompartments>(adminStorageKey);

  const setDefaultPaymentMethod = useMutation(new SetDefaultPaymentMethod());
  const deletePaymentMethod = useMutation(new DeletePaymentMethod());
  const enableAutoPay = useMutation(new EnableAutoPay());
  const defaultPaymentMethodId = useObservable(
    paymentDataCache.observe$<string>('defaultPaymentMethod'),
  );
  const paymentMethods = useObservable(
    paymentDataCache.observe$<PaymentMethodData[]>('paymentMethods'),
  );
  const stripeSessionStatus = useObservable(
    paymentDataCache.observe$<StripeSessionStatus>('stripeSessionStatus'),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subscriptions =
    useObservable(subscriptionDataCache.observe$<SubscriptionData[]>('subscription')) ?? [];
  const customerOrganization = useObservable(
    customerOrgDataCache.observe$<CustomerOrganizationData>('customerOrganization'),
  );
  const orgAdmins = useObservable(adminDataCache.observe$<UserData[]>('orgAdmins')) ?? [];
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const paymentHistory = useObservable(paymentDataCache.observe$<ChargeData[]>('paymentHistory'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const invoices = useObservable(invoiceDataCache.observe$<InvoiceData[]>('invoices')) ?? [];

  const [paymentHistoryOpen, setPaymentHistoryOpen] = useState(false);
  const [autoPayOpen, setAutoPayOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<ChargeData | undefined>(undefined);
  const [failedInvoices, setFailedInvoices] = useState<SubscriptionData[]>([]);
  const activeOrgPricebooks = licenseAgreements?.filter(
    license => license.docStatus && license.pricebookBundleDto.pricebook.paidBy === 'ORG',
  );
  const defaultPaymentMethod = paymentMethods?.find(method => method.id === defaultPaymentMethodId);
  const autoPay = customerOrganization?.automaticPayment;

  useEffect(() => {
    setFailedInvoices(subscriptions.filter(subscription => subscription.paymentFailed));
  }, [subscriptions]);

  const onView = (row: { id: number }) => {
    const data = paymentHistory?.find(transaction => transaction?.charge?.id === row.id);
    if (data) {
      setSelectedInvoice(data);
      setPaymentHistoryOpen(true);
    }
  };

  const onSetUpAutoPay = () => {
    if (paymentMethods && paymentMethods.length > 0) {
      setAutoPayOpen(true);
    } else {
      setToast(
        new Toast({
          message: 'Please add a payment method before setting up auto-pay',
          open: true,
          severity: 'error',
        }),
      );
    }
  };

  const onViewInvoice = (row: { id: number }) => {
    const invoiceData = invoices?.find(invoice => invoice.id === row.id);
    if (invoiceData) {
      window.open(invoiceData.invoiceUrl, '_blank');
    }
  };

  const onSetAsDefault = async (row: any) => {
    try {
      const newRow = row as PaymentMethodData;
      await setDefaultPaymentMethod.action(newRow.id);
      setToast(
        new Toast({
          message: `${(row as ActivePaymentMethodData).name} set as default!`,
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      setToast(
        new Toast({
          message: `${(row as ActivePaymentMethodData).name} could not be set as default`,
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  const onAddPayment = async () => {
    const setupIntentUrl = await adminPaymentApi.setupUrl();
    window.location.replace(setupIntentUrl.data);
  };

  const onDelete = async (row: any) => {
    try {
      const newRow = row as PaymentMethodData;
      await deletePaymentMethod.action(newRow.id);
    } catch (err) {
      setToast(
        new Toast({
          message: `${(row as ActivePaymentMethodData).name} could not removed`,
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  const handleSubmit = async () => {
    await enableAutoPay.action();
    await customerOrgDataCache.reload('customerOrganization');
    setAutoPayOpen(false);
    setToast(
      new Toast({
        message: 'Auto-pay set up successfully!',
        severity: 'success',
        open: true,
      }),
    );
  };

  return (
    <Page title='Payment'>
      <Spacer height='xxs' />
      <ChangeBillingAdmin admins={orgAdmins} />
      <PastInvoiceDrawer
        data={selectedInvoice}
        drawerOpen={paymentHistoryOpen}
        setDrawerOpen={setPaymentHistoryOpen}
      />
      <AutoPayDrawer
        invoices={invoices}
        activeOrgPricebooks={activeOrgPricebooks}
        defaultPaymentMethod={defaultPaymentMethod}
        drawerOpen={autoPayOpen}
        setDrawerOpen={setAutoPayOpen}
        handleSubmit={handleSubmit}
      />
      <Spacer height='xs' />
      {/* {stripeSessionStatus && (
        <>
          <IncompleteCheckoutSessionCard stripeSession={stripeSessionStatus} />
          <Spacer height='xs' />
        </>
      )} */}
      {failedInvoices.map(invoice => (
        <>
          <UnpaidInvoiceCard subscription={invoice} />
          <Spacer height='xs' />
        </>
      ))}
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          Invoices
        </Typography>
        {autoPay ? (
          <Typography variant='p16' color='secondary' fontStyle='italic'>
            auto-pay set for the end of the month
          </Typography>
        ) : (
          <Button onClick={onSetUpAutoPay} color='primary' style={{ fontSize: 14 }}>
            Set up auto-pay
          </Button>
        )}
      </Row>
      <Spacer height='xxs' />
      <DataTable
        headers={invoiceHeaders}
        sortBy='status'
        customSort={[
          {
            column: 'amount',
            function: (a: InvoiceData, b: InvoiceData) => sortDollars(a.amount, b.amount),
          },
          {
            column: 'created',
            function: (a: any, b: any) =>
              new Date(b?.created).getTime() - new Date(a?.created).getTime(),
          },
          {
            column: 'status',
            function: (a: any, b: any) => {
              const statusComparison = sortByInvoiceStatus(a, b);
              if (statusComparison !== 0) {
                // if diff statuses, compare by status
                return statusComparison;
              } else {
                // otherwise, compare by date
                return new Date(b.created).getTime() - new Date(a.created).getTime();
              }
            },
          },
        ]}
        data={
          invoices?.map(invoice => {
            return {
              id: invoice.id,
              amount: formatDollars(invoice.amount / 100),
              created: formatDateMMDDYYYY(invoice.createdTimestamp),
              status: capitalize(invoice.status),
              deletableRow: false,
            };
          }) ?? []
        }
        itemsPerPage={5}
        onButton1Click={autoPay ? undefined : onViewInvoice}
        button1Text={
          autoPay
            ? undefined
            : invoice => (invoice.status === InvoiceStatusEnum.Open ? 'View & pay' : 'View')
        }
        emptyStateText={'You do not have any invoices.'}
      />

      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          Payment methods
        </Typography>
        {!stripeSessionStatus && (
          <Button onClick={onAddPayment} color='primary' style={{ fontSize: 14 }}>
            Add a payment method
          </Button>
        )}
      </Row>
      <Spacer height='xxs' />

      <DataTable
        headers={methodsHeaders}
        sortBy='name'
        data={
          paymentMethods?.map(paymentMethod => {
            return {
              id: paymentMethod?.id,
              name: `${convertToTitleCase(
                paymentMethod?.type ?? '',
              )} ending in ${paymentMethod?.lastDigits}`,
              type: convertToTitleCase(paymentMethod?.type ?? ''),
              isDefault: paymentMethod?.id === defaultPaymentMethodId,
              isPinned: paymentMethod?.id === defaultPaymentMethodId,
              deletableRow: true,
            };
          }) ?? []
        }
        button1Text={'Set as default'}
        onButton1Click={onSetAsDefault}
        onDelete={onDelete}
        type={'paymentMethod'}
        emptyStateText={'You do not have any saved payment methods.'}
      />
      <DataTable
        headers={historyHeaders}
        sortBy='datePaid'
        customSort={[
          {
            column: 'datePaid',
            function: (a: any, b: any) =>
              new Date(b?.datePaid).getTime() - new Date(a?.datePaid).getTime(),
          },
          {
            column: 'totalAmount',
            function: (a: PaymentHistoryData, b: PaymentHistoryData) =>
              sortDollars(a.totalAmount, b.totalAmount),
          },
        ]}
        data={(paymentHistory ?? []).map(transaction => ({
          id: transaction?.charge?.id,
          description: transaction?.charge?.description,
          totalAmount: formatDollars((transaction?.charge?.amountPaid ?? 0) / 100),
          paymentMethod: `${convertToTitleCase(
            transaction?.paymentInfo?.paymentMethod ?? '',
          )} ending in ${transaction?.paymentInfo?.last4}`,
          datePaid: formatDateMMDDYYYY(transaction?.charge?.datePaid), // Format date as needed after sorting
          deletableRow: false,
        }))}
        title='Payment history'
        itemsPerPage={5}
        onButton1Click={onView}
        emptyStateText={'You do not have any historic payments.'}
      />
    </Page>
  );
};

export default Payment;
