import { useService } from '@aesop-fables/containr-react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '../components';
import { Crumbs } from '../components/Breadcrumbs';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../services/drillDown';
import { TeamCompartments, teamStorageKey } from '../data/team';
import { TeamData } from '../models/TeamData';
import { MutatorState, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { GroupCompartments, groupStorageKey } from '../data/group';
import { GroupData } from '../models/GroupData';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { OrganizationData } from '../models/OrganizationData';
import { UnselectOrganization } from '../data/organization/mutations';
import { UnselectGroup } from '../data/group/mutations';
import { UnselectTeam } from '../data/team/mutations';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../data/groupFeatureMap';
import { GroupFeatureMapData } from '../models/GroupFeatureMapData';

interface DrillDownBreadcrumbsProps {}

const DrillDownBreadcrumbs: React.FC<DrillDownBreadcrumbsProps> = () => {
  const location = useLocation();
  const appStorage = useAppStorage();

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const unselectGroup = useMutation(new UnselectGroup());
  const unselectTeam = useMutation(new UnselectTeam());
  const unselectOrganization = useMutation(new UnselectOrganization());

  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
  const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);
  const orgDataCache = appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

  const crumbs: Crumbs[] = [];
  const highestAccess = useObservable(drillDownWizard.highestAccess$);
  const origin = useObservable(drillDownWizard.origin$);
  const sidebarItems = useObservable(drillDownWizard.sidebarItems$);
  const defaultView = location.pathname === sidebarItems?.[0].pathname ?? '';
  const inOnboarding = location.pathname.includes('onboarding');

  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );
  const groupData = useObservable(groupDataCache.observe$<GroupData>('group'));
  const teamData = useObservable(teamDataCache.observe$<TeamData>('team'));
  const orgData = useObservable(orgDataCache.observe$<OrganizationData>('organization'));

  const allGroups = useObservable(groupDataCache.observe$<GroupData[]>('allGroups'));
  const allTeams = useObservable(teamDataCache.observe$<TeamData[]>('allTeams'));

  const numGroups = allGroups?.length ?? 0;
  const numTeams = allTeams?.length ?? 0;
  const groupName = groupData?.name ?? drillDownWizard.findGroupNameById();

  if (!groupFeatureMapData?.disableBreadCrumbs && location.pathname !== '/drill-down/profile') {
    if (location.pathname !== '/drill-down/choose-group' && origin === AdminTierTypeEnum.Group) {
      crumbs.push({
        pathname: '/drill-down/choose-group',
        label: 'Groups',
        clickable: true,
        onClick: async () => {
          updateCrumbs({
            groupData,
            teamData,
            orgData,
            unselectGroup,
            unselectTeam,
            unselectOrganization,
          });
        },
      });
    }

    if (groupName && (location.pathname !== '/controller/teams' || numGroups >= 2)) {
      crumbs.push({
        pathname: '/controller/teams',
        label: groupName,
        clickable: highestAccess === AdminTierTypeEnum.Group || origin === AdminTierTypeEnum.Group,
        onClick: async () => {
          updateCrumbs({
            teamData,
            orgData,
            unselectTeam,
            unselectOrganization,
          });
        },
      });
    }

    if (origin === AdminTierTypeEnum.Team) {
      crumbs.push({
        pathname: '/drill-down/choose-team',
        label: 'Teams',
        clickable: true,
        onClick: async () => {
          updateCrumbs({
            teamData,
            orgData,
            unselectTeam,
            unselectOrganization,
          });
        },
      });
    }

    if (
      teamData?.name &&
      (origin !== AdminTierTypeEnum.Organization ||
        (!origin && highestAccess !== AdminTierTypeEnum.Organization && numTeams >= 2))
    ) {
      crumbs.push({
        pathname: '/controller/organizations',
        label: teamData.name,
        clickable: true,
        onClick: async () => {
          updateCrumbs({
            orgData,
            unselectOrganization,
          });
        },
      });
    }

    if (origin === AdminTierTypeEnum.Organization) {
      crumbs.push({
        pathname: '/drill-down/choose-organization',
        label: 'Organizations',
        clickable: true,
        onClick: async () => {
          updateCrumbs({
            orgData,
            unselectOrganization,
          });
        },
      });
    }

    if (inOnboarding && !orgData?.name) {
      crumbs.push({
        pathname: location.pathname,
        label: 'New organization',
        clickable: true,
      });
    }

    if (orgData?.name) {
      crumbs.push({
        pathname: inOnboarding ? location.pathname : '/',
        label: orgData.name,
        clickable: true,
      });
    }

    sidebarItems?.filter(item => {
      if (location.pathname.includes(item.pathname) && !defaultView) {
        if (item.label === 'Dashboard') return null;
        return crumbs.push({
          pathname: item.pathname,
          label: item.label,
          clickable: true,
        });
      }
      return null;
    });
  }

  return <Breadcrumbs crumbs={crumbs} />;
};

interface UpdateCrumbsProps {
  groupData?: GroupData;
  teamData?: TeamData;
  orgData?: OrganizationData;
  unselectGroup?: MutatorState<number>;
  unselectTeam?: MutatorState<number>;
  unselectOrganization?: MutatorState<number>;
}

export const updateCrumbs = async (props: UpdateCrumbsProps) => {
  const { groupData, teamData, orgData, unselectGroup, unselectTeam, unselectOrganization } = props;

  if (groupData?.id) {
    await unselectGroup?.action(groupData.id);
  }
  if (teamData?.id) {
    await unselectTeam?.action(teamData.id);
  }
  if (orgData?.id) {
    await unselectOrganization?.action(orgData.id);
  }
};

export default DrillDownBreadcrumbs;
