import { HelpOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import { FormInput, Row, Spacer } from '.';
import { FormInputProps } from './FormInput';

interface HorizontalFormInputProps<T> extends FormInputProps<T> {
  label?: string;
  labelStyle?: {};
  inputStyle?: {};
  isDomain?: boolean;
  formattedValue?: string;
  rightAlignedKey?: boolean;
  extraStyles?: {};
  helpText?: JSX.Element;
  textSize?: string;
}

const HorizontalFormInput = <T extends unknown>(props: HorizontalFormInputProps<T>) => {
  const { label, readonly, initialValue, formattedValue, helpText, textSize } = props;
  const isDomain = props.isDomain ?? false;
  const rowStyles = props.rightAlignedKey
    ? { flexWrap: 'wrap', gap: '20px', alignItems: 'center', ...props.extraStyles }
    : { alignItems: 'center', gap: '20px', ...props.extraStyles };

  const labelStyles = props.rightAlignedKey
    ? { flex: '2 0 10px', justifyContent: 'flex-end', ...props.labelStyle }
    : { ...props.labelStyle };

  const inputStyles = props.rightAlignedKey
    ? { flex: '2 0 10px', ...props.inputStyle }
    : { ...props.inputStyle };

  return (
    <Row style={{ ...rowStyles }}>
      {label && (
        <Row style={{ ...labelStyles }}>
          <Typography
            variant={textSize && textSize === 'small' ? 'p14' : 'body1'}
            color='info.main'>
            {label}
          </Typography>
          {helpText && (
            <>
              <Spacer width='xxxs' />
              <Tippy placement='right' content={helpText} theme='tippy-rounded'>
                <HelpOutline />
              </Tippy>
            </>
          )}
        </Row>
      )}

      {readonly ? (
        <>
          {isDomain && (
            <>
              <Typography variant='body1' color='secondary.dark'>
                @
              </Typography>
              <Spacer width='xxxs' />
            </>
          )}
          <Typography {...props} style={{ ...inputStyles }} color='secondary.dark'>
            {formattedValue ? formattedValue : initialValue}
          </Typography>
        </>
      ) : (
        <>
          {isDomain && (
            <>
              <Typography variant='body1' color='secondary.dark'>
                @
              </Typography>
              <Spacer width='xxxs' />
            </>
          )}
          <FormInput {...props} inputStyle={{ ...inputStyles }} />
        </>
      )}
    </Row>
  );
};

export default HorizontalFormInput;
