import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Typography, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StripeSessionStatus } from '../../api/apis/AdminPaymentApi';
import { SubscriptionData } from '../../api/apis/SubscriptionApi';
import { Spacer, Row } from '../../components';
import { PaymentCompartments, paymentStorageKey } from '../../data/payment';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { SubscriptionCompartments, subscriptionStorageKey } from '../../data/subscription';
import { OrgSignUpStateData } from '../../models/SignUpData';
import TodoList from '../offboarding/TodoList';
import UnpaidInvoiceCard from '../payment/UnpaidInvoiceCard';

const ToDo: React.FC = () => {
  const appStorage = useAppStorage();
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  const stripeSessionStatus = useObservable(
    paymentDataCache.observe$<StripeSessionStatus>('stripeSessionStatus'),
  );
  const subscriptionDataCache =
    appStorage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
  const subscriptions =
    useObservable(subscriptionDataCache.observe$<SubscriptionData[]>('subscription')) ?? [];
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const { offboardingComplete } = orgSignUpState ?? { offboardingComplete: false };
  const [failedInvoices, setFailedInvoices] = useState<SubscriptionData[]>([]);

  useEffect(() => {
    setFailedInvoices(subscriptions?.filter(subscription => subscription.paymentFailed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant='h1' color='secondary'>
        To do
      </Typography>
      <Spacer height='xxs' />
      {stripeSessionStatus || failedInvoices.length > 0 || !offboardingComplete ? (
        <>
          {/* {stripeSessionStatus && (
            <>
              <IncompleteCheckoutSessionCard stripeSession={stripeSessionStatus} />
              <Spacer height='xs' />
            </>
          )} */}
          {failedInvoices.map(invoice => (
            <>
              <UnpaidInvoiceCard subscription={invoice} />
              <Spacer height='xs' />
            </>
          ))}
          {!offboardingComplete && (
            <Card variant='ghost' color='primary'>
              <CardContent className='basicCard'>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <TodoList />
                </Row>
              </CardContent>
            </Card>
          )}
        </>
      ) : (
        <Card variant='ghost' color='primary'>
          <CardContent className='pad30'>
            <Row style={{ justifyContent: 'center' }}>
              <Typography variant='h3' color='secondary'>
                You’re all caught up!
              </Typography>
            </Row>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default ToDo;
