import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import { useContext } from 'react';
import { ToastbarContext } from '../../App';
import { Page, Row, Column, Spacer } from '../../components';
import { useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { Toast } from '../../models/Toast';
import { AuthenticatedWizard, authenticatedWizardKey } from '../../services/authenticated';
import { useDeviceType } from '../../hooks/useDeviceType';

const ContactUs: React.FC = () => {
  const authWizard = useService<AuthenticatedWizard>(authenticatedWizardKey);
  const authState = useObservable(authWizard.state$);
  const email = authState?.product ? 'help@3nickels.com' : 'help@guidedchoice.com';
  const productName = authState?.product ? '3Nickels' : 'GuidedChoice';
  const { setToast } = useContext(ToastbarContext);

  const onEmail = () => {
    window.location.href = `mailto:${email}`;
  };
  const onCopy = () => {
    navigator.clipboard.writeText(email);
    setToast(new Toast({ message: 'Copied to clipboard!', open: true, severity: 'success' }));
  };
  const callPhoneNumber = () => {
    window.location.href = `tel:1-800-774-7459`;
  };
  const { isMobile, isTablet } = useDeviceType();

  return (
    <Page title='Contact us'>
      <Spacer height='xs' />
      <ContactCard title='Email us'>
        <Typography variant='h2' color='info.main'>
          Briefly describe what the situation is and a representative will message you back shortly.
        </Typography>
        <Spacer height='xxs' />
        <Row style={{ alignItems: 'center' }}>
          <Typography variant='p18Bold' color='info.main'>
            {productName} Help&nbsp;
          </Typography>
          <Link sx={{ textDecoration: 'underline' }} variant='h2' color='primary' onClick={onEmail}>
            {email}
          </Link>
        </Row>
        <Spacer height='sm' />
        {isMobile || isTablet ? (
          <Column style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button onClick={onEmail} className='md' color='primary' style={{ width: '100%' }}>
              Email us
            </Button>
            <Spacer height='xxs' />
            <Button onClick={onCopy} className='lg' color='secondary' style={{ width: '100%' }}>
              Copy to clipboard
            </Button>
          </Column>
        ) : (
          <Row style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button onClick={onCopy} className='lg' color='secondary' style={{ width: '185px' }}>
              Copy to clipboard
            </Button>
            <Spacer width='xs' />
            <Button onClick={onEmail} className='md' color='primary' style={{ width: '185px' }}>
              Email us
            </Button>
          </Row>
        )}
      </ContactCard>
      <Spacer height='xs' />
      <ContactCard title='Call us'>
        {isMobile || isTablet ? (
          <Column style={{ alignItems: 'left' }}>
            <Typography variant='h2' color='info.main'>
              To speak to a representative, call our toll-free number,&nbsp;
            </Typography>
            <Spacer height='xxs' />
            <Typography variant='p18Bold' color='info.main'>
              1-800-774-7459
            </Typography>
          </Column>
        ) : (
          <Row style={{ alignItems: 'center' }}>
            <Typography variant='h2' color='info.main'>
              To speak to a representative, call our toll-free number,&nbsp;
            </Typography>
            <Typography variant='p18Bold' color='info.main'>
              1-800-774-7459
            </Typography>
            <Typography variant='h2' color='info.main'>
              .
            </Typography>
          </Row>
        )}
        <Spacer height='xxs' />
        <Typography variant='p18Italic' color='info.main'>
          Hours: Monday through Friday, 8am-5pm PST
        </Typography>
        <Spacer height='xxs' />
        {isMobile && (
          <>
            <Spacer height='xs' />
            <Button
              onClick={callPhoneNumber}
              className='md'
              color='primary'
              style={{ width: '100%' }}>
              Call us
            </Button>
          </>
        )}
      </ContactCard>
    </Page>
  );
};

interface ContactCardProps extends React.PropsWithChildren {
  title: string;
}

const ContactCard: React.FC<ContactCardProps> = props => {
  const { title } = props;
  return (
    <Card variant='ghost' color='primary'>
      <CardContent className='basicCard'>
        <Typography variant='subtitle1' color='secondary.main'>
          {title}
        </Typography>
        <Spacer height='xxs' />
        {props.children}
      </CardContent>
    </Card>
  );
};

export default ContactUs;
