import { Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { Spacer, Column } from '../../components';
import MembersFileUpload from './MembersFileUpload';

const AddMembersBulk: React.FC = () => {
  const navigate = useNavigate();

  const onSubmit = async () => navigate('/members');

  return (
    <Column style={{ margin: '15px 100px' }}>
      <Spacer height='sm' />
      <Typography color='secondary.main' variant='p50Light'>
        Bulk add
      </Typography>
      <Spacer height='sm' />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <div style={{}}>
            <MembersFileUpload
              mode='add/edit'
              onSubmit={handleSubmit}
              leftButtonText='Cancel'
              rightButtonText='Done'
            />
          </div>
        )}
      </Form>
    </Column>
  );
};

export default AddMembersBulk;
