import { Typography, TypographyProps } from '@mui/material';
import { Children } from 'react';
import Row from './Row';
import Column from './Column';

const BulletedList: React.FC<TypographyProps> = ({ children, style, color }) => {
  return (
    <Column>
      {Children.map(children, (child, index) => (
        <BulletedText key={index} color={color} style={{ ...style }}>
          {child}
        </BulletedText>
      ))}
    </Column>
  );
};

export const BulletedText: React.FC<TypographyProps> = ({ children, style, color }) => {
  return (
    <>
      <Row>
        <Row style={{ flex: 1 }} />
        <Row style={{ flex: 6 }}>
          <Typography
            className='bulleted-text'
            variant='p16Light'
            style={{
              display: 'list-item',
              ...style,
            }}
            color={color ?? 'secondary.dark'}>
            {children}
          </Typography>
        </Row>
      </Row>
    </>
  );
};

export default BulletedList;
