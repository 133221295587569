import { Routes, Route, Navigate } from 'react-router-dom';
import { OrganizationSideNav } from '../components';
import {
  Members,
  Communications,
  LicenseAgreement,
  ContactUs,
  Dashboard,
  Payment,
  PlanInfoDashboard,
  PublicKey,
  OrganizationInfo,
  RoleAccess,
} from '../dashboard';
import * as Style from '../dashboard/main/style';
import { useDeviceType } from '../hooks/useDeviceType';
import Reports from '../dashboard/reports/Reports';

export const DashboardRoutes: React.FC = () => {
  const { isDesktop } = useDeviceType();

  return (
    <>
      <Style.MainSection>
        {isDesktop && <OrganizationSideNav />}
        <Style.MainContent>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/members' element={<Members />} />
            <Route path='/communications' element={<Communications />} />
            <Route path='/license-agreement' element={<LicenseAgreement />} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/plan-info' element={<PlanInfoDashboard />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/org-info' element={<OrganizationInfo />} />
            <Route path='/org-access' element={<RoleAccess />} />
            <Route path='/public-key' element={<PublicKey />} />
            <Route path='/reports' element={<Reports />} />
            <Route path='/*' element={<Navigate to='/' replace />} />
          </Routes>
        </Style.MainContent>
      </Style.MainSection>
    </>
  );
};
