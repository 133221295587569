import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

interface BillingDetailsProps {
  name?: string;
  email?: string;
  zipCode?: string;
  country?: string;
}

const BillingDetails: React.FC<BillingDetailsProps> = ({ name, email, zipCode, country }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow sx={{ border: 'none' }}>
            <TableCell variant='dataHead' style={{ width: '155px' }}>
              Billing details
            </TableCell>
            <TableCell variant='dataHead'>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant='dataBodyKey'>Name</TableCell>
            <TableCell variant='dataBodyValue'>{name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='dataBodyKey'>Email</TableCell>
            <TableCell variant='dataBodyValue'>{email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='dataBodyKey'> {zipCode ? 'Zip code' : null}</TableCell>
            <TableCell variant='dataBodyValue'>{zipCode}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='dataBodyKey'> {country ? 'Country' : null}</TableCell>
            <TableCell variant='dataBodyValue'>{country}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingDetails;
