import { HelpOutline } from '@mui/icons-material';
import { Button, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import * as yup from 'yup';
import { Svgs } from '../../assets/svg';
import {
  Column,
  StepsHeader,
  Spacer,
  Row,
  ToggleInput,
  HorizontalFormInput,
  FormError,
} from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { DeleteDomain, SetDomains } from '../../data/offboarding/mutations';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { UpdateSignUpState } from '../../data/signUp/mutations';
import { OrganizationData, RestrictionEnum } from '../../models/OrganizationData';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { AuthenticatedWizard, authenticatedWizardKey } from '../../services/authenticated';
import { OffboardingWizard, offboardingWizardKey } from '../../services/offboarding';
import { UpdateOrganizationData } from '../../data/organization/mutations';
import { reduceValidationError } from '../../helpers/utilityFunctions';
import { OrgDomain } from '../../models/OffboardingData';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { MembersApi } from '../../api/apis/MembersApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { UserCompartments, userStorageKey } from '../../data/user';
import { RestrictionCompartments, restrictionStorageKey } from '../../data/restrictions';
import { SignUpWizard, signUpWizardKey } from '../../services/signUp';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { useLoading } from '../../hooks/useLoading';
import { HelpBody, HelpTitle } from '../../components/HelpText';
import { BulletedText } from '../../components/BulletedList';
import MembersFileUpload from '../members/MembersFileUpload';
import { StepsHeaderTypes } from '../../models/StepsHeaderData';

const RestrictMemberAccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tsv, setTsv] = useState<string | undefined>(undefined);
  const updateSignUpState = useMutation(new UpdateSignUpState());
  const appStorage = useAppStorage();
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const offboardingWizard = useService<OffboardingWizard>(offboardingWizardKey);
  const authWizard = useService<AuthenticatedWizard>(authenticatedWizardKey);
  const current = location.pathname;
  const { onboardingComplete, offboardingComplete } = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  ) ?? { onboardingComplete: false, offboardingComplete: false };
  const { activeStep, totalSteps } = !onboardingComplete
    ? signUpWizard.currentStep(location)
    : offboardingWizard.getStepInfo(current);

  const todos = useObservable(offboardingWizard.todoList$) ?? [];
  const headerSteps = todos.some(t => t.text.includes('Restrict'))
    ? StepsHeaderTypes.DefaultOffboarding
    : StepsHeaderTypes.MemberPaysOffboarding;

  const items = ['By domain', 'By a list of members', `I don't want to restrict`];
  const restrictionsCache = appStorage.retrieve<RestrictionCompartments>(restrictionStorageKey);
  const domains =
    useObservable(restrictionsCache.observe$<{ domain: string }[]>('restrictions')) ?? [];
  if (domains.length === 0) {
    domains.push({
      domain: '',
    });
  }

  const orgCache = appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const organization = useObservable(orgCache.observe$<OrganizationData>('organization'));
  const setDomains = useMutation(new SetDomains());
  const deleteDomain = useMutation(new DeleteDomain());
  const updateOrganizationData = useMutation(new UpdateOrganizationData());
  const membersApi = useService<MembersApi>(ApiKeys.Members);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  useEffect(() => {
    if (groupFeatureMapData?.onlyRestrictMembersByDomain)
      updateOrganizationData.action({ restrictMemberAccess: RestrictionEnum.DOMAIN });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFeatureMapData?.onlyRestrictMembersByDomain]);

  const navBack = () => {
    navigate(-1);
  };

  const navToNext = async (values: any) => {
    setLoading(true);
    try {
      switch (values.restrictMemberAccess) {
        case 'By domain':
          await setDomains.action(values.domains);
          break;
        case 'By a list of members':
          if (tsv) {
            setLoading(true);
            let formData = new FormData();
            formData.append('file', tsv);
            await membersApi.bulkAddMembers(formData);
            userDataCache.reloadAll();
            setLoading(false);
            setToast(new Toast({ message: 'Members added', severity: 'success', open: true }));
          }
          break;
        default:
          break;
      }

      updateOrganizationData.action({
        restrictMemberAccess: convertBackToKey(values.restrictMemberAccess),
      });

      authWizard.setRestriction(convertBackToKey(values.restrictMemberAccess));
      authWizard.setRestriction(values.restriction);
      await updateSignUpState.action({
        lastCompletedStep: current,
        onboardingComplete: onboardingComplete || activeStep === totalSteps,
        offboardingComplete: offboardingComplete || activeStep === totalSteps,
      });
      const next = !onboardingComplete
        ? signUpWizard.nextStep(location)
        : offboardingWizard.nextStep(location);
      navigate(next.route);
    } catch (error) {
      console.error('error :>> ', error);
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Error parsing TSV. Please check your formatting and try again.',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const schema: any = yup.object().shape({
    restrictMemberAccess: yup.mixed().oneOf(items).required('Restriction is required'),
    domains: yup.array().when('restrictMemberAccess', (value: any) => {
      if (value === 'By domain') {
        return yup.array().of(yup.string().required('Domain is required'));
      }
      return yup.array();
    }),
  });

  const onDelete = async (id: string) => {
    await deleteDomain.action(id);
  };

  const validate = (values: any) =>
    schema
      .validate(values, { abortEarly: false })
      .then(() => {})
      .catch((err: any) => reduceValidationError(err));

  const initialValues = () => {
    const convertKeyToValue = (key: string): string => {
      switch (key) {
        case 'DOMAIN':
          return 'By domain';
        case 'LIST':
          return 'By a list of members';
        default:
          return `I don't want to restrict`;
      }
    };
    return {
      restrictMemberAccess: convertKeyToValue(organization?.restrictMemberAccess ?? ''),
      domains: domains,
    };
  };

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={navToNext}
      mutators={{ ...arrayMutators }}>
      {({
        form: {
          mutators: { push },
        },
        handleSubmit,
        values,
        submitError,
      }) => (
        <Column style={{ margin: '0px 100px' }}>
          <Spacer height='xs' />
          <StepsHeader activeStep={activeStep} totalSteps={totalSteps} headerSteps={headerSteps} />
          <Spacer height='xs' />
          <Typography variant='h1' color='secondary'>
            Restrict member access
          </Typography>
          <Spacer height='xs' />
          {!groupFeatureMapData?.onlyRestrictMembersByDomain && (
            <>
              <Row style={{ alignItems: 'center' }}>
                <Typography variant='h2' color='info.main'>
                  How would you like to restrict access to the product you purchased?&nbsp;
                </Typography>
                <Tippy placement='right' content={<RestrictionHelpText />} theme='tippy-rounded'>
                  <HelpOutline style={{ color: theme.palette.info.main }} />
                </Tippy>
              </Row>
              <Spacer height='xs' />
              <ToggleInput
                buttonStyle={{ maxWidth: '200px', height: '45px' }}
                fieldName='restrictMemberAccess'
                required={true}
                items={items}
              />
              <Spacer height='md' />
            </>
          )}

          {values.restrictMemberAccess === 'By domain' ||
          groupFeatureMapData?.onlyRestrictMembersByDomain ? (
            <>
              <Typography variant='p20SemiBold' color='secondary.dark'>
                Enter your organization’s domain(s)
              </Typography>
              <Spacer height='xxxs' />
              <Typography variant='p16Italic' color='info.main'>
                For example: In user@sample-domain.com, “sample-domain.com” would be the domain.
              </Typography>
              <Spacer height='sm' />
              <FieldArray style={{ paddingLeft: '34px', align: 'center' }} name='domains'>
                {({ fields }) =>
                  fields.map((name, index) => (
                    <div key={name}>
                      <Row style={{ alignItems: 'center' }}>
                        {index !== 0 && (
                          <Svgs.IconDelete
                            onClick={() => {
                              if ((fields.value[index] as OrgDomain)?.id !== undefined) {
                                onDelete((fields.value[index] as OrgDomain).id.toString());
                              }
                              fields.remove(index);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                        {index === 0 ? <Spacer style={{ width: '44px' }} /> : <Spacer width='xs' />}
                        <HorizontalFormInput
                          isDomain={true}
                          fieldName={`${name}.domain`}
                          label='Domain name'
                          required={index === 0}
                        />
                      </Row>
                      <Spacer height='sm' />
                    </div>
                  ))
                }
              </FieldArray>
              <Row
                style={{ alignItems: 'center', cursor: 'pointer' }}
                onClick={() => push('domains', undefined)}>
                <Svgs.IconAddLight />
                <Spacer width='xxs' />
                <Typography color='primary' variant='h3' sx={{ textDecoration: 'underline' }}>
                  Add another domain
                </Typography>
              </Row>
            </>
          ) : values.restrictMemberAccess === 'By a list of members' ? (
            <MembersFileUpload mode='add/edit' />
          ) : null}

          {submitError && <FormError err={submitError} />}

          <Spacer height='sm' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
              Back
            </Button>
            <Button
              disabled={!values.restrictMemberAccess}
              className='sm'
              color='primary'
              onClick={handleSubmit}>
              Next{!onboardingComplete ? ': Review contract' : ''}
            </Button>
          </Row>
        </Column>
      )}
    </Form>
  );
};

export const RestrictionHelpText = () => {
  return (
    <Column>
      <HelpTitle>Restricting access</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody>
        To make sure that only members of your organization sign up, you may want to restrict
        access.
      </HelpBody>
      <Spacer height='xxs' />
      <BulletedText>
        <HelpBody variant='p16Bold'>By domain:&nbsp;</HelpBody>
        <HelpBody>
          Enter your organization’s domain(s). We’ll check to make sure only emails with those
          domains have the ability to sign up.
        </HelpBody>
      </BulletedText>
      <Spacer height='xxs' />
      <BulletedText>
        <HelpBody variant='p16Bold'>By a list of members:&nbsp;</HelpBody>
        <HelpBody>
          Upload a TSV file with a list of members from your organization. We’ll check to make sure
          only those members have access.
        </HelpBody>
      </BulletedText>
      <Spacer height='xxs' />
    </Column>
  );
};

export const convertBackToKey = (value: string): RestrictionEnum => {
  switch (value) {
    case 'By domain':
      return RestrictionEnum.DOMAIN;
    case 'By a list of members':
      return RestrictionEnum.LIST;
    default:
      return RestrictionEnum.NONE;
  }
};

export default RestrictMemberAccess;
