import { Button } from '@mui/material';
import { Form } from 'react-final-form';
import { RightDrawer, Row, Spacer } from '../../components';

interface ProfileEditDrawerProps extends React.PropsWithChildren {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  onSubmit: (values: any) => void;
}

const ProfileEditDrawer: React.FC<ProfileEditDrawerProps> = props => {
  const { drawerOpen, setDrawerOpen, title, children, onSubmit } = props;

  const handleSave = (values: any) => {
    onSubmit(values);
    setDrawerOpen(false);
  };

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen} smallTitle='Edit Profile' title={title}>
      <Form onSubmit={handleSave}>
        {({ handleSubmit }) => (
          <>
            <Spacer height='sm' />
            {children}
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Close
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default ProfileEditDrawer;
