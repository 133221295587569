import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createObservedDataCache,
  ScriniumServices,
} from '@aesop-fables/scrinium';
import { inject, createServiceModule } from '@aesop-fables/containr';

import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { MendixClientData, MendixClientApi } from '../../api/apis/MendixClientApi';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { combineLatest, map } from 'rxjs';
import { OrganizationData } from '../../models/OrganizationData';

export interface MendixClientCompartments {
  mendixClientData: DataCompartmentOptions<MendixClientData | undefined>;
}

export const mendixClientStorageKey = 'data/mendixClient';

export class MendixClientDataCachePolicy extends DataCachePolicy<MendixClientCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.MendixClient)
    private readonly mendixClientApi: MendixClientApi,
  ) {
    super(mendixClientStorageKey);
  }

  buildDataCache() {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(map(([isAuthenticated, orgData]) => isAuthenticated === true && orgData !== undefined));
    return createObservedDataCache<MendixClientCompartments>({
      mendixClientData: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.mendixClientApi.get();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withMendixClientData = createServiceModule(mendixClientStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, MendixClientDataCachePolicy);
});
