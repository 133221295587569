import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class AdminPaymentApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  createSession(pricebookId: number, quantity: number): Promise<AxiosResponse<StripeSession>> {
    return this.axios.post(
      `session/create-admin-session?pricebookId=${pricebookId}&quantity=${quantity}`,
    );
  }
  getSessionPaymentStatus(sessionid: string): Promise<AxiosResponse<boolean>> {
    return this.axios.post(`session/paid?sessionId=${sessionid}`);
  }
  getDefaultPaymentMethod(): Promise<AxiosResponse<string>> {
    return this.axios.get('admin-payment/default-payment');
  }
  getAllPaymentMethods(): Promise<AxiosResponse<PaymentMethodData[]>> {
    return this.axios.get('admin-payment/payment');
  }
  setupUrl(): Promise<AxiosResponse<string>> {
    return this.axios.post('admin-payment/setup-intent-url');
  }
  setDefaultPaymentMethod(paymentMethodId: string): Promise<AxiosResponse<DefaultPaymentData>> {
    return this.axios.post(`admin-payment/set-default-payment?paymentMethodId=${paymentMethodId}`);
  }
  removePaymentMethod(paymentMethodId: string): Promise<AxiosResponse<PaymentMethodData>> {
    return this.axios.post(`admin-payment/detach-payment?paymentMethodId=${paymentMethodId}`);
  }
  updateCollectionMethod(automaticPayment: boolean): Promise<AxiosResponse<boolean>> {
    return this.axios.post(
      `admin-payment/update/collection-method?automaticPayment=${automaticPayment}`,
    );
  }
}
export interface StripeSession {
  id: string;
  url: string;
}

export interface StripeSessionStatus {
  id: string;
  url: string;
}

export interface PaymentMethodData {
  id: string;
  type: string;
  lastDigits: string;
}

export interface DefaultPaymentData {
  id: string;
  name: string;
  defaultPaymentMethod: string;
  email: string;
}

export type ActivePaymentMethodData = { name: string };
