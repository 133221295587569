import { Typography, Button } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../../components';
import { logoGcSmall } from '../../assets/png';
import { Svgs } from '../../assets/svg';
import { LearnMoreDrawerProps, PackageCard } from './LearnMoreAdviceAndThreeNickelsUDrawer';

export const LearnMoreGuidedChoiceDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
}) => {
  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: '90%' }}>
          <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            {pricebookBundle.pricebook.paidBy === 'USER' ? (
              <Svgs.IconMemberPaysLarge />
            ) : (
              <Svgs.IconOrgPaysLarge />
            )}
            <Spacer width='xxs' />
            <Typography color='secondary' variant='p12SemiBold'>
              {pricebookBundle.pricebook.paidBy === 'USER' ? 'Member pays' : 'Organization pays'}
            </Typography>
          </Row>

          <Spacer height='xxs' />
          <Typography color='secondary' variant='p30Light'>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        <img src={logoGcSmall} alt='logo' style={{ height: '40px' }} />
      </Row>

      <PackageCard pricebookBundle={pricebookBundle} />
      <Spacer height='xs' />

      <Typography color='secondary' variant='p18Bold'>
        Description
      </Typography>
      <Spacer height='xxs' />
      <Row>
        <Column style={{ width: '100%' }}>
          <Typography color='info.main' variant='body1'>
            One of the questions we’re asked the most is how to create a safe after-retirement
            income plan. That’s why we’ve put our expertise to work on personalized planning
            everyone near retirement or already retired can afford, and use to make confident
            decisions.
          </Typography>
        </Column>
        <Svgs.ArtRetirementLarge style={{ marginLeft: '20px', marginRight: '20px' }} />
      </Row>
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        At a glance...
      </Typography>
      <Column style={{ padding: '0px 10px' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletPiggyBank />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            Know exactly how much to <strong>save</strong>, across your accounts, to reach your
            retirement goals
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletInvestment />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            Know exactly what to <strong>invest</strong> in and at what percent
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletStatistics />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Retirement income projections</strong> for a weak, average, and strong market
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletLoans />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            <strong>Drawdown recommendations</strong> after retirement
          </Typography>
        </Row>
      </Column>

      <Spacer height='sm' />
      <Row style={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => setDrawerOpen(false)}
          className='sm'
          variant='outlined'
          color='secondary'>
          Close
        </Button>
      </Row>
      <Spacer height='md' />
    </RightDrawer>
  );
};
