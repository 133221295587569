import { Page, Spacer } from '../../components';

import PlanBasicsCard from './plan-cards/PlanBasicsCard';
import EmployerContributionsCard from './plan-cards/EmployerContributionsCard';
import InvestmentLineupCard from './plan-cards/InvestmentLineupCard';
import MemberContributionsCard from './plan-cards/MemberContributionsCard';
import PayrollDeductionCard from '../organization/PayrollDeductionCard';

const Plan = () => {
  return (
    <Page title='Plan info'>
      <Spacer height='xxs' />

      <PlanBasicsCard />
      <Spacer height='xxs' />

      <EmployerContributionsCard />
      <Spacer height='xxs' />

      <PayrollDeductionCard />
      <Spacer height='xxs' />

      <MemberContributionsCard />
      <Spacer height='xxs' />

      <InvestmentLineupCard />
    </Page>
  );
};

export default Plan;
