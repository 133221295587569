import { CreateDrawer, Row, Spacer } from '../../components';
import { Button, Typography, capitalize } from '@mui/material';
import { useState } from 'react';
import { AdminTierTypeEnum } from '../../services/drillDown';

interface SubHeaderRowProps {
  onCreate: (values: any) => void;
  hideCreate?: boolean;
  tierIcon?: React.ReactNode;
  tier: string;
}
const SubHeaderRow: React.FC<SubHeaderRowProps> = ({ onCreate, hideCreate, tierIcon, tier }) => {
  const [createDrawer, setCreateDrawer] = useState(false);

  return (
    <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <Row style={{ alignItems: 'center' }}>
        {tierIcon && (
          <>
            {tierIcon}
            <Spacer width='xs' />
          </>
        )}
        <Typography variant='p30' color='secondary.main'>
          Your {tier?.toLowerCase()}s
        </Typography>
      </Row>
      {!hideCreate && (
        <Button
          onClick={() =>
            capitalize(tier) === AdminTierTypeEnum.Organization
              ? onCreate(null)
              : setCreateDrawer(true)
          }>
          Create new {tier?.toLowerCase()}
        </Button>
      )}
      <CreateDrawer drawerOpen={createDrawer} setDrawerOpen={setCreateDrawer} onSubmit={onCreate} />
    </Row>
  );
};

export default SubHeaderRow;
