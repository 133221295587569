import * as React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

// When you want to call a toast you must call:
// const { setToast } = useContext(ToastbarContext);
// setToast(new Toast({ message: '<your message here>', open: true, severity: '<yourSeverity>', autoHideDuration: <someNumber> }));

// Tips:
// autoHideDuration will be 6000 milliseconds by default
// message will have a default message based on supplied severity type
// if you dont specify severity, no toast will appear.

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

interface ToastBarProps {
  open: boolean;
  severity?: AlertColor;
  message?: string;
  autoHideDuration?: number;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  actionElement?: React.ReactNode;
}

const ToastBar: React.FC<ToastBarProps> = ({
  open,
  severity,
  message,
  autoHideDuration,
  handleClose,
  actionElement,
}) => {
  if (!severity) {
    // to prevent it flashing green when the toast goes away. Mui sets it to success by default
    return null;
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration || 6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message || ''} {actionElement}
      </Alert>
    </Snackbar>
  );
};

export default ToastBar;
