import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
  ConfiguredDataSource,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { UserCompartments, userStorageKey } from '../user';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { CustomerOrganizationApi } from '../../api/apis/CustomerOrganizationApi';
import { CustomerOrganizationData } from '../../models/CustomerOrganizationData';
import { OrganizationData } from '../../models/OrganizationData';

export interface CustomerOrganizationCompartments {
  customerOrganization: DataCompartmentOptions<CustomerOrganizationData | undefined>;
}

export const customerOrganizationStorageKey = 'data/customerOrganization';

export class CustomerOrganizationDataCachePolicy extends DataCachePolicy<CustomerOrganizationCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.CustomerOrganization)
    private readonly customerOrganizationApi: CustomerOrganizationApi,
  ) {
    super(customerOrganizationStorageKey);
  }

  buildDataCache() {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.findCompartment('user').initialized$(),
      organizationDataCache.findCompartment('organization').initialized$(),
    ]).pipe(
      map(
        ([isAuthenticated, userInitialized, organizationInitialized]) =>
          isAuthenticated === true && userInitialized && organizationInitialized,
      ),
    );

    return createObservedDataCache<CustomerOrganizationCompartments>({
      customerOrganization: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const organization = await firstValueFrom(
              organizationDataCache.observe$<OrganizationData>('organization'),
            );
            if (organization.id) {
              const { data } = await this.customerOrganizationApi.getCustomerOrganization();
              return data;
            }
            return undefined;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withCustomerOrganizationData = createServiceModule(
  organizationStorageKey,
  services => {
    services.add<IDataCachePolicy>(Policies, CustomerOrganizationDataCachePolicy);
  },
);
