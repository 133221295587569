import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Column, Row, Spacer } from '../../components';
import { Typography } from '@mui/material';
import { Svgs } from '../../assets/svg';
import { RoleInfoCard } from '../multitier/AdminInfo';
import { MetricsCompartments, metricsStorageKey } from '../../data/metrics';
import { GroupMetricsData } from '../../models/MetricsData';

const GroupMetricsCard = () => {
  const appStorage = useAppStorage();

  const metricsDataCache = appStorage.retrieve<MetricsCompartments>(metricsStorageKey);
  const groupMetrics = useObservable(metricsDataCache.observe$<GroupMetricsData>('groupMetrics'));

  return (
    <>
      <Row style={{ justifyContent: 'space-between' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          Group metrics
        </Typography>
      </Row>
      <Spacer height='xs' />
      <RoleInfoCard editable={false}>
        <Row style={{ alignSelf: 'center' }}>
          <Column>
            <Typography variant='p12SemiBold' color='secondary.main'>
              Total active users
            </Typography>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconMembersDefault />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {groupMetrics?.activeLicenses ?? 0}
              </Typography>
            </Row>
          </Column>
          <Spacer width='xxxl' />
          <Column>
            <Typography variant='p12SemiBold' color='secondary.main'>
              Organizations
            </Typography>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconOrgPaysLarge />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {groupMetrics?.organizations ?? 0}
              </Typography>
            </Row>
          </Column>
          <Spacer width='xxxl' />
          <Column>
            <Typography variant='p12SemiBold' color='secondary.main'>
              Teams
            </Typography>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconTeamsDefault />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {groupMetrics?.teams ?? 0}
              </Typography>
            </Row>
          </Column>
          <Spacer width='xxxl' />
          <Column>
            <Typography variant='p12SemiBold' color='secondary.main'>
              Group admins
            </Typography>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.IconAdminsDefault />
              <Spacer width='xxs' />
              <Typography variant='p20SemiBold' color='secondary.main'>
                {groupMetrics?.admins ?? 0}
              </Typography>
            </Row>
          </Column>
        </Row>
      </RoleInfoCard>
    </>
  );
};

export default GroupMetricsCard;
