import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { TextField, FormHelperText, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import {
  OrganizationMetadataCompartments,
  organizationMetadataStorageKey,
} from '../../data/organizationMetadata';
import { OrganizationMetadata } from '../../models/OrganizationMetadata';

interface CustomBlurbTextBoxProps {
  onLimitExceeded: (val: boolean) => void;
  onChange: (val: string) => void;
}

export const CustomBlurbTextBox: React.FC<CustomBlurbTextBoxProps> = ({
  onLimitExceeded,
  onChange,
}) => {
  const appStorage = useAppStorage();
  const theme = useTheme();
  const charLimit = 500;
  const [customBlurb, setCustomBlurb] = useState('');
  const overLimit = customBlurb.length > charLimit;
  const organizationMetadataCache = appStorage.retrieve<OrganizationMetadataCompartments>(
    organizationMetadataStorageKey,
  );
  const organizationMetadata = useObservable(
    organizationMetadataCache.observe$<OrganizationMetadata>('metadata'),
  );

  useEffect(() => {
    setCustomBlurb(organizationMetadata?.signupPageText ?? '');
  }, [organizationMetadata?.signupPageText]);

  useEffect(() => {
    onLimitExceeded(overLimit);
  }, [overLimit, onLimitExceeded]);

  return (
    <>
      <TextField
        label='Custom blurb'
        variant='outlined'
        value={customBlurb}
        multiline
        rows={6}
        error={overLimit}
        onChange={e => {
          setCustomBlurb(e.target.value);
          onLimitExceeded(overLimit);
          onChange && onChange(e.target.value);
        }}
        margin='normal'
        fullWidth
      />
      <FormHelperText
        style={{
          margin: 0,
          textAlign: 'right',
          width: '100%',
          color: overLimit ? theme.palette.error.main : 'inherit',
        }}>
        {charLimit - customBlurb.length}
      </FormHelperText>
    </>
  );
};
