import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { ReportApi } from '../../../api/apis/ReportApi';
import { reloadReportCompartments } from '..';

export class RegenerateOrgReport implements IMutation<void> {
  async execute(context: MutationContext<void>): Promise<void> {
    const { storage, container } = context;
    const api = container.get<ReportApi>(ApiKeys.Report);

    await api.post();
    await reloadReportCompartments(storage, ['orgReport']);
  }
}
