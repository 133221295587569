import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createObservedDataCache,
  ScriniumServices,
} from '@aesop-fables/scrinium';
import { inject, createServiceModule } from '@aesop-fables/containr';

import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { TrustFamilyData, TrustFamilyApi } from '../../api/apis/TrustFamilyApi';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { combineLatest, map } from 'rxjs';
import { OrganizationData } from '../../models/OrganizationData';

export interface TrustFamilyCompartments {
  trustFamilyData: DataCompartmentOptions<TrustFamilyData[] | undefined>;
}

export const trustFamilyStorageKey = 'data/trustFamily';

export class TrustFamilyDataCachePolicy extends DataCachePolicy<TrustFamilyCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.TrustFamily)
    private readonly trustFamilyApi: TrustFamilyApi,
  ) {
    super(trustFamilyStorageKey);
  }

  buildDataCache() {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(map(([isAuthenticated, orgData]) => isAuthenticated === true && orgData !== undefined));
    return createObservedDataCache<TrustFamilyCompartments>({
      trustFamilyData: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.trustFamilyApi.getTrustFamily();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withTrustFamilyData = createServiceModule(trustFamilyStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, TrustFamilyDataCachePolicy);
});
