import { Card, CardContent, useTheme } from '@mui/material';
import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryLegend,
  VictoryLabel,
  VictoryTooltip,
  VictoryLabelStyleObject,
  PaddingProps,
} from 'victory';
import useChartTheme from '../themes/chart';
import { formatNumber } from '../helpers/utilityFunctions';

export interface ChartBarData {
  x: string;
  y: number;
}

interface DataSeries {
  data: ChartBarData[];
  color: string;
  label: string;
}

interface ChartBarProps {
  title: string;
  dataSeries: DataSeries[];
  xAxisLabel?: string;
  yAxisLabel: string;
  seriesLabel?: string;
  height?: number;
  width?: number;
  padding?: any;
  minHeightScale?: number;
}

const offsetLookup: { [key: number]: number } = {
  1: 120,
  2: 60,
  3: 40,
  4: 30,
  5: 25,
};

const ChartBar: React.FC<ChartBarProps> = ({
  title,
  dataSeries,
  yAxisLabel,
  seriesLabel,
  height = 400,
  width = 600,
  padding,
  minHeightScale = 0.03,
}) => {
  const theme = useTheme();
  const { chartTheme } = useChartTheme();

  const allCategories = [...new Set(dataSeries.flatMap(series => series.data.map(item => item.x)))];
  const isGrouped = dataSeries.length > 1;
  const compactLayout = allCategories.length > 4;
  const chartPadding: PaddingProps = {
    top: isGrouped ? 100 : 80,
    bottom: 50,
    left: 90,
    right: 50,
    ...padding,
  };
  const offset = offsetLookup[allCategories.length];
  const maxY = Math.max(...dataSeries.flatMap(series => series.data.map(item => item.y)));
  const minBarHeight = maxY * minHeightScale;

  return (
    <Card variant='ghost' color='primary'>
      <CardContent>
        <VictoryChart
          theme={chartTheme}
          width={width}
          height={height}
          padding={chartPadding}
          domainPadding={{ x: compactLayout ? [50, 50] : [100, 100] }}>
          <VictoryLabel
            text={title}
            y={20}
            x={10}
            style={{
              ...(chartTheme.axis?.style?.tickLabels as VictoryLabelStyleObject),
              fontWeight: '600',
              fontSize: 18,
            }}
          />

          {isGrouped && (
            <>
              <VictoryLabel
                text={seriesLabel}
                x={10}
                y={50}
                style={{
                  ...(chartTheme.axis?.style?.tickLabels as VictoryLabelStyleObject),
                  fontWeight: '600',
                  fill: theme.palette.secondary.main,
                }}
              />
              <VictoryLegend
                x={120}
                y={38}
                standalone={false}
                orientation='horizontal'
                data={dataSeries.map(series => ({
                  name: series.label,
                  symbol: { fill: series.color },
                }))}
                style={{
                  labels: chartTheme.axis?.style?.tickLabels as VictoryLabelStyleObject,
                }}
              />
            </>
          )}

          <VictoryAxis
            tickValues={allCategories}
            tickLabelComponent={
              <VictoryLabel
                textAnchor={compactLayout ? 'end' : 'middle'}
                angle={compactLayout ? -45 : 0}
              />
            }
            style={{
              tickLabels: { fontSize: 12, padding: 5 },
            }}
          />

          <VictoryAxis
            dependentAxis
            label={yAxisLabel}
            style={{ axisLabel: { padding: 60 } }}
            tickFormat={(t: number) => (maxY > 0 ? formatNumber(t) : '')}
          />

          <VictoryGroup offset={offset}>
            {dataSeries.map((series, index) => (
              <VictoryBar
                key={index}
                data={allCategories.map(category => {
                  const yValue = series.data.find(item => item.x === category)?.y ?? 0;
                  return {
                    x: category,
                    y: Math.max(yValue, yValue === 0 ? 0 : minBarHeight),
                    label: formatNumber(yValue),
                  };
                })}
                style={{ data: { fill: series.color } }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: props => ({
                              style: { ...props.style, fillOpacity: 0.8 },
                            }),
                          },
                          {
                            target: 'labels',
                            mutation: () => ({ active: true }),
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: () => null,
                          },
                          {
                            target: 'labels',
                            mutation: () => ({ active: false }),
                          },
                        ];
                      },
                    },
                  },
                ]}
                alignment='middle'
                labelComponent={<VictoryTooltip orientation='top' constrainToVisibleArea />}
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
      </CardContent>
    </Card>
  );
};

export default ChartBar;
