import { combineLatest, map } from 'rxjs';
import {
  AdminPaymentApi,
  PaymentMethodData,
  StripeSession,
  StripeSessionStatus,
} from '../../api/apis/AdminPaymentApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { ChargeApi, ChargeData } from '../../api/apis/ChargeApi';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
  DataCache,
} from '@aesop-fables/scrinium';
import { inject, createServiceModule } from '@aesop-fables/containr';
import { OrganizationData } from '../../models/OrganizationData';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { OrgSignUpStateApi } from '../../api/apis/OrgSignUpStateApi';

export interface PaymentCompartments {
  paymentMethods: DataCompartmentOptions<PaymentMethodData[] | undefined>;
  defaultPaymentMethod: DataCompartmentOptions<string | undefined>;
  paymentHistory: DataCompartmentOptions<ChargeData[] | undefined>;
  stripeSessionStatus: DataCompartmentOptions<StripeSessionStatus | undefined>;
  paidStatus: DataCompartmentOptions<boolean | undefined>;
}

export const paymentStorageKey = 'data/payment';

export class PaymentDataCachePolicy extends DataCachePolicy<PaymentCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.AdminPayment)
    private readonly adminPaymentApi: AdminPaymentApi,
    @inject(ApiKeys.Charge)
    private readonly chargeApi: ChargeApi,
    @inject(ApiKeys.OrgSignUpState)
    private readonly orgSignUpStateApi: OrgSignUpStateApi,
  ) {
    super(paymentStorageKey);
  }

  buildDataCache(): DataCache<PaymentCompartments> {
    const organizationDataCache =
      this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      organizationDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(map(([isAuthenticated, orgData]) => isAuthenticated === true && orgData !== undefined));
    return createObservedDataCache<PaymentCompartments>({
      paymentMethods: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.adminPaymentApi.getAllPaymentMethods();
          return data;
        }),
        defaultValue: undefined,
      },
      defaultPaymentMethod: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.adminPaymentApi.getDefaultPaymentMethod();
          return data;
        }),
        defaultValue: undefined,
      },
      paymentHistory: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.chargeApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
      stripeSessionStatus: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.orgSignUpStateApi.get();
          const stripeSessionJson = data.state?.stripeSession;
          return stripeSessionJson ? (JSON.parse(stripeSessionJson) as StripeSession) : undefined;
        }),
        defaultValue: undefined,
      },
      paidStatus: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.orgSignUpStateApi.get();
          const stripeSessionJson = data.state?.stripeSession;
          const stripeSession = stripeSessionJson
            ? (JSON.parse(stripeSessionJson) as StripeSession)
            : undefined;
          if (stripeSession && stripeSession.id) {
            const { data: paidStatus } = await this.adminPaymentApi.getSessionPaymentStatus(
              stripeSession.id,
            );
            return paidStatus;
          }
          return undefined;
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withPaymentData = createServiceModule(paymentStorageKey, services =>
  services.add<IDataCachePolicy>(Policies, PaymentDataCachePolicy),
);
