import { PaymentCompartments, paymentStorageKey } from '..';
import { AdminPaymentApi } from '../../../api/apis/AdminPaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';

export class SetDefaultPaymentMethod implements IMutation<string> {
  async execute(context: MutationContext<string>): Promise<void> {
    const { data, storage, container } = context;
    const adminPaymentApi = container.get<AdminPaymentApi>(ApiKeys.AdminPayment);

    // post new data to api
    await adminPaymentApi.setDefaultPaymentMethod(data);

    const cache = storage.retrieve<PaymentCompartments>(paymentStorageKey);
    await cache.reload('defaultPaymentMethod');
  }
}

export class DeletePaymentMethod implements IMutation<string> {
  async execute(context: MutationContext<string>): Promise<void> {
    const { data, storage, container } = context;
    const adminPaymentApi = container.get<AdminPaymentApi>(ApiKeys.AdminPayment);

    // post new data to api
    await adminPaymentApi.removePaymentMethod(data);

    const cache = storage.retrieve<PaymentCompartments>(paymentStorageKey);
    await cache.reload('paymentMethods');
  }
}

export class CancelCheckoutSession implements IMutation<void> {
  async execute(context: MutationContext<void>): Promise<void> {
    const { storage } = context;

    const cache = storage.retrieve<PaymentCompartments>(paymentStorageKey);

    await cache.reload('stripeSessionStatus');
  }
}

export class EnableAutoPay implements IMutation<void> {
  async execute(context: MutationContext<void>): Promise<void> {
    const { storage, container } = context;
    const adminPaymentApi = container.get<AdminPaymentApi>(ApiKeys.AdminPayment);

    await adminPaymentApi.updateCollectionMethod(true);

    const cache = storage.retrieve<PaymentCompartments>(paymentStorageKey);
    await cache.reloadAll();
  }
}
