import React, { useState } from 'react';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Row from './Row';

interface PasswordInputProps {
  amount?: string;
  weight?: string;
  weightRange?: string;
  label: string;
  style?: {};
  innerStyle?: {};
}

const PasswordInput: React.FC<PasswordInputProps> = ({ label, style, innerStyle }) => {
  const [input, setInput] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = (e: any) => {
    setShowPassword(!showPassword);
  };

  const inputHandler = (e: any) => {
    setInput(e.target.value);
  };

  return (
    <Row style={{ alignItems: 'center', ...style }}>
      <Typography variant='body1' color='info.main'>
        {label}
      </Typography>
      <FormControl sx={{ m: 1, width: '25ch', ...innerStyle }} variant='standard'>
        <InputLabel htmlFor='standard-adornment-password' />
        <Input
          id='standard-adornment-password'
          type={showPassword ? 'text' : 'password'}
          value={input}
          onChange={inputHandler}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Row>
  );
};

export default PasswordInput;
