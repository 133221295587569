import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { Column, Spacer, StepsHeader } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { SignUpWizard as SignUpWizardService, signUpWizardKey } from '../../services/signUp';
import { ToastbarContext } from '../../App';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { Toast } from '../../models/Toast';
import { PricebookBundle, PaymentTypeEnum } from '../../models/PricebookData';
import { AcceptLicenseAgreement } from '../../data/license/mutations';
import { UpdateSignUpState } from '../../data/signUp/mutations';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import { API_URL } from '../../api/apis';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { useDeviceType } from '../../hooks/useDeviceType';
import { TermsApi } from '../../api/apis/TermsApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { AdminPaymentApi } from '../../api/apis/AdminPaymentApi';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { StepsHeaderTypes } from '../../models/StepsHeaderData';
import { useLoading } from '../../hooks/useLoading';
import LicenseModal from './LicenseModal';
import PaymentDetails from './PaymentDetails';
import { ViewContractButton } from './ViewContractButton';
import { ContractActionButtons } from './ContractActionButtons';
import Cookies from 'js-cookie';
import { LicenseAgreementAcceptLogData } from '../../api/apis/LicenseAgreementAcceptLogApi';
import {
  licenseAgreementAcceptLogCompartments,
  licenseAgreementAcceptLogStorageKey,
} from '../../data/licenseAgreementAcceptLog';
import ContractFooter from './ContractFooter';
import { PaymentCompartments, paymentStorageKey } from '../../data/payment';
import { AdminCompartments, adminStorageKey } from '../../data/admin';
import { AdminStatusEnum, UserData } from '../../models/UserData';
import { ChangePrimaryBillingUser } from '../../data/admin/mutations';
import { UserCompartments, userStorageKey } from '../../data/user';

const Contract = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);

  const updateSignUpState = useMutation(new UpdateSignUpState());
  const acceptLicenseAgreement = useMutation(new AcceptLicenseAgreement());
  const changePrimaryBillingUser = useMutation(new ChangePrimaryBillingUser());

  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const licenseAgreementAcceptLogDataCache =
    appStorage.retrieve<licenseAgreementAcceptLogCompartments>(licenseAgreementAcceptLogStorageKey);
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  const adminDataCache = appStorage.retrieve<AdminCompartments>(adminStorageKey);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const signUpWizard = useService<SignUpWizardService>(signUpWizardKey);
  const adminPaymentApi = useService<AdminPaymentApi>(ApiKeys.AdminPayment);
  const termsApi = useService<TermsApi>(ApiKeys.Terms);

  const pricebookSelections = useObservable(
    pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections'),
  );
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const organizationData = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const paymentMethods = useObservable(
    paymentDataCache.observe$<PaymentMethodData[]>('paymentMethods'),
  );
  const orgAdmins = useObservable(adminDataCache.observe$<UserData[]>('orgAdmins')) ?? [];
  const userData = useObservable(userDataCache.observe$<UserData>('user'));

  const primaryBillingUser = orgAdmins.filter(
    admin => admin.status === AdminStatusEnum.Active && admin.primaryBillingUser,
  );
  const licenseData =
    orgSignUpState?.state?.licenseData && JSON.parse(orgSignUpState?.state.licenseData);
  const pricebook: PricebookBundle =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state.pricebook);
  const prePaid = pricebook?.pricebook?.paymentType === PaymentTypeEnum.Prepaid;
  const licenseAgreementAcceptLog =
    useObservable(
      licenseAgreementAcceptLogDataCache.observe$<Map<number, LicenseAgreementAcceptLogData>>(
        'licenseAgreementAcceptLog',
      ),
    ) ?? new Map<number, LicenseAgreementAcceptLogData>();

  const { activeStep, totalSteps } = signUpWizard.currentStep(location);
  const [open, setOpen] = useState(false);
  const [docUri, setDocUri] = useState<string | undefined>(undefined);
  const [doc, setDoc] = useState<LicenseAgreementData | undefined>(undefined);
  const [tosLink, setTosLink] = useState<string>();
  const [checkedOut, setCheckedOut] = useState<boolean>();
  const isUpdating = useRef(false);
  const resumeAddPackage = location?.state?.redirectReason === 'abandonedAddPackage';
  const { isMobile, isTablet } = useDeviceType();
  const steps = orgSignUpState?.onboardingComplete
    ? StepsHeaderTypes.AddPackage
    : StepsHeaderTypes.Onboarding;

  const pendingAgreement =
    licenseAgreements &&
    licenseAgreements?.find(
      licenseAgreement => licenseAgreement.docStatus && !licenseAgreement.hasAccepted,
    );
  const currentLicense =
    licenseAgreements &&
    licenseAgreements?.find(
      license => license.pricebookId === pricebook?.pricebook?.id && license.docStatus,
    );
  const meteredPricebooks = pricebookSelections?.filter(
    pricebook => pricebook.pricebook?.paymentType === PaymentTypeEnum.Metered,
  );
  const meteredPayment = (meteredPricebooks?.length ?? 0) > 0;
  const isADP = pricebookSelections?.some(pricebook =>
    pricebook.prices.some(price => price.processor.name === 'ADP'),
  );
  const orgPays = currentLicense
    ? currentLicense?.pricebookBundleDto.pricebook.paidBy === 'ORG'
    : false;
  const reviewed = currentLicense?.hasAccepted && currentLicense?.docStatus;

  const agreeText =
    orgPays &&
    meteredPayment &&
    organizationData?.automaticPayment &&
    (paymentMethods?.length ?? 0) === 0
      ? 'Add payment method'
      : orgPays && organizationData?.automaticPayment && !meteredPayment && !isADP
      ? 'Check out'
      : 'Done';

  useEffect(() => {
    const getDocUri = async () => {
      const doc = resumeAddPackage && pendingAgreement ? pendingAgreement : currentLicense;
      if (doc) {
        setDocUri(API_URL + 'license-agreement/doc?docId=' + doc.id);
        setDoc(doc);
      }
    };

    getDocUri();
  }, [currentLicense, pendingAgreement, resumeAddPackage]);

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await termsApi.getAll();
      const termsObject = res.data.find(doc => doc.docDescription === 'Terms of Service');
      if (!termsObject) {
        return;
      }
      const url = termsApi.get(termsObject.id);
      setTosLink(url);
    };
    getTermsLink();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateDateAccepted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLicense?.hasAccepted, doc, licenseAgreementAcceptLog.get(doc?.id ?? 0)]);

  useEffect(() => {
    if (open && !docUri) {
      setToast(
        new Toast({
          message: 'Error loading license agreement',
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      setOpen(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docUri, open]);

  useEffect(() => {
    setCheckedOut(
      orgPays &&
        ((meteredPayment &&
          (paymentMethods?.length ?? 0) === 0 &&
          organizationData?.automaticPayment) ||
          !meteredPayment)
        ? false
        : undefined,
    );
  }, [orgPays, meteredPayment, paymentMethods?.length, organizationData?.automaticPayment]);

  const navToNextStep = async () => {
    try {
      if (orgPays && primaryBillingUser.length === 0 && userData?.id) {
        await changePrimaryBillingUser.action(userData.id);
      }
      if (
        orgPays &&
        (organizationData?.automaticPayment || (prePaid && !orgSignUpState?.onboardingComplete))
      ) {
        const { data: stripeSession } = await adminPaymentApi.createSession(
          pricebook?.pricebook?.id ?? 0,
          licenseData?.quantity ?? 0,
        );
        await updateSignUpState.action({
          state: {
            ...orgSignUpState?.state,
            stripeSession: JSON.stringify(stripeSession),
            licenseData: JSON.stringify(licenseData),
            checkedOut: JSON.stringify(checkedOut),
          },
        });
        licenseDataCache.reload('licenseMetrics');

        if (stripeSession?.url) {
          window.location.replace(stripeSession.url);
        } else {
          const next = signUpWizard.nextStep(location);
          navigate(next.route);
        }
      } else if (orgSignUpState?.onboardingComplete) {

        await updateSignUpState.action({
          state: {
            ...orgSignUpState?.state,
            pricebook: JSON.stringify(pricebook),
          },
        });
        navigate('/congrats', {
          state: { quantity: licenseData?.quantity, chosenPackage: pricebook },
        });
      } else {
        const next = signUpWizard.nextStep(location);
        navigate(next.route);
      }
      setToast(
        new Toast({
          message: 'Contract successfully set up!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const navBack = async () => {
    try {
      setLoading(true);
      if (orgSignUpState?.onboardingComplete) {
        if (resumeAddPackage) {
          navigate('/');
        } else {
          if (!orgSignUpState.state?.stripeSession) {
            navigate(-1);
          } else {
            navigate('/addPackage');
          }
        }
      } else {
        const previousStep = signUpWizard.previousStep(location);
        navigate(previousStep?.route ?? '', { replace: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    if (isUpdating.current) return;

    setLoading(true);
    isUpdating.current = true;
    try {
      await updateSignUpState.action({
        lastCompletedStep: !orgSignUpState?.onboardingComplete
          ? signUpWizard.getCurrent({
              ...location,
              pathname: location.pathname.replace('addPackage', 'onboarding'),
            })
          : orgSignUpState.lastCompletedStep,
      });
    } catch (error) {
      console.error('Error on submit: ', error);
    } finally {
      isUpdating.current = false;
      navToNextStep();

      if (currentLicense?.hasAccepted && doc && licenseAgreementAcceptLog.get(doc.id)) {
        updateDateAccepted();
      }
    }
  };

  const onViewAgreement = () => {
    setOpen(true);
  };

  const onDownload = async () => {
    if (docUri) {
      const response = await fetch(docUri, {
        method: 'GET',
        headers: {
          'XSRF-TOKEN': Cookies.get('XSRF-TOKEN') ?? '',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        return setToast(
          new Toast({
            message: 'Error downloading file',
            severity: 'error',
            open: true,
          }),
        );
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${organizationData?.name} License Agreement.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  const onAgree = async () => {
    try {
      setLoading(true);
      if (doc) {
        setOpen(false);
        if (!licenseAgreementAcceptLog.get(doc.id)) {
          await acceptLicenseAgreement.action(doc.id);
          await licenseAgreementAcceptLogDataCache.reload('licenseAgreementAcceptLog');
          await licenseDataCache.reload('licenseAgreement');
        }

        await updateSignUpState.action({
          state: {
            ...orgSignUpState?.state,
            checkedOut: JSON.stringify(checkedOut),
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateDateAccepted = async () => {
    if (isUpdating.current) return;

    isUpdating.current = true;
    try {
      if (currentLicense?.hasAccepted && doc && licenseAgreementAcceptLog.get(doc.id)) {
        const { dateAccepted } = licenseAgreementAcceptLog.get(doc.id) ?? {};
        if (dateAccepted) {
          await updateSignUpState.action({
            state: {
              ...orgSignUpState?.state,
              checkedOut: JSON.stringify(checkedOut),
              dateAccepted: dateAccepted ?? '',
            },
          });
        }
      }
    } catch (error) {
      console.error('Error updating date accepted: ', error);
    } finally {
      isUpdating.current = false;
    }
  };

  return open ? (
    <LicenseModal
      open={open}
      setOpen={setOpen}
      docUri={docUri}
      reviewed={reviewed}
      onDownload={onDownload}
      onAgree={onAgree}
    />
  ) : (
    <Column style={{ margin: isMobile || isTablet ? '15px 20px' : '15px 100px' }}>
      {!resumeAddPackage ? (
        <>
          <StepsHeader
            activeStep={orgSignUpState?.onboardingComplete ? 2 : activeStep}
            totalSteps={orgSignUpState?.onboardingComplete ? 2 : totalSteps}
            headerSteps={steps}
          />
          <Spacer height='xs' />
          <Typography variant='h1' color='secondary'>
            Step {orgSignUpState?.onboardingComplete ? 2 : activeStep}: Contract
          </Typography>
        </>
      ) : (
        <>
          <Typography variant='h1' color='secondary'>
            Contract
          </Typography>
          <Spacer height='xs' />
          <Typography variant='body1' color='error.dark'>
            Please review your outstanding license agreement for {organizationData?.name}.
          </Typography>
        </>
      )}
      <Spacer height='sm' />

      <PaymentDetails
        selectedPricebook={pricebook}
        licenseQuantity={licenseData?.quantity?.toString() ?? ''}
        costPerLicense={licenseData?.cost}
        invoiceDate={licenseData?.invoiceDate}
        orgPays={orgPays}
        meteredPayment={meteredPayment}
        autoPay={organizationData?.automaticPayment}
        prePaid={prePaid}
      />
      <Spacer height='xs' />

      <ContractFooter
        meteredPayment={meteredPayment}
        autoPay={organizationData?.automaticPayment}
        orgPays={orgPays}
        prePaid={prePaid}
        invoiceDate={licenseData?.invoiceDate}
        tosLink={tosLink}
      />
      <Spacer height='md' />

      <ViewContractButton
        reviewed={reviewed}
        dateAccepted={orgSignUpState?.state?.dateAccepted}
        onViewAgreement={onViewAgreement}
      />
      <Spacer height='sm' />

      <ContractActionButtons
        agreeText={agreeText}
        resumeAddPackage={resumeAddPackage}
        reviewed={reviewed}
        navBack={navBack}
        onSubmit={onSubmit}
      />
      <Spacer height='sm' />
    </Column>
  );
};

export default Contract;
