import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { TeamData } from '../../models/TeamData';

export class TeamApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<TeamData>> {
    return this.axios.get('team');
  }

  getAll(): Promise<AxiosResponse<TeamData[]>> {
    return this.axios.get('team/all/by-group-id');
  }

  post(team: TeamData): Promise<AxiosResponse<TeamData>> {
    return this.axios.post('team', team);
  }

  update(team: TeamData): Promise<AxiosResponse<TeamData>> {
    return this.axios.put('team', team);
  }

  delete(id: number): Promise<AxiosResponse<TeamData>> {
    return this.axios.delete(`team/${id}`);
  }
}
