import { Autocomplete, TextField, InputAdornment, IconButton } from '@mui/material';
import { unionWith, isEqual, debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ApiKeys } from '../api/apis/ApiKeys';
import { SecuritiesApi, SecuritiesSearchRest } from '../api/apis/SecuritiesApi';
import { Svgs } from '../assets/svg';
import { useService } from '@aesop-fables/containr-react';

interface SecuritySearchProps {
  setSelectedSecurity: (security: SecuritiesSearchRest) => Promise<void>;
}

const SecuritySearch: React.FC<SecuritySearchProps> = ({ setSelectedSecurity }) => {
  const securitiesApi = useService<SecuritiesApi>(ApiKeys.Securities);

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [searchResults, setSearchResults] = useState<SecuritiesSearchRest[]>([]);

  const search = async () => {
    try {
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }
      const [names, symbols] = await Promise.all([
        securitiesApi.searchByName(searchTerm),
        securitiesApi.searchBySymbol(searchTerm),
      ]);
      const combine = unionWith(names.data, symbols.data, (a, b) => isEqual(a.id, b.id));
      const exactMatchTicker = combine.filter(a => a.ticker === searchTerm.toLocaleUpperCase());
      const exactMatchName = combine.filter(a => a.securityName === searchTerm);
      const sorted = combine.sort((a, b) => a.ticker?.localeCompare(b.ticker ?? '') ?? 0);

      const sortedArray = [...new Set([...exactMatchTicker, ...exactMatchName, ...sorted])];
      setSearchResults(sortedArray);
    } catch (error) {
      console.error(error);
      setSearchResults([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(debounce(search, 500), [searchTerm]);

  useEffect(() => {
    debounceSearch();

    return debounceSearch.cancel;
  }, [searchTerm, debounceSearch]);

  return (
    <Autocomplete
      sx={{ flex: '3 0 10px' }}
      options={searchResults}
      getOptionLabel={(option: SecuritiesSearchRest | undefined) => {
        const ticker = option?.ticker ?? '';
        const name = option?.securityName ?? '';
        return name + ' (' + ticker + ')';
      }}
      filterOptions={option => option}
      onChange={(event, newValue) => {
        if (newValue) {
          setSelectedSecurity(newValue);
          setSearchTerm(undefined);
        }
      }}
      inputValue={searchTerm ? searchTerm : ''}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          onChange={event => setSearchTerm(event.target.value)}
          variant='standard'
          sx={{
            label: { color: '#022a3a !important', fontSize: 18, fontWeight: 600 },
            input: { paddingTop: '15px' },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start' style={{ marginRight: '10px' }}>
                <Svgs.IconSearch />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  color='primary'
                  style={{ marginRight: '-35px' }}
                  onClick={() => setSearchTerm(undefined)}>
                  <Svgs.IconDelete />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder='Search by investment name or ticker'
        />
      )}
    />
  );
};

export default SecuritySearch;
