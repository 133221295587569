import { TeamData } from './../../models/TeamData';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, map } from 'rxjs';
import { UserData, UserRole } from '../../models/UserData';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { UserApi } from '../../api/apis/UserApi';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { OrganizationData } from '../../models/OrganizationData';
import { TeamCompartments, teamStorageKey } from '../team';
import { GroupData } from '../../models/GroupData';
import { UserCompartments, userStorageKey } from '../user';
import { GroupCompartments, groupStorageKey } from '../group';

export interface AdminCompartments {
  groupAdmins: DataCompartmentOptions<UserData[] | undefined>;
  teamAdmins: DataCompartmentOptions<UserData[] | undefined>;
  orgAdmins: DataCompartmentOptions<UserData[] | undefined>;
}

export const adminStorageKey = 'data/admin';

export class AdminDataCachePolicy extends DataCachePolicy<AdminCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.User)
    private readonly userApi: UserApi,
  ) {
    super(adminStorageKey);
  }

  buildDataCache() {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const groupDataCache = this.appStorage.retrieve<GroupCompartments>(groupStorageKey);
    const teamDataCache = this.appStorage.retrieve<TeamCompartments>(teamStorageKey);
    const orgDataCache = this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

    const groupDependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.observe$<UserRole[]>('userRoles'),
      groupDataCache.observe$<GroupData>('group'),
    ]).pipe(
      map(([isAuthenticated, roles, groupData]) => {
        return isAuthenticated === true && roles !== undefined && groupData !== undefined;
      }),
    );

    const teamDependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.observe$<UserRole[]>('userRoles'),
      teamDataCache.observe$<TeamData>('team'),
      groupDataCache.observe$<GroupData>('group'),
    ]).pipe(
      map(
        ([isAuthenticated, roles, teamData, groupData]) =>
          isAuthenticated === true &&
          roles !== undefined &&
          teamData !== undefined &&
          groupData !== undefined,
      ),
    );

    const orgDependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.observe$<UserRole[]>('userRoles'),
      orgDataCache.observe$<OrganizationData>('organization'),
    ]).pipe(
      map(
        ([isAuthenticated, roles, orgData]) =>
          isAuthenticated === true && roles !== undefined && orgData !== undefined,
      ),
    );

    return createObservedDataCache<AdminCompartments>({
      groupAdmins: {
        dependsOn: groupDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            if (roles.some(x => x.role?.includes(`GROUP_ADMIN_${group.id}`))) {
              const { data } = await this.userApi.getGroupUsers();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      teamAdmins: {
        dependsOn: teamDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            const team = (
              teamDataCache.findCompartment('team') as DataCompartment<TeamData>
            ).getData() as TeamData;
            if (
              roles.some(
                x =>
                  x.role?.includes(`TEAM_ADMIN_${team.id}`) ||
                  x.role?.includes(`GROUP_ADMIN_${group.id}`),
              )
            ) {
              const { data } = await this.userApi.getTeamUsers();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      orgAdmins: {
        dependsOn: orgDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.userApi.getOrgUsers();
          if (!data) {
            return undefined;
          }
          return data;
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withAdminData = createServiceModule(adminStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, AdminDataCachePolicy);
});
