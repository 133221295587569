import { Button, Card, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { Page, Row, Spacer, Column } from '../../components';
import PayrollDeductionCard from '../organization/PayrollDeductionCard';

const EmptyPlan = () => {
  const navigate = useNavigate();

  return (
    <Page title='Plan info'>
      <Spacer height='xxs' />
      <Row style={{ maxWidth: 895, justifyContent: 'space-between' }}>
        <Typography variant='p18Light' color='info.main'>
          Does your organization provide a retirement plan for their members? You can enter plan
          information here and make it easier for your members to get advice on their accounts.
        </Typography>
      </Row>
      <Spacer height='xs' />
      <Card variant='ghost' color='primary'>
        <CardContent className='pad30'>
          <Typography variant='p20SemiBold' color='secondary.main'>
            In order to add your plan info, follow these steps:
          </Typography>
          <Spacer height='xs' />
          <Column>
            <Row style={{ alignItems: 'center' }}>
              <Svgs.BulletInvestment style={{ paddingRight: '10px' }} />
              <Typography variant='p18Light' color='secondary'>
                1. Create your investment line-up
              </Typography>
            </Row>
            <Spacer height='xs' />
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Row style={{ alignItems: 'center' }}>
                <Svgs.BulletDocument style={{ paddingRight: '10px' }} />
                <Typography variant='p18Light' color='secondary'>
                  2. Enter your plan rules
                </Typography>
              </Row>
              <Button
                style={{ width: '185px' }}
                onClick={() => navigate('/plan-info/investment-lineup')}>
                Start
              </Button>
            </Row>
          </Column>
        </CardContent>
      </Card>
      <Spacer height='xs' />
      <PayrollDeductionCard />
    </Page>
  );
};

export default EmptyPlan;
