import { useTheme } from '@mui/material';

interface FormErrorProps {
  err: string;
}

const FormError: React.FC<FormErrorProps> = ({ err }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        color: theme.palette.error.main,
        fontSize: '14px',
        textAlign: 'center',
        lineHeight: '1.5',
        marginBottom: '20px',
      }}>
      {err || 'Oops! Something went wrong, please try again later'}
    </div>
  );
};

export default FormError;
