import { ApiKeys } from '../../api/apis/ApiKeys';
import { UserApi } from '../../api/apis/UserApi';
import {
  DataCompartmentOptions,
  DataCache,
  createObservedDataCache,
  ConfiguredDataSource,
} from '@aesop-fables/scrinium';
import { inject, createServiceModule } from '@aesop-fables/containr';
import { UserData, UserRole } from '../../models/UserData';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';

export interface UserCompartments {
  user: DataCompartmentOptions<UserData | undefined>;
  userRoles: DataCompartmentOptions<UserRole[] | undefined>;
}

export const userStorageKey = 'data/user';

export class UserDataCachePolicy extends DataCachePolicy<UserCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ApiKeys.User)
    private readonly userApi: UserApi,
  ) {
    super(userStorageKey);
  }

  buildDataCache(): DataCache<UserCompartments> {
    return createObservedDataCache<UserCompartments>({
      user: {
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const response = await this.userApi.get();
            this.authContext.setIsAuthenticated(true);
            return response.data;
          } catch (err) {
            console.error('User is not authenticated');
          } finally {
            this.authContext.setAuthReady(true);
          }
        }),
        defaultValue: undefined,
      },
      userRoles: {
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const response = await this.userApi.getRole();
            return response.data;
          } catch (err) {
            console.error('User is not authenticated');
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withUserData = createServiceModule(userStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, UserDataCachePolicy);
});
