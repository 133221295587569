import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { BaseReportData } from '../../models/ReportData';

export class ReportApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getOrgReports(date: any): Promise<AxiosResponse<BaseReportData>> {
    return this.axios.get(`/organization-report/by-organization-id/by-date?date=${date}`);
  }

  getTeamReports(date: any): Promise<AxiosResponse<BaseReportData>> {
    return this.axios.get(`/organization-report/by-team-id/by-date?date=${date}`);
  }

  getGroupReports(date: any): Promise<AxiosResponse<BaseReportData>> {
    return this.axios.get(`/organization-report/by-group-id/by-date?date=${date}`);
  }

  post(): Promise<AxiosResponse<BaseReportData>> {
    return this.axios.post('/organization-report/regenerate-organization-report');
  }

  getSummedTeamReport(date: any): Promise<AxiosResponse<BaseReportData>> {
    return this.axios.get(`/organization-report/summed-team-report/by-date?date=${date}`);
  }

  getSummedGroupReport(date: any): Promise<AxiosResponse<BaseReportData>> {
    return this.axios.get(`/organization-report/summed-group-report/by-date?date=${date}`);
  }

  getEarliestDate(): Promise<AxiosResponse<string>> {
    return this.axios.get(`organization-report/earliest-date`);
  }
}
