import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { UserData } from '../../models/UserData';

export class LicenseAgreementAcceptLogApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getByDocId(id: number): Promise<AxiosResponse<LicenseAgreementAcceptLogData>> {
    return this.axios.get(`license-agreement-accept-log/by-doc-id/by-org-id?docId=${id}`);
  }
}

export interface LicenseAgreementAcceptLogData {
  id: number;
  user: UserData;
  organizationId: number;
  dateAccepted: string;
  docId: number;
}
