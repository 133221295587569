import { Typography, Card, CardContent } from '@mui/material';
import { Column, Row, Spacer, HorizontalFormInput } from '../../components';
import { formatTwoDecimalPlaces } from '../../helpers/utilityFunctions';
import { Form } from 'react-final-form';
import { PricebookBundle } from '../../models/PricebookData';

interface PaymentDetailsProps {
  selectedPricebook: PricebookBundle | undefined;
  licenseQuantity: string;
  costPerLicense: number | undefined;
  invoiceDate: string;
  orgPays: boolean;
  meteredPayment: boolean | undefined;
  autoPay: boolean | undefined;
  prePaid: boolean;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  selectedPricebook,
  licenseQuantity,
  costPerLicense,
  invoiceDate,
  orgPays,
  meteredPayment,
  autoPay,
  prePaid,
}) => {
  const price =
    costPerLicense ??
    (selectedPricebook?.prices?.[0].price && selectedPricebook?.prices?.[0].price / 100) ??
    0;

  return (
    <Card
      variant='primaryLight'
      style={{
        paddingTop: 25,
        paddingBottom: 25,
        alignSelf: 'center',
        width: '70%',
        display: 'flex',
      }}>
      <Column
        style={{
          display: 'flex',
          flex: 1,
        }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <Form onSubmit={() => {}}>
            {() => (
              <Column
                style={{
                  width: '60%',
                }}>
                <Row>
                  <Typography
                    variant='p18SemiBold'
                    color='secondary.active'
                    sx={{ width: '56%', textAlign: 'right' }}>
                    {orgPays && meteredPayment && autoPay
                      ? 'Auto-pay'
                      : orgPays
                      ? 'Payment'
                      : 'Member payment'}{' '}
                    details
                  </Typography>
                  <Spacer height='md' />
                </Row>
                <HorizontalFormInput
                  readonly={true}
                  inputStyle={{ flex: 1.5 }}
                  initialValue={selectedPricebook?.pricebook?.pricebookName}
                  label='Package'
                  fieldName='package'
                  rightAlignedKey={true}
                />

                {orgPays && (prePaid ? !autoPay : true) && (
                  <>
                    <Spacer height='xxs' />
                    <HorizontalFormInput
                      readonly={true}
                      inputStyle={{ flex: 1.5 }}
                      initialValue={invoiceDate}
                      label={
                        orgPays && meteredPayment && autoPay ? 'Auto-payment date' : 'Invoice date'
                      }
                      fieldName={
                        orgPays && meteredPayment && autoPay ? 'autoPaymentDate' : 'invoiceDate'
                      }
                      rightAlignedKey={true}
                    />
                  </>
                )}

                {orgPays && prePaid && (
                  <>
                    <Spacer height='xxs' />
                    <HorizontalFormInput
                      readonly={true}
                      inputStyle={{ flex: 1.5 }}
                      initialValue={licenseQuantity}
                      label='Number of licenses'
                      fieldName='numberOfLicenses'
                      rightAlignedKey={true}
                    />
                  </>
                )}

                <Spacer height='xxs' />
                <HorizontalFormInput
                  readonly={true}
                  inputStyle={{ fontWeight: 'bold', flex: 1.5 }}
                  initialValue={`$${price}/${
                    selectedPricebook?.prices?.[0].intervalType === 'YEAR' ? 'yr' : 'mo'
                  }`}
                  label='Cost per license per member'
                  fieldName='costPerLicense'
                  rightAlignedKey={true}
                />
              </Column>
            )}
          </Form>
          <Column
            style={{
              width: '45%',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {orgPays ? (
              <Card
                variant='ghost'
                style={{
                  minWidth: '200px',
                  maxHeight: '100px',
                }}>
                <CardContent
                  style={{
                    height: '100%',
                  }}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Typography color='info.main' style={{ display: 'inline' }} variant='subtitle1'>
                      $
                    </Typography>
                    <Typography
                      color='secondary.dark'
                      style={{ display: 'inline' }}
                      variant='subtitle1'>
                      {meteredPayment
                        ? 0
                        : formatTwoDecimalPlaces(parseInt(licenseQuantity) * price)}
                    </Typography>
                  </Row>
                  <Row style={{ justifyContent: 'center' }}>
                    <Typography variant='p12SemiBold' color='secondary.main'>
                      {(prePaid && !autoPay ? 'total cost' : 'due today') + '*'}
                    </Typography>
                  </Row>
                </CardContent>
              </Card>
            ) : (
              <Card
                variant='ghost'
                style={{
                  minWidth: '200px',
                  maxHeight: '50px',
                }}>
                <CardContent>
                  <Column
                    style={{
                      alignItems: 'center',
                    }}>
                    <Typography variant='p12SemiBold' color='secondary.main'>
                      Member pays
                    </Typography>
                  </Column>
                </CardContent>
              </Card>
            )}
          </Column>
        </Row>
      </Column>
    </Card>
  );
};

export default PaymentDetails;
