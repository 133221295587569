import { Svgs } from '../assets/svg';
import { ChartBarData } from '../components/ChartBar';
import { ProductTypeEnum } from '../models/PricebookData';
import {
  AnyReportType,
  PricebookReport,
  ProgressData,
  Progress3nuData,
  ReportChart,
} from '../models/ReportData';

// Enums and Types
export enum AccountState {
  activeUserCount = 'Active',
  ineligibleCount = 'Ineligible',
  invitedCount = 'Invited',
  freeTrialPaymentPlatformCount = 'In trial period',
  unsubscribedCount = 'Unsubscribed',
  undecidedCount = 'Undecided',
  unknownIneligibleCount = 'Ineligible',
}

export interface UserDataTableRow {
  rowKey: string;
  totalCount: number | null;
  unknownCount: number | null;
  [key: string]: string | number | null;
}

export type AccountType = 'Subscription' | 'Free';

// Constants
const paidProducts: ProductTypeEnum[] = [
  ProductTypeEnum.GuidedChoice,
  ProductTypeEnum.Nickels,
  ProductTypeEnum.NickelsU,
];

// Utility Functions
export const getProductTypeKey = (productType: ProductTypeEnum | undefined): string => {
  if (!productType) return '';
  return productType === ProductTypeEnum.Nickels || productType === ProductTypeEnum.NickelsU
    ? '3Nickels'
    : productType?.charAt(0).toUpperCase() +
        productType
          .slice(1)
          .toLowerCase()
          .replace(/_./g, match => match[1].toUpperCase());
};

export const isPaidProduct = (productType: ProductTypeEnum): boolean => {
  return paidProducts.includes(productType);
};

export const getAccountType = (productType: ProductTypeEnum): AccountType => {
  return isPaidProduct(productType) ? 'Subscription' : 'Free';
};

export const getPricebookReportsByType = (
  report: AnyReportType,
  productTypes?: ProductTypeEnum | ProductTypeEnum[],
): PricebookReport[] => {
  if (!report) return [];

  let productTypesArray: ProductTypeEnum[] = [];
  if (productTypes) {
    productTypesArray = Array.isArray(productTypes) ? productTypes : [productTypes];
  }
  const filterByProductType = (pb: PricebookReport) =>
    !productTypesArray.length || productTypesArray.includes(pb.productType);

  if ('pricebookReports' in report) {
    return report.pricebookReports?.filter(filterByProductType) || [];
  }

  if ('organizationReports' in report) {
    return (
      report.organizationReports?.flatMap(
        org => org.pricebookReports?.filter(filterByProductType) || [],
      ) || []
    );
  }

  if ('teamReports' in report) {
    return (
      report.teamReports?.flatMap(
        team => team.pricebookReports?.filter(filterByProductType) || [],
      ) || []
    );
  }

  return [];
};

export const getIndividualReports = (report: AnyReportType): AnyReportType[] => {
  if ('organizationReports' in report) {
    return report.organizationReports;
  } else if ('teamReports' in report) {
    return report.teamReports;
  } else {
    return [report];
  }
};

export const getUniqueProductTypes = (report: AnyReportType | null): string[] => {
  if (!report) return [];

  const pricebookReports = getPricebookReportsByType(report);
  if (!pricebookReports.some(product => product.productType)) return [];

  return [...new Set(pricebookReports.map(report => getProductTypeKey(report?.productType)))];
};

export const transformReportData = (report: AnyReportType | null): UserDataTableRow[] => {
  if (!report) return [];

  const pricebookReports = getPricebookReportsByType(report);
  const rows = initializeRows();

  populateRowsWithData(rows, pricebookReports);
  populateUnknownColumn(report, rows);

  return calculateTotals(rows);
};

export const alphabetizeReports = (reports: AnyReportType[]): AnyReportType[] => {
  return reports.sort((a, b) => {
    const nameA = 'name' in a ? a.name : '';
    const nameB = 'name' in b ? b.name : '';
    return nameA.localeCompare(nameB);
  });
};

export const paginateReports = (
  reports: AnyReportType[],
  page: number,
  pageSize: number,
): AnyReportType[] => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return reports.slice(startIndex, endIndex);
};

export const sumNestedProperty = (obj: any, property: string): number => {
  if (typeof obj !== 'object' || obj === null) {
    return 0;
  }

  let sum = 0;

  if (property in obj && typeof obj[property] === 'number') {
    sum += obj[property];
  }

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      sum += sumNestedProperty(obj[key], property);
    }
  }

  return sum;
};

export const sumPropertyInArray = (array: any[], property: string): number => {
  if (!Array.isArray(array)) {
    return 0;
  }

  return array.reduce((sum, item) => {
    if (item && typeof item === 'object' && property in item) {
      const value = item[property];
      return sum + (typeof value === 'number' ? value : 0);
    }
    return sum;
  }, 0);
};

export const getIcon = (accountType: AccountState) => {
  switch (accountType) {
    case AccountState.activeUserCount:
      return <Svgs.IconStatusActive />;
    case AccountState.invitedCount:
      return <Svgs.IconStatusInvited />;
    case AccountState.ineligibleCount:
    case AccountState.unknownIneligibleCount:
      return <Svgs.IconStatusIneligible />;
    case AccountState.freeTrialPaymentPlatformCount:
      return <Svgs.IconStatusTrial />;
    case AccountState.undecidedCount:
      return <Svgs.IconStatusUndecided />;
    case AccountState.unsubscribedCount:
      return <Svgs.IconStatusUnsubscribed />;
    default:
      return null;
  }
};

export const getReportChartData = (
  report: AnyReportType,
  keyFn: (pricebookReport: PricebookReport) => string,
  dataMap: Map<keyof PricebookReport, string>,
): ReportChart => {
  const result: ReportChart = {};
  const categories = [...dataMap.values()];
  const pricebookReports = getPricebookReportsByType(report);

  pricebookReports.forEach((pricebookReport: PricebookReport) => {
    if (!pricebookReport) return;

    const key = keyFn(pricebookReport);

    const hasData = Array.from(dataMap.keys()).some(dataKey => {
      const value = pricebookReport[dataKey];
      return value !== null && value !== 0;
    });

    if (hasData) {
      if (!result[key]) {
        result[key] = initializeChartData(pricebookReport, dataMap);
      } else {
        result[key] = updateChartData(result[key], pricebookReport, dataMap);
      }
    }
  });

  // create empty 'Default' entry if no data
  if (Object.keys(result).length === 0) {
    result['Default'] = categories.map(category => ({ x: category, y: 0 }));
  }

  return result;
};

// Helper Functions
const safeIncrement = (current: number | string | null, increment: number): number => {
  if (current === null) {
    return increment;
  }
  return (typeof current === 'number' ? current : 0) + increment;
};

const initializeRows = (): Record<string, UserDataTableRow> => {
  const rows: Record<string, UserDataTableRow> = Object.values(AccountState).reduce(
    (acc, state) => {
      acc[state] = { rowKey: state } as UserDataTableRow;
      return acc;
    },
    {} as Record<string, UserDataTableRow>,
  );
  return rows;
};

const populateRowsWithData = (
  rows: Record<string, UserDataTableRow>,
  pricebookReports: PricebookReport[],
) => {
  pricebookReports.forEach(pricebookReport => {
    if (pricebookReport) {
      const productType = getProductTypeKey(pricebookReport.productType);
      Object.entries(pricebookReport).forEach(([key, value]) => {
        if (typeof value === 'number' && key in AccountState) {
          const accountState = key as keyof typeof AccountState;
          const rowKey = AccountState[accountState];
          rows[rowKey][productType] = safeIncrement(rows[rowKey][productType], value);
          if (['invitedCount', 'unknownIneligibleCount', 'undecidedCount'].includes(key)) {
            rows[rowKey].unknownCount = safeIncrement(rows[rowKey].unknownCount, value);
          }
        }
      });
    }
  });
};

const populateUnknownColumn = (report: AnyReportType, rows: Record<string, UserDataTableRow>) => {
  const properties = ['invitedCount', 'unknownIneligibleCount', 'undecidedCount'];
  properties.forEach(prop => {
    const count = sumNestedProperty(report, prop);
    if (count) {
      const rowKey =
        prop === 'unknownIneligibleCount'
          ? AccountState.ineligibleCount
          : AccountState[prop as keyof typeof AccountState];
      rows[rowKey].unknownCount = safeIncrement(rows[rowKey].unknownCount, count);
    }
  });
};

const calculateTotals = (rows: Record<string, UserDataTableRow>): UserDataTableRow[] => {
  return Object.values(rows)
    .filter(row => Object.entries(row).some(([key, value]) => key !== 'rowKey' && value !== null))
    .map(row => ({
      ...row,
      totalCount: Object.entries(row).reduce(
        (sum, [key, val]) =>
          key !== 'rowKey' && key !== 'totalCount' && typeof val === 'number' ? sum + val : sum,
        0,
      ),
    }));
};

const initializeChartData = (
  pricebookReport: PricebookReport,
  dataMap: Map<keyof PricebookReport, string>,
): ChartBarData[] => {
  return Array.from(dataMap).map(([key, label]) => ({
    x: label,
    y: (pricebookReport[key] as number | null) ?? 0,
  }));
};

const updateChartData = (
  prevData: ChartBarData[],
  pricebookReport: PricebookReport,
  dataMap: Map<keyof PricebookReport, string>,
): ChartBarData[] => {
  if (!pricebookReport) return prevData;

  const prevDataMap = new Map(prevData.map(entry => [entry.x, entry.y]));

  return Array.from(dataMap).map(([key, label]) => {
    const accumulatedVal = prevDataMap.get(label) ?? 0;
    const newVal = pricebookReport[key] as number | null;
    const updatedVal = (newVal ?? 0) + accumulatedVal;

    return {
      x: label,
      y: updatedVal,
    };
  });
};

function hasTypeData<T>(report: AnyReportType, keys: (keyof T)[]): boolean {
  const hasTopLevelData = keys.some(key => report[key as keyof AnyReportType] != null);
  if (hasTopLevelData) return true;

  const pricebookReports = getPricebookReportsByType(report);
  return pricebookReports.some(pricebookReport =>
    keys.some(key => pricebookReport[key as keyof PricebookReport] != null),
  );
}

export const hasProgressData = (report: AnyReportType): boolean => {
  const progressKeys: (keyof ProgressData)[] = [
    'acceptedTosCount',
    'onboardingCompletedCount',
    'budgetDetailsCompletedCount',
    'accountDetailsCompleteCount',
    'personalDetailsCompleteCount',
  ];

  const progress3nuKeys: (keyof Progress3nuData)[] = [
    'notStarted3nuCount',
    'zeroToTwentyFour3nuCount',
    'twentyFiveToSeventyFive3nuCount',
    'seventySixToNinetyNine3nuCount',
    'nuCompletedCount',
    'average3nuProgress',
  ];

  const hasProgressData = hasTypeData<ProgressData>(report, progressKeys);
  const hasProgress3nuData = hasTypeData<Progress3nuData>(report, progress3nuKeys);

  return hasProgressData || hasProgress3nuData;
};
