import { Typography, Card, CardContent } from '@mui/material';
import React from 'react';
import { Spacer, Row } from '../../components';

const RecentActivity: React.FC = () => {
  /* TODO: there are no designs for this section yet ; fill in later */
  return (
    <>
      <Typography variant='h1' color='secondary'>
        Recent activity
      </Typography>
      <Spacer height='xxs' />
      <Card variant='ghost' color='primary'>
        <CardContent className='basicCard'>
          <Row style={{ justifyContent: 'center' }}>
            <Typography variant='h3' color='secondary'>
              You're all caught up!
            </Typography>
          </Row>
        </CardContent>
      </Card>
    </>
  );
};

export default RecentActivity;
