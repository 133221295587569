import React from 'react';
import ChartBar from '../../../components/ChartBar';
import { AccountCreationData, ReportProps } from '../../../models/ReportData';
import useChartTheme from '../../../themes/chart';
import { getAccountType, getReportChartData } from '../../../helpers/reportUtils';

const creationMethodMap = new Map<keyof AccountCreationData, string>([
  ['accountCreationMethodManualCount', 'Manual'],
  ['accountCreationMethodAppleCount', 'Apple SSO'],
  ['accountCreationMethodGoogleCount', 'Google SSO'],
]);

const CreationMethodChart: React.FC<ReportProps> = ({ report }) => {
  const { chartColors } = useChartTheme();
  const chartData = getReportChartData(
    report,
    pricebookReport => getAccountType(pricebookReport?.productType),
    creationMethodMap,
  );

  const dataSeries = Object.entries(chartData).map(([accountType, data]) => ({
    data,
    color: chartColors[accountType as keyof typeof chartColors],
    label: accountType,
  }));

  return (
    <ChartBar
      title='Users per account creation method'
      dataSeries={dataSeries}
      yAxisLabel='Count per method'
      seriesLabel='Account type'
    />
  );
};

export default CreationMethodChart;
