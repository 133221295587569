import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
  ConfiguredDataSource,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, map } from 'rxjs';
import { UserCompartments, userStorageKey } from '../user';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { TeamData } from '../../models/TeamData';
import { TeamApi } from '../../api/apis/TeamApi';
import { UserRole } from '../../models/UserData';
import { TeamMetricsData } from '../../models/MetricsData';
import { TeamMetricsApi } from '../../api/apis/TeamMetricsApi';
import { GroupData } from '../../models/GroupData';
import { GroupCompartments, groupStorageKey } from '../group';

export interface TeamCompartments {
  team: DataCompartmentOptions<TeamData | undefined>;
  allTeams: DataCompartmentOptions<TeamData[] | undefined>;
  allMetrics: DataCompartmentOptions<TeamMetricsData[] | undefined>;
}

export const teamStorageKey = 'data/team';

export class TeamDataCachePolicy extends DataCachePolicy<TeamCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.Team)
    private readonly teamApi: TeamApi,
    @inject(ApiKeys.TeamMetrics)
    private readonly teamMetricsApi: TeamMetricsApi,
  ) {
    super(teamStorageKey);
  }

  buildDataCache() {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const groupDataCache = this.appStorage.retrieve<GroupCompartments>(groupStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.observe$<UserRole[]>('userRoles'),
    ]).pipe(map(([isAuthenticated, roles]) => isAuthenticated === true && roles !== undefined));

    const groupDependsOn = groupDataCache
      .observe$<GroupData>('group')
      .pipe(map(groupData => groupData !== undefined));

    return createObservedDataCache<TeamCompartments>({
      team: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.teamApi.get();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allTeams: {
        dependsOn: groupDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.teamApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allMetrics: {
        dependsOn: groupDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.teamMetricsApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withTeamData = createServiceModule(teamStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, TeamDataCachePolicy);
});
