import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createObservedDataCache,
  ScriniumServices,
} from '@aesop-fables/scrinium';
import { inject, createServiceModule } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { combineLatest, map } from 'rxjs';
import { GroupApi } from '../../api/apis/GroupApi';
import { UserCompartments, userStorageKey } from '../user';
import { GroupData } from '../../models/GroupData';
import { UserRole } from '../../models/UserData';
import { GroupMetricsData } from '../../models/MetricsData';
import { GroupMetricsApi } from '../../api/apis/GroupMetricsApi';

export interface GroupCompartments {
  group: DataCompartmentOptions<GroupData | undefined>;
  allGroups: DataCompartmentOptions<GroupData[] | undefined>;
  allMetrics: DataCompartmentOptions<GroupMetricsData[] | undefined>;
}

export const groupStorageKey = 'data/group';

export class GroupDataCachePolicy extends DataCachePolicy<GroupCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.Group)
    private readonly groupApi: GroupApi,
    @inject(ApiKeys.GroupMetrics)
    private readonly groupMetricsApi: GroupMetricsApi,
  ) {
    super(groupStorageKey);
  }

  buildDataCache() {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.observe$<UserRole[]>('userRoles'),
    ]).pipe(map(([isAuthenticated, roles]) => isAuthenticated === true && roles !== undefined));

    return createObservedDataCache<GroupCompartments>({
      group: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.groupApi.get();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allGroups: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.groupApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allMetrics: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.groupMetricsApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withGroupData = createServiceModule(groupStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, GroupDataCachePolicy);
});
