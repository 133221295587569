import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { formatDollars } from '../../../helpers/utilityFunctions';

interface ProductDetailsProps {
  productName?: string;
  quantity?: number;
  unitPrice?: number;
  total?: number;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  productName,
  quantity,
  unitPrice,
  total,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow sx={{ border: 'none' }}>
            <TableCell variant='dataHead' style={{ width: '155px' }}>
              Product details
            </TableCell>
            <TableCell variant='dataHead'>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant='dataBodyKey'>Product name</TableCell>
            <TableCell variant='dataBodyValue'>{productName}</TableCell>
          </TableRow>
          {/* TODO: Wire up when backend provides quantity */}
          {/* <TableRow>
            <TableCell variant='dataBodyKey'>Quantity</TableCell>
            <TableCell variant='dataBodyValue'>{quantity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='dataBodyKey'>Unit price</TableCell>
            <TableCell variant='dataBodyValue'>{formatDollars(unitPrice ?? 0)}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell variant='dataBodyKey'>Total</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} variant='dataBodyValue'>
              {formatDollars(total ?? 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductDetails;
