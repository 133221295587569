export enum StepsHeaderTypes {
  Onboarding = 'Onboarding',
  AdpOnboarding = 'AdpOnboarding',
  DefaultOffboarding = 'DefaultOffboarding',
  MemberPaysOffboarding = 'MemberPaysOffboarding',
  AddPackage = 'AddPackage',
  InvestmentLineup = 'InvestmentLineup',
}
export const StepSequences = {
  [StepsHeaderTypes.Onboarding]: ['About your org', 'Admins', 'Product', 'Licenses', 'Contract'],
  [StepsHeaderTypes.AdpOnboarding]: ['Deductions', 'Contract'],
  [StepsHeaderTypes.DefaultOffboarding]: ['Logo', 'Member access', 'Custom URL'],
  [StepsHeaderTypes.MemberPaysOffboarding]:  ['Logo', 'Custom URL'],
  [StepsHeaderTypes.AddPackage]: ['Package', 'Contract'],
  [StepsHeaderTypes.InvestmentLineup]: [
    'Investments',
    'Plan basics',
    'Employer contributions',
    'Member contributions',
  ],
};
