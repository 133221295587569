import { OrganizationMetadata } from './../../../models/OrganizationMetadata';
import { OrganizationMetadataCompartments, organizationMetadataStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { OrganizationMetadataApi } from '../../../api/apis/OrganizationMetadataApi';

export class CreateCustomBlurb implements IMutation<OrganizationMetadata> {
  async execute(context: MutationContext<OrganizationMetadata>): Promise<void> {
    const { data, storage, container } = context;
    const orgMetadataApi = container.get<OrganizationMetadataApi>(ApiKeys.OrganizationMetadata);

    await orgMetadataApi.post(data);

    const cache = storage.retrieve<OrganizationMetadataCompartments>(
      organizationMetadataStorageKey,
    );
    await cache.reloadAll();
  }
}

export class UpdateCustomBlurb implements IMutation<OrganizationMetadata> {
  async execute(context: MutationContext<OrganizationMetadata>): Promise<void> {
    const { data, storage, container } = context;
    const orgMetadataApi = container.get<OrganizationMetadataApi>(ApiKeys.OrganizationMetadata);

    await orgMetadataApi.replace(data);

    const cache = storage.retrieve<OrganizationMetadataCompartments>(
      organizationMetadataStorageKey,
    );
    await cache.reloadAll();
  }
}

export class RemoveCustomBlurb implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { storage, container } = context;
    const orgMetadataApi = container.get<OrganizationMetadataApi>(ApiKeys.OrganizationMetadata);

    await orgMetadataApi.delete();

    const cache = storage.retrieve<OrganizationMetadataCompartments>(
      organizationMetadataStorageKey,
    );
    await cache.reloadAll();
  }
}
