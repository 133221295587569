import { Box, Drawer, Typography } from '@mui/material';
import { Spacer } from '.';

export interface RightDrawerProps extends React.PropsWithChildren {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  innerStyles?: React.CSSProperties;
  smallTitle?: string;
  title?: string;
}

const RightDrawer: React.FC<RightDrawerProps> = props => {
  const { open, setOpen, smallTitle, title } = props;
  const handleClose = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
  };

  return (
    <Drawer
      sx={{
        zIndex: `${20} !important` /* if you touch this, make sure you haven't broken the edit org info drawer */,
      }}
      anchor='right'
      open={open}
      onClose={handleClose}>
      <Box
        role='presentation'
        sx={{
          width: '700px',
          padding: '20px',
          ...props.innerStyles,
        }}>
        {smallTitle ? (
          <>
            <Typography color='info.main' variant='p14'>
              {smallTitle}
            </Typography>
            <Spacer height='xxs' />
          </>
        ) : null}
        {title ? (
          <>
            <Typography color='secondary.main' variant='p30'>
              {title}
            </Typography>
            <Spacer height='xxs' />
          </>
        ) : null}
        {props.children}
      </Box>
    </Drawer>
  );
};
export default RightDrawer;
