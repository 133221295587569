import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';

export class GroupFeatureMapApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<GroupFeatureMapData>> {
    return this.axios.get('group-feature-map');
  }
}
