import ChartBar from '../../../components/ChartBar';
import { LoginCountData, ReportProps } from '../../../models/ReportData';
import useChartTheme from '../../../themes/chart';
import { getReportChartData, getAccountType } from '../../../helpers/reportUtils';

const loginCountMap = new Map<keyof LoginCountData, string>([
  ['webLoginsCount', 'Desktop'],
  ['appleMobileLoginsCount', 'Apple (mobile)'],
  ['appleWebLoginsCount', 'Apple (web)'],
  ['androidMobileLoginsCount', 'Android (mobile)'],
  ['androidWebLoginsCount', 'Android (web)'],
]);

const LoginCountChart: React.FC<ReportProps> = ({ report }) => {
  const { chartColors } = useChartTheme();
  const chartData = getReportChartData(
    report,
    pricebookReport => getAccountType(pricebookReport?.productType),
    loginCountMap,
  );

  const dataSeries = Object.entries(chartData).map(([accountType, data]) => ({
    data,
    color: chartColors[accountType as keyof typeof chartColors],
    label: accountType,
  }));

  return (
    <ChartBar
      title='All-time count of logins per platform'
      dataSeries={dataSeries}
      yAxisLabel='Count per platform'
      seriesLabel='Account type'
      padding={{ bottom: 85 }}
    />
  );
};

export default LoginCountChart;
