import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { UserCompartments, userStorageKey } from '../../user';
import { MembersApi } from '../../../api/apis/MembersApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { MemberCompartments, membersStorageKey } from '..';
import { LicenseCompartments, licenseStorageKey } from '../../license';

export class AddAMember implements IMutation<any> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const membersApi = container.get<MembersApi>(ApiKeys.Members);

    await membersApi.addAMember({ ...data });

    const dataCache = storage.retrieve<MemberCompartments>(membersStorageKey);
    await dataCache.reload('members');
  }
}

export class RemoveAMember implements IMutation<any> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const membersApi = container.get<MembersApi>(ApiKeys.Members);

    await membersApi.removeMember(data);

    const dataCache = storage.retrieve<MemberCompartments>(membersStorageKey);
    const licenseDataCache = storage.retrieve<LicenseCompartments>(licenseStorageKey);
    await dataCache.reload('members');
    await licenseDataCache.reload('licenseMetrics');
  }
}

export class EditAMember implements IMutation<any> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const membersApi = container.get<MembersApi>(ApiKeys.Members);

    await membersApi.editMember(data);

    // reload user data to load/refresh org data cache
    const userDataCache = storage.retrieve<UserCompartments>(userStorageKey);
    userDataCache.reloadAll();
  }
}
