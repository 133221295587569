import { OrganizationData } from '../../models/OrganizationData';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ScriniumServices,
  DataCompartmentOptions,
  createObservedDataCache,
  ConfiguredDataSource,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, map } from 'rxjs';
import { UserCompartments, userStorageKey } from '../user';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationApi } from '../../api/apis/OrganizationApi';
import {
  OrganizationMetricsData,
  OrgPricebookLicenseMetrics,
} from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { TeamData } from '../../models/TeamData';
import { TeamCompartments, teamStorageKey } from '../team';

export interface OrganizationCompartments {
  organization: DataCompartmentOptions<OrganizationData | undefined>;
  allOrganizations: DataCompartmentOptions<OrganizationData[] | undefined>;
  allMetrics: DataCompartmentOptions<OrganizationMetricsData[] | undefined>;
}

export const organizationStorageKey = 'data/organization';

export class OrganizationDataCachePolicy extends DataCachePolicy<OrganizationCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.Organization)
    private readonly organizationApi: OrganizationApi,
    @inject(ApiKeys.OrgPricebookLicenseMetrics)
    private readonly orgPricebookLicenseMetricsApi: OrgPricebookLicenseMetrics,
  ) {
    super(organizationStorageKey);
  }

  buildDataCache() {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const teamDataCache = this.appStorage.retrieve<TeamCompartments>(teamStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.findCompartment('user').initialized$(),
    ]).pipe(
      map(([isAuthenticated, userInitialized]) => isAuthenticated === true && userInitialized),
    );

    const teamDependsOn = teamDataCache
      .observe$<TeamData>('team')
      .pipe(map(teamData => teamData !== undefined));

    return createObservedDataCache<OrganizationCompartments>({
      organization: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.organizationApi.get();
          if (!data || !data.id) {
            // when an org isn't selected, will return 200 with empty response
            return undefined;
          }

          const urlResult = await this.organizationApi.getUrl();
          const qrResult = this.organizationApi.getQR(data.id);
          return { ...data, ...urlResult.data, qrUri: qrResult };
        }),
        defaultValue: undefined,
      },
      allOrganizations: {
        dependsOn: teamDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.organizationApi.getAll();
            return [...data];
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allMetrics: {
        dependsOn: teamDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.orgPricebookLicenseMetricsApi.get();
            return [...data];
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withOrganizationData = createServiceModule(organizationStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, OrganizationDataCachePolicy);
});
