export interface UserData {
  id?: number;
  firstName?: string;
  lastName?: string;
  role?: string;
  email?: string;
  status?: AdminStatusEnum;
  statusTimestamp?: string;
  primaryBillingUser?: boolean;
}

export enum AdminStatusEnum {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  Removed = 'REMOVED',
}

export const sortByAdminStatus = (
  a: { status: AdminStatusEnum },
  b: { status: AdminStatusEnum },
) => {
  const statusOrder: { [key: string]: number } = {
    active: 1,
    invited: 2,
    removed: 3,
  };

  const itemA = a.status.split(' ')[0].toUpperCase();
  const itemB = b.status.split(' ')[0].toUpperCase();

  return (
    (statusOrder[itemA.toLowerCase()] || Infinity) - (statusOrder[itemB.toLowerCase()] || Infinity)
  );
};

export interface UserRole {
  role?: string;
}

export interface LoginData {
  email?: string;
  password?: string;
}
