import React, { useEffect, useMemo, useState } from 'react';
import DataTable from './DataTable';
import {
  AdminTierTypeEnum,
  DrillDownWizard,
  drillDownWizardKey,
  sortByTier,
} from '../services/drillDown';
import _, { capitalize, lowerCase } from 'lodash';
import { formatDateMMDDYYYY } from '../helpers/utilityFunctions';
import { AdminStatusEnum, UserData, sortByAdminStatus } from '../models/UserData';
import { MutatorState, useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Card, CardContent } from '@mui/material';
import Column from './Column';
import Searchbox from './Searchbox';
import Spacer from './Spacer';
import { UserCompartments, userStorageKey } from '../data/user';
import { useService } from '@aesop-fables/containr-react';

interface AdminDataTableProps {
  role: AdminTierTypeEnum;
  usersList: UserData[];
  removeAdmin: MutatorState<number>;
  hideSearch?: boolean;
  loading?: boolean;
}

const headers = [
  {
    label: 'name',
    title: 'Name',
  },
  {
    label: 'role',
    title: 'Role',
  },
  {
    label: 'email',
    title: 'Email',
  },
  {
    label: 'status',
    title: 'Status',
  },
  {
    label: '',
    title: '',
  },
];

const sortAdminTableStatus = (a: any, b: any) => {
  const statusComparison = sortByAdminStatus(a, b);
  const extractDate = (val: UserData): number => {
    return val.statusTimestamp ? new Date(val.statusTimestamp).getTime() : 0;
  };

  if (statusComparison !== 0) {
    // if diff statuses, compare by status
    return statusComparison;
  } else {
    // otherwise compare by date
    return extractDate(a) - extractDate(b);
  }
};

const AdminDataTable: React.FC<AdminDataTableProps> = ({
  role,
  usersList,
  removeAdmin,
  hideSearch,
  loading,
}) => {
  const appStorage = useAppStorage();
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const tier = useObservable(drillDownWizard.tier$);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const userData = useObservable(userDataCache.observe$<UserData>('user'));

  const [searchWord, setSearchWord] = useState('');
  const [filteredDataForTable, setFilteredDataForTable] = useState<UserData[]>([]);

  const sortedList = useMemo(() => {
    return [...usersList].sort(sortAdminTableStatus);
  }, [usersList]);

  useEffect(() => {
    if (!searchWord) {
      setFilteredDataForTable(sortedList);
      return;
    }
    setFilteredDataForTable(
      sortedList?.filter((item: any) =>
        Object.values(item).some(
          (val: any) => val?.toString().toLowerCase().includes(searchWord.toLowerCase()),
        ),
      ),
    );
  }, [searchWord, usersList, sortedList]);

  const handleAdminRemoval = async (row: any) => {
    await removeAdmin.action(row.id);
  };

  return (
    <>
      {!hideSearch && (
        <Card variant='ghost' color='primary'>
          <CardContent className='searchbox'>
            <Column>
              <Searchbox
                callback={value => setSearchWord(value)}
                label='Search'
                placeholder='Search by admin name'
              />
            </Column>
          </CardContent>
        </Card>
      )}

      <Spacer height='sm' />
      <DataTable
        role={role}
        data={
          filteredDataForTable
            ?.filter(admin => admin.status !== AdminStatusEnum.Removed)
            ?.map(admin => {
              const lowerLevelAdmin =
                tier === AdminTierTypeEnum.Root
                  ? admin?.role?.includes('ORGANIZATION')
                  : tier === AdminTierTypeEnum.Organization
                  ? admin?.role?.includes('TEAM') || admin?.role?.includes('ORGANIZATION')
                  : true;
              return {
                id: admin.id,
                name:
                  admin.id === userData?.id
                    ? `${admin.firstName} ${admin.lastName} (You)`
                    : `${admin.firstName} ${admin.lastName}`,
                role: capitalize(admin.role).replace(/_/g, ' '),
                email: admin.email,
                status: `${_.capitalize(admin.status)} as of ${formatDateMMDDYYYY(
                  admin.statusTimestamp ?? '',
                )}`,
                deletableRow: admin.id !== userData?.id && lowerLevelAdmin,
                isPinned: admin.id === userData?.id,
              };
            }) ?? []
        }
        headers={headers}
        sortBy='role'
        customSort={[
          { column: 'role', function: sortByTier },
          { column: 'status', function: sortAdminTableStatus },
        ]}
        onDelete={handleAdminRemoval}
        type={'admins'}
        emptyStateText={`You don't have any other admins ${
          role === AdminTierTypeEnum.Team ? 'on' : 'in'
        } your ${lowerCase(role)}.`}
        filtered={searchWord.length > 0}
        lastColumnIsButtons={false}
        loading={loading}
      />
    </>
  );
};

export default AdminDataTable;
