import { ApiServices } from './ApiServices';
import { ApiSettings } from './ApiSettings';
import { ApiRegistry } from './ApiRegistry';
import { IAxiosRetryActivator } from './IAxiosRetryActivator';
import { AxiosRetryActivator } from './AxiosRetryActivator';
import { AxiosInstanceSettings } from './AxiosInstanceSettings';
import { AxiosFactory, IAxiosFactory } from './AxiosFactory';
import { Interceptor, RequestInterceptor, ResponseInterceptor } from './Interceptor';
import { createServiceModuleWithOptions } from '@aesop-fables/containr';

export const useApi = createServiceModuleWithOptions<ApiSettings>('api', (services, options) => {
  services.register(ApiServices.ApiSettings, options);
  services.register(ApiServices.AxiosSettings, options.defaults ?? {});
  services.importRegistry(ApiRegistry);
});

export { ApiRegistry, ApiServices, AxiosFactory, AxiosRetryActivator };

export type {
  ApiSettings,
  AxiosInstanceSettings,
  IAxiosFactory,
  IAxiosRetryActivator,
  Interceptor,
  RequestInterceptor,
  ResponseInterceptor,
};
