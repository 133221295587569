import { HelpOutline } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/tippy.css';
import React, { useContext, useState } from 'react';
import { EditDrawer, KeyValueLabel, Row, Spacer, UploadDropZone } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { OrganizationCard } from './OrganizationInfo';
import { LogoHelpText } from '../offboarding/UploadLogo';
import { SaveOrganizationLogo } from '../../data/organization/mutations';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';

const LogoCard: React.FC<React.PropsWithChildren> = () => {
  const appStorage = useAppStorage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const orgData = useObservable(organizationDataCache.observe$<OrganizationData>('organization'));

  return (
    <OrganizationCard title={`Logo`} editable={true} setDrawerOpen={setDrawerOpen}>
      <KeyValueLabel keyString='Logo' value={orgData?.logoUri ? 'Uploaded' : 'Not Uploaded'} />
      <LogoCardEdit title='Upload logo' drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </OrganizationCard>
  );
};

interface LogoCardEditProps {
  title: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LogoCardEdit: React.FC<LogoCardEditProps> = ({ title, drawerOpen, setDrawerOpen }) => {
  const theme = useTheme();
  const [logo, setLogo] = useState<Blob | undefined>(undefined);
  const saveOrganizationLogo = useMutation(new SaveOrganizationLogo());
  const { setToast } = useContext(ToastbarContext);

  const onSubmit = async () => {
    if (!logo) {
      setToast(new Toast({ message: 'Please upload a logo', severity: 'error', open: true }));
      return;
    }
    try {
      await saveOrganizationLogo.action(logo);
      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: 'Logo saved successfully!',
          autoHideDuration: 3000,
        }),
      );
    } catch (err) {
      console.error('error saving logo:', err);
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Error saving logo. Please try again.',
        }),
      );
    }
    setDrawerOpen(false);
  };

  return (
    <>
      <EditDrawer
        onSubmit={onSubmit}
        title={title}
        smallTitle='Edit organization'
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}>
        <Spacer height='xxs' />
        <Row style={{ width: '95%', alignItems: 'center' }}>
          <Typography variant='h2' color='info.main'>
            Upload a logo to personalize your experience. You can skip if you would like.&nbsp;
            <Tippy placement='right' content={<LogoHelpText />} theme='tippy-rounded'>
              <HelpOutline
                style={{
                  color: theme.palette.info.main,
                  marginBottom: -4,
                }}
              />
            </Tippy>
          </Typography>
        </Row>
        <Spacer height='md' />
        <UploadDropZone setData={setLogo} uploadTypes={['logo']} />
      </EditDrawer>
    </>
  );
};

export default LogoCard;
