import { useEffect, useState } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { DataTable, Page, Spacer } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { AuthenticatedWizard, authenticatedWizardKey } from '../../services/authenticated';
import {
  CommunicationsData,
  DefaultCommunicationsData,
  EmailTemplate,
} from '../../models/OrganizationData';

const Communications: React.FC = () => {
  /* TODO having an issue with state getting overwritten and toggles resetting */
  const navigate = useNavigate();
  const authWizard = useService<AuthenticatedWizard>(authenticatedWizardKey);
  const authState = useObservable(authWizard.state$);
  const [state, setState] = useState<CommunicationsData>(
    authState?.communicationsData ?? DefaultCommunicationsData,
  );

  useEffect(() => {
    authWizard.setCommunications(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  function createData(templateName: string, purpose: string, status: string) {
    return { templateName, purpose, status, deletableRow: false };
  }
  const currentData = [
    createData('Invitation', 'Invite members when added', 'ON'),
    createData('Discontinue alert', 'Notify members when discontinued', 'OFF'),
  ];

  const headers = [
    {
      title: 'Template Name',
      label: 'templateName',
    },
    {
      title: 'Purpose',
      label: 'purpose',
    },
    {
      title: 'Status',
      label: 'status',
    },
  ];

  const onView = (row: any) => {
    const newRow = row as EmailTemplate;
    navigate('./template', { state: { data: newRow } });
  };

  return (
    <Page title='Communications'>
      <Spacer height='xs' />
      <Typography variant='subtitle1' color='secondary.main'>
        Communication settings
      </Typography>
      <Spacer height='sm' />
      <Card variant='ghost' color='primary'>
        <CardContent className='communications'>
          <FormControl style={{ width: '100%' }} component='fieldset' variant='standard'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color='primary'
                    checked={state.addedEmail}
                    onChange={handleChange}
                    name='addedEmail'
                  />
                }
                label='Automatically send invite when new member is added'
                labelPlacement='start'
                sx={{ justifyContent: 'space-between' }}
              />

              <Spacer height='xs' />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.discontinuedEmail}
                    onChange={handleChange}
                    name='discontinuedEmail'
                  />
                }
                label='Automatically send notification when member is discontinued'
                labelPlacement='start'
                sx={{ justifyContent: 'space-between' }}
              />
            </FormGroup>
          </FormControl>
        </CardContent>
      </Card>

      <Spacer height='lg' />
      <DataTable
        title='Email templates'
        data={currentData}
        headers={headers}
        onButton1Click={onView}
      />
    </Page>
  );
};

export default Communications;
