import { Grid } from '@mui/material';
import { Spacer } from '../../../components';
import { HelpTitle, HelpBody } from '../../../components/HelpText';
import { AnyReportType } from '../../../models/ReportData';
import { ProgressCalculator } from './ReportProgressTracker';
import { getPricebookReportsByType, sumPropertyInArray } from '../../../helpers/reportUtils';
import { ProductTypeEnum } from '../../../models/PricebookData';

export const progressTrackerGuidedChoice: ProgressCalculator = (report: AnyReportType) => {
  const reportsGc = getPricebookReportsByType(report, ProductTypeEnum.GuidedChoice);

  const totalUsers = sumPropertyInArray(reportsGc, 'activeUserCount');
  const totalCreated =
    sumPropertyInArray(reportsGc, 'accountCreationMethodAppleCount') +
    sumPropertyInArray(reportsGc, 'accountCreationMethodGoogleCount') +
    sumPropertyInArray(reportsGc, 'accountCreationMethodManualCount');
  const completed = sumPropertyInArray(reportsGc, 'onboardingCompletedCount');

  return {
    title: 'GuidedChoice onboarding',
    totalUsers: totalUsers,
    notStarted: totalCreated - completed,
    inProgress: [],
    completed: completed,
    helpText: 'GuidedChoice active users',
    helpContent: <HelpTextGc />,
  };
};

const HelpTextGc = () => {
  return (
    <Grid>
      <HelpTitle>GuidedChoice active users</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody>
        Count of users connected to this organization who have an active GuidedChoice Advisory
        Service license.
      </HelpBody>
    </Grid>
  );
};
