import { inject } from '@aesop-fables/containr';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { ApiServices } from '../ApiServices';
import { OrganizationMetadata } from '../../models/OrganizationMetadata';

export class OrganizationMetadataApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<OrganizationMetadata>> {
    return this.axios.get(`organization-metadata`);
  }

  replace(metadata: OrganizationMetadata): Promise<AxiosResponse<OrganizationMetadata>> {
    return this.axios.put('organization-metadata', metadata);
  }

  post(metadata: OrganizationMetadata): Promise<AxiosResponse<OrganizationMetadata>> {
    return this.axios.post('organization-metadata', metadata);
  }

  delete(): Promise<AxiosResponse<OrganizationMetadata>> {
    return this.axios.delete(`organization-metadata`);
  }
}
