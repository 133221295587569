import { Card, CardContent, Link, Typography } from '@mui/material';
import { Page, Spacer } from '../../components';
import { useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { capitalize } from 'lodash';
import AboutYourGroupCard from '../group/AboutYourGroupCard';
import TeamMetricsCard from '../team/TeamMetricsCard';
import GroupMetricsCard from '../group/GroupMetricsCard';
import AboutYourTeamCard from '../team/AboutYourTeamCard';

const RoleInfo = () => {
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const tier = useObservable(drillDownWizard.tier$);

  type RoleInfo = {
    role: string;
    aboutCard: JSX.Element;
    metricsCard: JSX.Element;
  };

  const determineRoleAndComponents = (tier: string | undefined): RoleInfo | undefined => {
    switch (tier) {
      case AdminTierTypeEnum.Team:
        return {
          role: 'group',
          aboutCard: <AboutYourGroupCard />,
          metricsCard: <GroupMetricsCard />,
        };
      case AdminTierTypeEnum.Organization:
        return {
          role: 'team',
          aboutCard: <AboutYourTeamCard />,
          metricsCard: <TeamMetricsCard />,
        };
    }
    return undefined;
  };

  const displayInfo = determineRoleAndComponents(tier);

  return (
    <Page title={`${capitalize(displayInfo?.role)} info`}>
      <Spacer height='xs' />
      {displayInfo?.aboutCard}
      <Spacer height='xs' />
      {displayInfo?.metricsCard}
    </Page>
  );
};

interface RoleInfoCardProps extends React.PropsWithChildren {
  title?: string;
  editable: boolean;
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onEdit?: () => void;
}

export const RoleInfoCard: React.FC<RoleInfoCardProps> = props => {
  const { title, editable, setDrawerOpen, onEdit } = props;

  const onEditClick = () => {
    if (onEdit) {
      onEdit();
      return;
    }
    if (setDrawerOpen) {
      setDrawerOpen(true);
      return;
    }
  };
  return (
    <>
      {title && (
        <>
          <Typography variant='subtitle1' color='secondary.main'>
            {title}
          </Typography>
          <Spacer height='xs' />
        </>
      )}
      <Card variant='ghost' color='primary'>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          className={editable ? `profileCard` : `pad30`}>
          {editable ? (
            <>
              <Link onClick={onEditClick} style={{ alignSelf: 'flex-end' }} variant='body2'>
                Edit
              </Link>
              <Spacer height='xxs' />
            </>
          ) : null}
          {props.children}
        </CardContent>
      </Card>
    </>
  );
};

export default RoleInfo;
