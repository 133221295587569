import { useState, useEffect, useCallback, useRef } from 'react';

interface UseInfiniteScrollResult<T> {
  displayedItems: T[];
  hasMore: boolean;
  lastElementRef: (node: any) => void;
}

export function useInfiniteScroll<T>(
  allItems: T[],
  isEnabled: boolean = true,
  itemsPerLoad: number = 5,
): UseInfiniteScrollResult<T> {
  const [displayedItems, setDisplayedItems] = useState<T[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMore) {
            loadMoreItems();
          }
        },
        { rootMargin: '0px 0px 400px 0px' }, // trigger load 400px before the last element
      );

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore],
  );

  const loadMoreItems = useCallback(() => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);

    setDisplayedItems(prev => {
      const newItems = allItems.slice(0, prev.length + itemsPerLoad);

      setHasMore(newItems.length < allItems.length);
      setIsLoading(false);

      return newItems;
    });
  }, [allItems, itemsPerLoad, isLoading, hasMore]);

  useEffect(() => {
    if (!isEnabled) {
      setDisplayedItems([]);
      setHasMore(false);
      return;
    }

    setDisplayedItems(allItems.slice(0, itemsPerLoad));
    setHasMore(allItems.length > itemsPerLoad);
  }, [allItems, isEnabled, itemsPerLoad]);

  return { displayedItems, hasMore, lastElementRef };
}
