import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, map } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { GroupData } from '../../models/GroupData';
import { GroupCompartments, groupStorageKey } from '../group';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { GroupFeatureMapApi } from '../../api/apis/GroupFeatureMapApi';

export interface GroupFeatureMapCompartments {
  groupFeatureMap: DataCompartmentOptions<GroupFeatureMapData | undefined>;
}

export const groupFeatureMapStorageKey = 'data/groupFeatureMap';

export class GroupFeatureMapDataCachePolicy extends DataCachePolicy<GroupFeatureMapCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.GroupFeatureMap)
    private readonly groupFeatureMapApi: GroupFeatureMapApi,
  ) {
    super(groupFeatureMapStorageKey);
  }

  buildDataCache() {
    const groupDataCache = this.appStorage.retrieve<GroupCompartments>(groupStorageKey);

    const dependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      groupDataCache.observe$<GroupData>('group'),
    ]).pipe(
      map(([isAuthenticated, groupData]) => {
        return isAuthenticated === true && groupData !== undefined;
      }),
    );

    return createObservedDataCache<GroupFeatureMapCompartments>({
      groupFeatureMap: {
        dependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const { data } = await this.groupFeatureMapApi.get();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withGroupFeatureMapData = createServiceModule(groupFeatureMapStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, GroupFeatureMapDataCachePolicy);
});
