import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class MembersApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getMembers(): Promise<AxiosResponse<MemberData[]>> {
    return this.axios.get('members');
  }

  addAMember(member: any): Promise<AxiosResponse<MemberData[]>> {
    return this.axios.post('members/add', { ...member });
  }

  removeMember(id: number): Promise<AxiosResponse> {
    return this.axios.delete(`members/${id}`);
  }

  editMember(member: any): Promise<AxiosResponse<MemberData>> {
    return this.axios.patch(`members`, { ...member });
  }

  bulkAddMembers(data: FormData): Promise<AxiosResponse<RowData>> {
    return this.axios.post(`members/by-file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  bulkRemoveMembers(data: FormData): Promise<AxiosResponse> {
    return this.axios.put(`members/by-file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  searchMembers(text: string): Promise<AxiosResponse<MemberData[]>> {
    return this.axios.get('members/search', {
      params: { text: text },
    });
  }

  exportMembers(): Promise<AxiosResponse<Blob>> {
    return this.axios.get('members/all/by-file', { responseType: 'blob' });
  }
}

export interface RowData {
  invalidRowSize: number;
  invalidRows: MemberData[];
  validRowSize: number;
  validRows: MemberData[];
  persistedRowSize: number;
}

export interface MemberData {
  id: number;
  accountId: number;
  organizationId: number;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  statusTimestamp: string;
}
