import type { IAppStorage } from '@aesop-fables/scrinium';
import { ScriniumServices } from '@aesop-fables/scrinium';
import { inject, createServiceModule } from '@aesop-fables/containr';
import { BehaviorSubject, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';
import { TemplateData } from '../../models/SignUpData';
import {
  InitialOffboardingState,
  OffboardingWizardState,
  OffboardingStepData,
  OffboardingStepsDefault,
  ToDoListData,
  DefaultToDoList,
  MemberPaysToDoList,
  MemberPaysOffboardingSteps,
  OffboardingStepsAdp,
  AdpToDoList,
} from '../../models/OffboardingData';
import { OffboardingCompartments, offboardingStorageKey } from '../../data/offboarding';
import { Location } from 'react-router-dom';

export const offboardingWizardKey = 'services/offboardingWizard';

export enum OffboardingTemplateIdEnum {
  'MemberPays' = 1,
  'OrgPays' = 2,
  'Adp' = 6,
}

const getOffboardingSteps = (template: number): OffboardingStepData[] => {
  switch (template) {
    case OffboardingTemplateIdEnum.OrgPays:
      return OffboardingStepsDefault;
    case OffboardingTemplateIdEnum.MemberPays:
      return MemberPaysOffboardingSteps;
    case OffboardingTemplateIdEnum.Adp:
      return OffboardingStepsAdp;
    default:
      return OffboardingStepsDefault;
  }
};

const getToDoList = (template: number): ToDoListData[] => {
  switch (template) {
    case OffboardingTemplateIdEnum.MemberPays:
      return MemberPaysToDoList;
    case OffboardingTemplateIdEnum.Adp:
      return AdpToDoList;
    default:
      return DefaultToDoList;
  }
};

class OffboardingWizard implements Disposable {
  private readonly state: BehaviorSubject<OffboardingWizardState> = new BehaviorSubject(
    InitialOffboardingState,
  );
  private readonly offboardingComplete: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private offboardingSteps: OffboardingStepData[] = OffboardingStepsDefault;
  private todoList: BehaviorSubject<ToDoListData[]> = new BehaviorSubject<ToDoListData[]>([]);

  constructor(
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
  ) {
    this.init();
  }

  async init() {
    try {
      const offboardingDataCache =
        this.appStorage.retrieve<OffboardingCompartments>(offboardingStorageKey);
      offboardingDataCache.observe$<TemplateData>('offboardingTemplate').subscribe(template => {
        if (template) {
          this.offboardingSteps = getOffboardingSteps(template.id);
          this.todoList.next(getToDoList(template.id));
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  get state$(): Observable<OffboardingWizardState> {
    return this.state;
  }
  get offboardingComplete$(): BehaviorSubject<boolean> {
    return this.offboardingComplete;
  }

  offboardingCompleted() {
    this.offboardingComplete.next(true);
  }
  get todoList$(): Observable<ToDoListData[]> {
    return this.todoList;
  }
  resumeOffboarding(lastCompletedStep: string): OffboardingStepData {
    const index = this.offboardingSteps.findIndex(s => s.current === lastCompletedStep);
    const step = this.offboardingSteps.at(index + 1);
    return (
      step ??
      this.offboardingSteps.at(0) ?? {
        current: '/',
        route: '/uploadLogo',
        totalSteps: 3,
        activeStep: 0,
      }
    );
  }
  nextStep(location: Location): OffboardingStepData {
    const current = location.pathname;
    const index = this.offboardingSteps.findIndex(s => s.current === current);
    const step = this.offboardingSteps.at(index + 1);
    return (
      step ??
      this.offboardingSteps.at(0) ?? {
        current: '/',
        route: '/uploadLogo',
        totalSteps: 3,
        activeStep: 0,
      }
    );
  }
  // nextStep(current: string): string {
  //   return this.offboardingSteps.find(step => step.current === current)?.next ?? '/';
  // }
  getStepInfo(current: string): { activeStep: number; totalSteps: number } {
    const currentStep = this.offboardingSteps.find(step => step.current === current) ?? {
      totalSteps: 0,
      activeStep: 0,
    };
    const totalSteps = currentStep?.totalSteps;
    const activeStep = currentStep?.activeStep;
    return { activeStep: activeStep, totalSteps: totalSteps };
  }
  // start(origin: string) {
  //   if (origin === 'dashboard') {
  //     this.offboardingSteps = getOffboardingStepsDashboard(this.templateId);
  //     return;
  //   }
  //   if (origin === 'profile') {
  //     this.offboardingSteps = getOffboardingStepsProfile(this.templateId);
  //     return;
  //   }
  // }

  dispose(): void {
    console.log('disposing OffboardingWizard');
  }
}

export const withOffboardingWizard = createServiceModule(offboardingWizardKey, services => {
  services.use<OffboardingWizard>(offboardingWizardKey, OffboardingWizard);
});

export { OffboardingWizard };
