import { TeamData } from './../../models/TeamData';
import { DataCachePolicy, IDataCachePolicy, Policies } from '../IDataCachePolicy';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  ScriniumServices,
  createObservedDataCache,
} from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { combineLatest, map } from 'rxjs';
import { UserRole } from '../../models/UserData';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { TeamCompartments, teamStorageKey } from '../team';
import { GroupData } from '../../models/GroupData';
import { UserCompartments, userStorageKey } from '../user';
import { GroupCompartments, groupStorageKey } from '../group';
import { TeamMetricsApi } from '../../api/apis/TeamMetricsApi';
import { GroupMetricsData, TeamMetricsData } from '../../models/MetricsData';
import { GroupMetricsApi } from '../../api/apis/GroupMetricsApi';

export interface MetricsCompartments {
  groupMetrics: DataCompartmentOptions<GroupMetricsData | undefined>;
  teamMetrics: DataCompartmentOptions<TeamMetricsData | undefined>;
}

export const metricsStorageKey = 'data/metrics';

export class MetricsDataCachePolicy extends DataCachePolicy<MetricsCompartments> {
  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.GroupMetrics)
    private readonly groupMetricsApi: GroupMetricsApi,
    @inject(ApiKeys.TeamMetrics)
    private readonly teamMetricsApi: TeamMetricsApi,
  ) {
    super(metricsStorageKey);
  }

  buildDataCache() {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const groupDataCache = this.appStorage.retrieve<GroupCompartments>(groupStorageKey);
    const teamDataCache = this.appStorage.retrieve<TeamCompartments>(teamStorageKey);

    const groupDependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.observe$<UserRole[]>('userRoles'),
      groupDataCache.observe$<GroupData>('group'),
    ]).pipe(
      map(([isAuthenticated, roles, groupData]) => {
        return isAuthenticated === true && roles !== undefined && groupData !== undefined;
      }),
    );

    const teamDependsOn = combineLatest([
      this.authContext.isAuthenticated$,
      userDataCache.observe$<UserRole[]>('userRoles'),
      teamDataCache.observe$<TeamData>('team'),
      groupDataCache.observe$<GroupData>('group'),
    ]).pipe(
      map(
        ([isAuthenticated, roles, teamData, groupData]) =>
          isAuthenticated === true &&
          roles !== undefined &&
          teamData !== undefined &&
          groupData !== undefined,
      ),
    );

    return createObservedDataCache<MetricsCompartments>({
      groupMetrics: {
        dependsOn: groupDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            if (roles.some(x => x.role?.includes(`GROUP_ADMIN_${group.id}`))) {
              const { data } = await this.groupMetricsApi.get();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      teamMetrics: {
        dependsOn: teamDependsOn,
        unsubscribe: false,
        source: new ConfiguredDataSource(async () => {
          try {
            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            const team = (
              teamDataCache.findCompartment('team') as DataCompartment<TeamData>
            ).getData() as TeamData;
            if (
              roles.some(
                x =>
                  x.role?.includes(`TEAM_ADMIN_${team.id}`) ||
                  x.role?.includes(`GROUP_ADMIN_${group.id}`),
              )
            ) {
              const { data } = await this.teamMetricsApi.get();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });
  }
}

export const withMetricsData = createServiceModule(metricsStorageKey, services => {
  services.add<IDataCachePolicy>(Policies, MetricsDataCachePolicy);
});
