import { Button, Typography } from '@mui/material';
import { Svgs } from '../assets/svg';
import { Spacer } from '.';

interface DownloadButtonProps {
  onDownload: () => void;
  style?: {};
  textVariant?: any;
}
const DownloadButton: React.FC<DownloadButtonProps> = ({ onDownload, style, textVariant }) => {
  return (
    <Button sx={{ padding: '5px 20px 5px 10px', ...style }} color='secondary' onClick={onDownload}>
      <Svgs.IconDownload width='20px' />
      <Spacer width='xxxs' />
      <Typography sx={{ lineHeight: '1' }} variant={textVariant ?? 'p12'}>
        Download
      </Typography>
    </Button>
  );
};

export default DownloadButton;
