import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class InvoiceApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<InvoiceData[]>> {
    return this.axios.get('invoice/all');
  }
}

export interface InvoiceData {
  id: number;
  invoiceId: string;
  subscriptionId: number;
  amount: number;
  createdTimestamp: string;
  invoiceUrl: string;
  status: InvoiceStatusEnum;
}

export enum InvoiceStatusEnum {
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export const sortByInvoiceStatus = (
  a: { status: InvoiceStatusEnum },
  b: { status: InvoiceStatusEnum },
) => {
  const statusOrder: { [key: string]: number } = {
    [InvoiceStatusEnum.Open]: 1,
    [InvoiceStatusEnum.Paid]: 2,
    [InvoiceStatusEnum.Uncollectible]: 3,
    [InvoiceStatusEnum.Void]: 4,
  };

  return (
    (statusOrder[a.status.toLowerCase()] || Infinity) -
    (statusOrder[b.status.toLowerCase()] || Infinity)
  );
};
